import React, {useEffect, useState, useRef} from 'react';
import classes from './inviteModal.module.scss';
import {makeStyles} from "@material-ui/styles";
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import * as Config from '../../Config/config'
import * as Actions from '../../../src/store/actions'
import {useSelector, useDispatch} from 'react-redux';
import * as service from "../../Services/apiService";
import PhoneInput from 'react-phone-input-2';
import '../../countryFlag.css';
import moment from "moment";
import RegularButtons from "../../components/HoolvaButtons/RegularButtons/regularButtons";
// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    bgColor:{
        backgroundColor: theme.background,
        color: theme.textColor1
    },
    border:{
        border: "1px solid " + theme.modalBorderColor,
    },
    inputText: {
        backgroundColor: theme.textboxBGColor,
        border: '1px solid ' + theme.textboxBorderColor,
        color: theme.textboxTextColor,
        "&:hover, &:focus, &:active": {
            border: "1px solid "+ theme.textboxActiveColor,
        },
        '&::placeholder': {
            color:theme.textboxPlaceholderColor
        }
    },
    borderBottom:{
        borderBottom: "1px solid " + theme.dropdownBorderColor,
    },
    btnPrimary: {
        background: theme.button1BGColor,
        border: "1px solid "+ theme.button1BorderColor,
        color: theme.button1TextColor,
        "&:hover, &:focus": {
            background: theme.button1HoverBGColor
        }
    },
    btnSecondary: {
        background: theme.button2BGColor,
        border: "1px solid "+ theme.button2BorderColor,
        color: theme.button2TextColor,
        "&:hover, &:focus": {
            color: theme.button2HoverTextColor
        }
    },

    btnTernary: {
        background: theme.button3BGColor,
        border: "1px solid "+ theme.button3BGColor,
        color: theme.button3TextColor,
        "&:hover, &:focus": {
            border: "1px solid "+ theme.button3HoverBorderColor,
        }
    },tab:{
        backgroundColor:theme.modalBorderColor,
        color: theme.textColor3,
        '&:hover':{
            color: theme.textColor2
        }
    }
}))
const InviteModal = (props: any) => {
    const { modalOpen, setInviteModal, password, meetingInfo, t } = props;
    const {API_ROOT, displayName} = useSelector((state:any)=>state.Hoolva);
    const {publisherHandle, didsList}  = useSelector((state:any)=>state.Meet);
    const {MeetingInvite} = useSelector((state: any) => state.Schedule);
    const {loggedInData}  = useSelector((state:any)=>state.Auth);
    const dispatch = useDispatch();
    const theme = useStyles(props);
    const [inviteText, setInviteText] = useState('');
    const [validInvite, setValidInvite] = useState([]);
    const [inviteUrl, setInviteUrl] = useState('');
    const [copyUrl, setCopyUrl] = useState(false);
    const [meetingInvite, setMeetingInvite] = useState('');
    const [copyInvite, setCopyInvite] = useState(false);
    const [activeTab, setActiveTab] = useState(2);
    const textInput = useRef(null);
    // const flag = useRef(null);
    // const flag1 = useRef(null);
    // const flag2 = useRef(null);
    const [validation, setValidation] = useState(false);
    const [apiWaiting, setApiWaiting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [countryPhoneCode, setCountryPhoneCode] = useState('+1');
    const [countryPhoneCode1, setCountryPhoneCode1] = useState('+1');
    const [countryPhoneCode2, setCountryPhoneCode2] = useState('+1');
    const [countryCode, setCountryCode] = useState(["us","in"]);
    // const [phoneNumber, setPhoneNumber] = useState('');
    // const [phoneNumber1, setPhoneNumber1] = useState('');
    // const [phoneNumber2, setPhoneNumber2] = useState('');
    // const [countryPhone, setCountryPhone] = useState([{"name":"Canada","phone":"+16478462025"},{"name":"IN","phone":"+914842867440"},{"name":"USA","phone":"+16462174820"},{"name":"United Kingdom","phone":"+443339988878"}]);
    // const [selectedPhoneCountry, SetelectedPhoneCountry] = useState('USA');
    // const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("+16462174820");
    //console.log('loggedInData', loggedInData);


    const [defaultIso, setDefaultIso] = useState('us');
    // const [countryPhoneCode, setCountryPhoneCode] = useState('');
    // const [countryPhoneCode, setCountryPhoneCode] = useState('');

    useEffect(() => {
        getMeetingInvite();
    }, [MeetingInvite])
    useEffect(() => {
        if(modalOpen){
            loadInvite();
        }
    }, [modalOpen])
    const loadInvite = () => {
        let data = {
            "meeting_uuid" :meetingInfo.uuid,
        }
        dispatch(Actions.getMeetingInvite(data));
    }
    const handleInviteChange = (value: string) => {
        setInviteText(value);
        const text = value.trim();
        const inviteIds = text.length > 0 ? value.split(/[ ,;\r?\n]+/) : [];
        // @ts-ignore
        const valid = [];
        // @ts-ignore
        const inValid = [];
        inviteIds.map(ids => {
            if(validateEmailOrPhoneNumber(ids)) {
                valid.push(ids);
            } else {
                inValid.push(ids);
            }
        });
        setValidation(inValid.length===0? true:false);
    }
    const invitePhone = () =>{
        // @ts-ignore
        const valid = [];
        if(countryPhoneCode.length >= 10){
            // console.log("countryPhoneCode",countryPhoneCode);
            // console.log("countryPhoneCode",countryPhoneCode1);
            // console.log("countryPhoneCode",countryPhoneCode2);
            valid.push('+' + countryPhoneCode);
        }if(countryPhoneCode1.length >= 10){
            valid.push('+' + countryPhoneCode1);
        }if(countryPhoneCode2.length >= 10){
            valid.push('+' + countryPhoneCode2);
        }
        // @ts-ignore
        setApiWaiting(true);
        console.log("validvalidvalidvalid",valid);
        const formattedMessage = { "event" : 'callback_request', destination_numbers : valid};
        if (publisherHandle) {
            publisherHandle.send({ message: formattedMessage });
            setApiWaiting(false);
            setInviteText('');
            handleCopy('sending');
            setCountryPhoneCode('+1');
            setCountryPhoneCode1('+1');
            setCountryPhoneCode2('+1');
        }
    }
    const handlePhoneNumber = (Phone: string, flag:number) =>{
        if(flag === 1){
            setCountryPhoneCode(Phone);
        }else if(flag === 2){
            setCountryPhoneCode1(Phone);
        }else{
            setCountryPhoneCode2(Phone);
        }
        if(validateEmailOrPhoneNumber(countryPhoneCode) || validateEmailOrPhoneNumber(countryPhoneCode1) || validateEmailOrPhoneNumber(countryPhoneCode2)){
            setValidation(true);
        }else{
            setValidation(false);
        }
    }
    // const handleCountry = (Code: string) => {
    //     // @ts-ignore
    //     setCountryPhoneCode(countryCode[Code]);
    //     // setCountryPhoneC(Code);
    //     // @ts-ignore
    //     console.log("countryCode",countryCode[Code]);
    //     // @ts-ignore
    //     // setCallbackNumber(countryCode[Code]+phoneNumber);
    // }
    // const handleCountry1 = (Code: string) => {
    //     // @ts-ignore
    //     setCountryPhoneCode1(countryCode[Code]);
    //     console.log("countryCode1",Code);
    //     setCountryPhoneC1(Code);
    //     // @ts-ignore
    //     console.log("countryCode1",countryCode[Code]);
    //     // @ts-ignore
    //     // setCallbackNumber(countryCode[Code]+phoneNumber);
    // }
    // const handleCountry2 = (Code: string) => {
    //     // @ts-ignore
    //     setCountryPhoneCode2(countryCode[Code]);
    //     setCountryPhoneC2(Code);
    //     // @ts-ignore
    //     console.log("countryCode2",countryCode[Code]);
    //     // @ts-ignore
    //     // setCallbackNumber(countryCode[Code]+phoneNumber);
    // }
    // const ChangePhoneNumber = (value: string) => {
    //     setPhoneNumber(value.replace(/\D/,''));
    //     // setCallbackNumber(countryPhoneCode+value);
    // }
    const handleCopy = (type: string) => {
        if(type === 'url') {
            setCopyUrl(true);
        }else if(type === 'sending') {
            setSuccess(true);
        } else {
            setCopyInvite(true);
        }

        const timer = setTimeout(() => {
            if(type === 'url') {
                setCopyUrl(false);
            }else if(type === 'sending') {
                setSuccess(false);
                setValidation(false);
            } else {
                setCopyInvite(false);
            }
            if(timer) {
                clearTimeout(timer);
            }
        }, 3000)
    }
    const closeModal = () => {
        setInviteText('');
        setValidInvite([]);
        setInviteModal(false);
    }
    const handleTabSwitch = (id: number) => {
        setActiveTab(id);
        setInviteText('');
        // setValidation(true);
        // if(id === 1){
        //     setValidation(false);
        // }
        // @ts-ignore
        // textInput.current.focus();
    }
    const removeInviteId = (id: any) => {
        let invites = [...validInvite];
        invites = invites.filter(item => item !== id)
        setValidInvite(invites);
    }
    const getMeetingInvite = () => {
        if(MeetingInvite){
            let DialIn = '';
            let DialByLoc = '';
            if(MeetingInvite?.did_info){
                DialIn = MeetingInvite.did_info.length === 0 ? "":"One tap mobile\n";
                DialByLoc = MeetingInvite.did_info.length === 0 ? "":"Dial by your location\n";
                MeetingInvite.did_info.sort((a:any, b:any) => a.country > b.country ? 1:-1).map((node:any) => {
                    DialIn += ('    tel:'+node.did+',,'+MeetingInvite.meeting_id+'#'+ (MeetingInvite.pin ? ',,' + MeetingInvite.pin + '# ' : ' ') + node.country+'\n');
                    DialByLoc += ('    '+node.did+' '+node.country+'\n')
                })
            }
            setInviteUrl(MeetingInvite.meeting_url);
            if (meetingInfo.dial_in !== true) {
                DialIn = '';
                DialByLoc = '';
            }
let Invite = `${MeetingInvite.host} is inviting you to join a ${Config.configuration.brandName
} Meeting

Meeting URL: ${MeetingInvite.meeting_url}

Meeting Topic: ${MeetingInvite.meeting_topic}

Meeting ID: ${MeetingInvite.meeting_id}`;
if (MeetingInvite.password) {
Invite = Invite + `

Password: ${MeetingInvite.password}`;
}
if (MeetingInvite.pin && meetingInfo.dial_in === true) {
Invite = Invite + `

Phone PIN: ${MeetingInvite.pin}`;
}

Invite = Invite + `

${MeetingInvite.date_time ? "Date & Time: " + MeetingInvite.date_time : ""}`;

if (DialIn != '') {
    Invite = Invite + `
    
${DialIn}`;
}
if (DialByLoc != '') {
    Invite = Invite + `
    
${DialByLoc}`;
}
          setMeetingInvite(Invite);
        }
        
      };
    const validate = async () => {
        const text = inviteText.trim();
        const inviteIds = text.length > 0 ? inviteText.split(/[ ,;\r?\n]+/) : [];
        // @ts-ignore
        const valid = [];
        // @ts-ignore
        const inValid = [];
        inviteIds.map(ids => {
            if(validateEmailOrPhoneNumber(ids)) {
                if(activeTab === 1){
                    valid.push(ids);
                }else{
                    valid.push(ids);
                }
            } else {
                inValid.push(ids);
            }
        });

        // @ts-ignore
        setValidInvite([...validInvite, ...valid]);
        // @ts-ignore
        let wrongId = '';
        // @ts-ignore
        inValid.map((id, index) => {
            if(index === inValid.length -1) {
                wrongId = wrongId + '' +id
            } else {
                wrongId = wrongId + '' +id + ',';
            }
        })

        if(inValid.length === 0 && (valid.length > 0 || validInvite.length > 0)) {
            // @ts-ignore
            setApiWaiting(true);
            if(activeTab === 2){
                const InviteInfo = await service.sendEmail(API_ROOT, {
                    // @ts-ignore
                    emails: valid,
                    subject:`Join ${Config.configuration.brandName} Discussion`,
                    message:meetingInvite ,
                    from_firstname: loggedInData?.firstname,
                    from_lastname: loggedInData?.lastname,
                    reply_to: loggedInData?.email})
                console.log('InviteInfo', InviteInfo);
                // @ts-ignore
                if(InviteInfo.status === 204 || InviteInfo.status === 200) {
                    setApiWaiting(false);
                    setInviteText('');
                    handleCopy('sending');

                }else{
                    setApiWaiting(false);
                    setInviteText('');
                }
            } else {
                // @ts-ignore
                const formattedMessage = { "event" : 'callback_request', destination_numbers : valid};
                if (publisherHandle) {
                    publisherHandle.send({ message: formattedMessage });
                    setApiWaiting(false);
                    setInviteText('');
                    handleCopy('sending');
                }
            }

        }


    }
    const validateEmailOrPhoneNumber = (value: string) => {
        if(activeTab ===1){
            const phoneRegx = /^\+?(0|[1-9]\d*)$/;
            const prefix = /^\+?\d+$/;
            if(phoneRegx.test(value) && prefix.test(value) && value.length >= 8) {
                return true;
            }
        }else{
            const emailRegx = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;
            if (emailRegx.test(value)){
                return true;
            }
        }
        return false;
    }
    return (
        <div>
            {modalOpen ?
                <div>
                    <div className={cx(classes.modalBG)}>
                    </div>
                    <div className={cx(classes.modal)}>
                        <div className={cx(classes.content,theme.bgColor, theme.border)}>
                            <div className={cx(classes.header,theme.bgColor, theme.borderBottom)}>
                                <div className={cx(classes.padding8)}>
                                    <FontAwesomeIcon onClick={() => closeModal()} className={cx(classes.close)} icon={['fas', 'times']} />
                                    {t('meetingPage.inviteModal.title')}
                                </div>
                            </div>
                            <div className={cx(classes.tabs)}>
                                <div onClick={()=>handleTabSwitch(2)} className={cx(classes.tab,theme.tab,activeTab === 2 ? classes.active: '')}>
                                    <div className={cx(classes.titleWithBorder)}>
                                        {t('meetingPage.inviteModal.TabEmail')}
                                    </div>
                                </div>
                                {meetingInfo.dial_out === true && !meetingInfo.e2ee ? 
                                    <div onClick={()=>handleTabSwitch(1)} className={cx(classes.tab,theme.tab,activeTab === 1 ? classes.active: '')}>
                                        <div className={cx(classes.titleWithBorder)}>
                                            {t('meetingPage.inviteModal.TabPhone')}
                                        </div>
                                    </div>
                                :null}
                                <div  className={cx(classes.tab,theme.tab)} style={{width:'200px', cursor:'default'}}>
                                    <div className={cx(classes.title)}>

                                    </div>
                                </div>
                            </div>
                            <div className={cx(classes.body,theme.bgColor)}>
                                <div className={cx(classes.left)}>
                                    {activeTab === 2 ?
                                    <div>
                                        <textarea ref={textInput} name="Text1" placeholder={t('meetingPage.inviteModal.inviteTextAreaEmailPlaceHolder')}
                                              className={cx(classes.textarea,theme.inputText, validation ? '':classes.validationFailed)} onChange={(event) =>handleInviteChange(event.target.value)}  value={inviteText}></textarea>
                                    </div>
                                    :<div className={cx(classes.flexRow)}>
                                        <div className={cx(classes.flex)}>
                                        <PhoneInput
                                        countryCodeEditable={false}
                            country={defaultIso}
                            placeholder="Type your phone number"
                            value={countryPhoneCode}
                            // autoFocus={true}
                            onlyCountries={[...countryCode]}
                            onChange={phone => handlePhoneNumber(phone, 1)}
                        />
                                        </div>
                                        <div className={cx(classes.flex)}>
                                        <PhoneInput
                                        countryCodeEditable={false}
                            country={defaultIso}
                            placeholder="Type your phone number"
                            value={countryPhoneCode1}
                            onlyCountries={[...countryCode]}
                            onChange={phone => handlePhoneNumber(phone, 2)}
                        />
                                        </div>
                                        <div className={cx(classes.flex)}>
                                        <PhoneInput
                                        countryCodeEditable={false}
                            country={defaultIso}
                            placeholder="Type your phone number"
                            value={countryPhoneCode2}
                            onlyCountries={[...countryCode]}
                            onChange={phone => handlePhoneNumber(phone, 3)}
                        />
                                        </div>
                                    </div>}

                                    <div className={cx( classes.bottomButtons)}>
                                        <div className={cx(classes.space)}>
                                            <RegularButtons index={1} clicked={() => closeModal()} classname={[theme.btnTernary]} title={t('meetingPage.inviteModal.cancelButtonLabel')} />
                                        </div>
                                        <div className={cx(classes.space)}>
                                            <RegularButtons index={2} classname={[theme.btnPrimary,!validation || (activeTab === 2 && inviteText === '') || apiWaiting ? classes.disable:null]} disabled={!validation || apiWaiting} clicked={activeTab === 2 ? () => validate():() => invitePhone()} title={apiWaiting ? success  ? t('meetingPage.inviteModal.inviteSuccessButtonLabel') : t('meetingPage.inviteModal.invitingButtonLabel'): success  ? t('meetingPage.inviteModal.inviteSuccessButtonLabel'): t('meetingPage.inviteModal.inviteButtonLabel')} />
                                        </div>
                                    </div>
                                </div>
                                <div className={cx(classes.right)}>
                                {activeTab === 1 ? (
                                    <label className={cx(classes.aleft)}>
                                        {t('meetingPage.inviteModal.phoneTabRightLabel')}
                                    </label>
                                    ):( <div>
                                    <CopyToClipboard
                                        text={inviteUrl}
                                        onCopy={() => handleCopy('url')}
                                        options={{ format: 'text/plain' }}
                                    >
                                    <div>
                                        <RegularButtons index={1} classname={[theme.btnTernary, classes.spacing]} title={copyUrl ? t('meetingPage.inviteModal.copyStatus'):t('meetingPage.inviteModal.copyMeetingUrl')} />
                                    </div>
                                    </CopyToClipboard>
                                    {/* {copyUrl ? <span>{t('meetingPage.inviteModal.copyStatus')}</span> : null} */}
                                    <CopyToClipboard
                                        text={meetingInvite}
                                        onCopy={() => handleCopy('invite')}
                                        options={{ format: 'text/plain' }}
                                    >
                                    <div>

                                        <RegularButtons index={2} classname={[theme.btnTernary, classes.spacing]} title={copyInvite ? t('meetingPage.inviteModal.copyStatus'):t('meetingPage.inviteModal.copyMeetingInvite')} />

                                    </div>
                                    </CopyToClipboard>
                                    {/* {copyInvite ? <span>{t('meetingPage.inviteModal.copyStatus')}</span> : null} */}
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            : null }
        </div>

    );
};

export default InviteModal;
