import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import cx from "classnames";
import classes from "./hoolvaLayout.module.scss";
import Routes from "../Routes";
import routes from '../routes.json'
import * as Actions from "../store/actions";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HoolvaAlertMessage from "../components/HoolvaAlertMessage/hoolvaAlertMessage";
import { useLocation } from "react-router";
import { history } from "../App";
import LocalDb from "../../src/Services/dbService";
import HoolvaUtils from "../Services/HoolvaUtils";
import { debounce } from "lodash";
import { endMeeting } from "../store/actions";
// @ts-ignore
const useStyles = makeStyles(({ theme }) => ({
    main: {
        backgroundColor: theme.background,
        color: theme.textColor1
    },
    offline: {
        backgroundColor: theme.danger
    },
    online: {
        backgroundColor: theme.success
    },
    activeBox: {
        color: theme.button1BGColor + ' !important'
    }


}))
const HoolvaLayout = ({ t }: any, props: any) => {
    const theme = useStyles(props);
    const location = useLocation();
    const pathname = location.pathname;
    const dispatch = useDispatch();
    const [isDisconnected, setIsDisconnected] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmTitle, setConfirmTitle] = useState('');
    const [confirmMessage, setConfirmMessage] = useState('');
    const {ThemeLiteJSON, ThemeDarkJSON, tenantInfoJSON} = useSelector((state:any)=>state.Settings);
    const { loggedIn, browserCheck, dbStore, meetingList, displayTheme, userPermissionEntity } = useSelector((state: any) => state.Auth);
    const { hoolva } = useSelector((state: any) => state.Meet);
    const [PermissionSchedule, setPermissionSchedule] = useState(false);
    useEffect(() => {
        setPermissionSchedule(HoolvaUtils.checkUserPermission('meeting', 'meeting.put', userPermissionEntity));
        console.log("getTenantInfo ThemeLiteJSON", ThemeLiteJSON, ThemeDarkJSON)
        Object.keys(ThemeLiteJSON).map((variable: any) => {
            document.documentElement.style.setProperty(
                '--lite' + variable,
                ThemeLiteJSON[variable]
            );
            // console.log('THEME DATA LITE','--lite' + variable,ThemeLiteJSON[variable])
        });
        Object.keys(ThemeDarkJSON).map((variable: any) => {
            document.documentElement.style.setProperty(
                '--dark' + variable,
                ThemeDarkJSON[variable]
            );
            // console.log('THEME DATA DARK','--' + variable,ThemeDarkJSON[variable])
        });

        console.log('location.search', location.search, pathname);
        // const params = QueryString.parse(location.search);
        // console.log('params found', params);
        // try {
        //     if(params && params.rtdf) {
        //         //console.log('meetingInfo', params['?rtdf']);
        //         //@ts-ignore
        //         const decrypted = atob(params.rtdf);
        //         const meetingInfo = JSON.parse(decrypted);
        //         console.log('meetingInfo', meetingInfo);
        //        // dispatch(Actions.setMeetingId(meetingInfo.id + ''));
        //        // dispatch(Actions.setMeetingPassword(meetingInfo.password));
        //         const meetingData = {
        //             sname: meetingInfo.sname
        //         }
        //         const  response: any = dispatch(Actions.getMeetingInfo(meetingData));
        //         // dispatch(Actions.setMeetingInfo(meetingInfo.meetingId, '', meetingInfo.password));
        //         // history.push(routes.JOINAMEETING);
        //     }
        // } catch(e) {
        //     console.log('errror in fetching url', e);
        // }
    }, [userPermissionEntity])
    useEffect(() => {
        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);
        document.addEventListener("e2ee_error", handleEndToEndEncryptionError);
        return (() => {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
            document.removeEventListener("e2ee_error", handleEndToEndEncryptionError);
        });
    }, []);
    const toggleTheme = () => {
        const SwitchTheme = displayTheme === 'lite' ? 'dark' : 'lite';
        // setThemeSelected(SwitchTheme);
        dispatch(Actions.setTheme(SwitchTheme));
        LocalDb.updateDisplaySettings(dbStore, { theme: SwitchTheme }, (data: any) => {
            console.log('update displayName', data);
        })
    }

    const handleEndToEndEncryptionError = debounce(async event => {
        console.log("handleEndToEndEncryptionError", event.detail.reason);
        /**
         * Do the things if chrome flag is not enabled
         */
        console.log("Platform not Support");
        setConfirmTitle(t('layout.encryptionError'));
        setConfirmMessage(t('layout.encryptionErrorMsg'));
        setShowConfirm(true);
        dispatch(endMeeting(null, false));
    }, 700)
    const handleConfirm = () => {
        setShowConfirm(false);
      }
    const handleConnectionChange = (): void => {
        const condition = navigator.onLine ? 'online' : 'offline';

        if (condition === 'online') {
            const webPing = setInterval(
                () => {
                    fetch('//google.com', {
                        mode: 'no-cors',
                    })
                        .then(() => {
                            setIsDisconnected(false);
                            return clearInterval(webPing);
                        }).catch(() => {
                            setIsDisconnected(true)
                        })
                }, 2000);
            return;
        }
        return setIsDisconnected(true);
    }
    return (
        <div className={cx(classes.mainContainer, theme.main)}>
            <Routes verified={browserCheck} theme={theme} />
            {loggedIn &&  (PermissionSchedule || HoolvaUtils.checkUserPermission('meeting', 'meeting.update', userPermissionEntity)) && browserCheck && pathname !== routes.WAITINGROOM && pathname !== routes.RECONNECT && pathname !== routes.JOINAMEETING ? (
                <div className={cx(classes.flexBottom, classes.flexs)}>
                    <div className={cx(classes.center)}>
                        <div onClick={() => history.push(routes.HOME)} className={cx(classes.ButtomIcon, pathname === routes.HOME || pathname === routes.JOIN ? theme.activeBox : '')} >
                            <FontAwesomeIcon icon={['fas', 'users']} />
                            <div className={cx(classes.primary)}>{t('homePage.meetingTab')}</div>
                        </div>
                        {/*<div onClick={()=>setactiveTab(2)} className={cx(classes.ButtomIcon,activeTab === 2 ? classes.active:'')}>*/}
                        {/*    <FontAwesomeIcon icon={['fas', 'comment-alt']} />*/}
                        {/*    <div className={cx(classes.primary)}>{t('homePage.chatTab')}</div>*/}
                        {/*</div>*/}

                        <div onClick={() => history.push(routes.SCHEDULE)} className={cx(classes.ButtomIcon, pathname === routes.SCHEDULE ? theme.activeBox : '')}>
                            <FontAwesomeIcon icon={['fas', 'share-square']} />
                            <div className={cx(classes.primary)}>{t('homePage.scheduleTab')}</div>
                        </div>

                        {/* <div onClick={()=>toggleTheme()} className={cx(classes.ButtomIcon)}>
                            <FontAwesomeIcon icon={['fas', 'brush']} />
                        <div className={cx(classes.primary)}>Theme</div>
                        </div> */}
                        {/*<div onClick={()=>setactiveTab(4)} className={cx(classes.ButtomIcon,activeTab === 4 ? classes.active:'')}>*/}
                        {/*    <FontAwesomeIcon icon={['fas', 'record-vinyl']} />*/}
                        {/*    <div className={cx(classes.primary)}>{t('homePage.recordingTab')}</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            ) : null}
            <div
                className={isDisconnected ? cx(classes.networkStatusOffline, theme.offline) : cx(classes.networkStatusOnline, theme.online)}>{isDisconnected ? t('layout.noInternet') : t('layout.backOnline')}
            </div>
            <HoolvaAlertMessage mode={true} t={t} open={showConfirm} title={confirmTitle} message={confirmMessage} confirm={() => handleConfirm()} />
        </div>
    )

}
export default withNamespaces()(HoolvaLayout);
