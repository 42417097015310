import * as Actions from "../actions";
import {SET_LOCAL_RECORDING_STATUS} from "../actions";

const initialState: any = {
    recorder: null,
    localRecordState: false,
    recorderMode: '',
    recordType: '',
    audioParticipant: [],
    videoParticipant: [],
    recordStatus: false,
};
const RecorderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Actions.SET_RECORDER: {
            return {
                ...state,
                recorder: action.recorder,
                localRecordState: action.recorder ? true: false,
                recorderMode: action.mode,
                recordType: action.recordType
            }
        }
        case Actions.SET_LOCAL_RECORDING_STATUS: {
            return {
                ...state,
                recordStatus: action.status
            }
        }
        case Actions.SET_RECORD_PARTICIPANT: {
            return {
                ...state,
                videoParticipant: action.videoParticipant,
                audioParticipant: action.audioParticipant
            }
        }
        default: {
            return state;
        }
    }
}
export default RecorderReducer;
