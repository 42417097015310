import React from "react";
import cx from 'classnames';
import classes from './rightPanel.module.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Chat from "./Chat/chat";
import {makeStyles} from "@material-ui/styles";
import People from "./People/people";
// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    bgColor: {
        backgroundColor: theme.chatBGColor,
        borderLeft: "2px solid " +theme.chatBGColor
    },
    active: {
        backgroundColor: theme.chatBGColor + '!important',
        color:theme.textColor1 + '!important'
    },
    tab: {
        backgroundColor: theme.videoRoomTernaryColor,
        color:theme.textColor3
    }
}))
const RightPanel = (props: any) => {
    const theme = useStyles(props);
    const {participantList, chat,disableVideoButton, waitingList,
        t,localUnmuteCheckBox,currentSlider, maxTileinslider, people,
        togglePeople,openInvite, toggleChat, meetingInfo,  raiseHand,
        requestRaiseHand, requestLowerHand, allowUnMute, connectivityMode, room_uuid} = props;
    const TabSwitch = (mode: string) => {
        if(mode === 'chat'){
            togglePeople(false);
            toggleChat(true);
        }else{
            togglePeople(true);
            toggleChat(false);
        }
    }
    const Close = (e:any) => {
        e.stopPropagation();
        togglePeople(false);
        toggleChat(false);
    }
    return (
        <div className={cx(classes.rightPanels,theme.bgColor)}>
            <div className={cx(classes.tabs,theme.tab)}>
                <div onClick={() => TabSwitch('tab')} className={cx(classes.tab,theme.tab, !chat && people ? !meetingInfo.e2ee ? classes.PeopleOnly: classes.active:'', !chat && people ? theme.active:'')}>
                    <FontAwesomeIcon onClick={(e) => Close(e)} icon={['fas', 'times']} />
                    {t('meetingPage.rightPanel.people.title')}
                </div>
                {/* {!meetingInfo.e2ee ? */}
                    <div onClick={() => TabSwitch('chat')} className={cx(classes.tab,theme.tab, chat && !people ? classes.active:'', chat && !people ? theme.active:'')}>
                        <FontAwesomeIcon onClick={(e) => Close(e)} icon={['fas', 'times']} />
                        {t('meetingPage.rightPanel.chat.title')}
                    </div>
                {/* :null} */}
            </div>
            {chat ?
                <Chat
                    t={t}
                    room_uuid={room_uuid}
                    raiseHand={raiseHand}
                    meetingInfo={meetingInfo}
                    requestLowerHand={requestRaiseHand}
                    requestRaiseHand={requestLowerHand}
                    participantList={participantList}/>:null
            }
            {people ?
                <People participantList={participantList} t={t}
                    allowUnMute={allowUnMute}
                    waitingList={waitingList}
                    openInvite={openInvite}
                    disableVideoButton={disableVideoButton}
                    currentSlider={currentSlider}
                    maxTileinslider={maxTileinslider}
                    localUnmuteCheckBox={localUnmuteCheckBox}
                    connectivityMode={connectivityMode}
                    meetingInfo={meetingInfo}/>:null
            }
        </div>

    )
}
export default RightPanel;
