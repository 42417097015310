import React, {useState, useLayoutEffect} from 'react';
import cx from 'classnames';
import classes from './speakerView.module.scss';
import * as Actions from '../../../store/actions';
import {useDispatch} from "react-redux";
import {debounce} from 'lodash';
import VideoWidget from "../../../components/VideoWidget/videoWidget";


const SpeakerView = (props: any) => {
    const dispatch = useDispatch();
    const [videoHeight, setVideoHeight]:any = useState('10');
    const [videoWidth, setVideoWidth]:any = useState('10');
    const {screenShareParticipantList, chat, people, fluid, activeSpeaker, activeScreenShare, fullSStatus} = props;
    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
        }, [
            window.innerHeight,
            window.innerWidth,
            chat, people, fluid, fullSStatus
        ]);
    const updateSize = debounce(() => {
        const ULenght = 1;
        if (ULenght === 1) {
            const height = window.innerHeight- (fluid? 114: 0);
            let VWidth = window.innerWidth <= 800 && (chat || people) ? 312 : 554;
            const width = window.innerWidth-(chat || people? fullSStatus ? 312:VWidth: fullSStatus? 0:242);
            if ((width ) / height < 1.77777778) {
                setVideoHeight(width * 0.5625);
                setVideoWidth(width);
            } else {
                setVideoHeight(height);
                setVideoWidth(height * 1.77777778);
            }
        }
    }, 300)

    const handleCompleted = (id: string) => {
        console.log('animation completed');
        dispatch(Actions.handleAnimationComplete(id))
    }
    return(
        <div className={cx(classes.ScreenShare)}>
            {screenShareParticipantList.length > 0 ? (
                <div className={cx(classes.Share)}>
                    {screenShareParticipantList.map((participant: any) => {
                        return (
                            <VideoWidget
                                connectivityMode={'phone'}
                                ULenght={1}
                                key={participant.rfid}
                                participant={participant}
                                videoHeight={videoHeight}
                                videoWidth={videoWidth}
                                muted={true}
                                avatarSize={100}
                                activeScreenShare={activeScreenShare}
                                elemId={'screenshare_' + participant.id}
                                type={'screenshare'}
                            />
                        //     <div
                        //         key={participant.id}
                        //         className={cx(classes.VideoWrapper, activeScreenShare === participant.id? '':classes.hideVideo)}
                        //         style={{height:videoHeight+'px',width:videoWidth+'px', position:'relative'}}
                        //     >
                        //
                        //         {/* <section className={cx(classes.NameBoard, activeScreenShare === participant.id? '':classes.hideVideo)} style={{marginTop:videoHeight-30+'px',width:videoWidth-10+'px' }}>
                        // <span id={`screenShare${participant.id}`}>{participant.rfdisplay} {activeScreenShare}</span>
                        //         </section> */}
                        //         <div className={cx(classes.VideoOverlayAvatar,theme.noVideo, activeScreenShare === participant.id? '':classes.hideVideo)} style={{height:videoHeight+'px',width:videoWidth+'px'}}>
                        //             <FontAwesomeIcon icon={['fas', 'video-slash']} className={cx(classes.avatar)}/>
                        //         </div>
                        //         <div className={cx(classes.VideoOverlay, activeScreenShare === participant.id? '':classes.hideVideo)}  style={{height:videoHeight+'px',width:videoWidth+'px'}}>
                        //             <video
                        //                 style={{height:videoHeight+'px',width:videoWidth+'px', position:'relative'}}
                        //                 id={'screenshare_' + participant.id}
                        //                 autoPlay
                        //                 muted={true}
                        //             />
                        //
                        //         </div>
                        //     </div>
                        )
                    })}
                </div>
            ) : (
                <div className={cx(classes.Share)}>
                            {/*<div*/}
                            {/*    className={cx(classes.VideoWrapper)}*/}
                            {/*    style={{height:videoHeight+'px',width:videoWidth+'px', position:'relative'}}*/}
                            {/*>*/}

                            {/*    <section className={cx(classes.NameBoard)} style={{height:videoHeight+'px',width:videoWidth-20+'px' }}>*/}
                            {/*    {activeSpeaker?.raisehand ? <FontAwesomeIcon  className={(cx(classes.gold))} icon={["fas", "hand-paper"]} /> : null}*/}
                            {/*    {activeSpeaker?.reaction || activeSpeaker?.raisehand ? <HoolvaAnimation classname={cx(classes.reaction)} width={50+'px'} height={50+'px'}*/}
                            {/*                                                                      type={activeSpeaker?.reaction} loop={2}*/}
                            {/*                                                                      autoplay={true} hover={true} completed={() => handleCompleted(activeSpeaker.rfid)}/> : null}*/}
                            {/*        <span id={`activeSpeakerName`}></span>*/}

                            {/*    </section>*/}

                            {/*    <div className={cx(classes.VideoOverlayAvatar,theme.noVideo)} style={{height:videoHeight+'px',width:videoWidth+'px'}}>*/}

                            {/*        <FontAwesomeIcon icon={['fas', 'video-slash']} className={cx(classes.avatar)}/>*/}
                            {/*    </div>*/}
                            {/*    <div className={cx(classes.VideoOverlay)}  style={{height:videoHeight+'px',width:videoWidth+'px'}}>*/}
                            {/*        <video*/}
                            {/*            className={cx(activeSpeaker?.isPublisher ? classes.localvideo : '',activeSpeaker?.video === 'muted' ? classes.hideVideo: '')}*/}
                            {/*            style={{height:videoHeight+'px',width:videoWidth+'px', position:'relative'}}*/}
                            {/*            id={'activeSpeakerWidget'}*/}
                            {/*            autoPlay*/}
                            {/*            muted={true}*/}
                            {/*        />*/}

                            {/*    </div>*/}
                            {/*</div>*/}
                    {activeSpeaker ? <VideoWidget
                        connectivityMode={'phone'}
                        ULenght={1}
                        participant={activeSpeaker}
                        videoHeight={videoHeight}
                        videoWidth={videoWidth}
                        muted={true}
                        avatarSize={100}
                        elemId={'activeSpeakerWidget'}
                    /> : null}

                </div>
            )}

       </div>

    );
}
export default SpeakerView;
