import * as Actions from "./index";
import 'react-confirm-alert/src/react-confirm-alert.css';
import DetectRTC from "detectrtc";
import {showMessage} from "./notifyMessage.action";
import { history } from "../../App";
import routes from '../../routes.json'


export const SET_ENVIRONMENT: string = 'SET_ENVIRONMENT';
export const SET_MEETING_ID: string = 'SET_MEETING_ID';
export const SET_MEETING_PASSWORD: string = 'SET_MEETING_PASSWORD';
export const SET_DISPLAY_NAME: string = 'SET_DISPLAY_NAME';
export const SET_SYSTEM_ID: string = 'SET_SYSTEM_ID';
export const SET_IP: string = 'SET_IP';
export const RESET_HOOLVA: string = 'RESET_HOOLVA';
export const SET_MEETING_INFO: string = 'SET_MEETING_INFO';
export const SET_SYSTEM_STATE: string = 'SET_SYSTEM_STATE';
export const SET_ROOM_UUID = 'SET_ROOM_UUID';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_WINDOW_MODE = 'SET_WINDOW_MODE';
export const SET_AUDIO_DEVICES = 'SET_AUDIO_DEVICES';
export const SET_VIDEO_DEVICES = 'SET_VIDEO_DEVICES';
export const SET_XQ_MESSAGES_INFO = 'SET_XQ_MESSAGES_INFO';



export function setEnvironment(server: string, apiroot: string) {
    return {
        type: SET_ENVIRONMENT,
        server,
        apiroot
    }
}
export function setMeetingId(meetingId: string) {
    return {
        type: SET_MEETING_ID,
        meetingId
    }
}
export function setMeetingPassword(password: string) {
    return {
        type: SET_MEETING_PASSWORD,
        password
    }
}

export function setWindowMode(mode: boolean) {
    return {
        type: SET_WINDOW_MODE,
        mode
    }
}
export function setSessionId(sessionId: any) {
    return {
        type: SET_SESSION_ID,
        sessionId
    }
}
export function setAudioDevices(audioDevices: any) {
    return {
        type: SET_AUDIO_DEVICES,
        audioDevices
    }
}

export function setVideoDevices(videoDevices: any) {
    return {
        type: SET_VIDEO_DEVICES,
        videoDevices
    }
}

export function setDispalyName(displayName: string) {
    return {
        type: SET_DISPLAY_NAME,
        displayName
    }
}
export function setSystemId(systemId: string) {
    return {
        type: SET_SYSTEM_ID,
        systemId
    }
}

export function setDetectRtcState(systemState: any) {
    return {
        type: SET_SYSTEM_STATE,
        systemState
    }
}

export function setRoomUUID(roomUUid: string) {
    return {
        type: SET_ROOM_UUID,
        roomUUid
    }
  }

export function setMeetingInfo(meetingInfo: any) {
    return {
        type: SET_MEETING_INFO,
        meetingInfo
    }
}
export function setXqMessagesInfo(xqMessagesInfo: any) {
    return {
        type: SET_XQ_MESSAGES_INFO,
        xqMessagesInfo
    }
}

export function setIp(ip: string) {
    return {
        type: SET_IP,
        ip
    }
}
export function resetHoolva() {
    return {
        type: RESET_HOOLVA
    }
}

export function checkAudioVideoPermission() {
    return async function (dispatch: any, getState: any) {
        const {hoolva} = getState().Meet;
        // if(DetectRTC.browser.isSafari) {
        //     dispatch(Actions.setAudio(true));
        //     dispatch(Actions.setAudioPermission('granted'));
        //     dispatch(Actions.setVideo(true));
        //     dispatch(Actions.setVideoPermission('granted'));
        // } else if(!DetectRTC.browser.isFirefox) {
        if(!(DetectRTC.browser.isFirefox || DetectRTC.browser.isSafari)) {
            navigator.permissions.query({"name":'microphone'})
                .then(function(permissionStatus) {
                    console.log('microphone permission state is ', permissionStatus.state);
                    if(permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
                        if(getState().Meet.hoolva === null) {
                            dispatch(Actions.setAudio(true));
                            dispatch(Actions.setAudioPermission(permissionStatus.state));
                        }
                    } else {
                        dispatch(Actions.setAudio(false))
                        dispatch(Actions.setAudioPermission(permissionStatus.state));
                    }

                    permissionStatus.onchange = async function() {
                        console.log('microphone permission state has changed to ', this.state, hoolva, getState().Meet.hoolva);
                        if(this.state === 'granted' || permissionStatus.state === 'prompt') {
                            if(getState().Meet.hoolva === null) {
                                dispatch(Actions.setAudio(true));
                                dispatch(Actions.setAudioPermission(this.state));
                            }
                        } else {
                            dispatch(Actions.setAudio(false))
                            dispatch(Actions.setAudioPermission(this.state));
                        }

                    };

                });
                navigator.permissions.query({"name":'camera'})
                    .then(function(permissionStatus) {
                        console.log('camera permission state is ', permissionStatus.state);
                        if(permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
                            if(getState().Meet.hoolva === null) {
                                dispatch(Actions.setVideo(true));
                                dispatch(Actions.setVideoPermission(permissionStatus.state));
                            }
                        } else {
                            dispatch(Actions.setVideo(false))
                            dispatch(Actions.setVideoPermission(permissionStatus.state));
                        }

                        permissionStatus.onchange = async function() {
                            console.log('camera permission state has changed to ', this.state, hoolva);
                            if(this.state === 'granted' || permissionStatus.state === 'prompt') {
                                if(getState().Meet.hoolva === null) {
                                    dispatch(Actions.setVideo(true));
                                    dispatch(Actions.setVideoPermission(this.state));
                                }
                            } else {
                                dispatch(Actions.setVideo(false))
                                dispatch(Actions.setVideoPermission(this.state));
                            }
                        };
                    });
        } else {
            if(history.location.pathname === routes.JOINAMEETING) {
                navigator.mediaDevices.getUserMedia({audio: true, video: true})
                    .then(function(stream) {
                        /* use the stream */
                        dispatch(Actions.setAudio(true))
                        dispatch(Actions.setAudioPermission('granted'));
                        dispatch(Actions.setVideo(true));
                        dispatch(Actions.setVideoPermission('granted'));
                        stream.getTracks().forEach(function(track: MediaStreamTrack) {
                            track.stop();
                        });
                    })
                    .catch(error => {
                        navigator.mediaDevices.getUserMedia({audio: true, video: false})
                        .then(stream => {
                            dispatch(Actions.setAudio(true));
                            dispatch(Actions.setAudioPermission('granted'));
                            stream.getTracks().forEach(function(track: MediaStreamTrack) {
                                track.stop();
                            });
                        })
                        .catch(error => {
                            dispatch(Actions.setAudio(false));
                            dispatch(Actions.setAudioPermission('denied'));
                            navigator.mediaDevices.getUserMedia({video: true, audio: false})
                            .then(stream => {
                                dispatch(Actions.setVideo(true));
                                dispatch(Actions.setVideoPermission('granted'));
                                stream.getTracks().forEach(function(track: MediaStreamTrack) {
                                    track.stop();
                                });
                            })
                            .catch(error => {
                                dispatch(Actions.setVideo(false));
                                dispatch(Actions.setVideoPermission('denied'));
                            });
                        });
                    })
                    
            }
        }


    }
}

export function attachSinkId(element: any, sinkId: string) {
    return async function (dispatch: any, getState: any) {
        if (typeof element.sinkId !== 'undefined') {
            element.setSinkId(sinkId)
                .then(() => {
                    console.log(`Success, audio output device attached: ${sinkId}`);
                })
                .catch((error: any) => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    // console.error(errorMessage);
                    // Jump back to first output device in the list as it's the default.
                    // audioOutputSelect.selectedIndex = 0;
                });
        } else {
            console.warn('Browser does not support output device selection.');
        }
    }

}

export function handleDeviceChange (component: string) {
    return async function (dispatch: any, getState: any) {
        DetectRTC.load(async function () {
            const {audioDevices, videoDevices} = getState().Hoolva;
            const {audio, video, audioDeviceName, videoDeviceName, selectedAudio} = getState().Meet;
            const audioCompareA = audioDevices.filter(compareDevices(DetectRTC.audioInputDevices));
            const audioCompareB = DetectRTC.audioInputDevices.filter(compareDevices(audioDevices));
            const audioResult = audioCompareA.concat(audioCompareB);
            const videoCompareA = videoDevices.filter(compareDevices(DetectRTC.videoInputDevices));
            const videoCompareB = DetectRTC.videoInputDevices.filter(compareDevices(videoDevices));
            const videoResult = videoCompareA.concat(videoCompareB);
            console.log('audio video result', audioResult, videoResult)
            if (videoResult.length > 0 && audioResult.length > 0) {
                console.log("changing video", videoResult[0])
                if(video) {
                    if(videoDevices.length > DetectRTC.videoInputDevices.length) {
                        console.log("video device detached");

                        await dispatch(Actions.setDefaultVideoDevice(DetectRTC.videoInputDevices[0]?.deviceId, DetectRTC.videoInputDevices[0]?.label))

                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: DetectRTC.videoInputDevices.length > 0 ? DetectRTC.videoInputDevices[0]: null,
                                added: false
                            }}))
                        if(DetectRTC.videoInputDevices.length > 0) {
                            dispatch(showMessage('info', videoDeviceName === '' || !videoDeviceName ? 'Composite device switched to ' + DetectRTC.videoInputDevices[0]?.label : videoDeviceName   + ' switched to ' +  DetectRTC.videoInputDevices[0]?.label, {position: 'top-center'}, true));
                        }
                        if(component !== "join") {
                            dispatch(Actions.toggleVideo(false, true));
                        }


                    } else {
                        console.log("video device added");

                        await dispatch(Actions.setDefaultVideoDevice(videoResult[0].deviceId, videoResult[0].label))
                        if(component !== "join") {
                            dispatch(Actions.changeVideo(true, "video", videoResult[0].deviceId))
                        }
                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: videoResult[0],
                                added: true
                            }}))

                        dispatch(showMessage('info', videoDeviceName === '' || !videoDeviceName ? 'Composite device switched to ' + videoResult[0].label : videoDeviceName   + ' switched to ' +  videoResult[0].label, {position: 'top-center'}, true));
                    }
                } else {
                    if(videoDevices.length > DetectRTC.videoInputDevices.length) {
                        await dispatch(Actions.setDefaultVideoDevice(DetectRTC.videoInputDevices.length > 0 ? DetectRTC.videoInputDevices[0].deviceId : '', DetectRTC.videoInputDevices.length > 0 ? DetectRTC.videoInputDevices[0].label : ''));
                        if(DetectRTC.videoInputDevices.length > 0) {
                            document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                                detail: {
                                    data: DetectRTC.videoInputDevices[0],
                                    added: true
                                }}))
                         //   dispatch(showMessage('info', videoDeviceName === '' || !videoDeviceName ? 'Composite ' : videoDeviceName   + ' switched to ' +  DetectRTC.videoInputDevices[0]?.label, {position: 'top-center'}, true));
                        }
                    } else {
                        await dispatch(Actions.setDefaultVideoDevice(videoResult[0].deviceId, videoResult[0].label))
                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: videoResult[0],
                                added: true
                            }}))
                      //  dispatch(showMessage('info', videoDeviceName === '' || !videoDeviceName ? 'Composite ' : videoDeviceName   + ' switched to ' +  videoResult[0].label, {position: 'top-center'}, true));
                    }
                }
                if(audioResult.length > 0 && component !== "join") {
                    if(audioDevices.length > DetectRTC.audioInputDevices.length) {
                        console.log("removing audio")
                        let flag = false;
                        DetectRTC.audioInputDevices.map(audioDevice => {
                            if(audioDevice.deviceId === selectedAudio) {
                                flag = true;
                            }
                        });
                        if(flag) {
                            dispatch(Actions.changeVideo(true, "audio", selectedAudio ? selectedAudio : DetectRTC.audioInputDevices.length > 0 ? DetectRTC.audioInputDevices[0].deviceId : '', true));
                        } else {
                            dispatch(Actions.changeVideo(true, "audio", DetectRTC.audioInputDevices.length > 0 ? DetectRTC.audioInputDevices[0].deviceId : '', true));
                        }
                    }else {
                        console.log("adding audio device");
                        dispatch(Actions.changeVideo(true, "audio", selectedAudio ? selectedAudio : DetectRTC.audioInputDevices.length > 0 ? DetectRTC.audioInputDevices[0].deviceId : '', true));
                    }
                }

            } else if (videoResult.length > 0) {
                console.log("changing video", videoResult[0])
                if(video) {
                    if(videoDevices.length > DetectRTC.videoInputDevices.length) {
                        console.log("video device detached");

                        await dispatch(Actions.setDefaultVideoDevice(DetectRTC.videoInputDevices[0]?.deviceId, DetectRTC.videoInputDevices[0]?.label))
                        if(component !== "join") {

                            dispatch(Actions.toggleVideo(false, true));
                        }
                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: DetectRTC.videoInputDevices.length > 0 ? DetectRTC.videoInputDevices[0] : null ,
                                added: false
                            }}))
                        if(DetectRTC.videoInputDevices.length > 0) {
                            dispatch(showMessage('info', videoDeviceName === '' || !videoDeviceName ? 'Composite device switched to ' + DetectRTC.videoInputDevices[0]?.label : videoDeviceName   + ' switched to ' +  DetectRTC.videoInputDevices[0]?.label, {position: 'top-center'}, true));
                        }

                    } else {
                        console.log("video device added");

                        await dispatch(Actions.setDefaultVideoDevice(videoResult[0].deviceId, videoResult[0].label))
                        if(component !== "join") {
                            dispatch(Actions.changeVideo(true, "video", videoResult[0].deviceId))
                        }
                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: videoResult[0],
                                added: true
                            }}))

                        dispatch(showMessage('info', videoDeviceName === '' || !videoDeviceName ? 'Composite device switched to ' + videoResult[0].label : videoDeviceName   + ' switched to ' +  videoResult[0].label, {position: 'top-center'}, true));


                    }
                } else {
                    if(videoDevices.length > DetectRTC.videoInputDevices.length) {
                        await dispatch(Actions.setDefaultVideoDevice(DetectRTC.videoInputDevices.length > 0 ? DetectRTC.videoInputDevices[0].deviceId : '', DetectRTC.videoInputDevices.length > 0 ? DetectRTC.videoInputDevices[0].label : ''))
                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: DetectRTC.videoInputDevices[0],
                                added: false
                            }}));
                        if(DetectRTC.videoInputDevices.length > 0) {
                        //    dispatch(showMessage('info', videoDeviceName === '' || !videoDeviceName ? 'Composite ' : videoDeviceName   + ' switched to ' +  DetectRTC.videoInputDevices[0]?.label, {position: 'top-center'}, true));
                        }
                    } else {
                        await dispatch(Actions.setDefaultVideoDevice(videoResult[0].deviceId, videoResult[0].label))
                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: videoResult[0],
                                added: true
                            }}));
                    //    dispatch(showMessage('info', videoDeviceName === '' || !videoDeviceName ? 'Composite ' : videoDeviceName   + ' switched to ' +  videoResult[0].label, {position: 'top-center'}, true));
                    }
                }

            } else if (audioResult.length > 0) {
                console.log("changing audio")
                if(component !== "join") {
                    if(audioDevices.length > DetectRTC.audioInputDevices.length) {
                        console.log("removing audio setDefaultAudioDevice 1")
                        await dispatch(Actions.setDefaultAudioDevice(DetectRTC.audioInputDevices[0]?.deviceId, DetectRTC.audioInputDevices[0]?.label))
                        dispatch(Actions.changeVideo(true, "audio", DetectRTC.audioInputDevices[0]?.deviceId, false));
                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: DetectRTC.audioInputDevices.length > 0 ? DetectRTC.audioInputDevices[0] : null,
                                added: false
                            }}));
                        if(DetectRTC.audioInputDevices.length > 0) {
                            dispatch(showMessage('info', audioDeviceName === '' || !audioDeviceName ? 'Composite device switched to ' + DetectRTC.audioInputDevices[0]?.label : audioDeviceName   + ' switched to ' +  DetectRTC.audioInputDevices[0]?.label, {position: 'top-center'}, true));
                        }
                    } else {
                        console.log("adding audio device setDefaultAudioDevice 2");
                        await dispatch(Actions.setDefaultAudioDevice(audioResult[0].deviceId, audioResult[0].label))
                        dispatch(Actions.changeVideo(true, "audio", audioResult[0].deviceId, true));
                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: audioResult[0],
                                added: true
                            }}));
                        dispatch(showMessage('info', audioDeviceName === '' || !audioDeviceName ? 'Composite device switched to ' + audioResult[0].label : audioDeviceName   + ' switched to ' +  audioResult[0].label, {position: 'top-center'}, true));
                    }
                } else {
                    if(audioDevices.length > DetectRTC.audioInputDevices.length) {
                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: DetectRTC.audioInputDevices.length > 0 ? DetectRTC.audioInputDevices[0] : null,
                                added: false
                            }}));
                        if(DetectRTC.audioInputDevices.length > 0) {
                            dispatch(showMessage('info', audioDeviceName === '' || !audioDeviceName ? 'Composite device switched to ' + DetectRTC.audioInputDevices[0]?.label : audioDeviceName   + ' switched to ' +  DetectRTC.audioInputDevices[0]?.label, {position: 'top-center'}, true));
                        }
                    } else {
                        document.dispatchEvent(new CustomEvent("deviceSelectionChange", {
                            detail: {
                                data: audioResult[0],
                                added: true
                            }}));
                        dispatch(showMessage('info', audioDeviceName === '' || !audioDeviceName ? 'Composite device switched to ' + audioResult[0].label : audioDeviceName   + ' switched to ' +  audioResult[0].label, {position: 'top-center'}, true));
                    }

                }
            } else {
                console.log(null)
            }
            dispatch(setAudioDevices(DetectRTC.audioInputDevices));
            dispatch(setVideoDevices(DetectRTC.videoInputDevices));
        });
    }
}

function compareDevices(otherArray: any) {
    return function(current: any){
        return otherArray.filter(function(other: any){
            return other.deviceId == current.deviceId
        }).length == 0;
    }
}



