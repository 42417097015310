/**
 * Configuration file
 * Author: Ashok P A
 */

// dev server
// export const SERVER: string = 'wss://us7-dev.hoolva.com/ws/';
// export const API_ROOT: string = 'https://us7-dev.hoolva.com/v1/';
// export const ROOT: string = 'https://us7-dev.hoolva.com/';
// export const API_KEY: string = 'b92307c2-1a60-4d4b-b1fe-4d3ba57d1ca5';

// old production server
// export const SERVER = 'wss://us2-server-node0.hoolva.com/ws/';
// export const API_ROOT = 'https://us2-server-node0.hoolva.com/v1/';
// export const ROOT = 'https://us2-server-node0.hoolva.com/';
// export const API_KEY = 'b92307c2-1a60-4d4b-b1fe-4d3ba57d1ca5';

// production server
// export const SERVER = 'wss://us2-cluster.hoolva.com/ws/';
// export const API_ROOT = 'https://us2-cluster.hoolva.com/v1/';
// export const ROOT = 'https://hoolva.com/';
// export const API_KEY = 'b92307c2-1a60-4d4b-b1fe-4d3ba57d1ca5';

// // us1 server
// export const SERVER: string = 'wss://us1-dev.hoolva.com/ws';
// export const API_ROOT: string = 'https://us1-dev.hoolva.com/v1/';
// export const ROOT: string = 'https://us1-dev.hoolva.com/';
// export const API_KEY: string = 'b92307c2-1a60-4d4b-b1fe-4d3ba57d1ca5';

// us4 server
// export const SERVER: string = 'wss://us4-server-node0.hoolva.com/ws';
// export const API_ROOT: string = 'https://us4-server-node0.hoolva.com/v1/';
// export const ROOT: string = 'https://us4-server-node0.hoolva.com/';
// export const API_KEY: string = 'b92307c2-1a60-4d4b-b1fe-4d3ba57d1ca5';

    // us5 server
// export const SERVER: string = 'wss://us5-dev.hoolva.com/ws';
// export const API_ROOT: string = 'https://us5-dev.hoolva.com/v1/';
// export const ROOT: string = 'https://us5-dev.hoolva.com/';
// export const API_KEY: string = 'b92307c2-1a60-4d4b-b1fe-4d3ba57d1ca5';

    // us9 server
// export const SERVER: string = 'wss://us9-dev.hoolva.com/ws';
// export const API_ROOT: string = 'https://us9-dev.hoolva.com/v1/';
// export const ROOT: string = 'https://us9-dev.hoolva.com/';
// export const API_KEY: string = 'b92307c2-1a60-4d4b-b1fe-4d3ba57d1ca5';

// test server
// export const SERVER: string = 'wss://us3-server-node0.hoolva.com/ws';
// export const API_ROOT: string = 'https://us3-server-node0.hoolva.com/v1/';
// export const ROOT: string = 'https://us3-test.hoolva.com/';
// export const API_KEY: string = 'b92307c2-1a60-4d4b-b1fe-4d3ba57d1ca5';


// new test server
export const SERVER = process.env.REACT_APP_SERVER;
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const ROOT = process.env.REACT_APP_DOMAIN;
export const API_KEY = process.env.REACT_APP_API_KEY;

export const configuration: any = {
    simulcast: true,
    bitrate: 2400000,
    substream: 2,
    temporal: 1,
    screenshareFrameRate: 30,
    screenshareBitrate: 2048000,
    brandName: process.env.REACT_APP_BRAND_NAME,
    sname: process.env.REACT_APP_SNAME,
    brandingUrl: process.env.REACT_APP_BRAND_URL,
    upgradeTimer: 30000,
    screenshareHeight: 1080,
    screenshareWidth: 1920,
    supportVersion: 72, // check chrome version 72 and above
    maxTileLimit: 9,
    language:'en',
    theme:'lite',
    favIcon:'',
    logo:'',
    dynamicBackgrounds:true,
    screenLayout:'float',
    videoResolution: {
        width: 1280,//3840,
        height: 720,  //2160,
    },
    videoResolution2: {
        width: 640, //640,
        height: 360 //360
    },
    activeSpeakerDefaultLength: 3,
    bandWidthOptimization : true,
    pool: {
        high: 4,
        medium: 9,
        low: 10
    }

};
console.log("brandName is",configuration.brandName)
export const updateConfiguration = (key:string,value:any) => {
    configuration[key] = value;
console.log("brandName1 is",value)
}

export const bitRateRange: object = {
    1: 512000,
    2: 512000,
    3: 512000,
    4: 512000,
    5: 256000,
    6: 256000,
    7: 128000,
    8: 128000,
    9: 128000,
    10: 128000,
    11: 128000,
    12: 128000,
    13: 64000,
    14: 64000
}
export const bitRatePool: number[] = [
    512000,
    256000,
    128000,
    64000,
    32000
]
