import * as Actions from "../actions";

const initialState: any = {
    audioRoomHandle: null,
    connectionLost: 0
};
const AudioRoomReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Actions.SET_AUDIO_ROOM_PLUGIN_HANDLE: {
            return {
                ...state,
                audioRoomHandle: action.pluginHandle
            }
        }
        case Actions.SET_CONNECTION_LOST: {
            return {
                ...state,
                connectionLost: action.lost
            }
        }
        default: {
            return state;
        }
    }
}
export default AudioRoomReducer;
