


class HoolvaUtils {

    static setSystemId(systemId: string) {
        localStorage.setItem('system-id', systemId)
    }
    static getSystemId() {
        const systemId = localStorage.getItem('system-id');
        return systemId? systemId : '';
    }
    static logger(msg: string, data: any, type: string) {
          console.log(`%c Hoolva ${type} !!!${msg},  data -> ${data !== '' ? JSON.stringify(data): data}`,
              type === "error" ? 'background: red; color: black; display: block;' :
                  type === "success" ? 'background: green; color: black; display: block;' :
                      type === "info" ? 'background: blue; color: black; display: block;' :
                          type === "warning" ? 'background: orange; color: black; display: block;' :
                              type === "dark" ? 'background: black; color: white; display: block;' :
                                  ''
          );
    };


    static checkUserPermission(id: string, permission: string, userPermissionEntity: any) {
            if(userPermissionEntity) {
                const currentItem = userPermissionEntity[id];
                if (currentItem.type === 'collapse') {
                    let permissionList = userPermissionEntity[id].permission ? userPermissionEntity[id].permission : [];
                    currentItem.children.map((item: any) => {
                        permissionList = permissionList.concat(item.permission);
                    })
                    return permissionList.indexOf(permission) !== -1;
                } else {
                    return userPermissionEntity[id].permission ? userPermissionEntity[id].permission.indexOf(id + '.' + permission) !== -1 : false;
                }
            } else {
                return false;
            }
    }
    static  getStatsApi(handle: any) {
        const pc = handle.webrtcStuff.pc;
        const sender = pc.getSenders()[0];
        if(sender) {
            sender.getStats().then((res: any) => {
                res.forEach((report: any) => {
                    console.log("report", report)
                });
            });
        }
    }
    static getCurrentStun(handle: any) {
        if(handle.webrtcStuff.pc) {
            const sender = handle?.webrtcStuff?.pc?.getSenders()[0];
            console.log("sender",sender)
            if(sender) {
                const iceTransport = sender?.transport?.iceTransport;
                if(iceTransport) {
                    const pair = iceTransport.getSelectedCandidatePair();
                    console.log("pair", pair);
                    return pair.local.priority
                }
                else {
                    return null;
                }
                
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

}
export default HoolvaUtils;
