import React, {createRef, useRef, useState,useEffect, useLayoutEffect, useContext} from "react";
import cx from 'classnames';
import {debounce} from 'lodash';
import classes from './videoPanel.module.scss'
import VideoWidget from "../../../../components/VideoWidget/videoWidget";
import {CarouselContext} from "pure-react-carousel";
import {useDispatch} from "react-redux";
import * as Actions from '../../../../store/actions'

const VideoPanel = (props: any) => {
    const {participantList, start, stop, chat, sliderStatus, fluid, people, activeScreen, connectivityMode} = props;
    const abc = new Array(100);
    abc.fill(1, 0, 100);
    const videoRef = useRef(abc.map(() => createRef()));
    const [videoHeight, setVideoHeight]:any = useState('10');
    const [videoWidth, setVideoWidth]:any = useState('10');
    const [avatarSize, setAvatarSize]:any = useState(100);

    const dispatch = useDispatch();

    const carouselContext = useContext(CarouselContext);
   // const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
    useEffect(() => {
        function onChange() {

                dispatch(Actions.setCurrentSlider(carouselContext.state.currentSlide));

        }
        carouselContext.subscribe(onChange);
        return () => {carouselContext.unsubscribe(onChange); };
    }, []);

    useEffect(() => {

    }, [])
    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
            updateSize();

        return () => {
            window.removeEventListener('resize', updateSize)};
        updateSize.cancel();
        }, [
            window.innerHeight,
            window.innerWidth,
            participantList,
            sliderStatus, fluid, chat, people, activeScreen
    ]);
    const updateSize = debounce(() => {
        let attLen = 0;
        const slider = sliderStatus ? 20 : 20;
        participantList.map((participant: any, index: number) => {
            if( index >= start &&
                index <= stop) {
                attLen++;
            }
        });
        const ULenght = attLen === 0 ? 1 : attLen;
        let height = window.innerHeight - (fluid? 120: 20);
        let width = window.innerWidth-(chat || people? 302 + slider: slider);
        if(activeScreen===2){
            width = 240;
                setVideoHeight(width * 0.5625);
                setVideoWidth(width);

        }else{
            if (ULenght === 1) {
                    setVideoHeight(window.innerHeight- (fluid? 114: 0));
                    setVideoWidth(window.innerWidth-(chat || people? 302: 0));
            } else if (ULenght === 2) {
                height = height - 5;
                width = (width) / 2 - 5;
                    if ((width ) / height < 1.777778 * 2) {
                        setVideoHeight(width * 0.5625);
                        setVideoWidth(width);
                    } else {
                        setVideoHeight(height);
                        setVideoWidth(height * 1.777778);
                    }

            } else if (ULenght === 3 || ULenght === 4) {
                width = width - 20;
                height = height - 20;
                    if (width / height < 1.777778) {
                        setVideoHeight((width / 2) * 0.56666);
                        setVideoWidth((width / 2));
                    } else {
                        setVideoHeight(height / 2);
                        setVideoWidth((height / 2) * 1.777778);
                    }

            }else if (ULenght === 5 || ULenght === 6) {
                width = width - 30;
                height = height -10;
                    if ((width - 30) / height < 2.64705867) {
                        setVideoHeight((width / 3) * 0.56666);
                        setVideoWidth((width / 3));
                    } else {
                        setVideoHeight(height / 2);
                        setVideoWidth((height / 2) * 1.777778);
                    }
            }else if (ULenght >= 7 && ULenght <= 9) {
                width = width - 30;
                height = height -20;
                    if ((width - 30) / height < 1.76470578) {
                        setVideoHeight((width / 3) * 0.56666);
                        setVideoWidth((width / 3));
                    } else {
                        setVideoHeight(height / 3);
                        setVideoWidth((height / 3) * 1.777778);
                    }

            }else if (ULenght > 9 && ULenght <= 12) {
                width = width - 30;
                height = height -30;
                    if ((width - 30) / height < 1.76470578) {
                        setVideoHeight((width / 4) * 0.56666);
                        setVideoWidth((width / 4));
                    } else {
                        setVideoHeight(height / 4);
                        setVideoWidth((height / 4) * 1.777778);
                    }

            }else if (ULenght <= 16 && ULenght > 12) {
                width = width - 30;
                height = height -30;
                    if ((width - 30) / height < 1.76470484) {
                        setVideoHeight((width / 4) * 0.56666);
                        setVideoWidth((width / 4));
                    } else {
                        setVideoHeight(height / 4);
                        setVideoWidth((height / 4) * 1.777778);
                    }

            }
            else if (ULenght <= 20 && ULenght > 16) {
                width = width - 30;
                height = height -30;
                    if ((width - 40) / height < 2.23214286) {
                        setVideoHeight((width / 5) * 0.56666);
                        setVideoWidth((width / 5));
                    } else {
                        setVideoHeight(height / 4);
                        setVideoWidth((height / 4) * 1.777778);
                    }

            }
        }
        
    })


    return (
        <div className={cx(classes.Slider,classes.center,fluid || activeScreen === 2 ? classes.Fluid: '')}>
            <div className={cx(classes.flexCenter)}>
            {
                participantList.slice(start, stop + 1).map((participant: any, index: number) => {
                    // if( index >= start &&
                    //     index <= stop) {
                        return (
                            <VideoWidget
                                connectivityMode={connectivityMode}
                                ULenght={participantList.length}
                                avatarSize={avatarSize}
                                participant={participant}
                                setRef={videoRef.current[participant.rfindex]}
                                key={participant.id}
                                videoHeight={videoHeight}
                                videoWidth={videoWidth}
                                muted={index === 0}
                            />
                        )
                    //}

                })
            }
            </div>
        </div>
    )
}
export default VideoPanel;

