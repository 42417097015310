import React from "react";
import classes from './hoolvaConfirmMessage.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
const HoolvaConfirmMessage = (props: any) => {
    const {open, title, message,type, confirm,data, cancel, mode,t} = props
    return (
        <>
            {open && !mode ? (
                <div>
                    <div className={cx(classes.modalBG)}>
                    </div>
                    <div className={cx(classes.modal)}>
                        <div className={cx(classes.content)}>
                            <div className={cx(classes.header)}>
                                <div className={cx(classes.padding8)}>
                                    <FontAwesomeIcon onClick={() => cancel(type)} className={cx(classes.close)} icon={['fas', 'times']} />
                                    {title}
                                </div>
                            </div>
                            <div className={cx(classes.body)}>

                                <div className={cx(classes.tabBody)}>
                                    <div className={cx(classes.bodyContent)}>
                                        <div className={cx(classes.bodyTitle)}>
                                            {message}
                                        </div>
                                        <div style={{display: "flex"}}>
                                            <div className={cx(classes.input)}>
                                                <button onClick={() => cancel(type)}>
                                                    {type === 'unmuteAudio' ? t('schedule.StayMuted') : type === 'unmuteVideo' ? t('schedule.IWillDoItLater') : t("schedule.Cancel")}
                                                </button>
                                            </div>
                                            <div className={cx(classes.input)}>
                                                <button onClick={() =>confirm(type, data)}>
                                                    {type === 'unmuteAudio' ? t('schedule.UnmuteNow') : type === 'unmuteVideo' ? t('schedule.Startmyvideo') : type === 'kick' ? t('schedule.Remove') : t('schedule.Ok')}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    )
}
export default HoolvaConfirmMessage;
