import React from 'react';
import ReactDOM from 'react-dom';
import './index.module.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import './App.module.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'emoji-mart/css/emoji-mart.css';
import 'react-toastify/dist/ReactToastify.css';
import './datepicker.css';
import * as Config from './Config/config';
import {env} from '../package.json';

if (env !== 'development') {
    console.log = function() {};
    console.warn = function() {};
    console.error = function() {};
}

// let link = document.querySelector("link[rel~='icon']");
// if (!link) {
//     link = document.createElement('link');
//     //@ts-ignore
//     link.rel = 'icon';
//     document.getElementsByTagName('head')[0].appendChild(link);

// }
// //@ts-ignore
// link.href = "https://cdn-network.sgp1.digitaloceanspaces.com/"+Config.configuration.sname+"/logo-icon.png"
// document.title = Config.configuration.brandName + ' - online meeting for you and your team.';

const existingScript = document.getElementById('ebml');
if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://www.webrtc-experiment.com/EBML.js';
    script.id = 'ebml';
    document.body.appendChild(script);
    script.onload = () => {
        console.log('ebml.js loaded')
    };
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
