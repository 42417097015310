
// @ts-ignore
import {Janus} from 'janus-gateway';
import {env, version} from '../../../package.json';
import * as Service from '../../Services/apiService';
import { history } from '../../App';
import routes from '../../routes.json';
import XQMessageApi from '../../Services/xqService/xqService';
import {SFrame} from '../../Services/sframe/Client';
import * as Config from '../../Config/config';
import DetectRTC from "detectrtc";
import $ from 'jquery';
import * as HoolvaActions from '../actions/hoolva.action';
import * as ScreenShareAction from '../actions/screenShare.action';
import HoolvaUtils from "../../Services/HoolvaUtils";
import * as AudioRoomActions from '../actions/audioRoom.action';
import {setNotificationMessage, showMessage} from "./notifyMessage.action";
import {
    iceRestartScreenShare, resetIceRestartScreenShare,
    screenShareIceRestart,
    unSubscribeScreenShare
} from "../actions/screenShare.action";
import hark from "hark";
// @ts-ignore
import NotifySound from '../../assets/audio/notification.mp3';
// import Logo from '../../assets/logos/Hoolva.png';
import {confirmAlert} from "react-confirm-alert";
import {setDispalyName, setMeetingInfo} from "../actions/hoolva.action";
import LocalDb from "../../Services/dbService";
import {setClientAuthData, setMeetingList} from "./auth.action";
import {receivedPing, stopPingTest} from "./ping.action";
import {iceAudioRestart, isAudioIceRestart, resetIceRestartAudioRoom, switchStunAudio} from "../actions/audioRoom.action";
import jsPDF from "jspdf";
import {setLocalRecordingNotification, stopRecording, updateRecording} from "./record.action";
import * as Actions from "./hoolva.action";

export const SET_AUDIO_STATE: string = 'SET_AUDIO_STATE';
export const SET_VIDEO_STATE: string = 'SET_VIDEO_STATE';
export const SET_HOOLVA_CONNECTION: string = 'SET_HOOLVA_CONNECTION';
export const SET_PUBLISHER_PLUGIN_HANDLE: string = 'SET_PUBLISHER_PLUGIN_HANDLE';
export const SET_CURRENT_MEETING_INFO: string = 'SET_CURRENT_MEETING_INFO';
export const SET_PUBLISHERS_LIST: string = 'SET_PUBLISHERS_LIST';
export const SET_CURRENT_SLIDER: string = 'SET_CURRENT_SLIDER';
export const SET_SELECTED_OUTPUT_DEVICE: string = 'SET_SELECTED_OUTPUT_DEVICE';
export const SET_SELECTED_VIDEO_DEVICE: string = 'SET_SELECTED_VIDEO_DEVICE';
export const SET_SELECTED_AUDIO_DEVICE: string = 'SET_SELECTED_AUDIO_DEVICE';
export const SET_DATA_MODE: string = 'SET_DATA_MODE';
export const RESET_MEET: string = 'RESET_MEET';
export const SET_AUDIO_PERMISSION: string = 'SET_AUDIO_PERMISSION';
export const SET_VIDEO_PERMISSION: string = 'SET_VIDEO_PERMISSION';
export const SET_MESSAGE_LIST: string = 'SET_MESSAGE_LIST';
export const SET_DISABLE_VIDEO_BUTTON: string = 'SET_DISABLE_VIDEO_BUTTON';
export const SET_CHAT_MODE: string = 'SET_CHAT_MODE';
export const SET_CHAT_NOTIFICATION_COUNT: string = 'SET_CHAT_NOTIFICATION_COUNT';
export const SET_SPEECH_DETECTION: string = 'SET_SPEECH_DETECTION';
export const SET_ICE_STATE: string = 'SET_ICE_STATE';
export const SET_CONFIRM_DATA: string = 'SET_CONFIRM_DATA';
export const SET_RAISE_HAND: string = 'SET_RAISE_HAND';
export const SET_TIMER: string = 'SET_TIMER';
export const SET_FEED_BACK: string = 'SET_FEED_BACK';
export const SET_ALLOW_UNMUTE: string = 'SET_ALLOW_UNMUTE';
export const SET_ACTIVE_SCREEN: string = 'SET_ACTIVE_SCREEN';
export const SET_ACTIVE_SPEAKER: string = 'SET_ACTIVE_SPEAKER';
export const SET_DISPLAY_NAME_CHANGE: string = 'SET_DISPLAY_NAME_CHANGE';
export const SET_CONNECTIVITY_MODE = 'SET_CONNECTIVITY_MODE';
export const SET_MAX_TILE_LIMIT = 'SET_MAX_TILE_LIMIT';
export const SET_RECORD_STATE = 'SET_RECORD_STATE';
export const SET_LOCAL_UNMUTE_CHECK_STATE = 'SET_LOCAL_UNMUTE_CHECK_STATE';
export const SET_WAITING_PARTICIPANTS = 'SET_WAITING_PARTICIPANTS';
export const SET_JOIN_MODE = 'SET_JOIN_MODE';
export const SHOW_WAITING_MODAL = 'SHOW_WAITING_MODAL';
export const PREVENT_WAITING_MODAL = 'PREVENT_WAITING_MODAL';
export const SET_PEOPLE_TAB = 'SET_PEOPLE_TAB';
export const SET_CLOUD_RECORDING_STATUS = 'SET_CLOUD_RECORDING_STATUS';
export const SET_LOCAL_CLOUD_RECORDING_STATUS = 'SET_LOCAL_CLOUD_RECORDING_STATUS';
export const SET_NOTIFICATION_ALERT = 'SET_NOTIFICATION_ALERT';
export const SET_JOINED_STATE = 'SET_JOINED_STATE';
export const SET_WEBRTC_STATUS = 'SET_WEBRTC_STATUS';
export const SET_WAITING_ROOM_LOADER = 'SET_WAITING_ROOM_LOADER';
export const SET_VIDEO_RESOLUTIONS = 'SET_VIDEO_RESOLUTIONS';
export const SET_LATENCY_MODE = 'SET_LATENCY_MODE';
export const SET_DIDS = 'SET_DIDS';
export const SET_IS_WEB_CAM = 'SET_IS_WEB_CAM';

export let participants: any[] = [];
export let activeSpeakers: any[] = [];
export let connectedVideo = false;
export let messageArray: any[] = [];
export let isIceRestart = false;
export let isMediaState = true;
export let packetSend: string;
export let controller: any = null;
let checkConfigure = true;
let ended = false;
// @ts-ignore
let interval;
let  retries = 0;
// @ts-ignore
let timerInterval = null;
let activeSpeakerId = '';

export function setAudio(audio: boolean) {
    return {
        type: SET_AUDIO_STATE,
        audio
    };
}
export function setAudioPermission(permission: string) {
    return {
        type: SET_AUDIO_PERMISSION,
        permission
    }
}
export function setVideo(video: boolean) {
    return {
        type: SET_VIDEO_STATE,
        video
    }
}

export function setVideoPermission(permission: string) {
    return {
        type: SET_VIDEO_PERMISSION,
        permission
    }
}

export function setIsWebCam(mode: boolean) {
    return {
        type: SET_IS_WEB_CAM,
        mode
    }
}


export function setHoolvaConnection(hoolva: any) {
    return {
        type: SET_HOOLVA_CONNECTION,
        hoolva
    }
}
export function setPublisherPluginHandle(pluginHandle: any) {
    return {
        type: SET_PUBLISHER_PLUGIN_HANDLE,
        pluginHandle
    }
}

export function setCurrentMeetingInfo(msg: any) {
    const {topic, id, private_id, admin, start_time} = msg;
    return{
        type: SET_CURRENT_MEETING_INFO,
        topic,
        id,
        private_id,
        admin,
        start_time,
    }
}
export function setWaitingRoomParticipants(participants: any) {
    return {
        type: SET_WAITING_PARTICIPANTS,
        participants
    }
}
export function setDids(didsList: any) {
    const primaryDid = didsList.length > 0 ? didsList[0].did : '';
    return {
        type: SET_DIDS,
        didsList,
        primaryDid: primaryDid
    }
}

export function setDefaultOutputDevice(deviceId: string, label: string) {
    return {
        type: SET_SELECTED_OUTPUT_DEVICE,
        label,
        deviceId
    }
}
export function allowUnMute(mode: boolean) {
    return {
        type: SET_ALLOW_UNMUTE,
        mode
    }
}
export function allowLocalUnmute(localUnmuteCheckBox: boolean) {
    return {
        type: SET_LOCAL_UNMUTE_CHECK_STATE,
        localUnmuteCheckBox
    }
}

export function setDefaultVideoDevice(deviceId: string, label: string) {
    return {
        type: SET_SELECTED_VIDEO_DEVICE,
        label,
        deviceId
    }
}
export function setDefaultAudioDevice(deviceId: string, label: string) {
    console.log("setDefaultAudioDevice", deviceId, label)
    return {
        type: SET_SELECTED_AUDIO_DEVICE,
        label,
        deviceId
    }
}
export function setDataMode(mode: boolean) {
    return {
        type: SET_DATA_MODE,
        mode
    }
}

export function setVideoResolutions(width: number, height: number) {
    return {
        type: SET_VIDEO_RESOLUTIONS,
        width,
        height
    }
}

export function setChatMode(mode: boolean) {
    return {
        type: SET_CHAT_MODE,
        mode
    }
}

export function setChatNotification(count: number) {
    return {
        type: SET_CHAT_NOTIFICATION_COUNT,
        count
    }
}
export function setRecordState(recordState: boolean) {
    return {
        type: SET_RECORD_STATE,
        recordState
    }
}

export function setConfirmMessage(type: string, mode: boolean, data: any) {
    return async function(dispatch: any, getState: any) {
        const {t} = getState().Auth;
        let confirmData = {
            title: '',
            message: '',
            open: mode,
            type: type,
            data: {}
        };
        if (mode) {
            if (type === 'unmuteAudio') {
                confirmData.title = t('meetingPage.unmuteMicroPhoneRequestTitle');
                confirmData.message = t('meetingPage.unmuteMicroPhoneRequestMessage');
                confirmData.open = true;
                confirmData.type = type;
                confirmData.data = data;
            } else if (type === 'unmuteVideo') {
                confirmData.title = t('meetingPage.startCameraRequestTitle');
                confirmData.message = t('meetingPage.startCameraRequestMessage');
                confirmData.open = true;
                confirmData.type = type;
                confirmData.data = data;
            }
            if (type === 'kick') {
                confirmData.title = t('meetingPage.kickOutTitle');
                confirmData.message = t('meetingPage.kickOutConfirmMessage');
                confirmData.open = true;
                confirmData.type = type;
                confirmData.data = data;
            }

        }
        dispatch(setConfirmData(confirmData));
    }

}
export function setConfirmData(confirmData: any) {
    return {
        type: SET_CONFIRM_DATA,
        data: confirmData
    }
}
export function disableVideoButton(mode: boolean){
    return {
        type: SET_DISABLE_VIDEO_BUTTON,
        mode
    }
}

export function setPublishersList(publishers: any[]) {
    return {
        type: SET_PUBLISHERS_LIST,
        publishers
    }
}

export function setLatencyMode(mode: string) {
    return {
        type: SET_LATENCY_MODE,
        mode
    }
}

export function setCurrentSlider(slider: number) {
    return {
        type: SET_CURRENT_SLIDER,
        slider
    }
}

export function setMessageList(messages: any) {
    return {
        type: SET_MESSAGE_LIST,
        messages
    }
}

export function setDetectSpeech(speechMode: boolean) {
    return {
        type: SET_SPEECH_DETECTION,
        speechMode
    }
}
export function setRaiseHand(mode: boolean) {
    return {
        type: SET_RAISE_HAND,
        mode
    }
}
export function setIceState(state: string) {
    return {
        type: SET_ICE_STATE,
        state
    }
}

export function setFeedBack(mode: boolean) {
    return {
        type: SET_FEED_BACK,
        mode
    }
}
export function setJoinMode(mode: string) {
    return {
        type: SET_JOIN_MODE,
        mode
    }
}

export function setConnectivityMode(mode: string) {
    return {
        type: SET_CONNECTIVITY_MODE,
        mode
    }
}
export function setActiveScreen(screen: number) {
    return async function(dispatch: any, getState: any) {
        const {meetingInfo} = getState().Hoolva;
        if(screen === 2) {
            if(activeSpeakerId === '') {
                const handle = participants[0];
                dispatch(setActiveSpeaker(handle));
                dispatch(setScreen(screen));
                if(!meetingInfo.audio_bridge) {
                    if(document.getElementById('activeSpeakerWidget')) {
                        Janus.attachMediaStream(document.getElementById('activeSpeakerWidget'), handle.isPublisher ? handle.webrtcStuff.myStream : handle.webrtcStuff.remoteStream);
                    }

                } else if(handle.video === 'unmuted'){
                    if(document.getElementById('activeSpeakerWidget')) {
                        Janus.attachMediaStream(document.getElementById('activeSpeakerWidget'), handle.isPublisher ? handle.webrtcStuff.myStream : handle.webrtcStuff.remoteStream);
                    }

                }
                $('#activeSpeakerName').html(handle.rfdisplay);
            } else {
                if(participants.length > 0) {
                    if (participants.findIndex(data => data.rfid === activeSpeakerId) !== -1) {
                       const handle = participants[participants.findIndex(data => data.rfid === activeSpeakerId)];
                        dispatch(setActiveSpeaker(handle));
                        dispatch(setScreen(screen));
                        if(!meetingInfo.audio_bridge) {
                            Janus.attachMediaStream(document.getElementById('activeSpeakerWidget'), handle.isPublisher ? handle.webrtcStuff.myStream : handle.webrtcStuff?.remoteStream);
                        } else if(handle.video === 'unmuted') {
                            Janus.attachMediaStream(document.getElementById('activeSpeakerWidget'), handle.isPublisher ? handle.webrtcStuff?.myStream : handle.webrtcStuff?.remoteStream);
                        }

                        $('#activeSpeakerName').html(handle.rfdisplay);
                    }
                }
            }
        } else {
            dispatch(setScreen(screen));
        }
    }


}

export function setScreen(screen: number){
    return {
        type: SET_ACTIVE_SCREEN,
        screen
    }
}
export function setActiveSpeaker(speaker: any) {
    return {
        type: SET_ACTIVE_SPEAKER,
        speaker
    }
}

export function setCloudRecordingStatus(status: string) {
    return {
        type: SET_CLOUD_RECORDING_STATUS,
        status
    }
}


export function setLocalCloudRecordingStatus(status: string) {
    console.log("setLocalCloudRecordingStatus", status)
    return {
        type: SET_LOCAL_CLOUD_RECORDING_STATUS,
        status
    }
}

export function setDisplayNameChange(mode: boolean) {
    return {
        type: SET_DISPLAY_NAME_CHANGE,
        mode
    }
}
export function setMaxTileLimit(limit: number) {
    return {
        type: SET_MAX_TILE_LIMIT,
        limit
    }
}

export function setShowWaitingModal(show: boolean) {
    return {
        type: SHOW_WAITING_MODAL,
        show
    }
}
export function setPeopleTab(people: boolean) {
    return {
        type: SET_PEOPLE_TAB,
        people
    }
}

export function setNotification(notify: boolean) {
    return {
        type: SET_NOTIFICATION_ALERT,
        notify
    }
}

export function setJoinedState(state: boolean) {
    return {
        type: SET_JOINED_STATE,
        state
    }
}

export function preventShowWaitingModal(mode: boolean) {
    return {
        type: PREVENT_WAITING_MODAL,
        mode
    }
}

export function setWebrtcStatus(mode: boolean) {
    return {
        type: SET_WEBRTC_STATUS,
        mode
    }
}

export function setWatingRoomLoader(mode: boolean) {
    return {
        type: SET_WAITING_ROOM_LOADER,
        mode
    }
  }

export function resetMeet() {
    return{
        type: RESET_MEET
    };
}

export function initMeet() {
    return async function(dispatch: any, getState: any) {
        participants = [];
        activeSpeakers = [];
        messageArray = [];
        connectedVideo = false;
        ended = false;
        isIceRestart = false;
        isMediaState = true;
        retries = 0;
        Janus.init({
            debug: env === 'development' ? 'all' : false,
            callback() {
               // dispatch(setAudio(true));
                if(!Janus.isWebrtcSupported()) {
                    console.log('webrtc not supported');
                }
            }
        })
    }
}

export function getLoginClientData(data: any) {
    return async function(dispatch: any, getState: any) {
        const response = await Service.loginClient(getState().Hoolva.API_ROOT, data);
        return response;
    }
}

export function getDIDs(data: any) {
    return async function(dispatch: any, getState: any) {
        const response:any = await Service.getDids(getState().Hoolva.API_ROOT, data);
        console.log("didsList",response);
        if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 203 || response.status === 204) {
            dispatch(setDids(response.data));
        }
    }
}



export function getMeetingInfo(meetingInfo: any) {
    return async function(dispatch: any, getState: any) {
        const response:any = await Service.getCurrentMeetingInfo(getState().Hoolva.API_ROOT, meetingInfo);
        response.nonCromeE2e = false;
        if (response.status === 401) {
            history.push(routes.JOINAMEETING);
            dispatch(setMeetingInfo(null));
        } else if (response.status === 200) {
            if(!DetectRTC.browser.isChrome && response.data.e2ee){
                response.nonCromeE2e = true; // Update 505 HTTP Version Not Supported response 
            }else{
                await dispatch(setMeetingInfo(response.data));
                if(response.data.auto_record && response.data.host) {
                    dispatch(setLocalCloudRecordingStatus('start'));
                }
                dispatch(setJoinMode(response.data.join_mode));
                dispatch(saveMeetingToLocalDb(response.data));
                if(meetingInfo.sname) {
                    dispatch(HoolvaActions.setMeetingId(response.data.id + ''));
                    dispatch(HoolvaActions.setMeetingPassword(response.data.password ? response.data.password : ''));
                }
                history.push(routes.JOINAMEETING);
            }
        } else {
            await dispatch(setMeetingInfo(response.data));
        }
        return response;
    }
}
export function saveMeetingToLocalDb(meetingInfo: any) {
    return async function (dispatch: any, getState: any) {
        const {meetingList, dbStore} = getState().Auth;
        const meetingId = meetingInfo.id;
        let index = meetingList.findIndex((data: any) => data.value == meetingId);

        if(index === -1) {

            if(meetingList.length === 0) {
                LocalDb.setMeetingList(dbStore, {type: 'meetingList', meetingList: [{label: meetingInfo.name, value: meetingId}]}, (_meetingListResponse: any) => {

                });
            } else {
                const newMeetingList = [{label: meetingInfo.name, value: meetingId}]
                const updatedMeetingList = [...newMeetingList, ...meetingList];
                const toSaveMeetingList = updatedMeetingList.slice(0, 10);
                const seen = new Set();
                const filteredArr = toSaveMeetingList.filter(el => {
                    const duplicate = seen.has(el.value);
                    seen.add(el.value);
                    return !duplicate;
                });
                LocalDb.updateMeetingList(dbStore, filteredArr, (updateList: any) => {

                });
            }
            dispatch(setMeetingList([{label: meetingInfo.name, value: meetingId}], false));
        } else {
            if(meetingList.length === 0) {
                LocalDb.setMeetingList(dbStore, {type: 'meetingList', meetingList: [{label: meetingInfo.name, value: meetingId}]}, (meetingListResponse: any) => {

                });
                dispatch(setMeetingList([{label: meetingInfo.name, value: meetingId}], true));
            } else {
                const newMeetingList = [{label: meetingInfo.name, value: meetingId}]
                const updatedMeetingList = [...meetingList].filter(meeting => {return meeting.value !== meetingId});
                const toSave = [...newMeetingList, ...updatedMeetingList];
                const toSaveMeetingList = toSave.slice(0, 10);
                const seen = new Set();
                const filteredArr = toSaveMeetingList.filter(el => {
                    const duplicate = seen.has(el.value);
                    seen.add(el.value);
                    return !duplicate;
                });
                LocalDb.updateMeetingList(dbStore, filteredArr, (updateList: any) => {

                });
                dispatch(setMeetingList(filteredArr, true));
            }

        }
    }
}

export function connectHoolvaServer(reconnect: boolean) {
    return async function(dispatch: any, getState: any) {
        const {clientAuth, loggedInData} = getState().Auth;
        const {meetingInfo, displayName} = getState().Hoolva;
        participants = [];
        activeSpeakers = [];
        connectedVideo = false;
        isIceRestart = false;
        isMediaState = true;
        activeSpeakerId = '';
        ended = false;
        let isWarning=true

        const {t} = getState().Auth;
        const debugMode = displayName.split('-');
        let debug = false;
        if(debugMode.length === 2) {
            if(debugMode[1] === 'debug') {
                debug = true
            }
        }

        let userToken = null;
        if(loggedInData) {
            userToken =  localStorage.getItem("rtkiops");
        }

        const hoolva: any = await new Janus({
            server: getState().Hoolva.SERVER_ROOT,
            room: +getState().Hoolva.meetingId,
            name: getState().Hoolva.displayName,
            userToken: userToken,
            iceServersAudio: clientAuth.ice.iceServersAudio,
            iceServers: clientAuth.ice.iceServers,
            iceTransportPolicyAudio: clientAuth.ice.iceTransportPolicyAudio,
            iceTransportPolicy: clientAuth.ice.iceTransportPolicy,
            bundlePolicyAudio: clientAuth.ice.bundlePolicyAudio,
            bundlePolicy: clientAuth.ice.bundlePolicy,
            type: meetingInfo.audio_bridge ? 'mixedRoom' : 'videoRoom',
            mode: debug? 'development' : env,
            doc: debug? new jsPDF() : env === 'production' ? null : new jsPDF(),
            latencyRange: {
                low : 250,
                medium: 450,
                high: 1000
            },
            SFrame: SFrame,
            // reconnect,
            //sessionId: getState().Hoolva.sessionId,
            async success(data: any) {
                hoolva.printLog({status: 'success', data})
                await dispatch(HoolvaActions.setSessionId(data.sessionId));
                await dispatch(setHoolvaConnection(hoolva));
                if(getState().Hoolva.meetingId === '') {
                    hoolva.destroy();
                    dispatch(setHoolvaConnection(null));
                }
               // dispatch(attachVideoPlugin());
            },
            error(err: any) {
                if(retries > 3) {
                    hoolva.printLog({status: 'ended', message: 'Try reconnection for 3 times'});
                    dispatch(endMeeting({title: t('meetingPage.error4401Title'),
                        message: t('meetingPage.error4401'),
                        code: 4401}))
                } else {
                    if(!ended) {
                        hoolva.printLog({status: 'reconnecting', message: 'Try to reconnect..', attempt: retries})
                        retries = retries + 1;
                        resetIceRestartAudioRoom();
                        resetIceRestartScreenShare();
                        dispatch(autoReconnect());
                        history.push(routes.RECONNECT);
                    }
                }

            },
            applicationMessage(msg: any) {

                if(msg.application === 'network') {
                    console.log('netwok message came',  msg)
                    if(Config.configuration.simulcast) {
                        if(getState().Meet.latencyMode === 'HIGH' && (msg.latencyMode === 'MEDIUM' || msg.latencyMode === 'LOW')) {
                            dispatch(upgradeSubstreamBasedOnNetwork(msg.latencyMode));
                        } else if(getState().Meet.latencyMode === 'MEDIUM' && msg.latencyMode === 'LOW') {
                            dispatch(upgradeSubstreamBasedOnNetwork(msg.latencyMode));
                        } else if (msg.latencyMode === 'LOW') {
                            dispatch(upgradeSubstreamBasedOnNetwork(msg.latencyMode));
                        }
                        else if(msg.latencyMode === 'VERY_HIGH') {
                            dispatch(turnOffSubscriberVideo())
                            if(getState().Meet.video) {
                                dispatch(setNotificationMessage('Your video is turned off due to poor network conditions'));
                                dispatch(toggleVideo(false));
                            }
                        }
                        dispatch(setLatencyMode(msg.latencyMode));
                    }
                }
                if(msg.application === 'pong') {
                    if (isIceRestart ) {
                        dispatch(iceRestart());
                    }
                    if(isAudioIceRestart) {
                        dispatch(iceAudioRestart());
                    }
                    if(screenShareIceRestart) {
                        dispatch(iceRestartScreenShare());
                    }

                } else if(msg.screenshare === 'failed') {
                    dispatch(ScreenShareAction.setScreenShareButtonState(true));
                    if(msg.reason === 'max_limit_reached') {
                        // dispatch(showMessage('warn', 'Screen share max limit exceeded' , {}));
                        dispatch(setNotificationMessage(t('ScreenShare.ScreenShareMaxLimitExceeded')));
                    }
                }
                if (msg.videoroom === "failed") {
                  if (msg.reason === "max_limit_reached") {
                    const datas={
                      title:t("meetingPage.PleaseTryAgainLater"),
                      open:true,
                      mode:true,
                    //   message: t("meetingPage.This meeting has reached the maximum number of allowed participants. Please try again later")
                    message: t("meetingPage.max_limit_reached")

                    }
                      // dispatch(hoolvaConfirmMessage(message));
                      dispatch(setConfirmData(datas));
                    }
                    if (msg.reason === "max_concurrent_limit_reached") {
                        const datas={
                            title:t("meetingPage.PleaseTryAgainLater"),
                            open:true,
                            mode:true,
                            // message: t("meetingPage.Another meeting is already in progress for this Host. Please try again later")
                            message: t("meetingPage.max_concurrent_limit_reached")

                        }
                            // dispatch(hoolvaConfirmMessage(message));
                            dispatch(setConfirmData(datas))
                    }
                }
                if (msg.videoroom === "max_duration_reached") {
                    const datas={
                        key :"max_duration_reached",
                        title:t("meetingPage.TimeOut"),
                        open:true,
                        mode:true,
                        message: t("meetingPage.max_duration_reached")
                      }
                      dispatch(setConfirmData(datas));
                  console.log("time limiteeeeeeeee",msg);
                }
                if (msg.videoroom === "almost_duration_notification"&&isWarning){
                    isWarning = !isWarning
                    const datas={
                        key :"almost_duration_notification",
                        title:t("meetingPage.TimeOut"),
                        open:true,
                        mode:true,
                        message: t("meetingPage.almost_duration_notification1") + msg.remaining_time + t("meetingPage.almost_duration_notification2")                 
                    }
                    dispatch(setConfirmData(datas));
                }
            },
            applicationVideoRoomMessage(msg: any) {
                const {t} = getState().Auth;
                const {meetingInfo} = getState().Hoolva;
                if(msg.event === 'mute_request') {
                    dispatch(setNotificationMessage(t('meetingPage.putMuteByHost')));
                    if(meetingInfo.audio_bridge) {
                        dispatch(toggleMixedAudioMute(false));
                    } else {
                        dispatch(toggleMute(false));
                    }

                } else if(msg.event === 'mute_order') {
                    dispatch(allowUnMute(false));
                    dispatch(setNotificationMessage(t('meetingPage.putMuteByHost')));
                    if(meetingInfo.audio_bridge) {
                        dispatch(toggleMixedAudioMute(false));
                    } else {
                        dispatch(toggleMute(false));
                    }
                } else if(msg.event === 'unmute_request') {
                    dispatch(allowUnMute(true));
                    if(!getState().Meet.audio) {
                        dispatch(setConfirmMessage('unmuteAudio', true, {}));
                    }

                    // dispatch(toggleMute(true));
                } else if(msg.event === 'kickout') {
                    dispatch(endMeeting({title: t('meetingPage.kickOutTitle'),
                        message: t('meetingPage.kickOutMessage'),
                        code: ''}));
                } else if (msg.event === 'video_off_request') {
                    dispatch(setNotificationMessage(t('meetingPage.turnOffVideoByHost')));
                    dispatch(toggleVideo(false));
                } else if(msg.event === 'video_on_request') {
                    dispatch(setConfirmMessage('unmuteVideo', true, {}));
                  //  dispatch(toggleVideo(true));
                } else if(msg.event === 'raisehand_request_response') {
                    dispatch(setRaiseHand(true))
                    participants[0].raisehand = true;
                    dispatch(setPublishersList(participants));
                } else if(msg.event === 'lowerhand_request_response') {
                    dispatch(setRaiseHand(false))
                    participants[0].raisehand = false;
                    dispatch(setPublishersList(participants));
                } else if(msg.event === 'raisehand_request') {
                    console.log('Raisehand request')
                    if(participants.length > 0) {
                        if (participants.findIndex(data => data.rfid === msg.from_participant_id) !== -1) {
                            participants[participants.findIndex(data => data.rfid === msg.from_participant_id)].raisehand = true;
                            dispatch(setPublishersList(participants));
                        }
                    }
                } else if(msg.event === 'lowerhand_request') {
                    if(participants.length > 0) {
                        if (participants.findIndex(data => data.rfid === msg.from_participant_id) !== -1) {
                            participants[participants.findIndex(data => data.rfid === msg.from_participant_id)].raisehand = false;
                            dispatch(setPublishersList(participants));
                        }
                    }
                } else if (msg.event === 'remote_lowerhand_request') {
                    dispatch(sendRaiseHandRequest('lowerhand_request', +getState().Meet.publisherHandle.rfid))
                } else if(msg.event === 'end_meeting_request') {
                    dispatch(endMeeting({title: t('meetingPage.MeetingEnded'),
                        message: t('meetingPage.HostEndTheMeeting'),
                        code: 4405}))
                } else if(msg.event === 'end_meeting_request_response') {
                    dispatch(endMeeting(null));
                } else if(msg.event === 'change_display_response') {
                    dispatch(setDisplayNameChange(true));
                    const timerDisplay = setTimeout(() => {
                        dispatch(setDisplayNameChange(false));
                        if(timerDisplay) {
                            clearTimeout(timerDisplay)
                        }
                    }, 3000);

                } else if(msg.event === 'change_display') {
                    if(participants.length > 0) {
                        if (participants.findIndex(data => data.rfid === msg.from_participant_id) !== -1) {
                            participants[participants.findIndex(data => data.rfid === msg.from_participant_id)].rfdisplay = msg.update.display;
                            dispatch(setPublishersList([...participants]));
                        }
                    }
                } else if (msg.event === 'join_response') {
                    console.log('join response message', msg);
                    if(msg.status === 'open') {
                    const xqMsgInfo = {serviceAvailable:false,hostSessionId:'',keyLocator:'', key:''};
                        if(meetingInfo.e2ee) {
                            const sessionId = getState().Hoolva.sessionId;
                            XQMessageApi.setup(meetingInfo.e2ee_settings.app_id,"");
                            XQMessageApi.authorizeHostAndGetKeyLocator(sessionId, sessionId.toString(), "@alias.local", 256, 5)
                                .then((result:any) => {
                                        if(result !== null) {
                                            xqMsgInfo.serviceAvailable = true;
                                            xqMsgInfo.hostSessionId = sessionId;
                                            xqMsgInfo.keyLocator = result.keyLocator;
                                            xqMsgInfo.key = result.key;
                                            dispatch(attachVideoPlugin())
                                        } else {
                                            xqMsgInfo.serviceAvailable = false;
                                            console.log("something bad happened from xq please handle this")
                                        }
                                        dispatch(HoolvaActions.setXqMessagesInfo(xqMsgInfo));
                                        console.log("XQ.authorizeHostAndGetKeyLocator::", result);
                                    }
                                )
                                .catch(err => {
                                        xqMsgInfo.serviceAvailable = false;
                                        console.log('Error::XQ.authorizeHostAndGetKeyLocator::',err);
                                        console.log("something bad happened from xq please handle this")
                                    }
                                )
                        } else {
                            dispatch(attachVideoPlugin())
                        }
                    } else  if(msg.status === 'failed'){
                        dispatch(endMeeting({title: t('meetingPage.error4401Title'),
                            message: t('Room does not exist'),
                            code: 4406}))
                    } else{
                        history.push({pathname: routes.WAITINGROOM, state: msg.status});
                    }
                } else if (msg.event === 'join_requests') {
                    const {waitingList, preventWaitingModal, people} = getState().Meet;
                    const {meetingInfo} = getState().Hoolva;
                    const participants = msg.participants;
                    console.log('participants in waiting room', participants);
                    const waitingParticipant = [...waitingList, ...participants];
                    console.log('Notification in waiting room', getState().Meet.notifyAlert, getState().Meet.joinedState)
                    if(getState().Meet.notifyAlert && !getState().Hoolva.windowMode) {
                         showNotification(participants[0].name +' is in waiting room');
                        dispatch(setNotification(false));
                    }else if(getState().Meet.joinedState && !getState().Hoolva.windowMode) {
                        const currentParticipantList = getState().Meet.publishersList.filter((participant: any) => {
                            return participant.removed !== true;
                        });
                        console.log('notification after', currentParticipantList.length);
                        if(currentParticipantList.length === 1) {
                            showNotification(participants[0].name +' is in waiting room');
                            dispatch(setNotification(false));
                        }
                    } else if (!getState().Meet.joinedState && !getState().Meet.notifyAlert && !getState().Hoolva.windowMode) {
                        const currentParticipantList = getState().Meet.publishersList.filter((participant: any) => {
                            return participant.removed !== true;
                        });
                        console.log('notification after deny', currentParticipantList.length);
                        if(currentParticipantList.length === 1) {
                            showNotification(participants[0] .name +' is in waiting room');
                            dispatch(setNotification(false));
                        }
                    }
                    dispatch(setWaitingRoomParticipants(waitingParticipant));
                    if(meetingInfo.host && !preventWaitingModal && !people && participants.length > 0) {
                        dispatch(setShowWaitingModal(true));
                    }

                } else if (msg.event === 'join_notification') {
                    if(msg.enter) {
                        if(meetingInfo.e2ee) {
                            const xqMsgInfo = {serviceAvailable:msg.e2ee_token.serviceAvailable,hostSessionId:msg.e2ee_token.hostSessionId,keyLocator:msg.e2ee_token.keyLocator, key:''};
                            if(xqMsgInfo.serviceAvailable) {
                                XQMessageApi.setup(meetingInfo.e2ee_settings.app_id,"");
                                XQMessageApi.authorizeAndRetrieveKey(xqMsgInfo.hostSessionId, xqMsgInfo.keyLocator)
                                    .then((result:any) => {
                                            if(result !== null) {
                                                xqMsgInfo.key = result;
                                                console.log('xq msg info', xqMsgInfo);
                                                dispatch(HoolvaActions.setXqMessagesInfo(xqMsgInfo));
                                                dispatch(attachVideoPlugin());
                                            } else {
                                                console.log("something bad happened from xq please handle this join notification")
                                            }
                                            console.log("XQ.authorizeAndRetrieveKey::", result);
                                        }
                                    )
                                    .catch(err => {
                                            xqMsgInfo.serviceAvailable = false;
                                           // dispatch(HoolvaActions.setXqMessagesInfo(xqMsgInfo));
                                            console.log("something bad happened from xq please handle this join notification")
                                            console.log('Error::XQ.authorizeAndRetrieveKey::',err);
                                        }
                                    )
                            } else {
                                console.log("something bad happened from xq please handle this join notification")
                            }
                        } else {
                            dispatch(attachVideoPlugin());
                        }
                    } else {
                        dispatch(endMeeting({title: t('Host denied'),
                    message: t('Host denied you to enter this meeting'),
                    code: 4407}))
                    }
                } else if (msg.event === 'leave_notifications') {
                    const {waitingList, showWaitingModal} = getState().Meet;
                    HoolvaUtils.logger('waiting room leave_notifications', msg, 'info');
                    let removeWaitingList = [...waitingList];
                    const removedParticipants = msg.participants;
                    removedParticipants.map((participants :  any) => {
                        const sessionId = participants.session_id;
                        removeWaitingList = removeWaitingList.filter((list: any) => {
                            return list.session_id !== sessionId;
                        });
                    })
                    dispatch(setWaitingRoomParticipants(removeWaitingList));
                    if(removeWaitingList.length === 0 && showWaitingModal){
                        dispatch(setShowWaitingModal(false));
                    }

                }  else if(msg.event === 'set_join_mode_response') {
                    dispatch(setJoinMode(msg.join_mode));
                    if(msg.join_mode === 'open') {
                        dispatch(setWaitingRoomParticipants([]));
                    }
                } else if(msg.event === 'set_join_mode_notification') {
                    dispatch(setJoinMode(msg.join_mode));
                    if(msg.join_mode === 'open') {
                        dispatch(setWaitingRoomParticipants([]));
                        dispatch(setShowWaitingModal(false));
                    }
                }
                else if (msg.event === 'cloud_recording') {
                    if(msg.status === 'started') {
                        dispatch(setCloudRecordingStatus('start'));
                    } else {
                        dispatch(setCloudRecordingStatus(msg.status));
                    }
                }
                else if (msg.event === 'client_recording') {
                    if(msg.status === 'start') {
                        dispatch(setLocalRecordingNotification('start'));
                    } else {
                        dispatch(setLocalRecordingNotification(msg.status));
                    }
                }
                else if (msg.event === 'cloud_recording_ack') {
                    dispatch(setLocalCloudRecordingStatus(msg.status)); //stop, start
                } else if (msg.event === "switch_ice") {
                    console.log("Switch stun message from app server");
                    if(!DetectRTC.browser.isFirefox && !DetectRTC.browser.isSafari) {
                        dispatch(switchStunAudio())
                    }
                    
                }

            },
            onPongMessage(msg: any) {
                dispatch(receivedPing(msg))
            },
            async onChatMessage(msg: any) {
                const { xqMessagesInfo,meetingInfo } = getState().Hoolva;
                if(msg.event === "send_message_ack") {
                    const messageList = [...messageArray];
                    // messageList[messageList.findIndex((data: any) => data.transaction === msg.transaction)].status = 'sent';
                    // dispatch(setMessageList(messageList));
                    // messageArray = [...messageList];
                    const index = messageList.findIndex((data: any) => data.transaction === msg.transaction);
                    if(index !== -1 && !meetingInfo.e2ee) {
                        messageList[messageList.findIndex((data: any) => data.transaction === msg.transaction)].status = 'sent';
                        dispatch(setMessageList(messageList));
                        messageArray = [...messageList];
                    }


                } else {
                    if (msg.event === 'receive_message' || msg.event === 'receive_broadcast'){
                        if(!getState().Meet.chat) {
                            let count = getState().Meet.chatNotificationCount;
                            count = count + 1;
                            dispatch(setChatNotification(count));
                        }
                    }
                    if(!getState().Meet.chat && msg.type !== 'reaction') {
                      //  dispatch(showMessage('dark', msg.message, {}));
                    }
                    if(msg.type === 'reaction') {
                        if(participants.length > 0) {
                            if(msg.participant_id) {
                                if (participants.findIndex(data => data.rfid === msg.participant_id) !== -1) {
                                    participants[participants.findIndex(data => data.rfid === msg.participant_id)].reaction = msg.message;
                                    dispatch(setPublishersList(participants));
                                }
                            } else {
                                participants[0].reaction = msg.message;
                                dispatch(setPublishersList(participants));
                            }

                        }
                    } 
                    if(meetingInfo.e2ee) {
                        await XQMessageApi.decryptText(xqMessagesInfo.keyLocator,msg.message)
                        .then(response=>{
                            msg.message = response.decryptedText;
                        });                    
                    }

                    if(msg.type === 'file') {
                        let M = JSON.parse(msg.message);
                        msg.message = M.name;
                        msg.format = M.format;
                        msg.size = M.size;
                        msg.url = M.url;
                    }
                    let messageList = [...messageArray];
                    messageList.push(msg);
                    dispatch(setMessageList(messageList));
                    messageArray = [...messageList];
                }

            },
            destroyed() {
                HoolvaUtils.logger('Destroy messgae', {msg: 'Oh no Hoolva connection destroyed '}, 'error');
            }
        });

    }
}

export function attachVideoPlugin() {
    return async function(dispatch: any, getState: any) {
        dispatch(setWatingRoomLoader(true))
        const {hoolva, audio, video} = getState().Meet;
        const {meetingId, displayName, meetingInfo} = getState().Hoolva
        hoolva.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: 'hoolva_video_room',
                room: +meetingId,
                ptype: "publisher",
                displayName: displayName,
                meeting_uuid: meetingInfo.uuid,
                screenShare: false,
                audio: audio? 'unmuted' : 'muted',
                video: video? 'unmuted' : 'muted',
                host: meetingInfo.host,
                raisehand: false,
            async success(pluginHandle: any) {
                    hoolva.printLog({status: 'success-attach', data: {id: pluginHandle.id, room_uuid: pluginHandle.room_uuid}})
                    dispatch(setPublisherPluginHandle(pluginHandle));
                    dispatch(HoolvaActions.setRoomUUID(pluginHandle.room_uuid))
                    dispatch(joinAndConfigure(audio, video, false));
            },
            error(err: any) {
                    const {t} = getState().Auth;
                   // console.log('error attaching plugin', err);
                hoolva.printLog({error: 'error-attach', data: err})
                dispatch(endMeeting({title: t('meetingPage.error4401Title'),
                    message: t('meetingPage.error4401'),
                    code: 4402}))
            },
            consentDialog(on: boolean) {
                 console.log('consentDialog', on);
            },
            iceState(state: string) {
                 hoolva.printLog({status: 'ice-state', state});
                 if(!meetingInfo.audio_bridge) {
                     dispatch(setIceState(state));
                 }
                 if(state === 'failed') {
                     isIceRestart = true;
                     // if(!isMediaState) {
                     //     isIceRestart = false;
                     //     HoolvaUtils.logger('ice restart calling from icestate', '', 'info');
                      //   dispatch(iceRestart());
                     // } else {
                     //     isIceRestart = true;
                     // }
                 }
            },
            slowLink(uplink: boolean, lost: number) {
                console.log('slow link data in video room publisher handle', uplink, lost);
            },
            mediaState(medium: string, on: boolean) {
                    hoolva.printLog({status: 'media-state-videoroom', data: {started: on ? 'Started receiving' : 'Stopped receiving', medium}})
                let checkMedium = 'audio';
                if(meetingInfo.audio_bridge) {
                    checkMedium = 'video';
                }
                if(medium === checkMedium) {
                    isMediaState = on;
                }
                // if (!on && medium === checkMedium && isIceRestart) {
                //     isIceRestart = false;
                //     HoolvaUtils.logger('Ice restart calling from media state', '', 'info');
                //     dispatch(iceRestart());
                // }
            },
            webrtcState(on: boolean, reason: string, handle: any) {
                hoolva.printLog({status: 'webrtc-state-videoroom-publisher', data: ` WebRTC PeerConnection is ${
                        on ? 'up' : 'down'
                    } now`})
                if(on) {
                    handle.state = 'connected';
                    if(participants.length > 0) {
                        if (participants.findIndex(data => data.rfid === handle.rfid) !== -1) {
                            participants[participants.findIndex(data => data.rfid === handle.rfid)] = handle;
                            setPublishersList(participants)
                        }
                    }
                    if(!connectedVideo) {
                        if(getState().Hoolva.meetingInfo.audio_bridge) {
                            dispatch(AudioRoomActions.attachMixedAudioPlugin(getState().Meet.publisherId, false));
                        } else {
                            const{publisherHandle} = getState().Meet;
                            if(publisherHandle) {
                                const data = { event: 'publishers_list' };
                                publisherHandle.send({message: data});
                              //  publisherHandle.send({ event: 'publishers_list' });
                            }
                            history.push(routes.MEETING);
                        }
                    }
                    connectedVideo = true;
                }
                if(!on) {
                    const {t} = getState().Auth;
                    if (reason === 'ICE failed' || reason === 'DTLS timeout' || reason === 'trickle') {
                        hoolva.printLog({status: 'webrtc-state-failed', data: {message : 'ice failed publisher', reason}})
                        dispatch(setWebrtcStatus(false));
                    }
                }
            },
            onmessage(msg: any, jsep: any) {
                    dispatch(onMessagePublisher(msg, jsep));
            },
            onlocalstream(stream: MediaStream, handle: any) {
                    if(document.getElementById('video_' + handle.id) ) {
                        Janus.attachMediaStream(document.getElementById('video_' + handle.id), stream);
                        $(`#videoWidget${handle.id}`).html(handle.rfdisplay);
                    }

            },
            onremotestream(stream: MediaStream) {
                    console.log('remote stream',  stream);
            },
            oncleanup() {
                    console.log('::: Got a cleanup notification: we are unpublished now :::');
            }
        });
    }
}








export function findPacketSendInPublisher() {
    return async function(dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        // @ts-ignore
        let lastResult;
        const pc = publisherHandle.webrtcStuff.pc;
        interval = setInterval(() => {
            const sender = pc.getSenders()[0];
            if(sender) {
                sender.getStats().then((res: any) => {
                    res.forEach((report: any) => {
                        let packets;
                        if (report.type === 'outbound-rtp') {
                            if (report.isRemote) {
                                return;
                            }
                            packets = report.packetsSent;
                            // @ts-ignore
                            if (lastResult && lastResult.has(report.id)) {
                                // @ts-ignore
                                packetSend = packets - lastResult.get(report.id).packetsSent;
                                // // @ts-ignore
                                $(`#packet${publisherHandle.id}`).html(packetSend);
                                // console.log('packet send', packetSend, packets, lastResult.get(report.id).packetsSent)
                            }

                        }
                    });
                    lastResult = res;
                });
            }

        }, 1000)

    }
}

export function onMessagePublisher(msg: any, jsep: any) {
    return async function(dispatch: any, getState: any) {
        const {publisherHandle, latencyMode} = getState().Meet;
        const {meetingInfo} = getState().Hoolva;
        const event: any = msg.videoroom;
        console.log("messssssage",msg.videoroom)

        if (event !== undefined && event !== null) {
            if (event === 'joined') {
                console.log('publisher joined message', msg, msg.publishers_count);
                dispatch(disableVideoButton(false));
                dispatch(handleJoinedRoom(msg));
                if(msg.publishers_count === 1) {
                    dispatch(setNotification(true));
                }
                if(meetingInfo.audio_bridge) {
                    //dispatch(AudioRoomActions.attachMixedAudioPlugin(msg.id));
                }
               // dispatch(startPing())
              //  dispatch(findPacketSendInPublisher());
                // @ts-ignore
                if(timerInterval) {
                    // @ts-ignore
                    clearInterval(timerInterval);
                }
                timerInterval = setInterval(() => {
                    dispatch(timer(getState().Meet.timeInSec));
                }, 1000)

            }
            else if (event === 'screenshare') {
                console.log('screen share message');
                // dispatch(ScreenShareActions.subscribeScreenShare(msg.id, msg.room))
            }
            else if (event === 'stop_share') {
                console.log('stop screen share message');
            }
            else if (event === 'video_muted') {
                //console.log('remote participant video_mute message', msg);
                if(participants.length > 0) {
                    if (participants.findIndex(data => data.rfid === msg.id) !== -1) {
                        if(meetingInfo.audio_bridge) {
                            const currentParticipant = participants[participants.findIndex(data => data.rfid === msg.id)];
                            if(currentParticipant.hasOwnProperty('detach')) {
                                currentParticipant.detach();
                            }
                            currentParticipant.detached = true;
                            currentParticipant.state = 'connected';
                            currentParticipant.video = 'muted';
                            if(currentParticipant.rfid === getState().Meet.activeSpeaker?.rfid) {
                                dispatch(setActiveSpeaker(currentParticipant))
                            }
                            participants[participants.findIndex(data => data.rfid === msg.id)] = currentParticipant;
                            dispatch(setPublishersList(participants));
                            if(Config.configuration.simulcast) {
                                dispatch(optimizeBandWidthBasedOnVideoParticipant(true));
                            }
                            const {screenSharePublisherHandle, screenShareParticipants} = getState().ScreenShare;
                            const {recorderMode, recorder, audioParticipant, videoParticipant} = getState().Record;
                            if(recorderMode === 'gallery' && recorder) {
                                const currentScreenShareList = screenShareParticipants.filter((participant: any) => {
                                    return participant.removed !== true
                                });
                                if(currentScreenShareList.length === 0 &&  !screenSharePublisherHandle) {
                                    const recorederList = [...audioParticipant, ...videoParticipant];
                                    const index = recorederList.findIndex((participant: any) => participant.rfid == msg.id);
                                    if(index !== -1) {
                                        dispatch(updateRecording());
                                    }
                                }
                            }
                        } else {
                            participants[participants.findIndex(data => data.rfid === msg.id)].video = 'muted';
                            dispatch(setPublishersList(participants));
                        }

                    }
                }
            }
            else if (event === 'video_unmuted') {
              //  console.log('remote participant video_unmute message', msg);
                if(participants.length > 0) {
                    if (participants.findIndex(data => data.rfid === msg.id) !== -1) {
                        if(meetingInfo.audio_bridge) {
                            const currentParticipant = participants[participants.findIndex(data => data.rfid === msg.id)];
                            if(checkParticipantInCurrentSlide(currentParticipant, getState().Meet.currentSlider, getState().Meet.maxTileinslider) || (getState().Meet.activeSpeaker?.rfid === msg.id &&  getState().Meet.activeScreen !== 1)) {

                                if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                                    setTimeout(() => {
                                    dispatch(newRemoteFeed(currentParticipant.rfid, currentParticipant.rfdisplay, currentParticipant.audio, 'unmuted', currentParticipant.host, currentParticipant.raisehand, currentParticipant.room_part_id,currentParticipant.profile_picture));
                                    },200)
                                } else {
                                    participants[participants.findIndex(data => data.rfid === msg.id)].video = 'unmuted';
                                    dispatch(setPublishersList(participants));
                                }
                            } else {
                                participants[participants.findIndex(data => data.rfid === msg.id)].video = 'unmuted';
                                setTimeout(() => {dispatch(setPublishersList(participants))}, 1000)
                            }

                        } else {
                            participants[participants.findIndex(data => data.rfid === msg.id)].video = 'unmuted';
                            setTimeout(() => {dispatch(setPublishersList(participants))}, 1000)
                        }

                    }
                }

            }
            else if (event === 'mute' || event === 'mute_all') {
                console.log('mute Auth request');
            }
            else if (event === 'unmute' || event === 'unmute_all') {
                console.log('unmute Auth request');
            }
            else if (event === 'muted') {
               // console.log('remote participant audio mute message', msg);
                if(participants.length > 0) {
                    if (participants.findIndex(data => data.rfid === msg.id) !== -1) {
                        participants[participants.findIndex(data => data.rfid === msg.id)].audio = event;
                        dispatch(setPublishersList(participants));
                    }
                }
            }
            else if (event === 'unmuted') {
              //  console.log('remote participant audio un mute message', msg);
                if(participants.length > 0) {
                    if (participants.findIndex(data => data.rfid === msg.id) !== -1) {
                        participants[participants.findIndex(data => data.rfid === msg.id)].audio = event;
                        dispatch(setPublishersList(participants));
                    }
                }

            }
            else if (event === 'allow_unmute') {
                console.log('admin allow to unmute Auth');
            }
            else if (event === 'disallow_unmute') {
                console.log('admin block toggle Auth');
            }
            else if (event === 'end_meeting') {
                console.log('admin end the meeting');
            }
            else if (event === 'start_record') {
                console.log('Admin started recording');
            }
            else if (event === 'stop_record') {
                console.log('admin stopped recording')
            }
            else if (event === 'destroyed') {
                console.log('room has been destroyed');
            }
            else if (event === 'talking') {
                const {screenShareParticipants} = getState().ScreenShare;
                const {activeScreen} = getState().Meet;
                if(participants.length > 0) {
                    if (participants.findIndex(data => data.rfid === msg.id) !== -1) {
                        participants[participants.findIndex(data => data.rfid === msg.id)].talking = true;
                        const handle = participants[participants.findIndex(data => data.rfid === msg.id)];
                        if(Config.configuration.bandWidthOptimization) {
                            dispatch(manageActiveSpeaker(handle));
                        }

                        //if(handle.webrtcStuff && handle.webrtcStuff.remoteStream) {
                      //  console.log('screenShareParticipants', screenShareParticipants);
                        const isScreenShare = screenShareParticipants.filter((screen : any) => screen.removed !== true);
                        if(activeSpeakerId !== msg.id && participants[0].rfid !== msg.id && isScreenShare.length === 0 && activeScreen === 2) {
                            dispatch(setActiveSpeaker(handle));
                            Janus.attachMediaStream(document.getElementById('activeSpeakerWidget'), handle.isPublisher ? handle.webrtcStuff.myStream : handle.webrtcStuff.remoteStream);
                            $('#activeSpeakerName').html(handle.rfdisplay);
                        }
                        if(participants[0].rfid !== msg.id) {
                            activeSpeakerId = msg.id;
                        }
                        dispatch(setPublishersList(participants));
                    }
                }
            }
            else if (event === 'stopped-talking') {
                if(participants.length > 0) {
                    if (participants.findIndex(data => data.rfid === msg.id) !== -1) {
                        participants[participants.findIndex(data => data.rfid === msg.id)].talking = false;
                        dispatch(setPublishersList(participants));
                    }
                }
            }
            else if (event === 'slow_link') {
                console.log('slow link message');
            }
            
            else if (event === 'event') {
            console.log("messssssage",msg)

                if(msg.error) {
                    publisherHandle.printLog({status: 'janus-error', data: {messgae: 'error in publishing', error: msg.error}})
                }
                if(msg.substream) {
                    console.log('substream message publisher', msg)
                }
                if(msg.configured === 'ok') {
                    console.log('configured successfull', msg);
                    if(msg.video_codec) {
                        dispatch(setVideo(true));
                        publisherHandle.video = 'unmuted';
                        const publisherList = [...participants];
                        publisherList[0] = publisherHandle;
                        participants = [...publisherList];
                        dispatch(setPublishersList(publisherList))
                        dispatch(disableVideoButton(false));
                    } else {
                        dispatch(setVideo(false));
                        publisherHandle.video = 'muted';
                        const publisherList = [...participants];
                        publisherList[0] = publisherHandle;
                        participants = [...publisherList];
                        dispatch(setPublishersList(publisherList))
                        dispatch(disableVideoButton(false));
                    }
                    if(msg.audio_codec) {
                        console.log('audio flowing !!!!')
                    } else {
                        // dispatch(setAudio(false))
                        // publisherHandle.audio = 'muted';
                        // const publisherList = [...participants];
                        // publisherList[0] = publisherHandle;
                        // participants = [...publisherList];
                        // dispatch(setPublishersList(publisherList))
                    }
                }
                if (msg.publishers !== undefined && msg.publishers !== null) {
                    console.log('handle new joiner', msg)

                    if(getState().Meet.notifyAlert && !getState().Hoolva.windowMode) {
                        console.log('notifiy first person joined if in bacground');
                        showNotification(msg.publishers[0].display + ' Joined');
                        dispatch(setNotification(false));
                    } else if(getState().Meet.joinedState && !getState().Hoolva.windowMode && !getState().Meet.notifyAlert) {
                        const currentParticipantList = participants.filter(participant => {
                            return participant.removed !== true;
                        });
                        if(currentParticipantList.length === 1) {
                            console.log('notifiy only one  person there in meeting and other joined if in background');
                            showNotification(msg.publishers[0].display + ' Joined');
                            dispatch(setNotification(false));
                        }
                    }
                    dispatch(handleNewJoiner(msg));
                    dispatch(setJoinedState(true));
                }
                else if (msg.leaving !== undefined && msg.leaving !== null) {
                    console.log('Leaving message', msg)
                    dispatch(handleParticipantLeave(msg));
                }
            }
        }
        if (jsep !== undefined && jsep !== null) {
            console.log('Handling SDP as well...');
            await getState().Meet.publisherHandle.handleRemoteJsep({jsep});
        }
    }
}
export function joinAndConfigure(audio: boolean, video: boolean, data: boolean) {
    return async function(dispatch: any, getState: any) {
        dispatch(disableVideoButton(true))
        const {publisherHandle, audio, video, audioPermission, videoPermission,
            selectedAudio, selectedVideo, audioDeviceName, videoDeviceName,
            outputDeviceName, videoResWidth, videoResHeight} = getState().Meet;
        const {ip, displayName, meetingId, systemId, meetingInfo, xqMessagesInfo} = getState().Hoolva;
        const {t} = getState().Auth;
        participants = [
            publisherHandle
        ];
        dispatch(setPublishersList(participants));
        DetectRTC.load(async function () {
            let mediaObject = {
                audioRecv: false,
                videoRecv: false,
                audioSend: meetingInfo.audio_bridge ? false : audioPermission === 'granted' ? DetectRTC.hasMicrophone : false,
                videoSend: video,
                audio: meetingInfo.audio_bridge? false: audioPermission === 'granted' ? DetectRTC.hasMicrophone ? selectedAudio !== '' ? {deviceId: selectedAudio,
                    echoCancellation: true,
                    noiseSuppression: true,autoGainControl: true} : true : false : false,
                video: videoPermission === 'granted' ? DetectRTC.hasWebcam ? video ? selectedVideo !== '' ? {
                    deviceId: selectedVideo,
                    width: { ideal: 4096 },
                    height: { ideal: 2160 },
                    aspectRatio: {exact: 1.777777778}
                    // width: {exact: videoResWidth}, // {max: Config.configuration.videoResolution.width, min: Config.configuration.videoResolution2.width},
                    // height: {exact: videoResHeight}, //{max: Config.configuration.videoResolution.height, min: Config.configuration.videoResolution2.height},
                    // aspectRatio: 1.777777778
                } : {
                    // width: {exact: videoResWidth}, //{max: Config.configuration.videoResolution.width, min: Config.configuration.videoResolution2.width},
                    // height: {exact: videoResHeight}, //{max: Config.configuration.videoResolution.height, min: Config.configuration.videoResolution2.height},
                    // aspectRatio: 1.777777778
                    width: { ideal: 4096 },
                    height: { ideal: 2160 },
                    aspectRatio: {exact: 1.777777778}
                } : false : false : false,
                data: meetingInfo?.audio_bridge?  videoPermission !== 'granted' ? true : DetectRTC.hasWebcam ? !video : true : (audioPermission !== 'granted' && videoPermission !== 'granted') ? true : false,
            };

            if(env === 'testing') {
                mediaObject = {
                    audioRecv: false,
                    videoRecv: false,
                    audioSend: !meetingInfo.audio_bridge, //audioPermission === 'granted' ? DetectRTC.hasMicrophone : false,
                    videoSend: true, //video,
                    audio: !meetingInfo.audio_bridge,//audioPermission === 'granted' ? DetectRTC.hasMicrophone ? selectedAudio !== '' ? {deviceId: selectedAudio} : true : false : false,
                    video: true,
                    data: meetingInfo.audio_bridge ? video ? false : true : false
                };
            }


            HoolvaUtils.logger('Join and configure mediaObject', xqMessagesInfo, 'success');
            let shared = null;
            if(meetingInfo.e2ee) {
                shared = await getRoomKey(meetingId, xqMessagesInfo.key);
            }
            console.log('meetingInfo.e2ee ? false : Config.configuration.simulcast,', meetingInfo.e2ee ? false : Config.configuration.simulcast)
            await publisherHandle.createOffer({
                media: mediaObject,
                simulcast2: meetingInfo.e2ee ? false : Config.configuration.simulcast,
                sframe: meetingInfo.e2ee ? {
                    outgoingId: 34212,
                    sharedKey: shared
                } : null,
                isPublisher: true,
                e2ee: true,
                success: async function (jsep: any) {
                    checkConfigure = true;
                    publisherHandle.printLog({status: 'configure-success', data: 'join and configure received jsep'})
                    dispatch(setDataMode(!DetectRTC.hasMicrophone || audioPermission !== 'granted'));
                    let publish = {
                        request: 'joinandconfigure',
                        audio: meetingInfo?.audio_bridge? false : audioPermission === 'granted' ? DetectRTC.hasMicrophone : false,
                        video: videoPermission === 'granted' ? DetectRTC.hasWebcam ? video : false : false,
                        data: meetingInfo?.audio_bridge?  videoPermission !== 'granted' ? true : DetectRTC.hasWebcam ? !video : true : (audioPermission !== 'granted' && videoPermission !== 'granted') ? true : false,
                        bitrate: 512000,
                        substream: Config.configuration.substream,
                        temporal: Config.configuration.temporal,
                        videocodec: 'vp8',
                        room: +meetingId,
                        ptype: 'publisher',
                        display: displayName,
                        uuid: '',
                        network: 'wifi',
                        device: DetectRTC.osName,
                        deviceid: systemId,
                        version,
                        platform: 'Web',
                        ip,
                        inputAudioDeviceName: audioDeviceName,
                        videoDeviceName,
                        outputAudioDeviceName: outputDeviceName,
                    };
                    if(env === 'testing') {
                        publish = {
                            request: 'joinandconfigure',
                            audio: true, //audioPermission === 'granted' ? DetectRTC.hasMicrophone : false,
                            video: true, //videoPermission === 'granted' ? DetectRTC.hasWebcam ? video : false : false,
                            data: false,
                            bitrate: Config.configuration.bitrate,
                            substream: Config.configuration.substream,
                            temporal: Config.configuration.temporal,
                            videocodec: 'vp8',
                            room: +meetingId,
                            ptype: 'publisher',
                            display: displayName,
                            uuid: '',
                            network: 'wifi',
                            device: DetectRTC.osName,
                            deviceid: systemId,
                            version,
                            platform: 'Web',
                            ip,
                            inputAudioDeviceName: audioDeviceName,
                            videoDeviceName,
                            outputAudioDeviceName: outputDeviceName
                        };
                    }
                    if(!meetingInfo.e2ee) {
                        //@ts-ignore
                        delete publish.bitrate;
                    }
                    HoolvaUtils.logger('join and configure request', publish, 'success');
                    await publisherHandle.send({message: publish, jsep});
                    publisherHandle.simulcastValue = Config.configuration.substream;
                    const publisherList = [...participants];
                    publisherList[0] = publisherHandle;
                    participants = [...publisherList];
                    dispatch(setPublishersList(publisherList));
                    if(!DetectRTC.hasMicrophone || audioPermission !== 'granted' || !audio && !meetingInfo?.audio_bridge) {
                        dispatch(toggleMute(false))
                    }
                },
                async error(err: any) {
                    publisherHandle.printLog({status: 'Error in join and configure', err})
                    const loggerMessage = {message: 'error in generating joinandconfigure request in video room', error: err, event: 'logger'};
                    if(publisherHandle) {
                        publisherHandle.send({message: loggerMessage});
                        publisherHandle.detach();
                        getState().Meet.hoolva.destroy();
                        dispatch(setHoolvaConnection(null))
                        confirmAlert({
                            title: 'Failed to get devices',
                            message: 'Unable to fetch your device properly. Please try again with a different device or join with video and audio turned off',
                            closeOnClickOutside: false,
                            buttons: [
                                {
                                    label: t('meetingPage.errorOkLabel'),
                                    onClick: async () => {
                                        history.push(routes.JOINAMEETING);
                                    }
                                }
                            ]
                        });
                    }
                    // if(checkConfigure) {
                    //     await dispatch(setVideo(false));
                    //     publisherHandle.video = 'muted';
                    //     dispatch(setPublisherPluginHandle(publisherHandle));
                    //     participants[participants.findIndex(data => data.id === publisherHandle.id)].video = 'muted';
                    //     dispatch(setPublishersList(participants));
                    //     dispatch(joinAndConfigure(false, false, true));
                    //     checkConfigure = false;
                    // }

                }
            })
        });
    }
}

export function handleJoinedRoom(msg: any) {
    return async function (dispatch: any, getState: any) {
       // console.log('joined messgae', msg, getState().Meet.publisherHandle);
        const {publisherHandle, latencyMode} = getState().Meet;
        const {displayName} = getState().Hoolva;
        publisherHandle.rfid = msg.id;
        dispatch(setCurrentMeetingInfo(msg));

        if (msg.publishers !== undefined && msg.publishers !== null) {
            const publishersList = msg.publishers;
            // publishersList.map((publisher: any, index: number) => {
            //     publisher.rfindex = index + 1;
            //     publisher.video = false;
            // });
            // const allPublisher = [...participants, ...publishersList];
            // participants = [...allPublisher];
            // dispatch(setPublishersList(participants));
            /**
             * subscribing all remote participants
             */
            publishersList.map((publishers: any,index:number) => {
               const {id, display, screenshare, audio, video, host, room_part_id} =  publishers;
                const raisehand = publishers.other.raisehand ? publishers.other.raisehand : false;
                if(screenshare) {
                    console.log('new screen share participant', publishers);
                    dispatch(ScreenShareAction.subscribeScreenShare(publishers.id, publishers.display, publishers.room_part_id))
                } else {
                    if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                        setTimeout(() => {
                        dispatch(newRemoteFeed(id, display, audio, video, host, raisehand, room_part_id));
                        },(index+10) * 100)
                    }
                }
            });
        }
    }
}
function checkPositionInCurrentSlide(currentPosition: number, currentSlider: number, tileLength: number) {
    const sliderMaxLength = tileLength;
    const start = currentSlider* sliderMaxLength;
    const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
    if(currentPosition >= start && currentPosition <= end) {
        return true;
    } else {
        return false;
    }
}
function checkParticipantInCurrentSlide(handle: any, currentSlider: number, tileLimit: number) {

        const sliderMaxLength = tileLimit//Config.configuration.maxTileLimit;
        const currentParticipantList = participants.filter(participant => {
            return participant.removed !== true;
        });
        const thisParticipantPosition = currentParticipantList.findIndex(data => data.rfid === handle.rfid);
        const start = currentSlider* sliderMaxLength;
        const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
        if(thisParticipantPosition >= start && thisParticipantPosition <= end) {
            return true;
        } else {
            return false;
        }
}

export function joinAsSubscriber(handle: any) {
    return async function (dispatch: any, getState: any) {
        const {currentSlider, publisherPrivateId, maxTileinslider,
            activeSpeaker,activeScreen, latencyMode} = getState().Meet;
        const {meetingId, meetingInfo} = getState().Hoolva;
        const {screenShareParticipants} = getState().ScreenShare
        if(Config.configuration.bandWidthOptimization && !meetingInfo.audio_bridge) {
            const freeze = checkParticipantInCurrentSlide(handle, currentSlider, maxTileinslider);
            handle.freeze = !freeze;
            if(participants.length > 0) {
                if (participants.findIndex(data => data.rfid === handle.rfid) !== -1) {
                    participants[participants.findIndex(data => data.rfid === handle.rfid)].freeze = !freeze;
                }
            }
            dispatch(setPublishersList(participants));
            const subscribe = {
                request: 'join',
                room: +meetingId,
                ptype: 'subscriber',
                feed: handle.rfid,
                private_id: publisherPrivateId,
                video: handle.video === 'unmuted' && handle.freeze ? false : true
            };
            HoolvaUtils.logger('sending subscribe message', subscribe, 'success');
            handle.send({ message: subscribe });
        } else {
            const sliderMaxLength = maxTileinslider;
            const currentParticipantList = participants.filter(participant => {
                return participant.removed !== true ;
            });
            const start = currentSlider * sliderMaxLength;
            const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
            const participantInView = currentParticipantList.splice(start, end - start + 1);
            const videoParticipantInView = participantInView.filter((participant: any) => {
                return participant.video === 'unmuted' && !participant.detached && !participant.isPublisher
            });
            let simulcastedValue: number = 0;
            if(videoParticipantInView.length <= Config.configuration.pool.high) {
                simulcastedValue = 2;
            } else if(videoParticipantInView.length > Config.configuration.pool.high && participantInView.length <= Config.configuration.pool.medium) {
                simulcastedValue = 1
            } else {
                simulcastedValue = 0
            }
            if (latencyMode === 'MEDIUM') {
                if(simulcastedValue === 2) {
                    simulcastedValue = 1;
                }
            }
            if (latencyMode === 'HIGH' || latencyMode === 'VERY_HIGH') {
                simulcastedValue = 0;
            }

            let subscribe = {
                request: 'join',
                room: +meetingId,
                ptype: 'subscriber',
                feed: handle.rfid,
                private_id: publisherPrivateId,
                substream: simulcastedValue,
                temporal: Config.configuration.temporal,
            };
            if(!Config.configuration.simulcast) {
                //@ts-ignore
                delete subscribe.substream;
                delete subscribe.temporal;
            }

            HoolvaUtils.logger('sending subscribe message with substream as ' + simulcastedValue, subscribe, 'success');
            handle.send({ message: subscribe });
            dispatch(subscribeVideoRoomBasedOnView());
            participants[participants.findIndex(data => data.rfid === handle.rfid)].simulcastValue = simulcastedValue;
            dispatch(setPublishersList(participants));
            if(Config.configuration.simulcast) {
                dispatch(optimizeBandWidthBasedOnVideoParticipant(false));
            }

        }
    }
}

export function newRemoteFeed(id: string, display: string, audio: string, video: string, host:boolean, raisehand: boolean, room_part_id: any,profile_picture?:any){
    return async function (dispatch: any, getState: any) {
        let remoteHandle: any = null;
        const {hoolva, localCloudRecordingStatus} = getState().Meet;
        const {meetingInfo} = getState().Hoolva;
        hoolva.attach({
            plugin: 'janus.plugin.videoroom',
            opaqueId: 'hoolva-remote-particiant',
            ptype: "subscriber",
            participant_id: id,
            displayName: display,
            screenShare: false,
            audio,
            video,
            host,
            raisehand,
            room_part_id,
            profile_picture,
            success(pluginHandle: any) {
                pluginHandle.printLog({status: 'attach-subscriber-success'})
                remoteHandle = pluginHandle;
                const allPublisher = [...participants];
                if (participants.findIndex(data => data.rfid === pluginHandle.rfid) !== -1) {
                    const currentParticipant = participants[participants.findIndex(data => data.rfid === pluginHandle.rfid)];
                    const index = participants.findIndex(data => data.rfid === pluginHandle.rfid);
                    if(currentParticipant.hasOwnProperty('detach')) {
                        currentParticipant.detach();
                    }
                    const resetParticipant = Object.assign(currentParticipant, remoteHandle);
                    if(currentParticipant.rfid === getState().Meet.activeSpeaker?.rfid) {
                        dispatch(setActiveSpeaker(resetParticipant));
                    }
                    participants[index] = resetParticipant;
                } else {
                    let falsyIndex: any;
                    if(!allPublisher.every(function(element, index) {falsyIndex = index; return element.removed !== true})) {
                        participants[falsyIndex] = pluginHandle;
                        pluginHandle.rfindex = falsyIndex;
                    } else {
                        pluginHandle.rfindex = allPublisher.length;
                        participants = [...allPublisher, pluginHandle];
                    }
                    const currentParticipant = participants[participants.findIndex(data => data.rfid === pluginHandle.rfid)];
                    if(currentParticipant.rfid === getState().Meet.activeSpeaker?.rfid) {
                        dispatch(setActiveSpeaker(pluginHandle))
                    }
                }
                if(video === 'unmuted') {
                    console.log("recording cloud", localCloudRecordingStatus, meetingInfo.record_mode)
                    if(localCloudRecordingStatus === 'start' && meetingInfo.record_mode === 'host_with_others') {
                        dispatch(sendRecordRequest('configure', true));
                    }
                }

                dispatch(setPublishersList(participants));
                remoteHandle.simulcastStarted = false;
                dispatch(joinAsSubscriber(remoteHandle));
            },
            error(err: any) {
                Janus.error('  -- Error attaching plugin...', err);
                hoolva.printLog({status: 'attach-subscriber-error', err})
            },
            async onmessage(msg: any, jsep: any, handle: any) {
                const event = msg.videoroom;
                if (msg.error !== undefined && msg.error !== null) {
                    hoolva.printLog({status: 'attach-subscriber-error', error: msg.error})
                }
                else if (event !== undefined && event !== null) {
                    if (event === 'attached') {
                        if(msg.substream === 0 || msg.substream === 1 || msg.substream === 2) {
                            console.log('substream message', msg)
                            handle.simulcastValue = msg.substream;
                            participants[participants.findIndex(data => data.rfid === handle.rfid)] = handle;
                            dispatch(setPublishersList(participants));
                        } else if(msg.substream === -1) {
                            console.log('substream message came as -1', msg)
                            handle.simulcastValue = 0;
                            participants[participants.findIndex(data => data.rfid === handle.rfid)] = handle;
                            dispatch(setPublishersList(participants));
                        }
                    }
                    if(event === 'talking') {
                        console.log('talk subscriberr');
                    }
                    else if (event === 'event') {
                        if(msg.error){
                            hoolva.printLog({status: 'attach-subscriber-onmessage-error', error: msg.error})
                        }
                        if(msg.substream === 0 || msg.substream === 1 || msg.substream === 2) {
                            console.log('substream message', msg)
                            handle.simulcastValue = msg.substream;
                            participants[participants.findIndex(data => data.rfid === handle.rfid)] = handle;
                            dispatch(setPublishersList(participants));
                        } else if(msg.substream === -1) {
                            console.log('substream message came as -1', msg)
                            handle.simulcastValue = 0;
                            participants[participants.findIndex(data => data.rfid === handle.rfid)] = handle;
                            dispatch(setPublishersList(participants));
                        }
                        if(msg.configured === 'ok') {
                            console.log('configured ok message subscriber', msg)
                        }
                        if(msg.started === 'ok' && !getState().Hoolva.meetingInfo.audio_bridge) {
                            if(handle.webrtcStuff && handle.webrtcStuff.remoteStream) {
                                if(document.getElementById('video_' + handle.id)) {
                                    Janus.attachMediaStream(document.getElementById('video_' + handle.id), handle.webrtcStuff.remoteStream);
                                }
                            }
                            if(Config.configuration.bandWidthOptimization && !getState().Hoolva.meetingInfo.audio_bridge) {
                                const check = checkParticipantInCurrentSlide(handle, getState().Meet.currentSlider, getState().Meet.maxTileinslider);
                                if(participants.length > 0) {
                                    if (participants.findIndex(data => data.rfid === handle.rfid) !== -1) {
                                        participants[participants.findIndex(data => data.rfid === handle.rfid)].freeze = handle.video === 'unmuted' ? !check : false ;
                                    }
                                }
                                dispatch(setPublishersList(participants));
                                if(!check) {
                                    const timer = setTimeout(() => {
                                        const configureRequest = {
                                            request: "configure",
                                            audio: true,
                                            video: false
                                        }
                                        handle.send({message:configureRequest});
                                        if(!getState().Hoolva.meetingInfo.audio_bridge) {
                                            dispatch(optimizeBandWidthBasedOnView())
                                        } else {
                                            dispatch(subscribeVideoRoomBasedOnView());
                                        }

                                        if(timer) {
                                            clearTimeout(timer);
                                        }
                                    }, 5000)

                                }
                            }
                        }
                        if(msg.started === 'ok' && getState().Hoolva.meetingInfo.audio_bridge) {
                            const {screenSharePublisherHandle, screenShareParticipants} = getState().ScreenShare;
                            const {recorderMode, recorder, audioParticipant, videoParticipant} = getState().Record;
                            if(recorderMode === 'gallery' && recorder) {
                                const currentScreenShareList = screenShareParticipants.filter((participant: any) => {
                                    return participant.removed !== true
                                });
                                if(currentScreenShareList.length === 0 &&  !screenSharePublisherHandle) {
                                    const recorederList = [...audioParticipant, ...videoParticipant];
                                    const index = recorederList.findIndex((participant: any) => participant.rfid == handle.rfid);
                                    if(index !== -1) {
                                        dispatch(updateRecording());
                                    } else {

                                        if(videoParticipant.length < 4 ) {
                                            console.log('check check 123', videoParticipant.length);
                                            dispatch(updateRecording());
                                        }
                                    }
                                }
                            }

                         //   console.log('subscribeVideoRoomBasedOnView calling from subscriber started ok')
                            // dispatch(subscribeVideoRoomBasedOnView());
                        }
                    } else {

                    }
                    if (jsep !== undefined && jsep !== null) {
                        let shared = null;
                        if(getState().Hoolva.meetingInfo.e2ee) {
                            shared = await getRoomKey(getState().Hoolva.meetingId, getState().Hoolva.xqMessagesInfo.key);
                        }
                        remoteHandle.createAnswer({
                            jsep,
                            media: { audioSend: false, videoSend: false },
                            sframe: getState().Hoolva.meetingInfo.e2ee ? {
                                outgoingId: 34212,
                                incomingId: Math.floor(+remoteHandle.rfid/100000),
                                sharedKey: shared
                            } : null,
                            isPublisher: false,
                            e2ee: true,
                            success(jsepAnswer: any) {
                                const body = {
                                    request: 'start',
                                    room: +getState().Hoolva.meetingId,
                                };
                                remoteHandle.send({ message: body, jsep: jsepAnswer });
                            },
                            error(err: any) {
                                hoolva.printLog({status: 'error', data: {
                                        messgae: 'Webrtc error in create answer subscriber',
                                        err
                                    }})
                            }
                        });
                    }
                }
            },
            webrtcState(on: boolean, reason: string, handle: any) {
                handle.printLog({status: 'webrtc-state-subscriber', messgae: `WebRTC PeerConnection in remote feed is ${on ? 'up' : 'down'} now, reason ${reason}`})
                if(on) {
                    handle.state = 'connected';
                    if(participants.length > 0) {
                        if (participants.findIndex(data => data.rfid === handle.rfid) !== -1) {
                            participants[participants.findIndex(data => data.rfid === handle.rfid)] = handle;
                            if(handle.rfid === getState().Meet.activeSpeaker?.rfid) {
                                dispatch(setActiveSpeaker(handle));
                            }
                            setPublishersList(participants)
                        }
                    }
                }
                if(!on && reason === 'DTLS timeout' || reason === 'ICE failed') {
                    HoolvaUtils.logger('DTLS timeout happend for ' + handle.rfdisplay + 'resubscribing...', '', 'error')
                   if(getState().Hoolva.meetingInfo.audio_bridge) {
                       if(handle.video === 'unmuted') {
                           if(getState().Meet.latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                               setTimeout(() => {
                               dispatch(newRemoteFeed(handle.rfid, handle.rfdisplay, handle.audio, handle.video, handle.host, handle.raisehand, handle.room_part_id));
                               },200)
                            }
                           if(participants.length > 0) {
                               if (participants.findIndex(data => data.rfid === handle.rfid) !== -1) {
                                   participants[participants.findIndex(data => data.rfid === handle.rfid)].removed = true;
                                   setPublishersList(participants)
                               }
                           }
                           handle.detach();
                       }
                   } else {
                       if(getState().Meet.latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                
                           setTimeout(() => {
                           dispatch(newRemoteFeed(handle.rfid, handle.rfdisplay, handle.audio, handle.video, handle.host, handle.raisehand, handle.room_part_id));
                           },200)
                        }
                       if(participants.length > 0) {
                           if (participants.findIndex(data => data.rfid === handle.rfid) !== -1) {
                               participants[participants.findIndex(data => data.rfid === handle.rfid)].removed = true;
                               setPublishersList(participants)
                           }
                       }
                       handle.detach();
                   }

                }
            },
            slowLink(uplink: boolean, lost: number, medium: string, handle: any) {
                console.log('slow link data in video room subscriber handle', uplink, lost);
                if(Config.configuration.simulcast) {
                    if(medium === 'video' && uplink) {
                        console.log('need to downgrade substream for', handle.rfdisplay)
                        dispatch(downGradeSubstreamForHandle(handle))
                    }
                }

            },
            onlocalstream(stream: MediaStream) {
                console.log(
                    'The subscriber stream is recvonly, we dont expect anything here',
                    stream
                );
            },
            onremotestream(stream: MediaStream, handle: any, type: string, track: any) {
               // console.log('onremotestream', stream.getTracks());
                // if(handle.video !== 'unmuted' || (Config.configuration.bandWidthOptimization && handle.freeze)) {
                //     const mediaStream = stream.clone();
                //     Janus.attachMediaStream(
                //         document.getElementById('video_' + handle.id),
                //         mediaStream
                //     );
                // } else {
                //     const videoTrack = stream.getVideoTracks();
                //     const mediaStream = stream.clone();
                //     if(videoTrack.length > 0 && type === 'unmute') {
                //         Janus.attachMediaStream(
                //             document.getElementById('video_' + handle.id),
                //             mediaStream
                //         );
                //     }
                // }
                Janus.attachMediaStream(
                    document.getElementById('video_' + handle.id),
                    stream
                );
                if(handle?.rfid === getState().Meet.activeSpeaker?.rfid && getState().Hoolva.meetingInfo.audio_bridge) {
                    const isScreenShare = getState().ScreenShare.screenShareParticipants.filter((screen : any) => screen.removed !== true);
                    if(isScreenShare.length === 0) {
                        Janus.attachMediaStream(
                            document.getElementById('activeSpeakerWidget'),
                            stream
                        );
                    }
                }

                if(!getState().Hoolva.meetingInfo?.audio_bridge) {
                    dispatch(HoolvaActions.attachSinkId( document.getElementById('video_' + handle.id), getState().Meet.selectedOutput ))
                }

                $(`#videoWidget${handle.id}`).html(handle.rfdisplay);
            }
        })
    }
}

export function handleNewJoiner(msg: any) {
    return async function (dispatch: any, getState: any) {
        const {meetingInfo} = getState().Hoolva;
        const {currentSlider, maxTileinslider, latencyMode, localCloudRecordingStatus} = getState().Meet;
        const publishersList = msg.publishers;
        HoolvaUtils.logger('New joiner event', msg, 'success');
        publishersList.map((publisher: any) => {
            const { id, display, screenshare, audio, video, host, room_part_id,profile_picture } = publisher;
            const raisehand = publisher.other.raisehand ? publisher.other.raisehand : false;
            if(publisher.other.hasOwnProperty('client_recording')) {
                dispatch(setLocalRecordingNotification(publisher.other.client_recording));
            }
            if(screenshare) {
                console.log('new screen share participant', publisher);
                dispatch(showMessage('sucess', display + 'is joined as screen share', {}));
                dispatch(ScreenShareAction.subscribeScreenShare(publisher.id, publisher.display, publisher.room_part_id))
            } else {
                dispatch(showMessage('sucess', display + 'is joined as participant', {}));
                if(meetingInfo.audio_bridge && video === 'muted') {
                    const newParticipant = {
                        id: Janus.randomString(12),
                        rfid: id,
                        rfdisplay: display,
                        isPublisher: false,
                        detached : true,
                        removed	: false,
                        raisehand: raisehand,
                        talking: false,
                        audio: audio,
                        video: video,
                        host: host,
                        screenShare: false,
                        room_part_id: room_part_id,
                        profile_picture
                    }
                    const allPublisher = [...participants];
                    console.log('we are not subscribing video', newParticipant);
                    let falsyIndex: any;
                    if(!allPublisher.every(function(element, index) {falsyIndex = index; return element.removed !== true})) {
                        participants[falsyIndex] = newParticipant;
                    } else {
                        participants = [...allPublisher, newParticipant];
                    }
                    dispatch(setPublishersList(participants));
                    const {recorder, recorderMode, audioParticipant, videoParticipant} = getState().Record;
                    if(recorder && recorderMode === 'gallery') {
                        const recorderList = [...audioParticipant, videoParticipant];
                        const {screenSharePublisherHandle, screenShareParticipants} = getState().ScreenShare;
                        const currentScreenShareList = screenShareParticipants.filter((participant: any) => {
                            return participant.removed !== true
                        });
                        if(currentScreenShareList.length === 0 && !screenSharePublisherHandle && recorderList.length < 4) {
                            dispatch(updateRecording());
                        }
                    }
                } else if(meetingInfo.audio_bridge && video === 'unmuted') {
                    const allPublisher = [...participants];
                    let falsyIndex: number = 0;
                    let indexPosition: number = 0;
                    if(!allPublisher.every(function(element, index) {falsyIndex = index; return element.removed !== true})) {
                        indexPosition = falsyIndex;
                    } else {
                        indexPosition = allPublisher.length;
                    }
                    if (checkPositionInCurrentSlide(indexPosition, currentSlider, maxTileinslider)) {
                        if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                            setTimeout(() => {
                            dispatch(newRemoteFeed(id, display, audio, video, host, raisehand, room_part_id,profile_picture));
                            },200)
                        } else {
                            const newParticipant = {
                                id: Janus.randomString(12),
                                rfid: id,
                                rfdisplay: display,
                                isPublisher: false,
                                detached : true,
                                removed	: false,
                                raisehand: raisehand,
                                talking: false,
                                audio: audio,
                                video: video,
                                host: host,
                                screenShare: false,
                                room_part_id: room_part_id,
                                profile_picture
                            }
                            const allPublisher = [...participants];
                            let falsyIndex: any;
                            if(!allPublisher.every(function(element, index) {falsyIndex = index; return element.removed !== true})) {
                                participants[falsyIndex] = newParticipant;
                            } else {
                                participants = [...allPublisher, newParticipant];
                            }
                            dispatch(setPublishersList(participants));
                            const {recorder, recorderMode, audioParticipant, videoParticipant} = getState().Record;
                            if(recorder && recorderMode === 'gallery') {
                                const recorderList = [...audioParticipant, videoParticipant];
                                const {screenSharePublisherHandle, screenShareParticipants} = getState().ScreenShare;
                                const currentScreenShareList = screenShareParticipants.filter((participant: any) => {
                                    return participant.removed !== true
                                });
                                if(currentScreenShareList.length === 0 && !screenSharePublisherHandle && recorderList.length < 4) {
                                    dispatch(updateRecording());
                                }
                            }
                        }
                    } else {
                        const newParticipant = {
                            id: Janus.randomString(12),
                            rfid: id,
                            rfdisplay: display,
                            isPublisher: false,
                            detached : true,
                            removed	: false,
                            raisehand: raisehand,
                            talking: false,
                            audio: audio,
                            video: video,
                            host: host,
                            screenShare: false,
                            room_part_id: room_part_id,
                            profile_picture
                        }
                        const allPublisher = [...participants];
                        let falsyIndex: any;
                        if(!allPublisher.every(function(element, index) {falsyIndex = index; return element.removed !== true})) {
                            participants[falsyIndex] = newParticipant;
                        } else {
                            participants = [...allPublisher, newParticipant];
                        }
                        dispatch(setPublishersList(participants));
                        const {recorder, recorderMode, audioParticipant, videoParticipant} = getState().Record;
                        if(recorder && recorderMode === 'gallery') {
                            const recorderList = [...audioParticipant, videoParticipant];
                            const {screenSharePublisherHandle, screenShareParticipants} = getState().ScreenShare;
                            const currentScreenShareList = screenShareParticipants.filter((participant: any) => {
                                return participant.removed !== true
                            });
                            if(currentScreenShareList.length === 0 && !screenSharePublisherHandle && recorderList.length < 4) {
                                dispatch(updateRecording());
                            }
                        }
                    }

                } else {
                    if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                        setTimeout(() => {
                        dispatch(newRemoteFeed(id, display, audio, video, host, raisehand, room_part_id,profile_picture));
                        },200)
                    }
                }

            }

        });
    }
}


export function handleScreenShareAfterEnded() {
    return async function (dispatch: any, getState: any) {
        const {screenShareParticipants} = getState().ScreenShare;
        const {meetingInfo} = getState().Hoolva;
        if(Config.configuration.bandWidthOptimization && !meetingInfo.audio_bridge) {
            dispatch(optimizeBandWidthBasedOnView());
        } else if (Config.configuration.bandWidthOptimization && meetingInfo.audio_bridge) {
            dispatch(subscribeVideoRoomBasedOnView());
        }
        const isScreenShare = screenShareParticipants.filter((screen : any) => screen.removed !== true);
        if(isScreenShare.length === 0) {
            if(activeSpeakerId !== '') {
                if(participants.length > 0) {
                    if (participants.findIndex(data => data.rfid === activeSpeakerId) !== -1) {
                        const handle = participants[participants.findIndex(data => data.rfid === activeSpeakerId)];
                        dispatch(setActiveSpeaker(handle));
                        Janus.attachMediaStream(document.getElementById('activeSpeakerWidget'), handle.isPublisher ? handle.webrtcStuff.myStream : handle.webrtcStuff?.remoteStream);
                        $('#activeSpeakerName').html(handle.rfdisplay);
                    }
                }
            } else {
                const handle = participants[0];
                dispatch(setActiveSpeaker(handle));
                Janus.attachMediaStream(document.getElementById('activeSpeakerWidget'), handle.webrtcStuff.myStream);
                $('#activeSpeakerName').html(handle.rfdisplay);
                activeSpeakerId = '';
            }
        }
    }
}

export function handleParticipantLeave(msg: any) {
    return async function (dispatch: any, getState: any) {
        const {activeScreen} = getState().Meet;
        const { leaving, screenshare } = msg;
        if(leaving === 'ok') {

        }
        if(activeSpeakerId === leaving) {
            if(activeScreen === 2) {
                    const handle = participants[0];
                    dispatch(setActiveSpeaker(handle));
                    Janus.attachMediaStream(document.getElementById('activeSpeakerWidget'), handle.isPublisher ? handle.webrtcStuff.myStream : handle.webrtcStuff.remoteStream);
                    $('#activeSpeakerName').html(handle.rfdisplay);

            }
            activeSpeakerId = '';
        }

        if(screenshare) {
            const {recordType,recorder, audioParticipant, videoParticipant} = getState().Record;
            console.log('screenshare participant left', msg)
            dispatch(unSubscribeScreenShare(msg));
            if(!getState().Hoolva.meetingInfo.audio_bridge) {
                dispatch(optimizeBandWidthBasedOnView());
            }
            if(recordType !== 'audio' && recorder) {
                    dispatch(updateRecording());
            }
        } else {
            let remoteFeed = participants.find(participant => participant.rfid === leaving);
            const {recorderMode,recorder, audioParticipant, videoParticipant} = getState().Record;

            if(remoteFeed !== null && remoteFeed !== undefined) {
                if(!remoteFeed.detached) {
                    remoteFeed.detach();
                }
                const index = participants.findIndex((participant => participant.rfid == leaving));
                participants[index].removed = true;
                dispatch(setPublishersList([...participants]));
                if(remoteFeed.video === 'unmuted' && Config.configuration.simulcast) {
                    dispatch(optimizeBandWidthBasedOnVideoParticipant(true));
                }
                if(recorderMode === 'gallery' && recorder) {
                    const index = videoParticipant.findIndex((participant: any) => participant.rfid == leaving);
                    const index1 = audioParticipant.findIndex((participant: any) => participant.rfid == leaving);
                    if(index !== -1 || index1 !== -1) {
                        dispatch(updateRecording());
                    }
                }
                if(!getState().Hoolva.meetingInfo.audio_bridge) {
                    dispatch(optimizeBandWidthBasedOnView());
                } else {
                    console.log('subscribeVideoRoomBasedOnView calling from participant leave')
                    dispatch(subscribeVideoRoomBasedOnView())
                }

            }
        }
    }
}

export function mixedAudioTalkingAndStopTalking(msg: any){
    return async function (dispatch: any, getState: any) {
        const {activeScreen} = getState().Meet;
        const {screenShareParticipants} = getState().ScreenShare;
        const {meetingInfo} = getState().Hoolva;
        if(participants.length > 0) {
            if (participants.findIndex(data => data.rfid === msg.id) !== -1) {
                participants[participants.findIndex(data => data.rfid === msg.id)].talking = msg.audiobridge === 'talking' ? true : false;
                const handle = participants[participants.findIndex(data => data.rfid === msg.id)];
                if(!handle.isPublisher && msg.audiobridge === 'talking') {
                   dispatch(manageActiveSpeakerInMixedAudio(handle));
                }
                dispatch(setPublishersList(participants));
                if(msg.audiobridge === 'talking') {
                    const isScreenShare = screenShareParticipants.filter((screen : any) => screen.removed !== true);
                    if(activeSpeakerId !== msg.id && participants[0].rfid !== msg.id && isScreenShare.length === 0 && activeScreen === 2) {
                        dispatch(setActiveSpeaker(handle));
                        if(meetingInfo.audio_bridge && handle.video === 'unmuted') {
                            Janus.attachMediaStream(document.getElementById('activeSpeakerWidget'), handle.isPublisher ? handle.webrtcStuff?.myStream : handle.webrtcStuff?.remoteStream);
                        } else {
                            Janus.attachMediaStream(document.getElementById('activeSpeakerWidget'), handle.isPublisher ? handle.webrtcStuff?.myStream : handle.webrtcStuff?.remoteStream);
                        }

                        $('#activeSpeakerName').html(handle.rfdisplay);
                    }
                    if(participants[0].rfid !== msg.id) {
                        activeSpeakerId = msg.id;
                    }
                }

            }
        }
    }
}

export function mixedAudioRemoteMuteIndication(msg: any) {
    return async function (dispatch: any, getState: any) {
        if(participants.length > 0) {
            console.log('mute indication', participants, msg);
            if (participants.findIndex(data => data.rfid === msg.id) !== -1) {
                participants[participants.findIndex(data => data.rfid === msg.id)].audio = msg.muted ? 'muted': 'unmuted';
                if(msg.muted) {
                    participants[participants.findIndex(data => data.rfid === msg.id)].talking = false;
                }
                dispatch(setPublishersList(participants));
            }
        }
    }
}

export function autoReconnect() {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle, hoolva} = getState().Meet;
        const {recorder} = getState().Record;
        const {t} = getState().Auth;
        dispatch(setWatingRoomLoader(false));
        const {screenSharePublisherHandle} = getState().ScreenShare;
        await dispatch(setCurrentSlider(0));
        if(publisherHandle) {
            if(publisherHandle.webrtcStuff && publisherHandle.webrtcStuff.myStream) {
                publisherHandle.webrtcStuff.myStream.getTracks().forEach(function(track: MediaStreamTrack) {
                    track.stop();
                });
            }
        }
        if(recorder) {
            const recordEvent = {
                event : 'client_recording',
                status: 'stop',
                update: {
                    client_recording: 'stop'
                }
            }
            if(publisherHandle) {
                publisherHandle.send({message: recordEvent})
            }
            dispatch(stopRecording());
        }

        if (screenSharePublisherHandle) {
            if(screenSharePublisherHandle.webrtcStuff.myStream) {
                screenSharePublisherHandle.webrtcStuff.myStream.getTracks().forEach(function(track: MediaStreamTrack) {
                    track.stop();
                });
            }
        }

        if(hoolva) {
            hoolva.destroy();
        }
        // @ts-ignore
        if(interval) {
            // @ts-ignore
            clearInterval(interval);
            interval = null;
        }
        // dispatch(resetMeet());
        dispatch(ScreenShareAction.resetScreenShare());
        const {globalClientAuth, clientAuth} = getState().Auth;
        clientAuth.ice = globalClientAuth
        await dispatch(setClientAuthData(clientAuth));
        participants = [];
        connectedVideo = false;
        activeSpeakers = [];
        // messageArray = [];
        isIceRestart = false;
        isMediaState = true;

        let networkCheck = false;
        // @ts-ignore
        let networkTimeout = null;
        controller = new AbortController();
        const { signal } = controller;

            const webPing = setInterval(
                () => {
                    fetch('//google.com', {
                        mode: 'no-cors',
                        signal
                    })
                        .then(async () => {
                            if (!networkCheck) {
                                dispatch(connectHoolvaServer(true));
                                controller.abort();
                            }
                            networkCheck = true;
                            // @ts-ignore
                            if (networkTimeout) {
                                // @ts-ignore
                                clearTimeout(networkTimeout);
                            }
                            return clearInterval(webPing);
                        }).catch(() => {
                    })

                }, 2000);
        networkTimeout =  setTimeout(() => {
          //  console.log('clearing interval and showing end meeting of network issue');
            if(webPing) {
                clearInterval(webPing);
            }
            controller.abort();
            dispatch(endMeeting({title: t('meetingPage.error4401Title'),
                message: t('meetingPage.error4401'),
                code: 4401}))
        }, 60000);
    }
}
export function endMeeting(data: any, flag:any = true) {
    return async function (dispatch: any, getState: any) {
        dispatch(setWatingRoomLoader(false));
        const {hoolva, publisherHandle, feedback} = getState().Meet;
        const {recorder} = getState().Record;
        const {t} = getState().Auth;
        const {screenSharePublisherHandle} = getState().ScreenShare;
        await dispatch(setCurrentSlider(0));
        if(publisherHandle) {
            if(publisherHandle.webrtcStuff && publisherHandle.webrtcStuff.myStream) {
                publisherHandle.webrtcStuff.myStream.getTracks().forEach(function(track: MediaStreamTrack) {
                    track.stop();
                });
            }
        }
        if(recorder) {
            const recordEvent = {
                event : 'client_recording',
                status: 'stop',
                update: {
                    client_recording: 'stop'
                }
            }
            if(publisherHandle) {
                publisherHandle.send({message: recordEvent})
            }
            dispatch(stopRecording());
        }

        if (screenSharePublisherHandle) {
            if(screenSharePublisherHandle.webrtcStuff.myStream) {
                screenSharePublisherHandle.webrtcStuff.myStream.getTracks().forEach(function(track: MediaStreamTrack) {
                    track.stop();
                });
            }
        }

        if(hoolva) {
            hoolva.printLog({status: 'end-meeting', data});
            hoolva.destroy();
        }
        // @ts-ignore
        if(timerInterval) {
            // @ts-ignore
            clearInterval(timerInterval)
        }

        // @ts-ignore
        if(interval) {
            // @ts-ignore
            clearInterval(interval);
            interval = null;
        }
        dispatch(stopPingTest())
        dispatch(resetMeet());
        dispatch(ScreenShareAction.resetScreenShare());
        const {globalClientAuth, clientAuth} = getState().Auth;
        clientAuth.ice = globalClientAuth
        await dispatch(setClientAuthData(clientAuth));
        participants = [];
        connectedVideo = false;
        activeSpeakers = [];
        messageArray = [];
        isIceRestart = false;
        isMediaState = true;
        retries = 0;

        // dispatch(AuthActions.resetAuth());
        if(data && !ended) {
            ended = true;
            confirmAlert({
                title: data.title,
                message: data.message,
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: t('meetingPage.errorOkLabel'),
                        onClick: async () => {
                            if(feedback && data.code === 4405) {
                                history.push(routes.FEEDBACK);
                            } else {
                                dispatch(HoolvaActions.resetHoolva());
                                history.push(routes.HOME);
                            }
                            ended = true;
                        }
                    }
                ]
            });
        } else {
            if(feedback && data === null && flag) {
                history.push(routes.FEEDBACK);
            } else {
                if(data !== undefined){
                    dispatch(HoolvaActions.resetHoolva());
                } else {
                    //dispatch(HoolvaActions.setMeetingId(''));
                   // dispatch(HoolvaActions.setMeetingPassword(''));
                }
                history.push(routes.HOME);
            }

            ended = true;
        }

    }
}

export function toggleMixedAudioMute(mode: boolean) {
    return async function (dispatch: any, getState: any) {
        const {audioRoomHandle} = getState().AudioRoom;
        const {publisherHandle} = getState().Meet;
        audioRoomHandle.send({ message: { request: "configure", muted: !mode }});
        if(mode) {
            audioRoomHandle.unmuteAudio();
        } else {
            audioRoomHandle.muteAudio();
        }
        publisherHandle.audio = mode ? 'unmuted' : 'muted';
        if(!mode) {
            publisherHandle.talking = false;
        }
        dispatch(setAudio(mode));
        const publisherList = [...participants];
        publisherList[0] = publisherHandle;
        participants = [...publisherList];
        dispatch(setPublishersList(publisherList))
    }
}
export function toggleMute(mode: boolean) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle, publisherId, data} = getState().Meet;
        const {meetingId} = getState().Hoolva;
            let audioState;
            let audioToggleRequest;
            if(mode) {
                audioState = publisherHandle.unmuteAudio();
                audioToggleRequest = {
                    request: 'unmuted',
                    id: publisherId,
                    room: +meetingId
                }
            } else {
                audioState = publisherHandle.muteAudio();
                audioToggleRequest = {
                    request: 'muted',
                    id: publisherId,
                    room: +meetingId
                }
            }
           // console.log('toggle mute function state', audioState);
            if (audioState) {
                publisherHandle.send({message: audioToggleRequest});
                dispatch(setAudio(mode));
                publisherHandle.audio = mode ? 'unmuted' : 'muted';
                const publisherList = [...participants];
                publisherList[0] = publisherHandle;
                participants = [...publisherList];
                dispatch(setPublishersList(publisherList))
            }

    }
}

// export function offerAudio() {
//     return async function (dispatch: any, getState: any) {
//         const {publisherHandle, selectedVideo, selectedAudio, video, audioPermission, videoPermission} = getState().Meet;
//         DetectRTC.load(async function () {
//             publisherHandle.createOffer({
//                 media: {
//                     audioRecv: false,
//                     videoRecv: false,
//                     audioSend: true,
//                     keepVideo: true,
//                     addAudio: true,
//                     audio: DetectRTC.hasMicrophone &&  audioPermission !== 'denied' ? selectedAudio !== '' ? { deviceId: selectedAudio }: true: false,
//                     data: DetectRTC.hasMicrophone ||DetectRTC.hasWebcam,
//                     video: video ?  DetectRTC.hasWebcam ? videoPermission !== 'denied'? selectedVideo !== '' ?
//                         {
//                             deviceId: selectedVideo,
//                             width: Config.configuration.videoResolution.width,
//                             height: Config.configuration.videoResolution.height
//                         }  : {
//                             width: Config.configuration.videoResolution.width,
//                             height: Config.configuration.videoResolution.height
//                         }: false : false: false,
//                 },
//                 simulcast: false,
//                 success(jsep: any) {
//                     console.log('dataaaaaaaaaa', !DetectRTC.hasMicrophone && audioPermission === 'denied')
//                     dispatch(setDataMode(!DetectRTC.hasMicrophone || audioPermission === 'denied'));
//                     const publish = {
//                         request: 'configure',
//                         audio: DetectRTC.hasMicrophone && audioPermission !== 'denied',
//                         video: DetectRTC.hasWebcam && videoPermission !== 'denied' ? video : false
//                     };
//                     dispatch(setAudio(true));
//                     publisherHandle.send({ message: publish, jsep });
//                 },
//                 error(error: any) {
//                     console.log('error in audio offer', error)
//                     dispatch(showMessage('error', 'error in offer audio' , {}));
//                 }
//             });
//         });
//     }
// }

export function toggleVideo(mode: boolean, deviceSwitch?: boolean) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle, selectedVideo, audio, video, audioPermission, videoPermission,
            selectedAudio, videoResWidth, videoResHeight} = getState().Meet;
        const {meetingInfo} = getState().Hoolva;
        dispatch(disableVideoButton(true));
        DetectRTC.load(async function () {
            const mediaObj = {
                audioRecv: false,
                videoRecv: false,
                audioSend: meetingInfo.audio_bridge ? false : audioPermission === 'granted' ? DetectRTC.hasMicrophone : false,
                keepAudio: true,
                addVideo: mode,
                removeVideo: !mode,
                audio: meetingInfo.audio_bridge ? false : audioPermission === 'granted' ? DetectRTC.hasMicrophone ? selectedAudio !== '' ? {deviceId: selectedAudio} : true : false : false,
                data: meetingInfo.audio_bridge ? mode ? DetectRTC.hasWebcam ? videoPermission === 'granted' ? false : true : true: true :  DetectRTC.hasMicrophone ? false : audioPermission === 'granted' ? false : !mode,
                video: mode ?  DetectRTC.hasWebcam ? videoPermission === 'granted'? selectedVideo !== '' ?
                    {
                        deviceId: selectedVideo,
                        width: { ideal: 4096 },
                        height: { ideal: 2160 },
                        aspectRatio: {exact : 1.777777778}
                        // width: {exact: videoResWidth},//{min: Config.configuration.videoResolution2.width, max: Config.configuration.videoResolution.width},
                        // height: {exact: videoResHeight},//{min: Config.configuration.videoResolution2.height, max: Config.configuration.videoResolution.height},
                        // aspectRatio: 1.777777778
                    }  : {
                        width: { ideal: 4096 },
                        height: { ideal: 2160 },
                        aspectRatio: {exact : 1.777777778}
                        // width: {exact: videoResWidth},//{min: Config.configuration.videoResolution2.width, max: Config.configuration.videoResolution.width},
                        // height: {exact: videoResHeight},//{min: Config.configuration.videoResolution2.height, max: Config.configuration.videoResolution.height},
                        // aspectRatio: 1.777777778
                    }: false : false: false,
            }
            HoolvaUtils.logger('Media object while video turn on and off', mediaObj, 'info')
            publisherHandle.createOffer({
                media: mediaObj,
                simulcast2: meetingInfo.e2ee ? false : Config.configuration.simulcast,
                success(jsep: any) {
                   // console.log('datataaaaaaaaaaa', !DetectRTC.hasMicrophone,audioPermission, audioPermission === 'denied', !DetectRTC.hasMicrophone || audioPermission !== 'denied' )
                    publisherHandle.printLog({status: 'success-toggle-video'});
                    if(!mode && deviceSwitch) {
                        dispatch(showMessage('dark', "Your camera is turned off", {position: 'bottom-center'}, true));
                    }
                    dispatch(setDataMode(!DetectRTC.hasMicrophone || audioPermission === 'denied'));
                    const publish = {
                        request: 'configure',
                        audio: meetingInfo.audio_bridge ? false : DetectRTC.hasMicrophone && audioPermission !== 'denied',
                        video: DetectRTC.hasWebcam && videoPermission !== 'denied' ? mode : false,
                        data: meetingInfo.audio_bridge ? mode ? DetectRTC.hasWebcam ? videoPermission === 'granted' ? false : true : true: true :  DetectRTC.hasMicrophone ? false : audioPermission === 'granted' ? false : !mode,
                    };
                    publisherHandle.send({ message: publish, jsep });
                    // if(!mode) {
                    //     console.log('publisherHandle.webrtcStuff.myStream.getVideoTracks()', publisherHandle.webrtcStuff.myStream.getVideoTracks())
                    //     publisherHandle.webrtcStuff.myStream.getVideoTracks().forEach(function(track: MediaStreamTrack) {
                    //         track.stop();
                    //     });
                    // }
                    if(!audio && !meetingInfo?.audio_bridge) {
                        dispatch(toggleMute(false));
                    }
                },
                error(error: any) {
                    publisherHandle.printLog({status: 'error-toggle-video', error})
                    dispatch(disableVideoButton(false));
                    dispatch(showMessage('error', 'error in video turn on/off' , {}));
                }
            });
        });
    }
}
export function changeVideo(mode: boolean,flag: string, deviceId?: any, added?: boolean) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle, selectedVideo, audio, audioPermission, videoPermission,
            selectedAudio, videoResWidth, videoResHeight} = getState().Meet;
        const {meetingInfo} = getState().Hoolva;
        dispatch(disableVideoButton(true));
        let data = {};
        DetectRTC.load(async function () {
            if(flag === 'video'){
                data = {
                    audioRecv: false,
                    videoRecv: false,
                    audioSend: meetingInfo.audio_bridge? false : true,
                    keepAudio: meetingInfo.audio_bridge? false : true,
                    replaceVideo: mode,
                    removeVideo: !mode,
                    audio: meetingInfo.audio_bridge? false : audioPermission === 'granted' ? DetectRTC.hasMicrophone ? selectedAudio !== '' ? {deviceId: selectedAudio} : true : false : false,
                    data: meetingInfo.audio_bridge ? mode ? videoPermission === 'granted' ? true : false : false :DetectRTC.hasMicrophone ? false : audioPermission === 'granted' ? false : !mode,
                    video: mode ?  DetectRTC.hasWebcam ? videoPermission === 'granted'? selectedVideo !== '' ?
                        {
                            deviceId: deviceId ? deviceId :selectedVideo,
                            width: { ideal: 4096 },
                            height: { ideal: 2160 },
                            // width: {exact: videoResWidth}, //{min: Config.configuration.videoResolution2.width, max: Config.configuration.videoResolution.width},
                            // height: {exact: videoResHeight},  //{min: Config.configuration.videoResolution2.height, max: Config.configuration.videoResolution.height},
                            aspectRatio: {exact: 1.777777778}
                        }  : {
                            width: { ideal: 4096 },
                            height: { ideal: 2160 },
                            // width: {exact: videoResWidth}, //{min: Config.configuration.videoResolution2.width, max: Config.configuration.videoResolution.width},
                            // height: {exact: videoResHeight},//{min: Config.configuration.videoResolution2.height, max: Config.configuration.videoResolution.height},
                            // aspectRatio: 1.777777778
                            aspectRatio: {exact: 1.777777778}
                        }: false : false: false,
                }
            } else {
                data = {
                    audioRecv: false,
                    videoRecv: false,
                    audioSend: true,
                    replaceAudio: true,
                    keepVideo: true,
                    audio: audioPermission === 'granted' ? DetectRTC.hasMicrophone ? {deviceId: deviceId} : false : false,
                    data: DetectRTC.hasMicrophone ? false : audioPermission === 'granted' ? false : !mode,
                    video: mode ?  DetectRTC.hasWebcam ? videoPermission === 'granted'? selectedVideo !== '' ?
                        {
                            deviceId:  selectedVideo,
                            width: { ideal: 4096 },
                            height: { ideal: 2160 },
                            // width: {exact: videoResWidth},//{min: Config.configuration.videoResolution2.width, max: Config.configuration.videoResolution.width},
                            // height: {exact: videoResHeight},//{min: Config.configuration.videoResolution2.height, max: Config.configuration.videoResolution.height},
                            // aspectRatio: 1.777777778
                            aspectRatio: {exact: 1.777777778}
                        }  : {
                            width: { ideal: 4096 },
                            height: { ideal: 2160 },
                            // width: {exact: videoResHeight}, //{min: Config.configuration.videoResolution2.width, max: Config.configuration.videoResolution.width},
                            //height: {exact: videoResHeight}, //{min: Config.configuration.videoResolution2.height, max: Config.configuration.videoResolution.height},
                            // aspectRatio: 1.777777778
                            aspectRatio: {exact: 1.777777778}
                        }: false : false: false,
                }
            }
            HoolvaUtils.logger('media object while switching devices', data, 'info');
            if(!meetingInfo.audio_bridge || flag === 'video') {
                publisherHandle.createOffer({
                    media: data,
                    simulcast2: meetingInfo.e2ee ? false : Config.configuration.simulcast,
                    success(jsep: any) {
                        publisherHandle.printLog({status: 'success-change-video'})
                        // console.log('datataaaaaaaaaaa', !DetectRTC.hasMicrophone,audioPermission, audioPermission === 'denied', !DetectRTC.hasMicrophone || audioPermission !== 'denied' )
                        dispatch(setDataMode(!DetectRTC.hasMicrophone || audioPermission === 'denied'));
                        const publish = {
                            request: 'configure',
                            audio: meetingInfo.audio_bridge ?  false : DetectRTC.hasMicrophone && audioPermission !== 'denied',
                            video: DetectRTC.hasWebcam && videoPermission !== 'denied' ? mode : false
                        };
                        publisherHandle.send({ message: publish, jsep });
                        if(!audio && !meetingInfo.audio_bridge) {
                            dispatch(toggleMute(false));
                        }
                    },
                    error(error: any) {
                        publisherHandle.printLog({status: 'error-change-video', error})
                        dispatch(showMessage('error', 'error in change video ' , {}));
                    }
                });
            } else {
                dispatch(AudioRoomActions.changeAudioDevice(deviceId ? deviceId : null, added));
                dispatch(disableVideoButton(false));
            }
        });
    }
}

export function iceRestart() {
    return async function (dispatch: any, getState: any) {
        if(isIceRestart) {
            isIceRestart = false;
            HoolvaUtils.logger('Ice restarting......', '', 'dark');
            const {publishersList, publisherHandle, selectedVideo, audio, video, audioPermission, videoPermission,
                selectedAudio, videoResWidth, videoResHeight} = getState().Meet;
            const {screenShareParticipants} = getState().ScreenShare;
            const {meetingInfo} = getState().Hoolva;
            console.log('audio and video state in ice restart', audio, video);
            DetectRTC.load(async function () {
                publisherHandle.createOffer({
                    iceRestart: true,
                    simulcast2: meetingInfo.e2ee ? false : Config.configuration.simulcast,
                    media: {
                        audioRecv: false,
                        videoRecv: false,
                        audioSend: meetingInfo.audio_bridge ? false : audioPermission === 'granted' ? DetectRTC.hasMicrophone : false,
                        keepAudio: meetingInfo.audio_bridge ? false : audioPermission === 'granted' ? DetectRTC.hasMicrophone : false,
                        keepVideo: video,
                        audio: meetingInfo.audio_bridge? false: audioPermission === 'granted' ? DetectRTC.hasMicrophone ? selectedAudio !== '' ? {deviceId: selectedAudio, echoCancellation: true,
                            noiseSuppression: true,autoGainControl: true} : true : false : false,
                        data: meetingInfo?.audio_bridge? video? videoPermission !== 'granted' ? true : false : true : (audioPermission !== 'granted' && videoPermission !== 'granted') ? true : false,
                        video: video ?  DetectRTC.hasWebcam ? videoPermission === 'granted'? selectedVideo !== '' ?
                            {
                                deviceId: selectedVideo,
                                // width: {exact: videoResWidth},//{min: Config.configuration.videoResolution2.width, max: Config.configuration.videoResolution.width},
                                // height:{exact: videoResWidth}, //{min: Config.configuration.videoResolution2.height, max: Config.configuration.videoResolution.height},
                                // aspectRatio: 1.777777778
                                width: { ideal: 4096 },
                                height: { ideal: 2160 },
                                aspectRatio: {exact : 1.777777778}

                            }  : {
                                // width: {exact: videoResWidth}, //{min: Config.configuration.videoResolution2.width, max: Config.configuration.videoResolution.width},
                                // height: {exact: videoResWidth}, //{min: Config.configuration.videoResolution2.height, max: Config.configuration.videoResolution.height},
                                // aspectRatio: 1.777777778
                                width: { ideal: 4096 },
                                height: { ideal: 2160 },
                                aspectRatio: {exact : 1.777777778}

                            }: false : false: false,
                    },
                    success(jsep: any) {
                        publisherHandle.printLog({status: 'success-ice restart'})
                        const publish = {
                            request: 'configure',
                            restart: true,
                        };
                        publisherHandle.send({ message: publish, jsep });
                        if(!audio && !meetingInfo.audio_bridge) {
                            dispatch(toggleMute(false));
                        }
                    },
                    error(error: any) {
                        publisherHandle.printLog({status: 'error-ice restart', error})
                        dispatch(showMessage('error', 'error in ice restart publisher' , {}));
                    }
                });

                publishersList.map((publisher: any) => {
                    if(publisher.removed !== true && !publisher.detached) {
                        publisher.send({
                            message: { request: 'configure', restart: true, refresh: true }
                        })
                    }
                });
                screenShareParticipants.map((publisher: any) => {
                    if(publisher.removed !== true) {
                        publisher.send({
                            message: { request: 'configure', restart: true, refresh: true }
                        })
                    }
                });
            });
        }

    }
}

export function speechDetection(stream: MediaStream) {
    return async function (dispatch: any, getState: any) {
        const options = {interval: 100, threshold: -40};
        const speechEvents = hark(stream, options);
        speechEvents.on('speaking', () => {
                dispatch(setDetectSpeech(true));
        });

        speechEvents.on('stopped_speaking', () => {
            dispatch(setDetectSpeech(false));
        });
    }
}

export function muteUnmuteParticipant(event: string, participant_id: any) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        const muteUnMuteMessage = {
            event,
            participant_id
        };
        if(participant_id === null) {
            delete muteUnMuteMessage.participant_id;
        }
        if(publisherHandle) {
            publisherHandle.send({ message: muteUnMuteMessage });
        }
    }
}

export function approveDenyParticipants(event: string, list: any) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        if(getState().Hoolva.meetingInfo.e2ee){
            const xqMsgInfo = getState().Hoolva.xqMessagesInfo;
            list.forEach((element:any) => {
                if(element.enter === true) {
                    element.e2ee_token = {
                        keyLocator: xqMsgInfo.keyLocator,
                        hostSessionId: xqMsgInfo.hostSessionId,
                        serviceAvailable: xqMsgInfo.serviceAvailable
                    };
                }
            });

        }
        const approveDenyRequest = {
            event,
            participants: list
        }
        if(publisherHandle) {
            publisherHandle.send({ message: approveDenyRequest });
        }
    }
}

export function changeJoinState(event: string, state: string) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        const statechange = {
            event,
            join_mode: state
        }
        if(publisherHandle) {
            publisherHandle.send({ message: statechange });
        }
    }
}

export function sendRaiseHandRequest(event: string, id: number) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        const raisehandrequest = {
            event,
            host_only: false,
            update: {raisehand: event === 'raisehand_request' ? true : false}
        }
        if(event === 'remote_lowerhand_request') {
            // @ts-ignore
            raisehandrequest.participant_id = id;
            raisehandrequest.host_only = true;
            //@ts-ignore
            delete raisehandrequest.update;
        }
        if(publisherHandle) {
            publisherHandle.send({ message: raisehandrequest });
        }
    }
}

export function sendVideoToggleRequest(event: string, participant_id: number) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        const videoToggleRequest = {
            event,
            participant_id
        }
        if(publisherHandle) {
            publisherHandle.send({ message: videoToggleRequest });
        }
    }
}

export function sendEndMeetingRequest(event: string) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        const endMeeting = {
            event,
        }
        if(publisherHandle) {
            publisherHandle.send({ message: endMeeting });
        }
    }
}

export function sendDisplayNameChangeRequest(event: string, display: string) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        const displayNameRequest = {
            event,
            host_only: false,
            update: {display}
        }
        if(publisherHandle) {
            publisherHandle.send({ message: displayNameRequest });
            dispatch(setDispalyName(display))
            participants[0].rfdisplay = display;
            setPublishersList(participants);
        }
    }
}
export function sendCallBackRequest(event: string, number: string) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        const callBackRequest = {
            event,
            destination_numbers: [number]
        }
        if(publisherHandle) {
            publisherHandle.send({ message: callBackRequest });
        }
    }
}
export function sendRecordRequest(request: string, bool: boolean) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        const {meetingInfo} = getState().Hoolva;
        const recordRequest = {
            request,
            record: bool
        }
        if(bool) {
            const currentParticipantList = participants.filter(participant => {
                return  participant.removed !== true &&
                    participant.video === 'unmuted' &&
                    !participant.detached
            });
            const handleList: string [] = [];
            currentParticipantList.slice(0, 4).map((participant : any) => {
                handleList.push(participant.rfid);
            });
            if(meetingInfo.record_mode === 'host_with_others') {
                // @ts-ignore
                recordRequest.publishers_list = handleList;
            }
        }
        if(publisherHandle) {
            publisherHandle.send({ message: recordRequest });
        }
    }
}
export function sendKickMessage(id: number) {
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        const kickMessage = {
            event: "kickout",
            participant_id: id
        };
        if(publisherHandle) {
            publisherHandle.send({ message: kickMessage });
        }
    }
}

export function handleAnimationComplete(id: any) {
    return async function (dispatch: any, getState: any) {
        if(participants.length > 0) {
            if (participants.findIndex(data => data.rfid === id) !== -1) {
                participants[participants.findIndex(data => data.rfid === id)].reaction = null;
                dispatch(setPublishersList(participants));
            }
        }
    }
}

export function upgradeSubstreamBasedOnNetwork(latencyMode: string) {
    return async function (dispatch: any, getState: any) {
        const {currentSlider, maxTileinslider, publisherHandle} = getState().Meet;
        const sliderMaxLength = maxTileinslider;
        const currentParticipantList = participants.filter(participant => {
            return participant.removed !== true ;
        });
        const start = currentSlider * sliderMaxLength;
        const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
        const participantInView = currentParticipantList.splice(start, end - start + 1);
        const videoParticipantInView = participantInView.filter((participant: any) => {
            return participant.video === 'unmuted' && !participant.detached && !participant.isPublisher
        });
        const detachedVideoParticipant = participantInView.filter((participant: any) => {
            return participant.video === 'unmuted' && participant.detached && !participant.isPublisher
        });
        let maxSubstream: number = 0;
        if(videoParticipantInView.length <= Config.configuration.pool.high) {
            maxSubstream = 2;
        } else if(videoParticipantInView.length > Config.configuration.pool.high && participantInView.length <= Config.configuration.pool.medium) {
            maxSubstream = 1
        } else {
            maxSubstream = 0
        }

        if (latencyMode === 'MEDIUM') {
            if(maxSubstream === 2) {
                maxSubstream = 1;
            }
            detachedVideoParticipant.map((participant,index) => {
                setTimeout(() => {
                dispatch(newRemoteFeed(participant.rfid, participant.rfdisplay, participant.audio, 'unmuted', participant.host, participant.raisehand, participant.room_part_id));
                },(index+10) * 100)
                
            });
            dispatch(turnOnSubscriberVideo(latencyMode));
        }
        if (latencyMode === 'HIGH') {
            if(maxSubstream !== 0) {
                maxSubstream = 0;
            }
        }
        if (latencyMode === 'VERY_HIGH') {
            if(maxSubstream !== 0) {
                maxSubstream = 0;
            }
        }
        const mediumHandle: any [] = [];
        const highHandle: any [] = [];
        videoParticipantInView.map((participant: any) => {
            if(participant.simulcastValue === 0 &&  participant.simulcastValue < maxSubstream  && !participant.pauseVideo) {
                mediumHandle.push(participant.id)
            } else if(participant.simulcastValue === 1 &&  participant.simulcastValue < maxSubstream && !participant.pauseVideo) {
                highHandle.push(participant.id)
            }
        });
        if(mediumHandle.length !== 0) {
            const simulcastRequest = {
                request: "configure",
                substream: 1,
                temporal: Config.configuration.temporal,
                handles_list: mediumHandle
            }
            if(publisherHandle) {
                publisherHandle.send({message: simulcastRequest})
                console.log('sending simulcast configure request video participant length change with substrem as ' + 1, simulcastRequest);
            }
        }
        if(highHandle.length !== 0) {
            const simulcastRequest = {
                request: "configure",
                substream: 2,
                temporal: Config.configuration.temporal,
                handles_list: highHandle
            }
            if(publisherHandle) {
                publisherHandle.send({message: simulcastRequest})
                console.log('sending simulcast configure request video participant length change with substrem as ' + 2, simulcastRequest);
            }
        }
    }
}

export function turnOnSubscriberVideo(latencyMode: string) {
    return async function (dispatch: any, getState: any) {
        const {currentSlider, maxTileinslider, publisherHandle} = getState().Meet;
        const sliderMaxLength = maxTileinslider;
        const currentParticipantList = participants.filter(participant => {
            return participant.removed !== true ;
        });
        const start = currentSlider * sliderMaxLength;
        const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
        const participantInView = currentParticipantList.splice(start, end - start + 1);
        // const videoParticipantInView = participantInView.filter((participant: any) => {
        //     return participant.video === 'unmuted' && !participant.detached && !participant.isPublisher && participant.simulcastValue === -1
        // });

        const videoOffParticipants = participantInView.filter((participant: any) => {
            return participant.video === 'unmuted' && !participant.detached && !participant.isPublisher && participant.pauseVideo
        });

        // let maxSubstream: number = 0;
        // if(videoParticipantInView.length <= Config.configuration.pool.high) {
        //     maxSubstream = 2;
        // } else if(videoParticipantInView.length > Config.configuration.pool.high && participantInView.length <= Config.configuration.pool.medium) {
        //     maxSubstream = 1
        // } else {
        //     maxSubstream = 0
        // }
        //
        // if (latencyMode === 'MEDIUM') {
        //     if(maxSubstream === 2) {
        //         maxSubstream = 1;
        //     }
        // }
        // if (latencyMode === 'HIGH' || latencyMode === 'VERY_HIGH') {
        //     maxSubstream = 0;
        // }
        const handleList = videoOffParticipants.map(participant => participant.id);
        const turnVideoOnRequest = {
            request: "configure",
            video: true,
            substream: 0,
            handles_list: handleList
        }
        if(publisherHandle) {
            publisherHandle.send({message: turnVideoOnRequest});
            let result = participants.map(participant => {
                let currentId = handleList.find(id => id === participant.id);
                if(currentId) {
                    participant.pauseVideo = false;
                }
                return participant;
            });
            participants = [...result]
            dispatch(setPublishersList([...result]));
            console.log('sending turn on video configure request to all video subscribers', turnVideoOnRequest, [...result]);
        }
    }
}

export function turnOffSubscriberVideo() {
    return async function (dispatch: any, getState: any) {
        const {currentSlider, maxTileinslider, publisherHandle} = getState().Meet;
        const sliderMaxLength = maxTileinslider;
        const currentParticipantList = participants.filter(participant => {
            return participant.removed !== true ;
        });
        const start = currentSlider * sliderMaxLength;
        const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
        const participantInView = currentParticipantList.splice(start, end - start + 1);
        const videoParticipantInView = participantInView.filter((participant: any) => {
            return participant.video === 'unmuted' && !participant.detached && !participant.isPublisher
        });
        const handleList = videoParticipantInView.map(participant => participant.id);
        const turnVideoOffRequest = {
            request: "configure",
            video: false,
            handles_list: handleList
        }
        if(publisherHandle) {
            publisherHandle.send({message: turnVideoOffRequest});
            let result = participants.map(participant => {
                let currentId = handleList.find(id => id == participant.id);
                if(currentId) {
                    participant.pauseVideo = true;
                }
                return participant;
            });
            participants = [...result];
            dispatch(setPublishersList([...result]));
            console.log('sending turn off video configure request to all video subscribers', turnVideoOffRequest, [...result]);
        }
    }
}

export function downGradeSubstreamForHandle(handle: any) {
    return async function (dispatch: any, getState: any) {
        console.log('inside downGradeSubstreamForHandle', handle )
        const {currentSlider, maxTileinslider, activeScreen, publisherHandle} = getState().Meet;
        const currentSubstream = handle.simulcastValue;
        const sliderMaxLength = maxTileinslider;
        const currentParticipantList = participants.filter(participant => {
            return participant.removed !== true ;
        });
        const start = currentSlider * sliderMaxLength;
        const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
        const participantInView = currentParticipantList.splice(start, end - start + 1);
        const videoParticipantInView = participantInView.filter((participant: any) => {
            return participant.video === 'unmuted' && !participant.detached && !participant.isPublisher
        });
        console.log('videoParticipantInView downgrade', videoParticipantInView)
        let maxSubstream: number = 0;
        if(videoParticipantInView.length <= Config.configuration.pool.high) {
            maxSubstream = 2;
        } else if(videoParticipantInView.length > Config.configuration.pool.high && participantInView.length <= Config.configuration.pool.medium) {
            maxSubstream = 1
        } else {
            maxSubstream = 0
        }
        console.log('downgrading subsream ' + handle , currentSubstream, maxSubstream);

        //&& currentSubstream >= maxSubstream
        if(currentSubstream > 0  && !handle.pauseVideo) {
            console.log('downgrading subsream of ' + handle.rfdisplay);
            const subStreamRequest = {
                request: 'configure',
                substream: currentSubstream - 1,
                temporal: 1
            }
            if(handle) {
                handle.send({message: subStreamRequest})
            }
        }
    }
}

export  function optimizeBandWidthBasedOnVideoParticipant(leave: boolean)  {
    return async function (dispatch: any, getState: any) {
        const {currentSlider, maxTileinslider, activeScreen, publisherHandle, latencyMode} = getState().Meet;
        const sliderMaxLength = maxTileinslider;
        const currentParticipantList = participants.filter(participant => {
            return participant.removed !== true ;
        });
        const start = currentSlider * sliderMaxLength;
        const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
        const participantInView = currentParticipantList.splice(start, end - start + 1);
        const videoParticipantInView = participantInView.filter((participant: any) => {
            return participant.video === 'unmuted' && !participant.detached && !participant.isPublisher
        });
        let simulcastedValue: number = 0;
        if(videoParticipantInView.length <= Config.configuration.pool.high) {
            simulcastedValue = 2;
        } else if(videoParticipantInView.length > Config.configuration.pool.high && participantInView.length <= Config.configuration.pool.medium) {
            simulcastedValue = 1
        } else {
            simulcastedValue = 0
        }

        if (latencyMode === 'MEDIUM') {
            if(simulcastedValue === 2) {
                simulcastedValue = 1;
            }
        }
        if (latencyMode === 'HIGH' || latencyMode === 'VERY_HIGH') {
            simulcastedValue = 0;
        }
        const handleList: any [] = [];
        const handleLowList: any [] = [];
        videoParticipantInView.map((participant: any) => {
            if(leave) {
                if(participant.simulcastValue < simulcastedValue && !participant.pauseVideo) {
                    if(participant.simulcastValue === 1) {
                        handleList.push(participant.id);
                    } else if(participant.simulcastValue === 0) {
                        handleLowList.push(participant.id)
                    }

                }
            } else {
                if(participant.simulcastValue > simulcastedValue && !participant.pauseVideo) {
                    handleList.push(participant.id);
                }
            }
        })
        if(handleList.length !== 0) {
            const simulcastRequest = {
                request: "configure",
                substream: simulcastedValue,
                temporal: Config.configuration.temporal,
                handles_list: handleList
            }
            if(publisherHandle) {
                publisherHandle.send({message: simulcastRequest})
                console.log('sending simulcast configure request video participant length change with substrem as' + simulcastedValue, simulcastRequest);
            }
        }
        if(handleLowList.length !== 0) {
            const simulcastRequest = {
                request: "configure",
                substream: 1,
                temporal: Config.configuration.temporal,
                handles_list: handleLowList
            }
            if(publisherHandle) {
                publisherHandle.send({message: simulcastRequest})
                console.log('sending simulcast configure request video participant length change with substrem as' + simulcastedValue, simulcastRequest);
            }
        }
    }
}
export function optimizeBandWidthBasedOnSimulCast1() {
    return async function (dispatch: any, getState: any) {
        const {currentSlider, maxTileinslider, activeScreen, publisherHandle} = getState().Meet;
        const {screenShareParticipants} = getState().ScreenShare
        const sliderMaxLength = maxTileinslider;
        const currentParticipantList = participants.filter(participant => {
            return participant.removed !== true;
        });
        const isScreenShare = screenShareParticipants.filter((screen : any) => screen.removed !== true);
        const start = currentSlider * sliderMaxLength;
        const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
        const participantInView = currentParticipantList.splice(start, end - start + 1);
        const videoParticipantInView = participantInView.filter((participant: any) => {
            return participant.video === 'unmuted' && !participant.detached
        })
        let simulcastedValue: number;
        if(activeScreen === 1) {
            if(videoParticipantInView.length <= Config.configuration.pool.high) {
                simulcastedValue = 2;
            } else if(videoParticipantInView.length > Config.configuration.pool.high && participantInView.length <= Config.configuration.pool.medium) {
                simulcastedValue = 1
            } else {
                simulcastedValue = 0
            }
            const handleList: any [] = [];
            videoParticipantInView.map((participant: any) => {
                if (!participant.isPublisher ) {
                    handleList.push(participant.id);
                }
            })
            if(handleList.length !== 0) {
                const simulcastRequest = {
                    request: "configure",
                    substream: simulcastedValue,
                    temporal: Config.configuration.temporal,
                    handles_list: handleList
                }
                if(publisherHandle) {
                    publisherHandle.send({message: simulcastRequest})
                    console.log('sending simulcast configure request ', simulcastRequest);
                }
            }

        } else {
            simulcastedValue = 0;
            if(isScreenShare.length === 0) {
                const handleList: any [] = [];
                videoParticipantInView
                    .filter(
                        (participant) =>
                            participant.video === 'unmuted' &&
                            !participant.isPublisher &&
                            !participant.detached &&
                            !activeSpeakers.find((speakerId) => participant.rfid === speakerId)
                    ).map((participant: any) => {
                    handleList.push(participant.id);
                })
                if(handleList.length !== 0) {
                    const simulcastRequest = {
                        request: "configure",
                        temporal: Config.configuration.temporal,
                        substream: simulcastedValue,
                        handles_list: handleList
                    }
                    if(publisherHandle) {
                        publisherHandle.send({message: simulcastRequest})
                        console.log('sending simulcast configure request ', simulcastRequest);
                    }
                }
                if(activeSpeakers.length !== 0) {
                    const simulcastRequest1 = {
                        request: "configure",
                        temporal: Config.configuration.temporal,
                        substream: 1,
                        handles_list: activeSpeakers
                    }
                    if(publisherHandle) {
                        publisherHandle.send({message: simulcastRequest1})
                        console.log('sending simulcast1 configure request ', simulcastRequest1);
                    }
                }


            } else {
                simulcastedValue = 0;
                const handleList: any [] = [];
                videoParticipantInView.map((participant: any) => {
                    if (!participant.isPublisher ) {
                        handleList.push(participant.id);
                    }
                })
                const simulcastRequest = {
                    request: "configure",
                    substream: simulcastedValue,
                    temporal: Config.configuration.temporal,
                    handles_list: handleList
                }
                if(publisherHandle) {
                    publisherHandle.send({message: simulcastRequest})
                    console.log('sending simulcast configure request ', simulcastRequest);
                }
            }
        }
    }
}


export function subscribeVideoRoomBasedOnView() {
    return async function (dispatch: any, getState: any) {
        //debounce(() => {
            const {currentSlider, maxTileinslider, activeScreen, activeSpeaker, latencyMode} = getState().Meet;
            const {screenShareParticipants} = getState().ScreenShare
            const sliderMaxLength = maxTileinslider;
            const currentParticipantList = participants.filter(participant => {
                return participant.removed !== true;
            });
            const isScreenShare = screenShareParticipants.filter((screen : any) => screen.removed !== true);
            const start = currentSlider * sliderMaxLength;
            const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
            const participantInView = currentParticipantList.splice(start, end - start + 1);
            if(activeScreen !== 1 && isScreenShare.length === 0) {
                currentParticipantList
                    .filter(
                        (participant) =>
                            participant.video === 'unmuted' &&
                            !participant.isPublisher &&
                            !participant.detached &&
                            !activeSpeakers.find((speakerId) => participant.rfid === speakerId) &&
                        activeSpeaker.rfid !== participant.rfid
                    ).map((participant: any) => {
                    if (participant.hasOwnProperty('detach') && !participant.detached) {
                        console.log(
                            'unsubscribing video participant band width optimization',
                            participant.rfdisplay,
                        );
                        participant.detach();
                    }
                    participant.detached = true;
                    participants[
                        participants.findIndex((data) => data.rfid === participant.rfid)
                        ] = participant;
                    dispatch(setPublishersList(participants));
                });
                participantInView
                    .filter(
                        (participant) =>
                            participant.video === 'unmuted' &&
                            !participant.isPublisher &&
                            participant.detached,
                    )
                    .map((participant, index) => {
                        console.log('subscribing video participant gallery view band width optimization', participant.rfdisplay)
                        if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                            setTimeout(() => {
                            dispatch(newRemoteFeed(participant.rfid, participant.rfdisplay, participant.audio, 'unmuted', participant.host, participant.raisehand, participant.room_part_id));
                            },(index+10) * 100)        
                        }
                    });
                const currentActiveSpeakers = activeSpeakers.filter((speakerId) =>
                    !participantInView.find((participant) => participant.rfid === speakerId)
                );
                activeSpeakers = [...currentActiveSpeakers];
                activeSpeakers.map((speakerId) => {
                    const handle =
                        participants[
                            participants.findIndex((data) => data.rfid === speakerId)
                            ];
                    if (handle) {
                        if (
                            !handle.isPublisher &&
                            handle.detached &&
                            handle.video === 'unmuted'
                        ) {
                            console.log('subscribing video participant in speaker view');
                            if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                                setTimeout(() => {                                
                                dispatch(
                                    newRemoteFeed(
                                        handle.rfid,
                                        handle.rfdisplay,
                                        handle.audio,
                                        'unmuted',
                                        handle.host,
                                        handle.raisehand,
                                        handle.room_part_id,
                                    )
                                );
                            },200)

                            }

                        }
                    }
                });
            } else if(activeScreen !== 1 && isScreenShare.length !== 0) {
                currentParticipantList
                    .filter(
                        (participant) =>
                            participant.video === 'unmuted' &&
                            !participant.isPublisher &&
                            !participant.detached
                    ).map((participant: any) => {
                    //if(!checkParticipantInCurrentSlide(participant, currentSlider, maxTileinslider)) {
                    if (participant.hasOwnProperty('detach') && !participant.detached) {
                        console.log(
                            'unsubscribing video participant band width optimization',
                            participant.rfdisplay,
                        );
                        participant.detach();
                    }
                    participant.detached = true;
                    participants[
                        participants.findIndex((data) => data.rfid === participant.rfid)
                        ] = participant;
                    dispatch(setPublishersList(participants));
                    // }
                });
                participantInView
                    .filter(
                        (participant) =>
                            participant.video === 'unmuted' &&
                            !participant.isPublisher &&
                            participant.detached
                    )
                    .map((participant, index) => {
                        console.log('subscribing video participant gallery view band width optimization', participant.rfdisplay);
                        if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                            setTimeout(() => {
                            dispatch(newRemoteFeed(participant.rfid, participant.rfdisplay, participant.audio, 'unmuted', participant.host, participant.raisehand, participant.room_part_id));
                            },(index+10) * 100)
                        }
                    });
                const currentActiveSpeakers = activeSpeakers.filter((speakerId) =>
                    !participantInView.find((participant) => participant.rfid === speakerId)
                );
                activeSpeakers = [...currentActiveSpeakers];
            } else if (activeScreen === 1) {
                currentParticipantList
                    .filter((participant) =>
                        participant.video === 'unmuted' &&
                        !participant.isPublisher &&
                        !participant.detached)
                    .map(participant => {
                        if (participant.hasOwnProperty('detach') && !participant.detached) {
                            console.log(
                                'unsubscribing video participant gallery view band width optimization',
                                participant.rfdisplay,
                            );
                            participant.detach();
                        }
                        participant.detached = true;
                        participants[
                            participants.findIndex((data) => data.rfid === participant.rfid)
                            ] = participant;
                        dispatch(setPublishersList(participants));
                    });
                participantInView
                    .filter(
                        (participant) =>
                            participant.video === 'unmuted' &&
                            !participant.isPublisher &&
                            participant.detached
                    )
                    .map((participant, index) => {
                        console.log('subscribing video participant gallery view band width optimization', participant.rfdisplay)
                        if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                            setTimeout(() => {
                            dispatch(newRemoteFeed(participant.rfid, participant.rfdisplay, participant.audio, 'unmuted', participant.host, participant.raisehand, participant.room_part_id));
                            },(index+10) * 100) 
                        }
                    });
                const currentActiveSpeakers = activeSpeakers.filter((speakerId) =>
                    !participantInView.find((participant) => participant.rfid === speakerId)
                );
                activeSpeakers = [...currentActiveSpeakers];
            }
        // }, 300)
    }
}

export function subscribeVideoRoomBasedOnView1() {
    return async function (dispatch: any, getState: any) {
        // debounce(() => {
            const {currentSlider, maxTileinslider, activeScreen, latencyMode} = getState().Meet;
            const {screenShareParticipants} = getState().ScreenShare
            const sliderMaxLength = maxTileinslider;
            const currentParticipantList = participants.filter(participant => {
                return participant.removed !== true;
            });
            const isScreenShare = screenShareParticipants.filter((screen : any) => screen.removed !== true);
            const start = currentSlider * sliderMaxLength;
            const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
            if(currentParticipantList.length > 1) {
                currentParticipantList
                    .filter(participant => participant.video === 'unmuted' && !participant.isPublisher && !participant.detached)
                    .map(participant => {
                        let index = activeSpeakers.indexOf(participant.id);
                        if(index === -1 && activeScreen !== 1) {
                            if(participant.hasOwnProperty('detach')) {
                                console.log('unsubscribing video participant band width optimization', participant.rfdisplay)
                                participant.detach();
                            }
                            participant.detached = true;
                            participants[participants.findIndex(data => data.rfid === participant.rfid)] = participant;
                            dispatch(setPublishersList(participants));
                        } else if(index !== -1 && activeScreen !== 1 && isScreenShare.length !== 0) {
                            if(participant.hasOwnProperty('detach')) {
                                console.log('unsubscribing video participant band width optimization', participant.rfdisplay)
                                participant.detach();
                            }
                            participant.detached = true;
                            participants[participants.findIndex(data => data.rfid === participant.rfid)] = participant;
                            dispatch(setPublishersList(participants));
                        } else if(activeScreen === 1) {
                            if(participant.hasOwnProperty('detach')) {
                                if(participant.hasOwnProperty('detach')) {
                                    console.log('unsubscribing video participant band width optimization', participant.rfdisplay)
                                    participant.detach();
                                }
                                participant.detached = true;
                                participants[participants.findIndex(data => data.rfid === participant.rfid)] = participant;
                                dispatch(setPublishersList(participants));
                            }
                        }
                    })
                currentParticipantList.slice(start, end + 1).map((participant, index) => {
                    if(participant.video === 'unmuted' && participant.detached === true && !participant.isPublisher) {
                        console.log('subscribing video participant band width optimization', participant.rfdisplay)
                        if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                            setTimeout(() => {
                            dispatch(newRemoteFeed(participant.rfid, participant.rfdisplay, participant.audio, 'unmuted', participant.host, participant.raisehand, participant.room_part_id));
                            },(index+10) * 100)  
                        }
                    }
                });


                // if(activeScreen === 1) {
                //     if(activeSpeakers.length > 0) {
                //         activeSpeakers.map(speakers => {
                //             const handle = participants[participants.findIndex(data => data.rfid === speakers)];
                //             console.log('un subscribing active speake in gallery', handle);
                //             if(handle) {
                //                 if(!checkParticipantInCurrentSlide(handle, currentSlider, maxTileinslider)) {
                //                     if(!handle.isPublisher && !handle.detached && handle.hasOwnProperty('detach')) {
                //                         handle.detach();
                //                         handle.detached = true;
                //                         participants[participants.findIndex(data => data.rfid === handle.rfid)] = handle;
                //                         dispatch(setPublishersList(participants));
                //                     }
                //                 }
                //             }
                //
                //         });
                //     }
                // }
                if(activeScreen !== 1 && isScreenShare.length === 0) {
                    if(activeSpeakers.length > 0) {
                        activeSpeakers.map((speakers ,index)=> {
                            const handle = participants[participants.findIndex(data => data.rfid === speakers)];
                            console.log('subscribing active speakers in spekerview', handle);
                            if(handle) {
                                if(!handle.isPublisher && handle.detached && handle.video === 'unmuted') {
                                    if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                                        setTimeout(() => {
                                        dispatch(newRemoteFeed(handle.rfid, handle.rfdisplay, handle.audio, 'unmuted', handle.host, handle.raisehand, handle.room_part_id));
                                        },(index+10) * 100)
                                    }
                                }
                            }

                        });
                    }
                }
            }
       // }, 500);
    }
}

export function optimizeBandWidthBasedOnView() {
    return async function (dispatch: any, getState: any) {
        const {currentSlider, publisherHandle, maxTileinslider, activeScreen} = getState().Meet;
        const {screenShareParticipants} = getState().ScreenShare
        const sliderMaxLength = maxTileinslider;
        const currentParticipantList = participants.filter(participant => {
            return participant.removed !== true;
        });
        const start = currentSlider * sliderMaxLength;
        const end =  (currentSlider * sliderMaxLength) + (sliderMaxLength - 1);
        const handleId: any[]  = [];
        const stopHandleId: any = [];
        if(currentParticipantList.length > 1) {
            currentParticipantList.map((participant, index) => {
                if(index>= start && index <= end && index !== 0) {
                    if(participant.video === 'unmuted') {
                        handleId.push(participant.id);
                    }
                } else {
                    if(participant.video === 'unmuted' && index !== 0) {
                        stopHandleId.push(participant.id)
                    }
                }
            });
            const isScreenShare = screenShareParticipants.filter((screen : any) => screen.removed !== true);
            if(activeScreen !== 1 && isScreenShare.length === 0) {
                if(activeSpeakers.length > 0) {
                    activeSpeakers.map(speakers => {
                        let index = handleId.indexOf(speakers);
                        if(index === -1) {
                            handleId.push(speakers);
                        }
                    });
                }
            } else if(activeScreen !== -1 && isScreenShare.length !== 0){
                isScreenShare.map((screenshare: any) => {
                    let index = handleId.indexOf(screenshare.id);
                    if(index === -1) {
                        handleId.push(screenshare.id);
                    }
                })
            }

            const startVideo = {
                request: "configure",
                video: true,
                handles_list: handleId
            }
            const stopVideo = {
                request: "configure",
                video: false,
                handles_list: stopHandleId
            }
            if(publisherHandle) {
                if(handleId.length > 0) {
                    console.log('bandwidth optimization startVideoRequest', startVideo);
                    publisherHandle.send({message: startVideo})
                }
                if(stopHandleId.length > 0) {
                    console.log('bandwidth optimization stopVideoRequest', stopVideo);
                    publisherHandle.send({message: stopVideo})
                }
            }
        }

    }
}

export function manageActiveSpeaker(handle: any) {
    return async function (dispatch: any, getState: any) {
        const {activeScreen} = getState().Meet;
        if(Config.configuration.bandWidthOptimization) {
            if (activeSpeakers.length < Config.configuration.activeSpeakerDefaultLength) {
                let index = activeSpeakers.indexOf(handle.id);
                if(index === -1 && handle.video === 'unmuted' && !handle.isPublisher) {
                    activeSpeakers.push(handle.id)
                }
                if(activeScreen !== 1 && handle.video === 'unmuted' && !handle.isPublisher) {
                    console.log('sending configure start video request for current active speaker', handle.rfdisplay);
                    const configureStartVideoRequest = {
                        request: 'configure',
                        audio: true,
                        video: true,
                    };
                    handle.send({message: configureStartVideoRequest});
                }
            } else {
                let index = activeSpeakers.indexOf(handle.id);
                if(activeScreen !== 1 && handle.video === 'unmuted' && !handle.isPublisher) {
                    console.log('sending configure stop video request for  active speaker', activeSpeakers[0].rfdisplay);
                    const configureStopVideoRequest = {
                        request: 'configure',
                        audio: true,

                        video: false,
                    };
                    handle.send({message: configureStopVideoRequest});
                    console.log('sending configure start video request for current active speaker', handle.rfdisplay);
                        const configureStartVideoRequest = {
                            request: 'configure',
                            audio: true,
                            video: true,
                        };
                        handle.send({message: configureStartVideoRequest});
                }
                if (index === -1 && handle.video === 'unmuted' &&  !handle.isPublisher) {
                    activeSpeakers[0] = activeSpeakers[1];
                    activeSpeakers[1] = activeSpeakers[2];
                    activeSpeakers[2] = handle.id;
                }

            }

        }
    }
}
export function manageActiveSpeakerInMixedAudio(handle: any) {
    return async function (dispatch: any, getState: any) {
        const {activeScreen, currentSlider, maxTileinslider, latencyMode} = getState().Meet;
        const {screenShareParticipants} = getState().ScreenShare;
        const isScreenShare = screenShareParticipants.filter((screen : any) => screen.removed !== true);
        if (!checkParticipantInCurrentSlide(handle, currentSlider, maxTileinslider)) {
            if (activeSpeakers.length < Config.configuration.activeSpeakerDefaultLength) {
                let index = activeSpeakers.indexOf(handle.rfid);
                if(index === -1 && handle.video === 'unmuted' && !handle.isPublisher) {
                    activeSpeakers.push(handle.rfid);
                    if(activeScreen !== 1 && handle.detached && isScreenShare.length === 0) {
                        if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                            setTimeout(() => {
                            dispatch(newRemoteFeed(handle.rfid, handle.rfdisplay, handle.audio, handle.video, handle.host, handle.raisehand, handle.room_part_id));
                            },200)
                        }
                    }
                } else if(handle.video === 'unmuted' && !handle.isPublisher) {
                    if(activeScreen !== 1 && handle.detached && isScreenShare.length === 0) {
                        if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                            setTimeout(() => {
                            dispatch(newRemoteFeed(handle.rfid, handle.rfdisplay, handle.audio, handle.video, handle.host, handle.raisehand, handle.room_part_id));
                            },200)
                        }
                    }
                }
            } else {
                let index = activeSpeakers.indexOf(handle.rfid);
                if(index === -1 && handle.video === 'unmuted' && !handle.isPublisher) {
                    const removeHandle = activeSpeakers[0];
                    activeSpeakers.splice(0,1);
                    activeSpeakers.push(handle.rfid);
                    if(activeScreen !== 1 && handle.detached && isScreenShare.length === 0) {
                        if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                            setTimeout(() => {
                            dispatch(newRemoteFeed(handle.rfid, handle.rfdisplay, handle.audio, handle.video, handle.host, handle.raisehand, handle.room_part_id));
                            },200)
                        }
                    }
                    const rhandle = participants[participants.findIndex(data => data.rfid === removeHandle)];
                    if(rhandle) {
                        if(!rhandle.detached && rhandle.hasOwnProperty('detach')) {
                            console.log('detaching active speaker');
                            rhandle.detach();
                            rhandle.detached = true;
                            participants[participants.findIndex(data => data.rfid === rhandle.rfid)] = rhandle;
                            dispatch(setPublishersList(participants));
                        }
                    }
                } else if(handle.video === 'unmuted' && !handle.isPublisher) {
                    if(activeScreen !== 1 && handle.detached && isScreenShare.length === 0) {
                        if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                            setTimeout(() => {
                            dispatch(newRemoteFeed(handle.rfid, handle.rfdisplay, handle.audio, handle.video, handle.host, handle.raisehand, handle.room_part_id));
                   
                            },200)
                        }
                    }
                }
            }
        }
    }
}

export function manageActiveSpeakerInMixedAudio1(handle: any) {
    return async function (dispatch: any, getState: any) {
        const {activeScreen, currentSlider, maxTileinslider, latencyMode} = getState().Meet;
        const {screenShareParticipants} = getState().ScreenShare;
        if(!checkParticipantInCurrentSlide(handle, currentSlider, maxTileinslider)) {
            if(activeScreen !== 1 && handle.video === 'unmuted' && !handle.isPublisher && handle.detached) {
                console.log('subscribing ' + handle.rfdisplay + 'in video room  mixed audio room');
                // attach here
                const isScreenShare = screenShareParticipants.filter((screen : any) => screen.removed !== true);
                if(isScreenShare.length === 0) {
                    if(latencyMode !== 'VERY_HIGH' || !Config.configuration.simulcast) {
                        setTimeout(() => {
                        dispatch(newRemoteFeed(handle.rfid, handle.rfdisplay, handle.audio, handle.video, handle.host, handle.raisehand, handle.room_part_id));
                        },200)
                    }
                }

                let index = activeSpeakers.indexOf(handle.rfid);
                if(index === -1) {
                    if (activeSpeakers.length < Config.configuration.activeSpeakerDefaultLength) {
                        activeSpeakers.push(handle.rfid);
                    } else {
                        const removeHandle = activeSpeakers[0];
                        console.log('removing video room subscription', removeHandle, participants, activeSpeakers)
                        const rhandle = participants[participants.findIndex(data => data.rfid === removeHandle)];
                        if(rhandle) {
                            if(!rhandle.detached && rhandle.hasOwnProperty('detach')) {
                                console.log('detaching active speaker');
                                rhandle.detach();
                                rhandle.detached = true;
                                participants[participants.findIndex(data => data.rfid === rhandle.rfid)] = rhandle;
                                dispatch(setPublishersList(participants));
                            }
                        }
                        activeSpeakers.splice(0,1);
                        activeSpeakers.push(handle.rfid);
                        // activeSpeakers[0] = activeSpeakers[1];
                        // activeSpeakers[1] = activeSpeakers[2];
                        // activeSpeakers[2] = handle.rfid;
                    }
                } else {
                    console.log('checking else')
                }
            } else if (activeScreen !== 1 && handle.video === 'unmuted' && !handle.isPublisher && !handle.detached){
                let index = activeSpeakers.indexOf(handle.rfid);
                if(index === -1) {
                    if (activeSpeakers.length < Config.configuration.activeSpeakerDefaultLength) {
                        activeSpeakers.push(handle.rfid);
                    } else {
                        const removeHandle = activeSpeakers[0];
                        const rhandle = participants[participants.findIndex(data => data.rfid === removeHandle)];
                        if(rhandle) {
                            if(!rhandle.detached && rhandle.hasOwnProperty('detach')) {
                                console.log('detaching active speaker');
                                rhandle.detach();
                                rhandle.detached = true;
                                participants[participants.findIndex(data => data.rfid === rhandle.rfid)] = rhandle;
                                dispatch(setPublishersList(participants));
                            }
                        }
                        activeSpeakers.splice(0,1);
                        activeSpeakers.push(handle.rfid);
                        // activeSpeakers[0] = activeSpeakers[1];
                        // activeSpeakers[1] = activeSpeakers[2];
                        // activeSpeakers[2] = handle.rfid;
                    }
                }
            }
        }
        // if (activeSpeakers.length < Config.configuration.activeSpeakerDefaultLength) {
        //     let index = activeSpeakers.indexOf(handle.id);
        //     if (index === -1 && handle.video === 'unmuted' && !handle.isPublisher) {
        //         if(!checkParticipantInCurrentSlide(handle, currentSlider)) {
        //             activeSpeakers.push(handle.id);
        //             if(handle.detached === true && activeScreen !== 1) {
        //                 // attach video
        //             }
        //         }
        //     } else {
        //         let index = activeSpeakers.indexOf(handle.id);
        //         if(activeScreen !== 1 && handle.video === 'unmuted' && !handle.isPublisher) {
        //
        //         }
        //
        //     }
        // }
    }
}

export function changeResolutionRequest(res: number, temporal : number) {
    return async function (dispatch: any, getState: any) {
        const handleId: any[] = [];
       participants.map((participant => {
           if(!participant.isPublisher && participant.video === 'unmuted' && !participant.detached) {
               handleId.push(participant.id);

           }
       }))
        const configureRequest = {
            request: "configure",
            substream: res,
            temporal: temporal,
            handles_list: handleId
        }
        participants[0].send({message: configureRequest})
    }
}



export function timer(seconds: number) {
    let hours: any = Math.floor(seconds / 3600);
    let minutes: any = Math.floor((seconds - hours * 3600) / 60);
    let sec: any = seconds - hours * 3600 - minutes * 60;
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (sec < 10) {
        sec = '0' + sec;
    }
    const time = hours + ':' + minutes + ':' + sec;
    return {
        type: SET_TIMER,
        time,
        seconds : seconds + 1
    }
}
function showNotification(message: string) {
    if(!DetectRTC.browser.isSafari) {
        const audio = new Audio(NotifySound);
        const notification = {
            title: Config.configuration.brandName,
            body: message,
            icon: Config.configuration.favIcon,
            silent: true
        }
        if (!('Notification' in window)) {
            console.log("This browser does not support notifications.");
        } else if(window.Notification.permission === 'default') {
            window.Notification.requestPermission().then(function(result) {
                const myNotification = new window.Notification(notification.title, notification);
                setTimeout(myNotification.close.bind(myNotification), 5000);
            });
        } else if(window.Notification.permission === 'granted') {
            const myNotification = new window.Notification(notification.title, notification);
            setTimeout(myNotification.close.bind(myNotification), 5000);
        }
        audio.play();
    }
    
}


export function submitChatFile(data: any, room_uuid:any, chat:any) {
    return async function(dispatch: any, getState: any) {
        const response: any = await Service.submitChatFile(getState().Hoolva.API_ROOT, data, room_uuid);
        if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 203 || response.status === 204) {
            dispatch(UpdateChatMessage(chat, response.data));
            return response.data;
        } else {
            dispatch(UpdateFailedChatMessage(chat, ''));
            return false;
        }
    }
}
export function UpdateChatMessage(chat:any, url: string) {
    return async function(dispatch: any, getState: any) {
        const messageList = [...messageArray];
        let index = messageList.findIndex((data: any) => data.timestamp === chat.timestamp);
        messageList[index].loading = false;
        messageList[index].status = 'sent';
        messageList[index].url = url;
        dispatch(setMessageList(messageList));
        messageArray = [...messageList];
        console.log("submitChatFile",messageArray);
    }
}
export function UpdateFailedChatMessage(chat:any, url: string) {
    return async function(dispatch: any, getState: any) {
        const messageList = [...messageArray];
        let index = messageList.findIndex((data: any) => data.timestamp === chat.timestamp);
        messageList[index].loading = false;
        messageList[index].status = 'failed';
        messageList[index].url = url;
        dispatch(setMessageList(messageList));
        messageArray = [...messageList];
        console.log("submitFailedChatFile",messageArray);
    }
}
export function PushChatMessage(msg:any) {
    return async function(dispatch: any, getState: any) {
        let messageList = [...messageArray];
        messageList.push(msg);
        dispatch(setMessageList(messageList));
        messageArray = [...messageList];
        console.log("submitChatFile",messageArray);
    }
}
export function getTenantInfo() {
    return async function(dispatch: any, getState: any) {
        const response = await Service.getTenantInfo(getState().Hoolva.API_ROOT);
        return response;
    }
}

export async function getRoomKey (roomId:string, secret:string)
{
    console.log("getRoom key params", roomId, secret);
    const enc = new TextEncoder ();
    //console.log(">>getRoomKey::",enc,enc.encode(secret));
    const keyMaterial = await window.crypto.subtle.importKey (
        "raw",
        enc.encode(secret),
        "PBKDF2",
        false,
        ["deriveBits", "deriveKey"]
    );
    return window.crypto.subtle.deriveKey (
        {
            name: "PBKDF2",
            salt: enc.encode(roomId + ''),
            iterations: 1000,
            hash: "SHA-256"
        },
        keyMaterial,
        {"name": "AES-CTR", "length": 128},
        true,
        ["encrypt", "decrypt"]
    );
}

export function getCurrentProtocolUsed(type: string) {
    return async function(dispatch: any, getState: any) {
        const {audioRoomHandle} = getState().AudioRoom;
        const {publisherHandle} = getState().Meet;
        const {screenSharePublisherHandle} = getState().ScreenShare;
        const handle = type === '/getAudioProtocol' ? audioRoomHandle : type === '/getVideoProtocol' ? publisherHandle : screenSharePublisherHandle;
        if(handle) {
            const priority = HoolvaUtils.getCurrentStun(handle);
            console.log("priority", priority);
            const currentProtocol =  priority >> 24;
            console.log("currentProtocol", priority);
            const sendMessage = {
                event: "send_message",
                type: "text",
                message: type,
                status: "sent",
                sender: true,
                participant_name: publisherHandle.rfdisplay,
                timestamp: new Date().getTime()
            }
            let messageListArray = [...messageArray];

            messageListArray.push(sendMessage);
            dispatch(setMessageList(messageListArray));
            messageArray = [...messageListArray];
            const message = {
                event: "receive_message",
                type: "text",
                message: '',
                status: "sent",
                sender: false,
                participant_name: 'System',
                timestamp: new Date().getTime()
            }
            if (currentProtocol === 0) {
                message.message= "tls"
            }
            else if (currentProtocol === 1) {
                message.message= "tcp"
            } else if(currentProtocol === 2) {
                message.message= "udp"
            } else if(currentProtocol === 100) {
                message.message= "srflx"
            }
            else if(currentProtocol === 110) {
                message.message= "prflx"
            }else {
                message.message = currentProtocol + " "
            }

            let messageList = [...messageArray];
            messageList.push(message);
            dispatch(setMessageList(messageList));
            messageArray = [...messageList];
        }
    }
}




