import * as Actions from '../actions';
import {SET_USER_PERMISSIONS} from "../actions";

const initialState: any = {
    userId: '',
    browserCheck: false,
    dbStore: null,
    meetingList: [],
    loggedInData: null,
    loggedIn: false,
    displayTheme: 'dark',
    t: null,
    clientAuth: null,
    globalClientAuth: null,
    authProviders: [],
    userPermission: null,
    userPermissionEntity: null,
    usetTempId:''
};

const AuthReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case Actions.SET_USER_ID: {
            return {
                ...state,
                userId: action.userId
            }
        }
        case Actions.SET_BROWSER_VERIFY: {
            return {
                ...state,
                browserCheck: action.verify
            }
        }
        case Actions.SET_DB_STORE: {
            return  {
                ...state,
                dbStore: action.dbStore
            };
        }
        case Actions.SET_USER_PERMISSIONS: {
            let permissionEntity: any = {};
            action.permission.children.map((perm: any) => {
                permissionEntity[perm.id] = perm;
            });
            return {
                ...state,
                userPermission: action.permission,
                userPermissionEntity: permissionEntity
            }
        }
        case Actions.SET_I18N: {
            return {
                ...state,
                t: action.t
            }
        }
        case Actions.SET_MEETING_LIST: {
            return {
                ...state,
                meetingList: action.update ? [...action.meetingList] : [...action.meetingList, ...state.meetingList]
            }
        }
        case Actions.SET_LOGIN_INFO: {
            return {
                ...state,
                loggedIn: action.info ? true : false,
                loggedInData: action.info
            }
        }
        case Actions.SET_CLIENT_AUTH: {
            return {
                ...state,
                clientAuth: action.data
            }
        }
        case Actions.SET_GLOBAL_CLIENT_AUTH: {
            return {
                ...state,
                globalClientAuth: action.data,
            }
        }
        case Actions.SET_AUTH_PROVIDERS: {
            return {
                ...state,
                authProviders: action.providers
            }
        }
        case Actions.RESET_AUTH: {
            return {...initialState}
        }
        case Actions.SET_THEME : {
            return {
                ...state,
                displayTheme: action.displayTheme
            }
        }
        case Actions.SET_USER_TEMP_ID : {
            return {
                ...state,
                usetTempId: action.usetTempId
            }
        }
        default: {
            return state;
        }
    }
}
export default AuthReducer;
