import Datastore from 'nedb';
class LocalDb {
    static loadLocalDb() {
        const db = new Datastore({
            filename: 'hoolva',
            autoload: true,
            });
        return db;
    }

    static getDefaultValues(db: any, cb: any) {
        db.find({type: 'settings'}, function (err: any, data: any) {
            if(err) {
                cb({
                    status: 'error',
                    error: err
                });
                return;
            }
            cb({
                status: 'success',
                data
            })
        });
    }

    static setDefaultValues(db: any, data: any, cb: any) {
        db.insert(data, (error: any, response: any) => {
            if (error) {
                cb({
                    status: "error",
                    error
                });
                return;
            }
            cb({
                status: "success",
                data: response
            })
        })
    }
    static updateDefaultSettings(db: any, data: any, cb: any) {
        db.update(
            {type: 'settings'},
            {$set: {displayName: data.displayName, audioDeviceId: data.audioDeviceId,
                    videoDeviceId: data.videoDeviceId, systemId: data.systemId, outPutDeviceId: data.outPutDeviceId}},
            (error: any, data: any) => {
                if(error) {
                    cb({
                        status: 'error',
                        error
                    });
                    return;
                }
                cb({
                    status: 'success',
                    data
                })
            }
        )
    }

    static setAuthInfo(db: any, data: any, cb: any) {
        db.insert(data, (error: any, response: any) => {
            if (error) {
                cb({
                    status: "error",
                    error
                });
                return;
            }
            cb({
                status: "success",
                data: response
            })
        })
    }

    static removeAuthInfo(db: any, cb: any) {
        db.remove({ type: 'auth' }, {}, function (err: any, numRemoved: number) {
            if(err) {
                cb({
                    status: 'error',
                    error: err
                });
                return;
            }
            cb({
                status: 'success',
                data: numRemoved
            })
        });

    }

    static getAuthInfo(db: any, cb: any) {
        db.find({type: 'auth'}, function (err: any, data: any) {
            if(err) {
                cb({
                    status: 'error',
                    error: err
                });
                return;
            }
            cb({
                status: 'success',
                data
            })
        });
    }

    static getMeetingList(db: any, cb: any) {
        db.find({type: 'meetingList'}, function (err: any, data: any) {
            if(err) {
                cb({
                    status: 'error',
                    error: err
                });
                return;
            }
            cb({
                status: 'success',
                data
            })
        });
    }

    static updateMeetingList(db: any, data: any, cb: any) {
        db.update(
            {type: 'meetingList'},
            {$set: {meetingList: data}},
            (error: any, data: any) => {
                if(error) {
                    cb({
                        status: 'error',
                        error
                    });
                    return;
                }
                cb({
                    status: 'success',
                    data
                })
            }
        )
    }
    static setMeetingList(db: any, data: any, cb: any) {
        db.insert(data, (error: any, response: any) => {
            if (error) {
                cb({
                    status: "error",
                    error
                });
                return;
            }
            cb({
                status: "success",
                data: response
            })
        })
    }
    static getDisplaySettings(db: any, cb: any) {
        db.find({type: 'display'}, function (err: any, data: any) {
            if(err) {
                cb({
                    status: 'error',
                    error: err
                });
                return;
            }
            cb({
                status: 'success',
                data
            })
        });
    }

    static setDisplaySettings(db: any, data: any, cb: any) {
        db.insert(data, (error: any, response: any) => {
            if (error) {
                cb({
                    status: "error",
                    error
                });
                return;
            }
            cb({
                status: "success",
                data: response
            })
        })
    }
    static updateDisplaySettings(db: any, data: any, cb: any) {
        db.update(
            {_id: data._id, type: 'display'},
            {$set: {theme: data.theme,layout:data.layout,language:data.language,dynamicBG:data.dynamicBG,sliderLength:data.sliderLength}},
            {},
            (error: any, data: any) => {
                if(error) {
                    cb({
                        status: 'error',
                        error
                    });
                    return;
                }
                cb({
                    status: 'success',
                    data
                })
            }
        )
    }
    static setFeedBack(value: boolean) {
        localStorage.setItem('feedback', value ? 'true' : 'false');
    }
    static getFeedBack() {
        let feedback = localStorage.getItem('feedback');
        if (feedback) {
            return feedback === 'true' ? true : false
        } else {
            return true;
        }
    }
}
export default LocalDb;
