
export let pingTimer: number = 0;
export let pingTestTimer: any = null;
export let pingPongTimer: any = null;
export let rx_ping_times : any = {};
export let pings_received: number = 0;
export let ping_times: any = {};
export let pings_sent: number = 0;
export let total_ping_time: number = 0;
export let total_jitter: number = 0;
export let last_ping_time: number = 0;

export function startPing() {
    return async function (dispatch: any, getState: any) {
        total_ping_time = 0;
        pings_received = 0;
        pings_sent = 0;
        total_jitter = 0;
        last_ping_time = 0;
        ping_times = {}
        pingTestTimer = setInterval(() => {
            dispatch(sendPing(pingTimer));
            ++pingTimer;
        }, 1000)
    }
}
export function stopPingTest() {
    return async function (dispatch: any, getState: any) {
        if(pingTestTimer) {
            clearInterval(pingTestTimer);
            pingTestTimer = null;
        }
        total_ping_time = 0;
        pings_received = 0;
        pings_sent = 0;
        total_jitter = 0;
        last_ping_time = 0;
        ping_times = {}
    }
}
export function sendPing(pingText: number){
    return async function (dispatch: any, getState: any) {
        const {publisherHandle} = getState().Meet;
        const pingMessage = {"application" : "ping", "text" : pingText}
        if(publisherHandle) {
            publisherHandle.send({message: pingMessage});
            ping_times[pings_sent] = performance.now();
            ++pings_sent;
        }
    }
}
export function receivedPing(msg: any){
    return async function (dispatch: any, getState: any) {
        console.log('reciveing ping messages');
        const t2 = performance.now();

        rx_ping_times[pings_received] = t2;
        ++pings_received;
        const t1 = ping_times[msg.text];
        if(t1) {
            if(0 !== last_ping_time) {
                total_jitter += Math.abs(last_ping_time-(t2-t1));
                const avg_jitter = total_jitter/(pings_received-1);
             //   console.log('Average jitter', avg_jitter.toFixed(0));
            }
            last_ping_time = t2-t1;
            total_ping_time += last_ping_time;
        }
        const avg = total_ping_time/pings_received;
        const packet_loss = (pings_sent-pings_received)/pings_sent*100;
        // console.log('ping sent', pings_sent);
        // console.log('ping received', pings_received);
        // console.log('Average ping time', avg.toFixed(0));
        // console.log('Packet loss', packet_loss.toFixed(0));

    }
}
