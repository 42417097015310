import React, {useState} from 'react';
import Lottie from 'react-lottie';

const HoolvaAnimation = (props: any ) => {
    const {type, width, height, loop, autoplay, hover, clicked, completed, classname} = props;
    const [reset, setReset] = useState(true);
    const playAnimation = () => {
        setReset(true);
    }
    const stopAnimation = () => {
        setReset(false);
    }

    const completeAnimation = () =>{
       // console.log('firing complete')
        completed();
    }
    const defaultOptions = {
        loop: loop,
        count: 5,
        autoplay: autoplay,
        isStopped: reset,
        animationData: type === 'heart' ? require('../../assets/animations/heart.json') :
            type === 'like' ? require('../../assets/animations/like.json') :
                type === 'clap' ? require('../../assets/animations/clap.json') :
                    type === 'smiley' ? require('../../assets/animations/smiley.json') : null ,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div className={classname} style={{width: 60}} key={"animation" + reset} onMouseEnter={() => hover ? playAnimation() : null}
             onMouseLeave={() => hover ? stopAnimation() : null}
             onClick={clicked} >
            {/*<Player*/}
            {/*    autoplay={autoplay}*/}
            {/*    loop={loop}*/}
            {/*    lottieRef={instance => {*/}
            {/*        setLottieInstance(instance) // the lottie instance is returned in the argument of this prop. set it to your local state*/}
            {/*    }}*/}
            {/*    hover={hover}*/}
            {/*    // onEvent={event => {*/}
            {/*    //     console.log("animation events", event);*/}
            {/*    //     if (event === "pause") {*/}
            {/*    //         completeAnimation();*/}
            {/*    //     } // check event type and do something*/}
            {/*    // }}*/}
            {/*    onStateChange={state => {*/}
            {/*        console.log('state change', state);*/}
            {/*    }}*/}
            {/*    src={type === 'heart' ? require('../../assets/animations/heart.json') :*/}
            {/*        type === 'like' ? require('../../assets/animations/like.json') :*/}
            {/*            type === 'clap' ? require('../../assets/animations/clap.json') :*/}
            {/*                type === 'smiley' ? require('../../assets/animations/smiley.json') : null }*/}
            {/*    style={{ height: height, width: width }}*/}
            {/*/>*/}
        <Lottie options={defaultOptions}
                height={height}
                width={width}
                eventListeners={
                    [
                            {
                                eventName: 'complete',
                                callback: () => {completeAnimation()},
                            },
                        ]
                }
        />
        </div>
    )
}
export default React.memo(HoolvaAnimation);
