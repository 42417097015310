import React, {useEffect} from "react";
import routes from '../routes.json';
import {history} from "../App";


const HoolvaAuth = (props: any) => {
    useEffect(() => {
        if(props.meetingId === '') {
            history.push(routes.HOME)
        }
    })
    return  <>{props.children}</>;
}
export default HoolvaAuth;
