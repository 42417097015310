import React, {useRef, useEffect, useState} from "react";
import cx from "classnames";
import classes from "./quickChat.module.scss";
import HoolvaAnimation from "../HoolvaAnimations/hoolvaAnimation";

const QuickChat = (props: any) => {
    const { quickChat, sendMsg, sendReaction, t } = props;
    const textInput = useRef(null);
    const [message, setMessage] = useState("");
    const ResetQuickChat = (e: any) => {
      e.stopPropagation();
    }
    useEffect(() => {
      // @ts-ignore
      textInput.current.focus();
      setMessage('');
    }, [quickChat]);
    const handleMessageChange = (value: string) => {
      setMessage(value);
    };
    const handleChatEnter = (event: any) => {
      if (event.key === "Enter" && !event.shiftKey) {
          sendMsg(message)
          setMessage('');
        // ChangeQuickChat(false);
      }
    };

  return (
    <div onClick={(e)=>ResetQuickChat(e)} className={classes.quickChat}>
      <div className={classes.smiley}>
          <HoolvaAnimation width={50} height={50}
                           type={'like'} loop={false}
                           autoplay={true} hover={false} clicked={() => sendReaction("like")} completed={() => console.log()} />
        {/*<FontAwesomeIcon icon={["fas", "thumbs-up"]}  />*/}
          <HoolvaAnimation width={50} height={50}
                           type={'smiley'} loop={false}
                           autoplay={true} hover={false} clicked={() => sendReaction("smiley")} completed={() => console.log()}/>
          <HoolvaAnimation width={50} height={50}
                           type={'heart'} loop={false}
                           autoplay={true} hover={false} clicked={() => sendReaction("heart")} completed={() => console.log()}/>
          <HoolvaAnimation width={50} height={50}
                           type={'clap'} loop={false}
                           autoplay={true} hover={false} clicked={() => sendReaction("clap")} completed={() => console.log()}/>
        {/*<FontAwesomeIcon icon={["fas", "smile"]} onClick={() => sendReaction("smiley")} />*/}
        {/*<FontAwesomeIcon icon={["fas", "heart"]} onClick={() => sendReaction("heart")} />*/}
        {/*<FontAwesomeIcon icon={["fas", "hand-paper"]} onClick={() => sendReaction("clap")} />*/}
      </div>
      <div style={{ padding: "4px" }} className={cx(classes.Text)}>
        <input
          type="text"
          placeholder={t('meetingPage.quickChat.typeMessage')}
          ref={textInput}
          onChange={(event) => handleMessageChange(event.target.value)}
          onKeyDown={(event) => handleChatEnter(event)}
          value={message}
        />
      </div>
    </div>
  );
};
export default React.memo(QuickChat);
