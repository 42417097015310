import * as Actions from '../actions';

const initialState: any = {
    options: {
        position: 'top-left',
        autoClose: 8000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: false,
        pauseOnHover: true,
    },
    state: false,
    type: 'success',
    notifyMessage: ''
}

const NotifyMessageReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case Actions.SHOW_MESSAGE: {
            return {
                ...state,
                state: true,
                type: action.messageType,
                options: {
                    ...initialState.options,
                    ...action.options
                }
            }
        }
        case Actions.HIDE_MESSAGE: {
            return {
                ...state,
                state: false
            }
        }
        case Actions.SET_NOTIFICATION_MESSAGE: {
            return {
                ...state,
                notifyMessage: action.message
            }
        }
        default: {
            return state;
        }
    }
}
export default NotifyMessageReducer;
