import * as Service from "../../Services/apiService";

export const SET_UPCOMING_MEETING: string = 'SET_UPCOMING_MEETING';
export const SET_ACTIVE_MEETING: string = 'SET_ACTIVE_MEETING';
export const SET_OPEN_MEETING: string = 'SET_OPEN_MEETING';
export const SET_ACTIVE_SCHEDULE_MEETING: string = 'SET_ACTIVE_SCHEDULE_MEETING';
export const SET_MEETING_LIST_LENGTH: string = 'SET_MEETING_LIST_LENGTH';
export const SET_PERSONAL_MEETING: string = 'SET_PERSONAL_MEETING';
export const SET_MEETING_INVITE: string = 'SET_MEETING_INVITE';

export function setUpcomingMeetings(meetings: any) {
    return {
        type: SET_UPCOMING_MEETING,
        meetings
    }
}
export function setActiveMeeting(meeting: any) {
    console.log("Active Log", meeting);
    return {
        type: SET_ACTIVE_MEETING,
        meeting
    }
}
export function setOpenMeeting(openMeetings: any) {
    return {
        type: SET_OPEN_MEETING,
        openMeetings
    }
}
export function setPersonalMeeting(personalMeeting: any) {
    return {
        type: SET_PERSONAL_MEETING,
        personalMeeting
    }
}
export function setActiveScheduleMeeting(meeting: any) {
    return {
        type: SET_ACTIVE_SCHEDULE_MEETING,
        meeting
    }
}
export function setMeetingListLength(meetingListLength: any) {
    return {
        type: SET_MEETING_LIST_LENGTH,
        meetingListLength
    }
}
export function setMeetingInvite(MeetingInvite: any) {
    return {
        type: SET_MEETING_INVITE,
        MeetingInvite
    }
}
export function getUpcomingMeetings(uuid: string, flag: boolean) {
    return async function (dispatch: any, getState: any) {
        const response: any = await Service.getUpComingMeetings(getState().Hoolva.API_ROOT);
        console.log('response', response);
        if (response.status === 200) {
            const meetingList = response.data.schedules.filter((meeting: any) => {
                return meeting.status === 'enabled';
            }).sort(function (x: any, y: any) {
                return y.start_date_time + x.start_date_time;
            })
            dispatch(setMeetingListLength(meetingList.length));
            if (meetingList.length > 0 && flag) {
                if (uuid === '') {
                    console.log('active meeting', meetingList[0])
                    dispatch(setActiveScheduleMeeting(meetingList[0]));
                    dispatch(getMeetings(meetingList[0].meeting_id, meetingList[0].uuid));
                } else {
                    let filt = meetingList.filter((meeting: any) => { return meeting.uuid === uuid });
                    let NewMeeting = meetingList.filter((meeting: any) => { return meeting.meeting_uuid === uuid });
                    console.log('UPDATE_ACTIVE_MEETING', meetingList, uuid, NewMeeting)
                    if (filt.length > 0) {
                        dispatch(setActiveScheduleMeeting(filt[0]));
                        dispatch(getMeetings(filt[0].meeting_id, filt[0].uuid));
                    } else if (NewMeeting.length > 0) {
                        dispatch(setActiveScheduleMeeting(NewMeeting[0]));
                        dispatch(getMeetings(NewMeeting[0].meeting_id, NewMeeting[0].uuid));
                    } else {
                        dispatch(setActiveScheduleMeeting(meetingList[0]));
                        dispatch(getMeetings(meetingList[0].meeting_id, meetingList[0].uuid));
                    }
                    // dispatch(setActiveScheduleMeeting(meetingList[0]));
                    // dispatch(getMeetings(meetingList[meetingList.length-1].meeting_id));
                }
            }
            dispatch(setUpcomingMeetings(meetingList))
            return { status: 'success' }
        } else {
            return { status: 'failed' }
        }
    }
}
export function getPersonalMeetings() {
    return async function (dispatch: any, getState: any) {
        const response:any = await Service.getPersonalMeeting(getState().Hoolva.API_ROOT, { personal: true });
        console.log('response', response);
        if (response.status === 200) {
            if (response.data.length > 0) {
                console.log('active meeting', response.data[0])
                dispatch(setPersonalMeeting(response.data[0]));
            }
            return { status: 'success' }
        } else {
            return { status: 'failed' }
        }
    }
}
export function EditPersonalMeeting(meetingData: any, uuid: string) {
    return async function (_dispatch: any, getState: any) {
        const meetingResponse:any = await Service.updateMeeting(getState().Hoolva.API_ROOT, uuid, meetingData);
        console.log('edit meeting res', meetingResponse);
        if (meetingResponse.status === 200 || meetingResponse.status === 204) {
            // dispatch(getUpcomingMeetings(meetingData.uuid));
            return { status: 'success', message: "Personal meeting edited successfully" }
        } else {
            return { status: 'failed', message: "Personal Meeting editing failed" }
        }
    }
}
export function getOpenMeetings(uuid: any, flag: boolean) {
    return async function (dispatch: any, getState: any) {
        const response: any = await Service.getMeeting(getState().Hoolva.API_ROOT, { recurrence: true, recurrence_type: 'open', _sort_oder: 'desc', _sort_column: 'a_ctime', personal: false, _limit: 99 });
        console.log('response', response);
        if (response.status === 200) {
            if (response.data.meetings.length > 0) {
                console.log('Open meeting', response.data.meetings)
                dispatch(setOpenMeeting(response.data.meetings));
                if (uuid !== '' && flag) {
                    let filt = response.data.meetings.filter((meeting: any) => { return meeting.uuid === uuid });
                    if (filt.length > 0) {
                        dispatch(setActiveMeeting(filt[0]));
                        const meeting = filt[0];
                        let data;
                        if (meeting?.meeting_id) {
                            data = {
                                "meeting_uuid": meeting.meeting_uuid,
                                "schedule_uuid": meeting.uuid
                            }
                        } else {
                            data = {
                                "meeting_uuid": meeting.uuid
                            }
                        }
                        dispatch(getMeetingInvite(data))
                    }
                } else if (flag) {
                    dispatch(setActiveMeeting(response.data.meetings[0]));
                    const meeting = response.data.meetings[0];
                    let data;
                    if (meeting?.meeting_id) {
                        data = {
                            "meeting_uuid": meeting.meeting_uuid,
                            "schedule_uuid": meeting.uuid
                        }
                    } else {
                        data = {
                            "meeting_uuid": meeting.uuid
                        }
                    }
                    dispatch(getMeetingInvite(data))
                }
            } else {
                dispatch(setOpenMeeting([]));
            }
            return { status: 'success' }
        } else {
            return { status: 'failed' }
        }
    }
}
export function getMeetings(id: string, schedule_id: string) {
    return async function (dispatch: any, getState: any) {
        const response:any = await Service.getMeeting(getState().Hoolva.API_ROOT, { 'id': id });

        if (response.status === 200) {
            if (response.data.length > 0) {
                if (id !== '') {
                    dispatch(setActiveMeeting(response.data[0]));
                    const meeting = response.data[0];
                    let data;
                    console.log('IINvite', meeting, schedule_id);
                    if (meeting?.meeting_id) {
                        data = {
                            "meeting_uuid": meeting.meeting_uuid,
                            "schedule_uuid": schedule_id
                        }
                    } else {
                        data = {
                            "meeting_uuid": meeting.uuid,
                            "schedule_uuid": schedule_id
                        }
                    }
                    dispatch(getMeetingInvite(data))
                }
            }
            return { status: 'success' }
        } else {
            return { status: 'failed' }
        }
    }
}

export function scheduleMeeting(meetingData: any) {
    return async function (dispatch: any, getState: any) {
        const meetingResponse: any = await Service.scheduleMeeting(getState().Hoolva.API_ROOT, meetingData);
        if (meetingResponse.status === 200) {
            console.log("scheduleMeeting", meetingData, meetingResponse);
            if (meetingData.recurrence_type === 'open') {
                dispatch(getOpenMeetings(meetingResponse.data.uuid,true));
                dispatch(getUpcomingMeetings(meetingResponse.data.uuid, false));
            } else {
                dispatch(getUpcomingMeetings(meetingResponse.data.uuid, true));
                dispatch(getOpenMeetings('',false));
            }
            if (meetingData.recurrence_type !== 'open') {
                const Invite = {
                    "guests": [],
                    "meeting_uuid": meetingResponse.data.uuid
                }
                Service.sendCalendarMeetingInvite(getState().Hoolva.API_ROOT, Invite);
            }
            return { status: 'success', message: "Meeting scheduled successfully" }
        } else {
            return { status: 'failed', message: "Meeting not scheduled" }
        }
    }
}

export function editScheduleMeeting(meetingData: any, uuid: string, _loggedInData: any) {
    return async function (dispatch: any, getState: any) {
        const meetingResponse: any = await Service.updateMeeting(getState().Hoolva.API_ROOT, uuid, meetingData);
        console.log('edit meeting res', meetingResponse);
        if (meetingResponse.status === 200 || meetingResponse.status === 204) {
            console.log("scheduleMeeting", meetingData);
            if (meetingData.recurrence_type === 'open') {
                dispatch(getOpenMeetings(uuid, true));
                dispatch(getUpcomingMeetings('', false));
            } else {
                dispatch(getUpcomingMeetings(uuid, true));
                dispatch(getOpenMeetings('', false));
            }
            if (meetingData.recurrence_type !== 'open') {
                const Invite = {
                    "guests": [],
                    "meeting_uuid": uuid
                }
                Service.sendCalendarMeetingInvite(getState().Hoolva.API_ROOT, Invite);
            }
            return { status: 'success', message: "Meeting edited successfully" }
        } else {
            return { status: 'failed', message: "Meeting editing failed" }
        }
    }
}
export function sendCalendarMeetingInvite(Invite: any) {
    return async function (_dispatch: any, getState: any) {
        const InviteResponse:any = await Service.sendCalendarMeetingInvite(getState().Hoolva.API_ROOT, Invite);
        console.log('sendCalendarMeetingInvite', InviteResponse);
        if (InviteResponse.status === 200 || InviteResponse.status === 204) {
            return { status: 'success', message: "Meeting invite sent successfully" }
        } else {
            return { status: 'failed', message: "Invite failed" }
        }
    }
}
export function getMeetingInvite(data: any) {
    return async function (dispatch: any, getState: any) {
        const response:any = await Service.getMeetingInvite(getState().Hoolva.API_ROOT, data);
        console.log('response', response);
        if (response.status === 200) {
            dispatch(setMeetingInvite(response.data));
            return response.data;
        } else {
            return { status: 'failed' }
        }
    }
}

export function updateScheduleMeeting(meetingData: any, _loggedInData: any) {
    return async function (dispatch: any, getState: any) {
        const meetingResponse:any = await Service.updateScheduleMeeting(getState().Hoolva.API_ROOT, meetingData);
        console.log('Delete meeting res', meetingResponse);
        if (meetingData.recurrence_type === 'open' && meetingData.recurrence) {
            dispatch(getOpenMeetings('',true));
            dispatch(getUpcomingMeetings('', false));
        } else {
            dispatch(getUpcomingMeetings('', true));
            dispatch(getOpenMeetings('',false));
        }
        if (meetingResponse.status === 200 || meetingResponse.status === 204) {
            return { status: 'success', message: meetingData.status ? "Meeting deleted successfully" : "Meeting edited successfully" }
        } else {
            return { status: 'failed', message: meetingData.status ? "Invalid meeting or Meeting already deleted" : "Invalid meeting or Meeting already deleted" }
        }
    }
}
export function deleteMeeting(activeMeeting: any) {
    return async function (dispatch: any, getState: any) {
        const meetingResponse:any = await Service.deleteMeeting(getState().Hoolva.API_ROOT, activeMeeting.uuid);
        console.log('Delete meeting res', meetingResponse);
        if (activeMeeting.recurrence_type === 'open' && activeMeeting.recurrence) {
            console.log('Delete meeting OPEN', meetingResponse, activeMeeting);
            dispatch(getOpenMeetings('',true));
            dispatch(getUpcomingMeetings('', false));
        } else {
            dispatch(getUpcomingMeetings('', true));
            dispatch(getOpenMeetings('',false));
            console.log('Delete meeting CLOSE', meetingResponse, activeMeeting);
        }
        if (meetingResponse.status === 200 || meetingResponse.status === 204) {
            return { status: 'success', message: "Meeting deleted successfully" }
        } else {
            return { status: 'failed', message: "Invalid meeting or Meeting already deleted" }
        }
    }
}

