import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useSelector} from "react-redux";

function HoolvaMessage(){
    const {options} = useSelector((state:any)=>state.Notify);


    return <ToastContainer
        position={options.position}
        autoClose={options.autoClose}
        hideProgressBar={options.hideProgressBar}
        newestOnTop={options.newestOnTop}
        closeOnClick={options.closeOnClick}
        rtl={options.rtl}
        closeButton= {false}
        transition={options.transition}
        pauseOnFocusLoss={options.pauseOnFocusLoss}
        draggable={options.draggable}
        pauseOnHover={options.pauseOnHover}
        style={{width: "max-content", minWidth:'400px', textAlign:'center',marginBottom: options.position === 'bottom-center' ?"40px":"0px", marginTop: options.position === 'top-center' ?"40px":"0px"}}
       />
}
export default React.memo(HoolvaMessage);
