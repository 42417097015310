import React, {
  useRef,
  useState,
  useEffect,
} from "react";
import cx from "classnames";
import classes from "./chat.module.scss";
import { Picker } from 'emoji-mart';
import {debounce} from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../../store/actions";
import * as HoolvaActions from '../../../../store/actions/hoolva.action';
import {makeStyles} from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Linkify from 'react-linkify';
import Message from "./message/message";
import moment from "moment";
import {setNotificationMessage} from "../../../../../src/store/actions/notifyMessage.action";
import {jsPDF} from 'jspdf';
import {getCurrentProtocolUsed} from "../../../../store/actions";
import XQMessageApi from "../../../../Services/xqService/xqService";
import DetectRTC from "detectrtc";
// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
  RightMsg: {
      color: theme.textColor1 + "!important"
  },
  LeftMsg: {
      color:theme.textColor1 + "!important"
  },
  Msg: {
      color:theme.textColor3
  },
  selectBar: {
    backgroundColor: theme.videoRoomTernaryColor,
    color:theme.textColor3 + "!important",
    '&:hover': {
      color:theme.iconHoverColor
    }
  },
  fa: {
    color: theme.textColor3,
    '&:hover': {
      color: theme.iconHoverColor
    }
  },
  settings: {
    color: theme.textColor3,
    '&:hover': {
      color: theme.iconHoverColor
    }
  },
  selectDD: {
    color:theme.textColor3,
    '&:hover': {
      color: theme.iconHoverColor
    },
    option:{

    }
  },
  TextArea:{
    backgroundColor: theme.videoRoomTernaryColor,
    
  },
  Text:{
    backgroundColor: theme.chatBGColor,
    color: theme.textColor1,
  }
}))
const Chat = (props: any) => {
  const theme = useStyles(props);
  const dispatch = useDispatch();
  const { participantList, t, room_uuid, meetingInfo} = props;
  const inputFileRef = useRef( null );
  const [dragFlag, setDragFlag] = useState(false);
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedParticipant, setSelectedParticipant] = useState("all");
  const [selectedParticipantName, setSelectedParticipantName] = useState("");
  const [chatExport, setChatExport] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const { publisherHandle, messageList } = useSelector(
    (state: any) => state.Meet
  );
  const { xqMessagesInfo } = useSelector((state: any) => state.Hoolva);
  const el = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    // @ts-ignore
    if (el.current) {
      // @ts-ignore
      el.current.scrollIntoView({ block: "end" });
    }
  }, [messageList.length]);

  const exportToPdf = () => {
    if(messageList.length > 0) {
      setChatExport(true);
      const doc = new jsPDF();
      doc.setFontSize(9);
      let y = 10;
      messageList.map((message: any) => {
        console.log('messgae', message);
        doc.setFont('times', "italic");
        if (message.sender) {
          let txt = message.event === "send_message" ? "You (" + message.participant_name + ")" : "You (Everyone)";
          doc.text(txt, 10, y);
        } else {
          let txt = message.event === "receive_message" ? message.participant_name + " (Private)" : message.participant_name + " (Everyone)"
          doc.text(txt, 10, y);
        }
        y = y + 5;
        const strArr = doc.splitTextToSize(message.message, 180);
        doc.setFont('times', "normal")
        if(message.type === 'reaction') {
          doc.text("reaction message (" +message.message+ ")", 10, y);
        } else {
          doc.text(strArr, 10, y);
        }

        y = y + ((doc.getTextDimensions(message.message).h * strArr.length) + 2);
        doc.setFont('times', "italic")
        doc.text(moment(message.timestamp).format("DD: MM : YYYY, h:mm:ss a"), 10, y);
        y = y + 8;
        if (y > 290) {
          doc.addPage();
          y = 10;
        }
      })
      doc.save("chat-" + new Date().getTime() + '.pdf');
      setChatExport(false);
    }
  }

  const toggleEmojiPicker = () => {
    setShowPicker(!showPicker);
  }
  const selectEmoji = (emoji: any) => {
    const emojiString = emoji.native;
    setMessage(message + " " + emojiString + " ");
    if(inputRef) {
      // @ts-ignore
      inputRef.current.focus();
    }

  }
  const sendMessage = async(message: string, type: string) => {
    if(message.trim() !== '') {
      let formattedMessage;
      let encryptedMessage;
      setShowPicker(false);
      if(message === '/getAudioProtocol' || message === '/getVideoProtocol' || message === '/getScreenProtocol') {
          dispatch(getCurrentProtocolUsed(message))
      } else {
        if(meetingInfo.e2ee) {
          await XQMessageApi.encryptText(message,xqMessagesInfo.hostSessionId+"@alias.local",10,xqMessagesInfo.keyLocator)
          .then(response=>{
            encryptedMessage = response.encryptedText;
          });
        }
        if (selectedParticipant === "all") {
          formattedMessage = {
            event: "send_message",
            type,
            message:meetingInfo.e2ee?encryptedMessage:message,
          };
        } else {
          formattedMessage = {
            event: "send_message",
            type,
            message:meetingInfo.e2ee?encryptedMessage:message,
            participant_id: +selectedParticipant,
            participant_name: selectedParticipantName,
          };
        }

        if (publisherHandle) {
          publisherHandle.send({ message: formattedMessage });
        }
      }


      setSending(false);
      setMessage("");
    }

  };

  const handleMessageChange = (value: string) => {
    setMessage(value);
  };
  const handleChatEnter = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      setSending(true);
      sendMessage(message, 'text');
    }
  };

  const handleParticipantChange = (id: string) => {
    const splitedValue = id.split(":");
    setSelectedParticipant(splitedValue[0]);
    setSelectedParticipantName(splitedValue[1]);
  };

  const changeHandler = (event:any) => {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      uploadFile(event.target.files[i]);
    }
    event.target.value = null;
  };
  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  // const uploadFile = async (file:any) => {
  //   console.log("selectedFile", file, room_uuid);
  //   if(file){
  //     if(file.size > 100000000){
  //       dispatch(setNotificationMessage('This file exceeds maximum upload size of 100 MB '));
  //     }else{
  //       let timestamp = new Date().getTime();
  //       let size = formatBytes(file.size);
  //       let chat;
  //       if (selectedParticipant === "all") {
  //         chat = {
  //           sender:true,
  //           event:"",
  //           type:'file',
  //           loading:true,
  //           size:size,
  //           flag:true,
  //           format: file.type,
  //           timestamp:timestamp,
  //           message: file.name
  //         };
  //         dispatch(Actions.PushChatMessage(chat));
  //       } else {
  //         chat = {
  //           sender:true,
  //           event:"send_message",
  //           type:'file',
  //           loading:true,
  //           flag:true,
  //           size:size,
  //           timestamp: timestamp,
  //           message: file.name,
  //           format: file.type,
  //           participant_id: +selectedParticipant,
  //           participant_name: selectedParticipantName,
  //         };
  //         dispatch(Actions.PushChatMessage(chat));
  //       }
  //       const formData = new FormData();
  //       formData.append('document', file);
  //       let url = await dispatch(Actions.submitChatFile(formData, room_uuid, chat));
  //       if(url){
  //         let ChatJ = {name:file.name,format:file.type, size:size, url:url}
  //         sendMessage(JSON.stringify(ChatJ), 'file');
  //       }
  //     }
  //   }else{
  //     console.log("selectedFile invalid format");
  //     dispatch(setNotificationMessage('Invalid File'));
  //   }
  // }

  const uploadFile = async (file: any) => {
    console.log("selectedFile", file, room_uuid);
    if (file) {
      if (file.size > 100000000) {
        dispatch(setNotificationMessage('This file exceeds maximum upload size of 100 MB '));
      } else {
        let timestamp = new Date().getTime();
        let size = formatBytes(file.size);
        let chat;
        if (selectedParticipant === "all") {
          chat = {
          sender: true,
          event: "",
          type: 'file',
          loading: true,
          size: size,
          flag: true,
          format: file.type,
          timestamp: timestamp,
          message: file.name
          };
          dispatch(Actions.PushChatMessage(chat));
        } else {
          chat = {
          sender: true,
          event: "send_message",
          type: 'file',
          loading: true,
          flag: true,
          size: size,
          timestamp: timestamp,
          message: file.name,
          format: file.type,
          participant_id: +selectedParticipant,
          participant_name: selectedParticipantName,
          };
          dispatch(Actions.PushChatMessage(chat));
        }
        
        let sourceFile:File;
        if(meetingInfo.e2ee) {
          let encryptedFile:any;
          await XQMessageApi.encryptFile(file, xqMessagesInfo.hostSessionId+"@alias.local", 10, false)
          .then(response => {
            encryptedFile = response.payload;
          });
          sourceFile = new File([encryptedFile], file.name, {
            type: "text/plain",
          });
        }
        else {
          sourceFile = file;
        }
                
        const formData = new FormData();
        formData.append('document', sourceFile);
        let url = await dispatch(Actions.submitChatFile(formData, room_uuid, chat));
        if (url) {
          //let ChatJ = { name: file.name, format: file.type, size: size, url: url }
          let ChatJ = { name: sourceFile.name, format: sourceFile.type, size: size, url: url }
          sendMessage(JSON.stringify(ChatJ), 'file');
        }
      }
    } else {
      //console.log("selectedFile invalid format");
      dispatch(setNotificationMessage('Invalid File'));
      }
    }

  const onBtnClick = () => {
    // @ts-ignore
    inputFileRef.current.click();
  }
  const handleDragLeave = (e:any) => {
    Drag(false);
    e.preventDefault();
    e.stopPropagation();
  };
  const handleHLeave = (e:any) => {
    console.log("handleHLeave");
    Drag(false);
  };

  const Drag = debounce((flag:boolean) => {
    setDragFlag(flag);
  },50);

  const handleDragOver = (e:any) => {
    Drag(true);
    e.preventDefault();
    e.stopPropagation();
  }
  const handleDrop = (e:any) => {
    Drag(false);
    let files = [...e.dataTransfer.files];
    console.log("selectedFile", files, room_uuid);
    files.map((file: any) => {
      console.log("selectedFile", file, room_uuid);
      uploadFile(file);
    });
    e.dataTransfer.clearData();
    e.preventDefault();
    e.stopPropagation();
  };

  return (
      <div className={cx(classes.Chat)} onMouseLeave={e => handleHLeave(e)}>
        <div className={cx(classes.Msg, theme.Msg)} onDragOver={e => handleDragOver(e)} onDrop={e => handleDrop(e)} >
          {dragFlag ? <div onDragOver={e => handleDragOver(e)} onDrop={e => handleDrop(e)} onDragLeave={e => handleDragLeave(e)} className={cx(classes.DropHere)}>
            <div >

            </div>
          </div> : null}
          {dragFlag && meetingInfo && meetingInfo.e2ee ?  <div className={cx(classes.DropHere2)}>
            <div >
              Drop Here
              <span>
            <FontAwesomeIcon  icon={['fal', 'file-upload']} className={cx(classes.avatarSpin)}/>
            </span>
            </div>
          </div> : null}
          <div className={cx(classes.bottom)}>
            {messageList.map((message: any, index: number) => {
              return (
                  !(message.type === 'file' && message.sender) || message?.flag ?
                      <Message meetingInfo={meetingInfo} message={message} key={index}/>
                      :null
              );
            })}

            <div id="el" ref={el} />

          </div>
          {showPicker ? <Picker
              style={{ position: 'absolute', bottom: '10px', right: '5px', width: 290}}
              onSelect={(emoji: any) => selectEmoji(emoji)}
              showPreview={false}  set="twitter" title={"Hoolva"}  /> : null}

        </div>

        <div>
          <div className={cx(classes.selectBar, theme.selectBar)}>
            <select
                className={cx(theme.selectDD)}
                style={{ width: 80 }}
                onChange={(event) => handleParticipantChange(event.target.value)}
                value={selectedParticipant + ":" + selectedParticipantName}
            >
              <option key={"all_participant"} value={"all"}>
                Everyone
              </option>
              {participantList.map((participant: any) => {
                if (!participant.isPublisher) {
                  return (
                      <option
                          key={participant.rfid}
                          value={participant.rfid + ":" + participant.rfdisplay}
                      >
                        {participant.rfdisplay}
                      </option>
                  );
                }
              })}
            </select>
            <FontAwesomeIcon className={cx(theme.fa)} icon={["fas", "smile"]} onClick={toggleEmojiPicker}/>
            <input type="file" name="file" hidden={true} ref={inputFileRef} multiple onChange={changeHandler} />
            {/* {!(meetingInfo && meetingInfo.e2ee) ?  */}
              <FontAwesomeIcon className={cx(theme.fa)} onClick={onBtnClick} icon={["fas", "paperclip"]} />
            {/* :null} */}
            <span className={cx(classes.chatSettings)}>
            <span className={cx(messageList.length === 0 ? classes.disabled:null, classes.save, theme.settings)} onClick={() => chatExport && messageList.length > 0 ? null : exportToPdf()}>{chatExport ? t('meetingPage.rightPanel.chat.saving') : t('meetingPage.rightPanel.chat.saveChat') }</span>
              {/*<FontAwesomeIcon className={cx(theme.fa)} icon={["fas", "ellipsis-h"]} />*/}
          </span>
          </div>
          <div style={{ padding: "2px" }} className={cx(classes.Text,theme.TextArea)}>
            <input 
                type="text"
                ref={inputRef}
                className={cx(theme.Text, classes.textBx, sending ? classes.sending:null)}
                placeholder={t('meetingPage.rightPanel.chat.typeMessage')}
                onChange={(event) => handleMessageChange(event.target.value)}
                onKeyDown={(event) => handleChatEnter(event)}
                value={message}
                //@ts-ignore
                spellcheck="false"


            />
          </div>
        </div>
      </div>
  );
};
export default Chat;
