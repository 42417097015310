import React, {useEffect, useState} from 'react';
import * as Config from "../Config/config";
import * as Actions from "../store/actions";
import {useDispatch, useSelector} from "react-redux";
import HoolvaSplashScreen from "../components/HoolvaSplashScreen/HoolvaSplashScreen";
import {showMessage} from "../store/actions";
import { updateConfiguration, configuration } from "../Config/config";
import DetectRTC from "detectrtc";
export let faceBookToken: any = null;

const Auth = (props: any) => {
    const [waitAuthCheck, setWaitAuthCheck] = useState(true);
    const dispatch = useDispatch();
    const {authProviders} = useSelector((state:any)=>state.Auth);
    useEffect(()=> {
        if(waitAuthCheck) {
            dispatch(Actions.checkAudioVideoPermission());
            getLoginClientData();
        }
    }, [waitAuthCheck])

    // useEffect(() => {
    //     const { signIn } = useGoogleLogin( {
    //         clientId: authProviders.find((provider: any) => {return provider.provider === 'google'}).parameters.app_id,
    //         onSuccess (data) {
    //             console.log('success data', data);
    //         },
    //         onAutoLoadFinished(data) {
    //             console.log('on auto load finish', data);
    //             if(!data) {
    //                 setWaitAuthCheck(false)
    //             }
    //         },
    //         onFailure(error) {
    //             console.log('error in google login', error);
    //         },
    //         cookiePolicy:'single_host_origin',
    //         isSignedIn: true
    //     });
    //
    // },[authProviders])

   //  const { signIn } = useGoogleLogin( {
   //      clientId: googleClientId,
   //      onSuccess (data) {
   //          console.log('success data', data);
   //      },
   //      cookiePolicy:'single_host_origin',
   //      isSignedIn: true
   //  });
   // console.log('isSignedIn', signIn);

    const handleConnectionChange = (): void => {
        const condition = navigator.onLine ? 'online' : 'offline';
        console.log('checkkkkkk');
        if (condition === 'online' || 'offline') {
          const webPing = setInterval(() => {
            fetch('https://www.google.com', {
              mode: 'no-cors',
            })
              .then(() => {
               // setIsDisconnected(false);
               getLoginClientData();
                return clearInterval(webPing);
              })
              .catch(() => {
             //   setIsDisconnected(true);
              });
          }, 2000);
          return;
        }
        // eslint-disable-next-line consistent-return
        //return setIsDisconnected(true);
      };
    const initFaceBookSdk = (appId: string) => {
        return new Promise(resolve => {
            if(appId) {
                //@ts-ignore
                const fbAsyncInit = function() {
                    //@ts-ignore
                    window.FB.init({
                        appId      : appId,//'327871198508329',
                        cookie     : true,
                        xfbml      : true,
                        version    : 'v9.0'
                    });
                    //@ts-ignore

                    window.FB.AppEvents.logPageView();
                    //@ts-ignore
                    window.FB.getLoginStatus(function(response: any) {   // Called after the JS SDK has been initialized.
                        console.log('getLoginStatus',response);        // Returns the login status.
                        if(response.authResponse) {
                            faceBookToken = response.authResponse.accessToken
                        }
                    });

                };
                fbAsyncInit();

                (function(d, s, id){
                    var js, fjs = d.getElementsByTagName(s)[0];
                    if (d.getElementById(id)) {return;}
                    js = d.createElement(s); js.id = id;
                    //@ts-ignore
                    js.src = "https://connect.facebook.net/en_US/sdk.js";
                    //@ts-ignore
                    fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
            }

         });
    }

    const getLoginClientData = async () => {
        const data = {
            "hoolva-app": Config.API_KEY
        };
        const  response: any = await dispatch(Actions.getLoginClientData(data));
        console.log('getLoginClientData', response);
        if (response.status === 200 || response.status === 201 ||response.status === 202 || response.status === 203 || response.status === 204) {
            // const dbStore = LocalDb.loadLocalDb();
            const Tenant: any = await dispatch(Actions.getTenantInfo());
            if (Tenant.status === 200 || Tenant.status === 201 || Tenant.status === 202 || Tenant.status === 203 || Tenant.status === 204) {
                console.log("getTenantInfo",Tenant.data.brandname);
                updateConfiguration('brandName',Tenant.data.brandname);
                updateConfiguration('brandingUrl',Tenant.data.brandUrl);
                updateConfiguration('favIcon',Tenant.data.logos?.favicon);
                updateConfiguration('logo',Tenant.data.logos.logoDark);
                updateConfiguration('sname',Tenant.data.sname);
                console.log("configuration",configuration);
                document.title = Tenant.data.brandname + ' - online meeting for you and your team.';
                var link:any = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = Tenant?.data?.logos?.favicon;
                if(Tenant?.data?.theme?.dark){
                    dispatch(Actions.setThemeDarkVariables(Tenant.data.theme.dark));
                }else{
                    dispatch(showMessage('warn', 'Invalid Theme file', {}))
                }
                if(Tenant?.data?.theme?.lite){
                    dispatch(Actions.setThemeLiteVariables(Tenant.data.theme.lite));
                }
                dispatch(Actions.setTenantInfo(Tenant.data));
                await dispatch(Actions.setAuthProviders(response.data.auth_provider_configs));
                // setGoogleClientId("315238570647-72et337eh0dhk2vo6k080rmhfh1g0vnh.apps.googleusercontent.com");
                // const authProviderResponse = await dispatch(Actions.getSSOProvider());
                await dispatch(Actions.setClientAuthData(response.data));
                await dispatch(Actions.setGlobalAuthData({...response.data.ice}))
                if(response.data.auth_provider_configs && response.data.auth_provider_configs.length > 0) {
                    initFaceBookSdk(response.data.auth_provider_configs.find((provider: any) => {return provider.provider === 'facebook'}).parameters.app_id);
                }
                DetectRTC.load(async function () {
                    dispatch(Actions.setDetectRtcState(DetectRTC));
                    if ((DetectRTC.browser.isChrome && DetectRTC.browser.version >= Config.configuration.supportVersion) || (DetectRTC.browser.isFirefox && DetectRTC.browser.version >=85) || (DetectRTC.browser.isSafari && DetectRTC.browser.version >= 13) ) {
                        console.log('checking browser ok');
                        dispatch(Actions.setBrowserVerification(true));
                    } else {
                        console.log('checking browser not ok');
                        dispatch(Actions.setBrowserVerification(false))
                    }
                    setWaitAuthCheck(false);
                });
            }else {
                dispatch(showMessage('error', '4402: This Tenant is not authorized', {}))
            }
            // });
        }else if (response.status === 'failed' && response.reason === 'network error') {
            console.log('test');
            handleConnectionChange();
        } else {
            dispatch(showMessage('error', '4401: This client is not authorized', {}))
        }
    }
    return waitAuthCheck ? <HoolvaSplashScreen /> : <>{props.children}</>;
}
export default Auth;
