import React from 'react';
import classes from './endMeetingModal.module.scss';
import {makeStyles} from "@material-ui/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import RegularButtons from "../HoolvaButtons/RegularButtons/regularButtons";
import LocalDb from "../../Services/dbService";
// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    bgColor:{
        backgroundColor: theme.background,
        color: theme.textColor1
    },
    inputText: {
        backgroundColor: theme.textboxBGColor,
        border: '1px solid ' + theme.textboxBorderColor,
        color: theme.textboxTextColor,
        "&:hover, &:focus, &:active": {
            border: "1px solid "+ theme.textboxActiveColor,
        },
        '&::placeholder': {
            color:theme.textboxPlaceholderColor
        }
    },
    border:{
        border: "1px solid " + theme.modalBorderColor,
    },
    text: {
        color:theme.textColor1
    },
    btnPrimary: {
        background: theme.button1BGColor,
        border: "1px solid "+ theme.button1BorderColor,
        color: theme.button1TextColor,
        "&:hover, &:focus": {
            background: theme.button1HoverBGColor
        }
    },
    btnSecondary: {
        background: theme.button2BGColor,
        border: "1px solid "+ theme.button2BorderColor,
        color: theme.button2TextColor,
        "&:hover, &:focus": {
            color: theme.button2HoverTextColor
        }
    },
    btnTernary: {
        background: theme.button3BGColor,
        border: "1px solid "+ theme.button3BGColor,
        color: theme.button3TextColor,
        "&:hover, &:focus": {
            border: "1px solid "+ theme.button3HoverBorderColor,
        }
    }
}))
const EndMeetingModal = (props: any) => {
    const {feedback, setFeedBack, t} = props;
    const toggleChecked = (checked: any) => {
        LocalDb.setFeedBack(checked);
        setFeedBack(checked);
    }
    const { modalOpen, setEndModal, endForAll, endMeeting, meetingInfo } = props;
    const theme = useStyles(props);
    return (
        <div>
            {modalOpen ?
                <div>
                    <div className={cx(classes.modalBG)}>
                    </div>
                    <div className={cx(classes.modal)}>
                        <div className={cx(classes.content,theme.bgColor, theme.border)}>
                            <div className={cx(classes.header,theme.bgColor)}>
                                <div className={cx(classes.padding8)}>
                                    <FontAwesomeIcon onClick={() => setEndModal(false)} className={cx(classes.close)} icon={['fas', 'times']} />
                                    {meetingInfo.host ? t('meetingPage.leaveMeetingModal.title'): t('meetingPage.leaveMeetingModal.participantTitle')}
                                </div>
                            </div>
                            <div className={cx(classes.body,theme.bgColor, theme.text)}>
                                <div className={cx(classes.left)}>
                                    <div className={cx(classes.msg)}>
                                        {meetingInfo.host ? t('meetingPage.leaveMeetingModal.message'):t('meetingPage.leaveMeetingModal.participantMessage')}
                                        <label className={cx(classes.container)}>
                                            {t('meetingPage.leaveMeetingModal.feedback')}
                                            <input onChange={(event) =>toggleChecked(event.target.checked)}  checked={feedback} type="checkbox"/>
                                            <span className={cx(classes.checkmark)}></span>
                                        </label>
                                    </div>
                                    <div className={cx( classes.bottomButtons)}>
                                        <div className={cx(classes.space)}>
                                            <RegularButtons index={1} clicked={() => setEndModal(false)} classname={[theme.btnTernary]} title={t('meetingPage.leaveMeetingModal.cancelButton')} />
                                        </div>
                                        <div className={cx(classes.space)}>
                                            <RegularButtons index={2} clicked={() => endMeeting(false)} classname={meetingInfo.host ? [theme.btnTernary]: [theme.btnPrimary]} title={meetingInfo.host ? t('meetingPage.leaveMeetingModal.leaveMeeting'):t('meetingPage.leaveMeetingModal.participantleaveMeeting')} />
                                        </div>
                                        {meetingInfo.host ? (<div className={cx(classes.space)}>
                                            <RegularButtons index={2} clicked={() => endForAll()} classname={[theme.btnPrimary]} title={t('meetingPage.leaveMeetingModal.endAll')} />
                                        </div>) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            : null }
        </div>

    );
};

export default React.memo(EndMeetingModal);
