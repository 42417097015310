import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import {debounce} from 'lodash';
import Avatar from 'react-avatar';
// @ts-ignore
import {Janus} from 'janus-gateway';
import classes from './videoWidget.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {makeStyles} from "@material-ui/styles";
import $ from "jquery";
import HoolvaAnimation from "../HoolvaAnimations/hoolvaAnimation";
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../store/actions';
import {env} from '../../../package.json';

// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    noVideo: {
        backgroundColor: theme.videoRoomSecondaryColor
    },
    Avatar: {
        border: "3px solid " + theme.videoRoomSecondaryColor
    }
}))
const VideoWidget = (props: any) => {
     const {loggedInData } = useSelector((state:any) => state.Auth);
 
    const dispatch = useDispatch()
    const theme = useStyles(props);
    const [createdName, setCreatedName]=useState('')
    const {participant, stateUpdate, muted, videoWidth, videoHeight,
        type, ULenght, connectivityMode, elemId, activeScreenShare, avatarSize} = props;


    useEffect(() => {
        if(document.getElementById('screenshare_' + participant.id) && type === 'screenshare') {
            // @ts-ignore
            if (document.getElementById('screenshare_' + participant.id).srcObject === null) {
                if(participant?.webrtcStuff?.remoteStream && type === 'screenshare') {
                    Janus.attachMediaStream(document.getElementById('screenshare_' + participant.id), participant.webrtcStuff.remoteStream);
                }
            }
        }
    });
    useEffect(() => {
        //console.log('refreshing video widget', participant.webrtcStuff.remoteStream,participant.isPublisher, participant.rfdisplay);
        if(document.getElementById('video_' + participant.id) && type !== 'screenshare') {
            // @ts-ignore
            if (document.getElementById('video_' + participant.id).srcObject === null) {
                if(participant.isPublisher) {
                    Janus.attachMediaStream(document.getElementById('video_' + participant.id), participant.webrtcStuff.myStream);
                } else {
                    if(participant?.webrtcStuff?.remoteStream && type !== 'screenshare') {
                        Janus.attachMediaStream(document.getElementById('video_' + participant.id), participant.webrtcStuff.remoteStream);
                    }

                }
            }
        }

        const remoteVideo = document.getElementById('video_' + participant.id);
        if(remoteVideo) {
            // @ts-ignore
            remoteVideo.addEventListener('resize', handleVideoSize(remoteVideo));
        }


        return(() => {
            if(remoteVideo) {
                // @ts-ignore
                remoteVideo.removeEventListener('resize', handleVideoSize(remoteVideo));
            }

        })

    }  )

    useEffect(() =>{
        let name = participant?participant.rfdisplay.trim():null
        if(name!== null){
            if(/\s/g.test(String(name)) === true){
                let str = String(name).split(" ")
                console.log(str, 'str')
                let fName = str[0]
                let lName = str[str.length -1]
                console.log(fName, lName ,'kkkkz')
                setCreatedName(fName.concat(' ',lName))
            }else{
                let str = String(name).slice(0,3)
                let fName = str[0]
                let lName = str[1]
                console.log(fName, lName ,'kkkkz')
                setCreatedName(fName.concat(' ',lName))
            }

        }
    },[participant.rfdisplay])


    const handleVideoSize = debounce(video => {
        //console.log('videso size', event, data);
        if(env === 'development') {
            // @ts-ignore
            $(`#videoWidgetSize${participant.id}`).html(`${video.videoWidth}x${video.videoHeight}`);
        }

        //document.getElementById('videoWidgetSize' + participant.id).html(`${video.videoWidth}x${video.videoHeight}`);
    }, 300)

    const handleCompleted = () => {
        console.log('animation completed');
        dispatch(Actions.handleAnimationComplete(participant.rfid))
    }

    return (
            <div
                className={cx(classes.VideoWrapper , activeScreenShare === participant.rfid || type !== 'screenshare' ? '':classes.hideVideo)}
                style={{height:videoHeight+'px',width:videoWidth+'px', position:'relative'}}
                id={stateUpdate + 'videoWidget'}
                onClick={() => console.log('current participant info', participant)}
                // @ts-ignore
                // title={participant.webrtcStuff.pc?.address + " " + document.getElementById('video_' + participant.id)?.srcObject}
            >
                <div className={cx(classes.TileBG,theme.noVideo)}></div>
                <div className={classes.TileLoader}>
                {participant.state === 'connecting' ?
                    <FontAwesomeIcon icon={['fas', 'circle-notch']} spin className={cx(classes.avatarSpin,classes.avatar)}/>:null
                }
                </div>
                {type !== 'screenshare' ? (
                    <section className={cx(classes.NameBoard)} style={{width:videoWidth-10+'px' }}>
                    <div style={{marginTop:'10px'}}>
                        {participant.raisehand ? <FontAwesomeIcon  className={(cx(classes.gold))} icon={["fas", "hand-paper"]} /> : null}
                        {participant.reaction || participant.raisehand ? <HoolvaAnimation classname={cx(classes.reaction)} width={50+'px'} height={50+'px'}
                                                                    type={participant.reaction} loop={2}
                                                                    autoplay={true} hover={true} completed={() => handleCompleted()}/> : null}
                        </div>
                        <div className={cx(classes.name)} >
                        {participant.audio === 'muted' ? <FontAwesomeIcon icon={['fas', 'microphone-slash']} /> : null}
                            <span className={classes.flex} title={participant.rfdisplay} id={`videoWidgetioo${participant.id}`}>
                            <span className={cx(classes.DisplayNameElli)} style={{maxWidth:videoWidth-200+'px'}}>{`${participant.rfdisplay}`}</span> {participant.isPublisher ? ' (You)' : null}
                            {env !== 'production' ? participant.detached ? ' (No)' : ' (Yes) ' : null}
                                {env !== 'production' ? participant.simulcastValue: null}
                                {env !== 'production' ? participant.pauseVideo ? ' ⏸️' : null : null}</span>

                            {/*<span id={`packet${participant.id}`}>  </span>*/}
                            <span id={`videoWidgetSize${participant.id}`}></span>


                    </div>
                </section> ) : null}
                <div className={cx(classes.VideoOverlayAvatar,theme.noVideo, participant.talking && participant.audio !== 'muted' && ULenght!==1? classes.activeSpeaker : theme.Avatar)} style={{height:videoHeight-6+'px',width:videoWidth-6+'px'}}>
                {participant.state !== 'connecting' ?
                    participant.video === 'muted' ?
                            participant?.profile_picture !== null && participant?.profile_picture !== "undefined" && participant?.profile_picture ?
                                <img  src ={participant?.profile_picture} style={{ height: avatarSize }} /> 
                                    : loggedInData?.profile_picture !== null && loggedInData?.profile_picture && loggedInData?.profile_picture !== "undefined" && participant?.isPublisher ?
                                        <img src ={loggedInData?.profile_picture} style={{ height: avatarSize }} /> 
                                            : <Avatar name={createdName} size={avatarSize} round={false} />
                                                : null 
                                                    : null}
                </div>
                
                <div className={cx(classes.VideoOverlay, participant.talking && participant.audio !== 'muted' && ULenght!==1 ? classes.activeSpeaker : '')}  style={{height:videoHeight-6+'px',width:videoWidth-6+'px'}}>

                </div>
                {/* || participant.detached === true */}
                <video
                    key={participant.id}
                    className={cx(theme.noVideo,classes.videoTile,participant.state !== 'connected' ? classes.OpacityVideo: participant.detached ? classes.OpacityVideo: null, participant.isPublisher ? classes.localvideo : '', participant.video === 'muted' ? classes.hideVideo : '' )}
                    style={{height:videoHeight+'px',width:videoWidth+'px', objectFit:ULenght===1 && type !== 'screenshare' ? 'cover':'contain'}}
                    id={elemId ? elemId : 'video_' + participant.id}
                    // ref={setRef}
                    muted={muted ||  connectivityMode === 'phone'} //|| props.selectedMode === 'phone'
                    autoPlay
                />
            </div>
    )
}
function propsEqual(prevProps: any, nextProps: any) {
    return prevProps.muted !== nextProps.muted
        && prevProps.type !== nextProps.type
        && prevProps.videoWidth !== nextProps.videoWidth
        && prevProps.videoHeight !== nextProps.videoHeight
        && prevProps.ULenght !== nextProps.ULenght
        && prevProps.connectivityMode !== nextProps.connectivityMode
        && prevProps.participant !== nextProps.participant
        && prevProps.participant.rfid === nextProps.participant.rfid
        && prevProps.participant.rfdisplay !== nextProps.participant.rfdisplay
        && prevProps.participant.audio !== nextProps.participant.audio
        && prevProps.participant.video !== nextProps.participant.video
        && prevProps.participant.raisehand !== nextProps.participant.raisehand
        && prevProps.participant.reaction !== nextProps.participant.reaction
        && prevProps.participant.host !== nextProps.participant.host
        && prevProps.participant.pauseVideo !== nextProps.participant.pauseVideo
        && prevProps.participant.state !== nextProps.participant.state;
}

export default React.memo(VideoWidget, propsEqual);
