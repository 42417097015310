import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

import {translationEn} from './locales/en/transalation';
import {translationDE} from './locales/de/transalation';
import {translationUZ} from './locales/uz/transalation';
import {translationML} from './locales/ml/transalation';
import {translationAR} from './locales/ar/transalation';
import {translationFR} from './locales/fr/transalation';



// the translations
const resources = {
    en: {
        translation: translationEn
    },
    de: {
        translation: translationDE
    },
    ml: {
        translation: translationML
    },
    uz: {
        translation: translationUZ
    },
    ar: {
        translation: translationAR
    },
    fr: {
        translation: translationFR
    }
};


i18n
    .use(detector)
    // @ts-ignore
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",
        fallbackLng: "en", // use en if detected lng is not available

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
