import React, {useEffect} from "react";
import cx from "classnames";
import classes from "./reconnecting.module.scss";
import FadeIn from "react-fade-in";
import { makeStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {withNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {history} from "../../App";
import routes from '../../routes.json';
import * as Actions from '../../store/actions';
import {controller} from "../../store/actions";
// @ts-ignore
const useStyles = makeStyles(({ theme }) => ({
  btnSecondary: {
    background: theme.button2BGColor,
    border: "1px solid "+ theme.button2BorderColor,
    color: theme.button2TextColor,
    "&:hover, &:focus": {
        color: theme.button2HoverTextColor
    }
},
main: {
  color: theme.textColor1
},
}));
const Reconnecting = ({t}: any, props: any) => {
  const theme = useStyles(props);
  const dispatch = useDispatch();
  const {webrtcStatus, hoolva} = useSelector((state:any)=>state.Meet);

  useEffect(() => {
    if(!webrtcStatus) {
      if(hoolva) {
        dispatch(Actions.endMeeting({title: 'Unable to connect',
          message: 'Could not reach our servers, please try again.',
          code: 4409}))
      }
    }
  },[webrtcStatus])
  const cancelReconnection = () => {
    if(controller) {
      controller.abort()
    }
    if(hoolva) {
      hoolva.destroy();
      dispatch(Actions.resetHoolva());
      dispatch(Actions.resetMeet());
      dispatch(Actions.resetScreenShare());
      history.push(routes.HOME);
    }
  }

  return (
    <FadeIn>
      <div className={cx(classes.hoolva)}>
        <div className={cx(classes.main, classes.flex)}>
          <div>
            <div className={cx(classes.content)}>
              <div className={cx(classes.icon)}>
                <FontAwesomeIcon icon={['fas', 'wifi']} />
              </div>
              <div className={cx(classes.connect, theme.main)}>
              {t('meetingPage.waitingToReconnect')}
              </div>
            </div>
            <div className={classes.cancelButtonDiv}>
              <button className={theme.btnSecondary} onClick={cancelReconnection}> Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </FadeIn>
  );
};

export default withNamespaces()(Reconnecting);
