import React, {useState, useEffect} from "react";
import {useGoogleLogin} from 'react-google-login'
import HoolvaSplashScreen from "../components/HoolvaSplashScreen/HoolvaSplashScreen";
import {useDispatch, useSelector} from "react-redux";
import * as Config from "../Config/config";
import i18n from "../i18n";
import * as Actions from '../store/actions';
import LocalDb from "../Services/dbService";
import {faceBookToken} from "./auth";
import {useLocation} from "react-router";
import * as QueryString from "query-string";

let tokenId: string = '';
let profileImg: any = null;

const SSO = (props: any) => {
    const [waitAuthCheck, setWaitAuthCheck] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const {authProviders} = useSelector((state:any)=>state.Auth);
    let clientId = 'google_client_id';
    if(authProviders && authProviders.length > 0) {
        clientId = authProviders.find((provider: any) => {return provider.provider === 'google'}).parameters.app_id;
    }
    if(!clientId) {
        clientId = 'google_client_id';
    }


    const { signIn } = useGoogleLogin( {
        clientId: clientId, //
        onSuccess (data) {
            //@ts-ignore
            console.log('success google login data', data, data.tokenId);
            //@ts-ignore
            //setGoogleToken(data.tokenId);
            tokenId = data.tokenId;
            //@ts-ignore
            profileImg = data.profileObj.imageUrl
        },
        onAutoLoadFinished(data) {
            ssoLoginData();
            // if(!data) {
            //     setWaitAuthCheck(false)
            // }
        },
        onFailure(error) {
            console.log('error in google login', error);
            ssoLoginData();
        },
        cookiePolicy:'single_host_origin',
        isSignedIn: true
    });



    useEffect(() => {
      //  ssoLoginData();
    }, [])

    const ssoLoginData = async () => {
        // const data = {
        //     "hoolva-app": Config.API_KEY
        // };
        // const  response: any = await dispatch(Actions.getLoginClientData(data));
        // console.log('getLoginClientData', response);
        // if (response.status === 200 || response.status === 201 ||response.status === 202 || response.status === 203 || response.status === 204) {
            const dbStore = LocalDb.loadLocalDb();
            // dispatch(Actions.setAuthProviders(response.data.auth_provider_configs));
            // setGoogleClientId("315238570647-72et337eh0dhk2vo6k080rmhfh1g0vnh.apps.googleusercontent.com");
            // const authProviderResponse = await dispatch(Actions.getSSOProvider());
            //dispatch(Actions.setClientAuthData(response.data));
            dispatch(Actions.setDbStore(dbStore));
            LocalDb.getAuthInfo(dbStore, async (loggedInResponse: any) => {
                console.log('loggedInResponse', loggedInResponse.data, location.search);
                const feedBack = LocalDb.getFeedBack();
                dispatch(Actions.setFeedBack(feedBack));
                if(loggedInResponse.data.length > 0) {
                    const loggedInData = loggedInResponse.data[0];
                    let loginResponse: any = null;
                    if (loggedInData.sso === 'auth') {
                        const password = atob(loggedInData.srtf);
                        const email = loggedInData.email;
                        const userData = {
                            email,
                            password
                        }
                        loginResponse = await dispatch(Actions.doLogin(userData));
                        if (loginResponse && loginResponse.status === 200) {
                            loggedInData.profile_picture=loginResponse.data.profile_picture;
                            loggedInData.email = loginResponse.data.email;
                            loggedInData.name = loginResponse.data.firstname + ' ' + loginResponse.data.lastname;
                        } else {
                            console.log('clearing local database');
                            LocalDb.removeAuthInfo(dbStore, (data: any) => {
                                console.log('db cleared');
                            })
                        }

                        // loggedInData.img = loginResponse.data.picture;
                        console.log('login response from login api', loginResponse, location.search);
                    } else if(loggedInData.sso === 'google') {
                        console.log('google auto login');
                        if(tokenId && tokenId !== '') {
                            const googleLogin = {
                                provider: 'google',
                                token_id: tokenId
                            }
                            loginResponse = await dispatch(Actions.doSSOProviderLogin(googleLogin));
                            if (loginResponse && loginResponse.status === 200) {
                                loggedInData.email = loginResponse.data.email;
                                loggedInData.name = loginResponse.data.firstname + ' ' + loginResponse.data.lastname;
                                loggedInData.img = profileImg;
                            } else {
                                LocalDb.removeAuthInfo(dbStore, (data: any) => {
                                    console.log('db cleared');
                                })
                            }

                        } else {
                           // loginResponse.status = 400;
                            console.log('clearing local database');
                            LocalDb.removeAuthInfo(dbStore, (data: any) => {
                                console.log('db cleared');
                            })

                        }

                    } else if(loggedInData.sso === 'facebook') {
                        console.log('facebook auto login');
                        if(faceBookToken && faceBookToken !== '') {
                            const facebookLogin = {
                                provider: 'facebook',
                                token_id: faceBookToken
                            }
                            loginResponse = await dispatch(Actions.doSSOProviderLogin(facebookLogin));
                            if (loginResponse && loginResponse.status === 200) {
                                loggedInData.email = loginResponse.data.email;
                                loggedInData.name = loginResponse.data.firstname + ' ' + loginResponse.data.lastname;
                            } else {
                                LocalDb.removeAuthInfo(dbStore, (data: any) => {
                                    console.log('db cleared');
                                })
                            }

                           // loggedInData.img = loginResponse.data.picture;
                        } else {
                            console.log('clearing local database');
                           // loginResponse.status = 400;
                            LocalDb.removeAuthInfo(dbStore, (data: any) => {
                                console.log('db cleared');
                            })
                        }

                    }

                    if (loginResponse && loginResponse.status === 200) {
                        console.log('login info to save in redux', loggedInData);
                        localStorage.setItem("rtkiops", loginResponse.data["hoolva-user"]);
                        dispatch(Actions.setLoginInfo(loggedInData));
                        dispatch(Actions.setDispalyName(loginResponse.data.firstname + ' ' +loginResponse.data.lastname));
                    }
                }

                LocalDb.getDefaultValues(dbStore, (response: any) => {
                    console.log('data from nedb', response);
                    const {data} = response;
                    console.log('dataaaaaa', data);
                    if(data.length !== 0) {
                        if(loggedInResponse.data.length === 0) {
                            dispatch(Actions.setDispalyName(data[0].displayName));
                        }
                        dispatch(Actions.setDefaultVideoDevice(data[0].videoDeviceId, ''));
                        console.log("setDefaultAudioDevice 11")
                        dispatch(Actions.setDefaultAudioDevice(data[0].audioDeviceId, ''));
                        dispatch(Actions.setDefaultOutputDevice(data[0].outPutDeviceId, ''));
                        dispatch(Actions.setSystemId(data[0].systemId));
                    }
                });
                LocalDb.getMeetingList(dbStore, (response: any) => {
                    if(response.status === 'success') {
                        if(response.data.length > 0) {
                            const meetingList = response.data[0].meetingList;
                            console.log('Meeting List', meetingList)
                            const seen = new Set();
                            const filteredArr = meetingList.filter((el:any) => {
                                const duplicate = seen.has(el.value);
                                seen.add(el.value);
                                return !duplicate;
                            });
                            console.log('filtered array', filteredArr)
                            dispatch(Actions.setMeetingList(filteredArr, false));
                        }
                    }
                })
                LocalDb.getDisplaySettings(dbStore, (response: any) => {
                    if(response.status === 'success') {
                        if(response.data.length > 0) {
                            console.log('get Theme', response.data)
                            dispatch(Actions.setTheme(response.data[0].theme))
                            dispatch(Actions.setLayout(response.data[0].layout))
                            dispatch(Actions.setLanguage(response.data[0].language));
                            i18n.changeLanguage(response.data[0].language);
                            dispatch(Actions.setSliderTileLength(response.data[0].sliderLength));
                            dispatch(Actions.setDynamicBG(response.data[0].dynamicBG));
                        }else{
                            dispatch(Actions.setTheme(Config.configuration.theme))
                            dispatch(Actions.setLayout(Config.configuration.screenLayout));
                            dispatch(Actions.setLanguage(Config.configuration.language));
                            dispatch(Actions.setSliderTileLength(Config.configuration.maxTileLimit));
                            dispatch(Actions.setDynamicBG(Config.configuration.dynamicBackgrounds));
                            LocalDb.setDisplaySettings(dbStore, {
                                theme: Config.configuration.theme,
                                layout:Config.configuration.screenLayout,
                                language:Config.configuration.language,
                                dynamicBG:Config.configuration.dynamicBackgrounds,
                                sliderLength:Config.configuration.maxTileLimit,
                                type: 'display'}, (data: any) => {
                                console.log('Init Theme', data);
                            });
                        }
                    }
                })
                console.log('auth check', location.search)
                const params = QueryString.parse(location.search);
                console.log('params found', params);
                try {
                    if(params && params.rtdf) {
                        //console.log('meetingInfo', params['?rtdf']);
                        //@ts-ignore
                        const decrypted = atob(params.rtdf);
                        const meetingInfo = JSON.parse(decrypted);
                        console.log('meetingInfo', meetingInfo);
                        // dispatch(Actions.setMeetingId(meetingInfo.id + ''));
                        // dispatch(Actions.setMeetingPassword(meetingInfo.password));
                        const meetingData = {
                            sname: meetingInfo.sname
                        }
                        const  response: any = dispatch(Actions.getMeetingInfo(meetingData));
                        // dispatch(Actions.setMeetingInfo(meetingInfo.meetingId, '', meetingInfo.password));
                        // history.push(routes.JOINAMEETING);
                    }
                } catch(e) {
                    console.log('errror in fetching url', e);
                }
                setWaitAuthCheck(false);
                // DetectRTC.load(async function () {
                //     dispatch(Actions.setDetectRtcState(DetectRTC));
                //     if ((DetectRTC.browser.isChrome || DetectRTC.browser.isOpera) && DetectRTC.browser.version >= Config.configuration.supportVersion) {
                //         console.log('checking browser ok');
                //         dispatch(Actions.setBrowserVerification(true));
                //     } else {
                //         console.log('checking browser not ok');
                //         dispatch(Actions.setBrowserVerification(false))
                //     }
                //     setWaitAuthCheck(false);
                // });
            });
        // }else if (response.status === 'failed' && response.reason === 'network error') {
        //     console.log('test');
        //     handleConnectionChange();
        // } else {
        //     dispatch(showMessage('error', '4401: This client is not authorized with Hoolva', {}))
        // }
    }

    return waitAuthCheck ? <HoolvaSplashScreen /> : <>{props.children}</>;
}
export default SSO;
