import React from "react";
import classes from './hoolvaAlertMessage.module.scss';
import {makeStyles} from "@material-ui/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    bgColor:{
        backgroundColor: theme.background,
        color: theme.textColor1
    },
    border:{
        border: "1px solid " + theme.modalBorderColor,
    },
    inputText: {
        backgroundColor: theme.textboxBGColor,
        border: '1px solid ' + theme.textboxBorderColor,
        color: theme.textboxTextColor,
        "&:hover, &:focus, &:active": {
            border: "1px solid "+ theme.textboxActiveColor,
        },
        '&::placeholder': {
            color:theme.textboxPlaceholderColor
        }
    },
    borderBottom:{
        borderBottom: "1px solid " + theme.dropdownBorderColor,
    },
    btnPrimary: {
        background: theme.button1BGColor,
        border: "1px solid "+ theme.button1BorderColor,
        color: theme.button1TextColor,
        "&:hover, &:focus": {
            background: theme.button1HoverBGColor
        }
    },
    btnSecondary: {
        background: theme.button2BGColor,
        border: "1px solid "+ theme.button2BorderColor,
        color: theme.button2TextColor,
        "&:hover, &:focus": {
            color: theme.button2HoverTextColor
        }
    },

    btnTernary: {
        background: theme.button3BGColor,
        border: "1px solid "+ theme.button3BGColor,
        color: theme.button3TextColor,
        "&:hover, &:focus": {
            border: "1px solid "+ theme.button3HoverBorderColor,
        }
    }
}))
const HoolvaAlertMessage = (props: any) => {
    const {open, title, message, confirm, mode,t} = props
    const theme = useStyles(props);
    return (
        <>
            {open && mode? (
                <div>
                <div className={cx(classes.modalBG)}>
                </div>
                <div className={cx(classes.modal)}>
                    <div className={cx(classes.content,theme.bgColor, theme.border)}>
                        <div className={cx(classes.header,theme.bgColor, theme.borderBottom)}>
                            <div className={cx(classes.padding8)}>
                                <FontAwesomeIcon onClick={confirm} className={cx(classes.close)} icon={['fas', 'times']} />
                                {title}
                            </div>
                        </div>
                        <div className={cx(classes.body,theme.bgColor)}>

                            <div className={cx(classes.tabBody)}>
                                <div className={cx(classes.bodyContent)}>
                                    <div className={cx(classes.bodyTitle)}>
                                    {message}
                                    </div>
                                    <div className={cx(classes.input)}>
                                        <button className={theme.btnPrimary} onClick={confirm}>
                                          { t("schedule.Ok")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ) : null}
        </>
    )
}
export default React.memo(HoolvaAlertMessage);
