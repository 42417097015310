import * as Service from "../../Services/apiService";
import { showMessage} from "./notifyMessage.action";

export const SET_USER_ID: string = 'SET_USER_ID';
export const SET_BROWSER_VERIFY = 'SET_BROWSER_VERIFY';
export const RESET_AUTH: string = 'RESET_AUTH';
export const SET_DB_STORE: string = 'SET_DB_STORE';
export const SET_MEETING_LIST: string = 'SET_MEETING_LIST';
export const SET_LOGIN_INFO: string = 'SET_LOGIN_INFO';
export const SET_I18N: string = 'SET_I18N';
export const SET_THEME: string = 'SET_THEME';
export const SET_CLIENT_AUTH = 'SET_CLIENT_AUTH';
export const SET_AUTH_PROVIDERS = 'SET_AUTH_PROVIDERS';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const SET_GLOBAL_CLIENT_AUTH = 'SET_GLOBAL_CLIENT_AUTH';
export const SET_USER_TEMP_ID = 'SET_USER_TEMP_ID';
export function setUserId(userID: string) {
    return {
        type: SET_USER_ID,
        userID
    };
}
export function setI18N(t: any){
    return {
        type: SET_I18N,
        t
    }
}

export function setDbStore(dbStore: any) {
    return {
        type: SET_DB_STORE,
        dbStore
    }
}

export function setMeetingList(meetingList: any, update: boolean) {
    console.log('setMeetingList action', meetingList, update)
    return {
        type: SET_MEETING_LIST,
        meetingList,
        update
    }
}

export function setBrowserVerification(verify: boolean) {
    return {
        type: SET_BROWSER_VERIFY,
        verify
    }
}

export function setLoginInfo(info: any) {
    return {
        type: SET_LOGIN_INFO,
        info
    }
}

export function setClientAuthData(data: any) {
    return {
        type: SET_CLIENT_AUTH,
        data
    }
}
export function setGlobalAuthData(data: any) {
    console.log("globalClientAuth", data);
    return {
        type: SET_GLOBAL_CLIENT_AUTH,
        data
    }
}

export function setUserPermissions(permission: any) {
    return {
        type: SET_USER_PERMISSIONS,
        permission
    }
}
export function setUserTempId(usetTempId: any) {
    return {
        type: SET_USER_TEMP_ID,
        usetTempId
    }
}

export function setAuthProviders(providers: any) {
    return {
        type: SET_AUTH_PROVIDERS,
        providers
    }
}

export function doLogin(userData: any) {
    return async function(dispatch: any, getState: any) {
        const response:any = await Service.doLogin(getState().Hoolva.API_ROOT, userData);
        console.log("response",response);
        if (response.status === 200) {
            if(!response.data.change_password){
                const permissions:any = await Service.getUserPermission(getState().Hoolva.API_ROOT);
                console.log('permissions', permissions);
                if(permissions.status === 200) {
                    dispatch(setUserPermissions(permissions.data))
                }
            }else{
                dispatch(setUserTempId(response.data.tmpid))
            }
        }
        return response;
    }
}
export function updatePassword(userData: any) {
    console.log("updateResp", userData);
    return async function(dispatch: any,getState: any) {
        const response:any = await Service.updatePassword(getState().Hoolva.API_ROOT, userData);
        if (response.status === 204) {
            dispatch(showMessage('info', 'Password Updated Successfully', {}));
        }
        return response;
    }
}

export function getSSOProvider() {
    return async function(dispatch: any, getState: any) {
        const response = await Service.getSSOProviderConfig(getState().Hoolva.API_ROOT);
        console.log('sso providers', response );
        return response;
    }
}

export function doSSOProviderLogin(data: any) {
    return async function(dispatch: any, getState: any) {
        const response:any = await Service.doSSOProviderLogin(getState().Hoolva.API_ROOT, data);
        console.log('sso provide login response', response);
        if (response.status === 200) {
            if(!response.data.change_password){
                const permissions:any = await Service.getUserPermission(getState().Hoolva.API_ROOT);
                console.log('permissions', permissions);
                if(permissions.status === 200) {
                    dispatch(setUserPermissions(permissions.data))
                }
            }
        }
        return response;
    }
}

export function doSSOProviderSignUp(data: any) {
    return async function(dispatch: any, getState: any) {
        const response = await Service.doSSOProviderSignUp(getState().Hoolva.API_ROOT, data);
        console.log('sso provide login response', response);
        return response;
    }
}

export function doLogout() {
    return async function(dispatch: any, getState: any) {
        const response = await Service.doLogOut(getState().Hoolva.API_ROOT);
    }
}

export function resetAuth() {
    return {
        type: RESET_AUTH
    }
}
export function setTheme(displayTheme: string) {
    console.log("Action.displayTheme",displayTheme);
    let element =  document.querySelector("html");
    // @ts-ignore
    element.classList.add(displayTheme);
    // @ts-ignore
    element.classList.remove(displayTheme === 'lite' ? 'dark':'lite');
    return {
        type: SET_THEME,
        displayTheme
    }
}
