import React, {createRef, useEffect, useRef, useLayoutEffect, useState} from "react";
import cx from 'classnames';
import {debounce} from 'lodash';
import classes from './videoSlider.module.scss';
import * as Config from '../../../Config/config';
import * as Actions from '../../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, CarouselContext, WithStore } from 'pure-react-carousel';
import VideoPanel from "./VideoPanel/videoPanle";
import { useDispatch } from "react-redux";

const VideoSlider = (props: any) => {
    // const maxTileinslider = Config.configuration.maxTileLimit;
    const {participantList, stateUpdate, chat, fluid, currentSlider, people, activeScreen, connectivityMode, maxTileinslider,setShortMenuOpt} = props;
    const abc = new Array(100);
    abc.fill(1, 0, 100);
    const videoRef = useRef(abc.map(() => createRef()));
    const [sliderArray, setSliderArray] = useState([]);
    // const [maxTileinslider, setMaxTileinslider] = useState(Config.configuration.maxTileLimit);
    const [sliderStatus, setSliderStatus] = useState(false);
    const dispatch = useDispatch();
    // const [orientation, setOrientation] = useState("horizontal");

    //const maxIndex=(participantList.length / maxTileinslider);
    // const sliderArray = _.range(0, Math.ceil(maxIndex),1);
    // console.log('sliderarray', sliderArray);
    // useEffect(() => {
    //     if (activeScreen  === 2 ) {
    //         setOrientation('vertical');
    //     }else{
    //         setOrientation('horizontal');
    //     }
    // }, [activeScreen])
    useLayoutEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
        }, [
            window.innerHeight,
            window.innerWidth,
            participantList.length, stateUpdate, activeScreen,chat, people
    ]);
    const updateSize = debounce(async() => {
        if((window.innerWidth <= 820 && (chat || people)) || (window.innerWidth <= 480 && !(chat || people))){
            setShortMenuOpt(true);
        }else{
            setShortMenuOpt(false)
        }
        if(activeScreen === 2){
            await dispatch(Actions.setMaxTileLimit(Math.floor((window.innerHeight - 200) / 135)));
            console.log('max tile insider', Math.floor((window.innerHeight - 200) / 135));
            // setMaxTileinslider( Math.floor((window.innerHeight - 200) / 135));
            if (maxTileinslider === 0) {
                await dispatch(Actions.setMaxTileLimit(1));
                // setMaxTileinslider(1);
            }
        }else{
            await dispatch(Actions.setMaxTileLimit(Config.configuration.maxTileLimit))
            // setMaxTileinslider(Config.configuration.maxTileLimit);
        }
        if (participantList.length > 0) {
            let sliderLength = Math.floor(
                participantList.length / maxTileinslider
            );
            if (sliderLength < 1) {
                sliderLength = 1;
            } else {
                let extraCount =
                    participantList.length % maxTileinslider;
                if (extraCount !== 0) {
                    sliderLength = sliderLength + 1;
                }
            }
            let sliders = [];
            for (let i = 1; i <= sliderLength; i++) {
                sliders.push({
                    id: "galleryview" + i,
                    component: "Gallery View",
                    start: i * maxTileinslider - maxTileinslider,
                    end: i * maxTileinslider - 1
                });
            }
            // console.log("new slider", sliders);
            // @ts-ignore
            setSliderArray(sliders)
            setSliderStatus(participantList.length > maxTileinslider);
        }
    }, 300)


    useEffect(() => {
        updateSize();
        return(() => {
            updateSize.cancel();
        })
    }, [])


        // @ts-ignore
    const slideChange = (data) => {
        console.log('slide change', data);
    }

    return (
            <div >
                <CarouselProvider orientation={activeScreen===1 ? 'horizontal':'vertical'} className={cx(classes.carousel,fluid || activeScreen===2 ? classes.Fluid: '', chat || people ? activeScreen===2 ? classes.SpeakerChatFluidView : classes.carouselChat: activeScreen===2 ? classes.SpeakerFluidView :'')}
                  naturalSlideWidth={100}
                  naturalSlideHeight={125}
                  totalSlides={sliderArray.length}
                  currentSlide={currentSlider}
                >
                    { sliderStatus && currentSlider !== 0?
                        <ButtonBack className={cx(activeScreen===1 ? classes.left:classes.top )}>
                            <div className={cx(classes.icon)} >
                                <FontAwesomeIcon icon={['fas', 'chevron-left']} />
                            </div>
                        </ButtonBack>
                        : null
                    }

                    <Slider className={cx(classes.slider)}
                    onChange={slideChange}>
                            {sliderArray.map((slide:any)=>{
                            return(<Slide  index={slide} key={slide.id} >
                                    <VideoPanel activeScreen={activeScreen} connectivityMode={connectivityMode} sliderStatus={sliderStatus} fluid={fluid} people={people} chat={chat} start={slide.start} participantList={participantList} stop={slide.end} stateUpdate={stateUpdate} />
                                </Slide>)})}
                    </Slider>
                    { sliderStatus && currentSlider + 1 !== sliderArray.length?
                        <ButtonNext className={cx(activeScreen===1 ? classes.right:classes.bottom )}>
                            <div className={cx(classes.icon)} >
                                <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                            </div>
                        </ButtonNext>
                    : null
                    }

                </CarouselProvider>
            </div>


        )


}
// // @ts-ignore
// export default WithStore(VideoPanel, state => ({
//     currentSlide: state.currentSlide,
//     totalSlides: state.totalSlides,
//     visibleSlides: state.visibleSlides,
// }));
export default VideoSlider;
// return participantList.map((participant: any, index: number) => {
            // <div
            //     key={participant.id}
            //     className={cx(
            //         classes.VideoElementDiv,
            //         ' float-left no-padding ',
            //     )}
            // >
            //     <VideoWidget
            //         participant={participant}
            //         setRef={videoRef.current[participant.rfindex]}
            //         key={participant.id}
            //         muted={index === 0}
            //     />
            //     {/*localMute={localMute}*/}
            //     {/*selectedMode={props.selectedMode}*/}
            //     {/*videoHeight={props.videoHeight}*/}
            //     {/*videoWidth={props.videoWidth}*/}
            //     {/*localVideo={localVideo}*/}
            //     {/*pauseSubscriber={pauseSubscriber}*/}
            //     {/*startSubscriber={startSubscriber}*/}
            //     {/*setBitRate={setBitRate}*/}

            // </div>
            // })
