import * as Config from '../../Config/config'

export const translationEn = {
    "brandName": Config.configuration.brandName,
    "splashScreen": {
        "loading": "Loading..."
    },
    "layout": {
        "noInternet":"No internet",
        "backOnline": "Back online",
        "encryptionError": "Platform not support",
        "encryptionErrorMsg":"Modal Heading : Google chrome flag not set. Modal Body : You need to enable a settings in google chrome to join this End to End Encrypted meeting. Type chrome://flags on the chrome's address bar and enable Experimental Web Platform features. Restart Chrome to make it working.",
    },
    "homePage": {
        "domainPlaceHolder": "IP or domain name",
        "joinButton": "Join a meeting",
        "loginButton": "Login",
        "noAccount": "No account?",
        "createAccount": "Create your FREE account",
        "logoutButton": "Logout",
        "cancelButton": "Cancel",
        "startMeetingButton": "Start meeting",
        "meetingTab": "Meetings",
        "chatTab": "Chat",
        "scheduleTab": "Schedule",
        "recordingTab": "Recording",
        "upcomingMeeting": "Upcoming",
        "personalMeeting": "Personal",
        "scheduleMeetingButton": "Schedule meeting",
        "personalMeetingRoomPlaceHolder": "My personal meeting room",
        "meetingIdLabel": "Meeting ID",
        "meetingIdPlaceHolder": "Meeting ID",
        "passwordLabel": "Password",
        "passwordPlaceHolder": "Password",
        "saveButton": "Save",
        "editButton": "Edit",
        "deleteButton": "Delete",
        "showInvitationButton": "Show invitation",
        "copyInvitationButton": "Copy invitation",
        "ViewProfile":"View Profile",
        "ChangePassword":"Change Password"
    },
    "loginPage": {
        "title": "Login",
        "emailPlaceHolder": "Email",
        "passwordPlaceHolder": "Password",
        "rememberMe": "Remember me",
        "forgotPassword": "Forgot password ?",
        "cancelButton": "Cancel",
        "loginButton": "Login",
        "loginWithFaceBook": "Login with facebook",
        "loginWithGoogle": "Login with google",
        "loginError": "Username or Password is not correct"
    },
    "resetPassword": {
        "title": "Reset Password",
        "newPasswordPlaceHolder": "New Password",
        "confirmNewPasswordPlaceHolder": "Confirm new Password",
        "cancelButton": "Cancel",
        "updateButton": "Change Password",
    },
    "joinPage": {
        "title": "Join a meeting",
        "meetingIdText": "Meeting ID",
        "submitButton": "Submit",
        "cancelButton": "Cancel",
        "connectingText": "Connecting",
        "noInternet": "No internet",
        "wrongMeetingId": "Invalid meeting ID",
        "invalidMeetingId": "Meeting ID should be 10 digits"

    },
    "previewPage": {
        "title": "Proceed to the meeting",
        "meetingIdLabel": "Meeting ID",
        "passwordPlaceHolder": "Password",
        "displayNamePlaceHolder": "Display name",
        "cancelButton": "Cancel",
        "connectingText": "Connecting",
        "joinWithDevice": "Join",
        "joinWithMicrophone": "Join with mic",
        "joinWithWebCam": "Join with cam",
        "joinWithOutDevice": "Join with cam & mic off",
        "deviceText":"Device selection",
        "invalidPassword": "Invalid password",
        "noInternet": "No internet",
        "deviceBlockedTitle": "Cam and mic are blocked.",
        "deviceBlockedMessage1":"",
        "deviceBlockedMessage2":" needs to access your cam and mic. Click the cam blocked icon in the address bar.",
        "allowDevicePermissionTitle1": "Allow " ,
        "allowDevicePermissionTitle2" :" to use your cam and mic.",
        "allowDevicePermissionMessage1":"",
        "allowDevicePermissionMessage2":  " needs to access your cam and mic so that other participants can see you and hear you.",
        "allowMicrophonePermissionTitle1": "Allow ",
        "allowMicrophonePermissionTitle2": "to use your mic.",
        "allowMicrophonePermissionMessage1":"",
        "allowMicrophonePermissionMessage2":   " needs to access your mic so that other participants can hear you.",
        "allowVideoPermissionTitle1": "Allow ",
        "allowVideoPermissionTitle2" :" to use your cam.",
        "allowVideoPermissionMessage1":"",
        "allowVideoPermissionMessage2": " needs to access your cam so that other participants can see you. ",
        "microphoneBlockedTitle": "Mic is blocked.",
        "microphoneBlockedMessage1":"",
        "microphoneBlockedMessage2": " needs to access your mic. Click the mic blocked icon in the address bar.",
        "webCamBlockedTitle": "Cam is blocked",
        "webCamBlockedMessage1":"",
        "webCamBlockedMessage2":  " needs to access your cam. Click the cam blocked icon in the address bar.",
        "noDeviceFoundTitle": "No audio and video devices found.",
        "noDeviceFoundMessage1":"",
        "noDeviceFoundMessage2": " needs to access your mic and cam. Please install or join with out devices.",
        "speakerTestPlay": "Play",
        "speakerTestStop": "Stop",
        "recordTestRecord": "Record",
        "recordTestPlaying": "Playing...",
        "recordTestStop": "Stop"
    },
    "meetingPage": {
        "max_concurrent_limit_reached":"Another meeting for this host is already in progress. Please try again later",
        "max_limit_reached":"This meeting has reached the maximum number of participants allowed. Please try again later",
        "max_duration_reached":"Your meeting ended due to time limit. Please upgrade your plan for meetings without limits",
        "almost_duration_notification1":"This meeting will end in ",
        "almost_duration_notification2": " minutes. Please upgrade your plan to have meetings without limits",
        "PleaseTryAgainLater":"Please try again later",
        "TimeOut":"Time out",
        "HostEndTheMeeting":"Host end the meeting",
        "MeetingEnded":"Meeting Ended",
        "UploadorDragandDropHere":"Upload or Drag and Drop Here",
 
        "topBar": {
            "invite": "Invite",
            "screenShareView": "Screen Share",
            "speakerView": "Speaker View",
            "galleryView": "Gallery View",
            "stickyMode": "Sticky Layout",
            "floatMode": "Float Layout",
            "fullScreen": "Full Screen",
            "restore": "Restore Screen",
            "lockMeeting":"Lock Meeting",
            "openMeeting":"Unlock Meeting"
        },
        "inviteModal": {
            "title": "Invite people to this meeting",
            "inviteTextAreaPhonePlaceHolder": "Enter or paste phone numbers separated by comma , space or  semicolon",
            "inviteTextAreaEmailPlaceHolder": "Enter or paste emails separated by comma , space or  semicolon",
            "cancelButtonLabel": "Cancel",
            "phoneTabRightLabel": "A call is triggered to the number given and the participant has to accept the call to join this meeting.",
            "inviteButtonLabel": "Invite",
            "invitingButtonLabel": "Inviting..",
            "inviteSuccessButtonLabel": "Success",
            "copyMeetingUrl": "Copy meeting URL",
            "copyMeetingInvite": "Copy meeting invite",
            "copyStatus": "Copied",
            "TabPhone":"Phone",
            "TabEmail":"Email"
        },
        "menu": {
            "settings": "Settings",
            "noMicrophone": "Audio device not installed",
            "noMicroPhonePermission": "Please allow access to mic",
            "noMicroPhoneShort": "No Audio",
            "noMicroPhonePermissionShort": "No Permission",
            "audioMute" : "Mute",
            
            "audioUnMute": "Unmute",
            "noWebCam": "Video device not installed",
            "noWebCamPermission": "Please allow access to cam",
            "noWebCamShort": "No Video",
            "noWebCamPermissionShort": "No Permission",
            "stopVideo": "Stop",
            "startVideo": "Start",
            "switchAudio": "Switch Audio",
            "switchAudioShort": "Switch",
            "ConnectAudioShort":"Connect ",
            "ConnectAudio":"Connect Audio",
            "stopShare":  "Stop Share",
            "stopShareShort": "Stop",
            "startShare": "Share Screen",
            "startShareShort": "Share",
            "people": "People",
            "chat": "Chat",
            "raiseHand": "Raise Hand",
            "raiseHandShort": "Raise",
            "lowerHand": "Lower Hand",
            "lowerHandShort": "Lower",
            "leaveMeeting": "Leave Meeting",
            "leaveMeetingShort": "Leave",
            "menu":"Menu",
            "stopRecord":" Stop Record",
            "startRecord":"Start Record",
            "stopShortRecord":"Stop",
            "startShortRecord":"Record"
        },
        "quickChat": {
            "typeMessage": "Type a message"
        },
        "rightPanel": {
            "chat": {
                "title": "Chat",
                "typeMessage": "Type a message",
                "saveChat": "Save",
                "saving": "Saving..."
            },
            "people": {
                "title": "People",
                "inMeeting": "In Meeting",
                "muteAll": "Mute All",
                "unmuteAll": "Unmute All",
                "allowUnMute": "Allow people to unmute",
                "Allow":"Allow",
                "Deny":"Deny",
                "Waiting":"Waiting"
            }
        },
        "leaveMeetingModal": {
            "title": "End meeting",
            "participantTitle": "Leave meeting",
            "message": "Are you sure you want to End this meeting?",
            "participantMessage": "Are you sure you want to Leave this meeting?",
            "feedback": "Give feedback",
            "cancelButton": "Cancel",
            "leaveMeeting": "End for me",
            "participantleaveMeeting": "Leave meeting",
            "endAll": "End for all"
        },
        "connectivityModal": {
            "title": "Choose your audio connectivity",
            "computerAudio": "Computer Audio",
            "phoneAudio": "Dial in",
            "callback": "Dial out",
            "computerAudioMessage1": "",
            "computerAudioMessage2": " will use your computer in-built or connected audio devices.",
            "joinWithComputerAudio": "Join with computer audio",
            "leaveComputerAudio": "Leave computer audio",
            "joinWithPhone": "Join with phone",
            "joinWithCallback": "Get a call"
        },
        "settings": {
            "title": "Settings",
            "general": "General",
            "profile": "Profile",
            "audioVideo": "Audio & Video",
            "profileNameVali":"Please enter minimum 3 characters",
            "theme": "Themes",
            "about": "About",
            "language": "Language",
            "dynamicBackground": "Dynamic backgrounds",
            "topAndBottomBar": "Top and bottom bar",
            "participantCountInGalleryView": "Participants per page in gallery view",
            "changeProfile": "Change",
            "displayName": "Display name",
            "displayNameChangeSuccessMessage": "Display name changed successfully",
            "updateDisplayName": "Update",
            "viewProfile": "View Profile",
            "video": "Video",
            "cam": "Cam",
            "audio": "Audio",
            "mic": "Mic",
            "speakers": "Speakers",
            "microphone": "Microphone",
            "themeLite": "Lite",
            "themeDark": "Dark",
            "version": "Version"
        },
        "reconnecting": "Reconnecting ...",
        "waitingToReconnect": "Waiting for connection",
        "youAreOnMute": "You are on mute",
        "unmuteMicrophoneRequestTitle": "Unmute microphone request",
        "unmuteMicrophoneRequestMessage": "The host is requesting to unmute your microphone",
        "error4401": "Make sure you are connected to the internet and your connection to " + Config.configuration.brandName + " is not blocked by a firewall or proxy.",
        "error4401Title": "Unable to connect",
        "errorOkLabel": "Ok",
        "putMuteByHost": "You are put on mute by the host",
        "kickOutTitle": "Remove from meeting",
        "kickOutConfirmMessage": "Do you really want to remove this person from the meeting?",
        "kickOutMessage": "Host has removed you from the meeting.",
        "turnOffVideoByHost": "Your video is turned off by the host",
        "unmuteMicroPhoneRequestTitle": "Unmute microphone request",
        "unmuteMicroPhoneRequestMessage": "The host is requesting to unmute your microphone",
        "startCameraRequestTitle": "Start camera request",
        "startCameraRequestMessage": "The host is requesting to start your video"
    },
    "schedule": {
        "mode": "Mode",
        "topic": "Topic",
        "meetingId": "Meeting ID",
        "password": "Password",
        "e2eEncryption":"End to End Encryption",
        "autoRecord":"Auto Record",
        "pin": "Pin",
        "RecurringCheckBox":"Recurring",
        "Recurrence":"Recurrence",
        "Occurrences":"Occurrences",
        "RecordMode":"Record mode",
        "dialInMode":"Phone bridge",
        "recordAutomatically":"Record automatically",
        "Security":"Security",
        "LockTheRoom":"Lock the room",
        "joinBeforeHost":"join before host",
        "timeZone":"Time zone",
        "DateAndTime":"Date and time",
        "date":"Date",
        "time":"Time",
        "invite":"Invite",
        "security":"Security",
        "cancelButton":"Cancel",
        "hideInvitationButton": "Hide invitation",
        "showInvitationButton": "Show invitation",
        "copyInvitationButton":"Copy invitation",
        "schedulebutton":"Schedule",
        "openMeeting":"Open",
        "deleteModalHeader": "Delete meeting",
        "deleteModalTitle": "Do you really want to delete this meeting?",
        "deleteModalTitleRecentLink": "",
        "deleteModalButtonAllOccurance": "Delete all occurrences",
        "deleteModalButtonThisOccurance": "Delete this occurrence",
        "deleteModalButtonThisMeeting": "Delete this meeting",
        "updateModalHeader": "Edit meeting",
        "updateModalTitle": "Do you really want to edit this meeting?",
        "updateModalTitleRecentLink": "",
        "updateModalButtonAllOccurance": "Edit all occurrences",
        "updateModalButtonThisOccurance": "Edit this occurrence",
        "updatePersonalMeetingButton": "Edit personal meeting",
        "updatePersonalMeeting": "Edit personal meeting",
        "noUpcomingMeetingsFound":"No upcoming meetings found",
        "noOpenMeetingsFound":"No open meetings found",
        "noPersonalMeetingsFound":"No personal meetings found",
        "RepeatEvery":"Repeat every",
        "Day":"Day",
        "Week":"Week",
        "Month":"Month",
        "copied":"Copied",
        "Occurson":"Occurs on",
        "EndDate":"End date",
        "After":"after",
        "OfTheMonth":"of the month",
        "scheduleResponse": "Meeting scheduled successfully",
        "ScheduleMeeting": "Schedule Meeting",
        "Ok":"ok",
        "editResponse":"Meeting edited successfully",
        "Cancel":"cancel",
        "Invite":"Invite",
        "DeleteMeeting":"Delete Meeting",
        "editResponseMessage":"Meeting deleted successfully",
        "MeetingInvite":"Meeting invite",
        "ScheduleMeeting":"Schedule Meeting",
        "Remove":"Remove",
        "Startmyvideo":"Start my video",
        "IWillDoItLater":"I\'ll do it later",
        "Meeting invite sent successfully":"Meeting invite sent successfully",
        "Inviting":"Inviting..",
        "StayMuted":"Stay muted",
        "UnmuteNow":"Unmute now",
        "WhereDoYouWantToStoreThisRecording":"Where do you want to store this recording",
        "ThisComputer":"This Computer",
        "Cloud":"Cloud",
        "Record":"Record",




    },
    "infoWindow": {
        "MeetingID":"Meeting ID",
        "Host":"Host",
        "Password":"Password",
        "Pin":"Pin",
        "InviteLink":"Invite Link",
        "Copied":"Copied",
        "CopyMeetingURL":"Copy meeting URL"
    },
    "feedBack": {
        "title": "How did it go?",
        "subTitle1": "Thank you for using " ,
        "subTitle2": ". Please help us to improve",
        "subTitle3": "better.",
        "audioIssues": "Audio issues",
        "videoIssues": "Video issues",
        "suggestions": "Any suggestions for improvement",
        "cancelLabel": "Cancel",
        "submitLabel": "Submit",
        "feedBackTitle": "Thank you for your feedback.",
        "feedBackSuccess": "Your feedback has been submitted successfully. ",
        "echoIssue": "I heard echo",
        "audioMissing": "I couldn't hear one the participant",
        "audioBlackout": "No audio at All",
        "unexpectedEnd": "Meeting ended unexpectedly",
        "videoWasFrozen":"Video was frozen",
        "videoWasFlickering":"Paritcipant's video was flickering",
        "myVideoWasNotShowingToThem":"My Video was not showing to them",
        "videoWasMissing":"Participant's video was missing"
    },
    "Waiting":{
        "SitBackAndRelax":"Sit back and relax,",
        "HostWillSoonStartMeeting":"Your host will soon be here to start the meeting.",
        "PleaseWait":"Please wait,",
        "HostWillSoonLetYouIn":"Your Host will soon let you in.",
        "Joining":"Joining",
        "YouAreJoiningTheMeeting":"You are joining the meeting.",
        "LeaveMeeting":"Leave meeting",
        "IfYouAreTheHost":"If you are the host",
        "toStartThisMeeting":"to start this meeting",
        "GettingBored":"Getting bored",
        "Try":"Try",
        "fun":"fun",
        "BackToWaitingRoom":"Back to waiting room",
        "PlayWithArrowkeys":"Play with arrow keys"
    },
    "WaitingModal":{
        "waitingModalTitle":"Waiting room",
        "waitingModalBody":"People are waiting for your approval to join this meeting.",
        "dontShow":"Don't show this window again for this meeting.",
        "KnowWho":"Review",
        "Admit":"Admit",
        "AdmitAndOpen":"Admit & open",
        "Deny":"Deny",
        "Open":" & open"
    },
    "Reconnecting":{
        "Rejoin":"Rejoin",
        "PoorNetwork":"Poor Network",

        "NetworkUnstable":"Your network seems to be unstable. Please switch to a different network if available.",
    },
    "ScreenShare":{
        "ScreenShareMaxLimitExceeded":"Screen share max limit exceeded"
    }
}
