import React from "react";
import classes from "./quickMenu.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QuickMenu = (props: any) => {
    const ResetQuickChat = (e: any) => {
        // e.stopPropagation();
        setShortMenu(false);
    }
    const { t, connectivityMode, reconnectAudio,initScreenShare,stopScreenShare,localScreenShareMode,TabSwitch,participantList,
        chatNotificationCount,requestLowerHand,requestRaiseHand,raiseHand,setShortMenu,getCookie,meetingInfo } = props;
    return(
        <div onClick={(e)=>ResetQuickChat(e)} className={classes.QMenu}>
            {/* {!systemState.hasMicrophone || audioPermission === 'denied' ? (
                <div onClick={() => showAlertMessage()} className={classes.danger}>
                    <FontAwesomeIcon icon={["fas", "microphone-slash"]}/>
                    {!systemState.hasMicrophone ? t('meetingPage.menu.noMicroPhoneShort'): t('meetingPage.menu.noMicroPhonePermissionShort')}
                </div>
            ) :  (
                <div onClick={() => toggleAudio(!audio)}>
                    <FontAwesomeIcon icon={["fas", audio ? 'microphone' : "microphone-slash"]}/>
                    {audio ? t('meetingPage.menu.audioMute'): t('meetingPage.menu.audioUnMute')}
                </div>
            )}
            { !systemState.hasWebcam || videoPermission === 'denied' ? (
                <div onClick={() => showAlertMessage()} className={classes.danger}>
                    <FontAwesomeIcon icon={["fas", 'video-slash']}/>
                    {!systemState.hasWebcam ? t('meetingPage.menu.noWebCamShort'): t('meetingPage.menu.noWebCamPermissionShort')}
                </div>
            ) :  (
                <div onClick={() => toggleVideo(!video)}>
                    <FontAwesomeIcon icon={["fas", video ? 'video' : "video-slash"]}/>
                    {video ? t('meetingPage.menu.stopVideo') : t('meetingPage.menu.startVideo')}
                </div>
            )} */}
            {meetingInfo.e2ee ? connectivityMode !=='phone' ? (
                <div onClick={() => reconnectAudio(true)}>
                    <FontAwesomeIcon icon={["fal", "exchange"]}/>
                    {t('meetingPage.menu.switchAudio')}
                </div>
            ):(
                <div onClick={() => reconnectAudio(true)}>
                    <FontAwesomeIcon icon={["fas", "plug"]}/>
                    {t('meetingPage.menu.ConnectAudio')}
                </div>
            ):null}
            {localScreenShareMode ? (
                <div onClick={() => stopScreenShare()} style={{color:'orangered'}}>
                    <FontAwesomeIcon icon={["fas", "square"]}/>
                    {t('meetingPage.menu.stopShare')}
                </div>
            ):(
                <div onClick={() => initScreenShare()}>
                    <FontAwesomeIcon icon={["fal", "presentation"]}/>
                    {t('meetingPage.menu.startShare')}
                </div>
            )}
            <div onClick={() => TabSwitch('people')}>
                <FontAwesomeIcon icon={["fas", "users"]}/>
                {t('meetingPage.menu.people')} ({participantList.length})
            </div>
            {meetingInfo.e2ee ? 
            <div onClick={() => TabSwitch('chat')}>
                <FontAwesomeIcon icon={["fas", "comment-alt"]} />
                {t('meetingPage.menu.chat')}
                {chatNotificationCount !== 0 ?
                    ({chatNotificationCount}) : ''
                }
            </div>
            :null}
            {raiseHand ? (
                <div onClick={() => requestLowerHand()}>
                    <FontAwesomeIcon icon={["fas", "hand-paper"]} rotation={180}/>
                    {t('meetingPage.menu.lowerHand')}
                </div>
            ):(
                <div onClick={() => requestRaiseHand()}>
                    <FontAwesomeIcon icon={["fas", "hand-paper"]}/>
                    {t('meetingPage.menu.raiseHand')}
                </div>
            )}
            <div onClick={() => getCookie("hoolva-app")}>
                <FontAwesomeIcon icon={["fas", "cog"]}/>
                {t('meetingPage.menu.settings')}
            </div>
        </div>
    );
}
export default QuickMenu;
