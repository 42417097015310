import React, { useEffect } from "react";
import classes from "./hoolvaNotification.module.scss";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setNotificationMessage } from "../../store/actions";

const HoolvaNotificationMessage = (props: any) => {
  const { message } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setNotificationMessage(""));
    }, 5000);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);
  return (
    <div className={classes.notification}>
      <span>
          <FontAwesomeIcon className="fa-lg" icon={['fas', 'info-circle']} /> 
          {message}
          </span>
    </div>
  );
};
export default HoolvaNotificationMessage;
