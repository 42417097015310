import {toast} from "react-toastify";
import {env} from '../../../package.json'


export const SHOW_MESSAGE: string = 'SHOW_MESSAGE';
export const HIDE_MESSAGE: string = 'HIDE_MESSAGE';
export const SET_NOTIFICATION_MESSAGE: string = 'SET_NOTIFICATION_MESSAGE';

/**
 * FunctionName: showMessage
 * Description: to show toast based on type
 * @param messageType // info, warn, success, error, dark, default
 * @param message: string //message to display in toast
 * @param options configuration of toast
 *  position: top-right top-left top-center bottom-left bottom-center bottom-right
 *  autoClose: 3000, time period to close if false it will remain there until manual close
 */
export function showMessage(messageType: string, message: string, options: any, show?:any) {
    if(env === 'development' || show) {
        switch (messageType) {
            case 'success':
                toast.success(message);
                break;
            case 'info':
                toast.info(message);
                break;
            case 'warn':
                toast.warn(message);
                break;
            case 'error':
                toast.error(message);
                break;
            case 'dark':
                toast.dark(message);
                break;
            default:
                toast(message)
                break;
        }
    }

        return {
            type: SHOW_MESSAGE,
            messageType,
            options
        };


}

export function setNotificationMessage(message: string) {
    return {
        type: SET_NOTIFICATION_MESSAGE,
        message
    }
}
export function hideMessage() {
    return {
        type: HIDE_MESSAGE
    };
}
