// @ts-ignore
import {Janus} from 'janus-gateway';
import HoolvaUtils from "../../Services/HoolvaUtils";
import $ from "jquery";
import { history } from '../../App';
import routes from '../../routes.json';
import * as HoolvaActions from "./hoolva.action";
import {
    mixedAudioRemoteMuteIndication,
    mixedAudioTalkingAndStopTalking,
    setAudio,
    setIceState
} from "./meet.action";
import DetectRTC from "detectrtc";
import {showMessage} from "./notifyMessage.action";
import {setClientAuthData} from "./auth.action";
import {confirmAlert} from "react-confirm-alert";
export const SET_AUDIO_ROOM_PLUGIN_HANDLE = 'SET_AUDIO_ROOM_PLUGIN_HANDLE';
export const SET_CONNECTION_LOST = 'SET_CONNECTION_LOST';
export let connectedMedia = false;
export let isAudioMediaState = true;
export let isAudioIceRestart = false;
export let showSwitchNetWorkMessage = true;
export let isSwitchStunNeeded = false;

export function setAudioRoomPluginHandle(pluginHandle: any) {
    return {
        type: SET_AUDIO_ROOM_PLUGIN_HANDLE,
        pluginHandle
    }
}
export function resetIceRestartAudioRoom() {
    isAudioIceRestart = false;
}

export function setConnectionLost(lost: number) {
    return {
        type: SET_CONNECTION_LOST,
        lost
    }
}

export function attachMixedAudioPlugin(id: any, reconnect: boolean) {
    return async function(dispatch: any, getState: any) {
        const {hoolva, audio, video} = getState().Meet;
        if(reconnect) {
            connectedMedia = true;
        } else {
            connectedMedia = false;
        }

        showSwitchNetWorkMessage = true;
        const {meetingId} = getState().Hoolva
        hoolva.attach({
            plugin: 'janus.plugin.audiobridge',
            opaqueId: 'audiobridgetest-iL1USAYONGDy',
            room: +meetingId,
            participant_id: id,
            async success(pluginHandle: any) {
                hoolva.printLog({status: 'success-attach-audioroom'})
                await dispatch(setAudioRoomPluginHandle(pluginHandle));
                dispatch(registerParticipant(id));
            },
            error: function(error: any) {
                hoolva.printLog({status: 'error-attach-audioroom', error})
            },
            consentDialog(on: boolean) {
                HoolvaUtils.logger('consent Dialog mixed audio', on, 'success');
            },
            iceState: function(state: string) {
                hoolva.printLog({status: 'ice-state-change-audioroom', state})
                dispatch(setIceState(state));
                if(state === 'failed') {
                    isAudioIceRestart = true;
                }
            },
            slowLink(uplink: boolean, lost: number) {
                console.log('slow link data in audio bridge', uplink, lost);
                dispatch(setConnectionLost(lost))
            },
            mediaState: function(medium: string, on: boolean) {
                hoolva.printLog({status: 'media--state-audioroom', data: {message: "Audio room " + (on ? "started" : "stopped") + " receiving " + medium} })
                isAudioMediaState = on;
            },
            webrtcState: function(on: boolean, reason: string) {
                hoolva.printLog({status: 'webrtc-state-audioroom', data: {message: "WebRTC PeerConnection for audio room  is " + (on ? "up" : "down") + " now, reason " + reason} })
                if (reason === 'ICE failed' || reason === 'DTLS timeout' || reason === 'trickle') {
                    dispatch(setIceState('failed'));
                    isAudioIceRestart = true;
                }

            },
            onmessage: function(msg: any, jsep: any) {
                dispatch(onMessageMixedAudioPublisher(msg, jsep));
            },
            onlocalstream: function(stream: MediaStream) {
                HoolvaUtils.logger('audio room on local stream recieved we are not doing anything with this stream', stream, 'info');
            },
            onremotestream: function(stream: MediaStream, handle: any) {
                if(!connectedMedia)  {
                    const{publisherHandle} = getState().Meet;
                    if(publisherHandle) {
                        const data = { event: 'publishers_list' };
                        publisherHandle.send({message: data});

                    }
                    history.push(routes.MEETING);
                }
                connectedMedia = true;
                HoolvaUtils.logger('audio room  recived remote stream', stream, 'success');
                Janus.attachMediaStream($('#mixedAudio').get(0), stream);
                dispatch(HoolvaActions.attachSinkId( document.getElementById('mixedAudio'), getState().Meet.selectedOutput ))
            },
            oncleanup: function () {
                HoolvaUtils.logger(" ::: Got a cleanup notification :::", {}, 'warn');
            }
        })
    }
}

export function registerParticipant(id: any) {
    return async function(dispatch: any, getState: any) {
        const {meetingId, displayName} = getState().Hoolva;
        const {audioRoomHandle} = getState().AudioRoom;
        const register = { request: "join", room: +meetingId, display: displayName, id: id, quality: 7 };
        HoolvaUtils.logger('sending Join request audio room', register, 'success');
        await audioRoomHandle.send({message: register});
    }
}

export function onMessageMixedAudioPublisher(msg: any, jsep: any) {
    return async function(dispatch: any, getState: any) {
        const event = msg["audiobridge"];
        HoolvaUtils.logger('mixed audio message received', msg, 'success');
        if(event) {
            if(event === "joined") {
                HoolvaUtils.logger('Successfully joined in mixed audio', msg , 'success')
                dispatch(handleJoinedAudioRoom(msg));
                if(msg["participants"]) {
                    console.log('participant events', msg["participants"]);
                    const participantList = msg["participants"];
                    participantList.map((participant: any) => {
                        dispatch(mixedAudioRemoteMuteIndication(participant))
                    });
                }
            } else if(event === "roomchanged") {
                HoolvaUtils.logger('User need to switch room', msg, 'warn');
            } else if(event === "destroyed") {
                HoolvaUtils.logger('Room destroy please end this meeting and inform the user', msg, 'info');
            } else if(event === "event") {
                console.log('event msg checking mute unmute', msg);
                if(msg["result"] == "ok") {
                    console.log("result ok msg from audio room", msg, jsep)
                    if(jsep) {
                        isSwitchStunNeeded = true;
                    }
                }
                if(msg["participants"]) {
                    console.log('participant events', msg["participants"]);
                    const participantList = msg["participants"];
                    participantList.map((participant: any) => {
                        dispatch(mixedAudioRemoteMuteIndication(participant))
                    });
                } else if(msg["error"]) {
                    if(msg["error_code"] === 485) {
                        HoolvaUtils.logger('"<p>Apparently room <code>" + myroom + "</code> (the one this demo uses as a test room) " +\n' +
                            '\t\t\t\t\t\t\t\t\t\t\t\t\t\t"does not exist...</p><p>Do you have an updated <code>janus.plugin.audiobridge.jcfg</code> " +\n' +
                            '\t\t\t\t\t\t\t\t\t\t\t\t\t\t"configuration file? If not, make sure you copy the details of room <code>" + myroom + "</code> " +\n' +
                            '\t\t\t\t\t\t\t\t\t\t\t\t\t\t"from that sample in your current configuration file, then restart Janus and try again."', {}, 'error');
                    } else if(msg["error_code"] === 487) {
                        HoolvaUtils.logger('Not in room need to re_join in audio room', msg, 'error');
                        const {audioRoomHandle} = getState().AudioRoom;
                        const {publisherHandle} = getState().Meet;
                        await audioRoomHandle.detach();
                        dispatch(attachMixedAudioPlugin(publisherHandle.rfid, true));
                    } else {
                        HoolvaUtils.logger('Error happen !!!!', msg, 'error');
                    }
                    return;

                }
                if(msg["leaving"]) {
                    // One of the participants has gone away?
                    const leaving = msg["leaving"];
                    HoolvaUtils.logger("Participant left: " + leaving , msg, 'info');
                    // const participantsList = [...participants];
                    // const newList = participantsList.filter((participant => participant.id !== leaving));
                    // console.log('new participant list', newList);
                    // participants = [...newList];
                    // dispatch(setPublishersList(participants));`
                }
            } else if (event === 'talking') {
                console.log('audio bridge talking', msg)
                dispatch(mixedAudioTalkingAndStopTalking(msg));
            } else if (event === 'stopped-talking') {
                console.log('audio bridge stopped-talking', msg)
                dispatch(mixedAudioTalkingAndStopTalking(msg));
            }
        }
        if(jsep) {
            Janus.debug("Handling SDP as well...", jsep);
            await getState().AudioRoom.audioRoomHandle.handleRemoteJsep({ jsep: jsep });
        }
    }
}


export function handleJoinedAudioRoom(msg: any) {
    return async function (dispatch: any, getState: any) {
        const {audioRoomHandle} = getState().AudioRoom;
        const {audio, audioPermission} = getState().Meet;
        const {displayName, selectedAudio} = getState().Hoolva;
        if(msg["id"]) {
           //  dispatch(setCurrentMeetingInfo(msg));
            const mediaObj = { video: false, data: audioPermission === 'granted'? false : true,
                audioSend: audioPermission !== 'granted' ?  false : true,
                audioRecv: true,
                echoCancellationType: 'browser'
                }
                if(audioPermission === 'granted') {
                    // @ts-ignore
                    mediaObj.audio =  audioPermission === 'granted' ? selectedAudio !== '' && selectedAudio ? {
                        deviceId: selectedAudio,
                        echoCancellation: {exact: true},
                        noiseSuppression: {exact: true},
                        autoGainControl: {exact: true},
                        googNoiseSuppression: {exact: true},
                        googNoiseReduction: {exact: true}
                    } : {
                        echoCancellation: {exact: true},
                        noiseSuppression: {exact: true},
                        autoGainControl: {exact: true},
                        googNoiseSuppression: {exact: true},
                        googNoiseReduction: {exact: true}
                    }: false;
                }
            // const mediaObj = {audio: false}
            console.log('media object   audio room', mediaObj);
            audioRoomHandle.createOffer(
                {
                    media: mediaObj,	// This is an audio only room
                    audio_bridge: true,
                    success: function(jsep: any) {
                        audioRoomHandle.printLog({status: 'success-audio-room-create-offer'})
                        const publish = { request: "configure", muted: !audio };
                        if(!audio) {
                            audioRoomHandle.muteAudio();
                        }
                        audioRoomHandle.send({ message: publish, jsep: jsep });

                        HoolvaUtils.logger('Sending publish request', publish, 'success');
                    },
                    error: function(error: any) {
                        audioRoomHandle.printLog({status: 'error-audio-room-create-offer', error})
                        //  bootbox.alert("WebRTC error... " + error.message);
                    }
                });
        }
        if(msg["participants"]) {
          //  participants = msg["participants"];
            HoolvaUtils.logger('participant list in joined event', msg["participants"], 'success');
        }
        if(msg["id"]) {
         //   participants.push ({id: msg["id"], display: displayName, muted: audio})
        }
    }
}

export function iceAudioRestart(mode?: boolean) {
    return async function(dispatch: any, getState: any) {
        if(isAudioIceRestart || mode) {
            console.log('audio room ice restarting ...');
            isAudioIceRestart = false;
            const {audioRoomHandle} = getState().AudioRoom;
            const {selectedAudio} = getState().Hoolva;
            const {audio, audioPermission} = getState().Meet;
            DetectRTC.load(async function () {
                const mediaObj = { video: false, data: audioPermission === 'granted'? false : true,
                    audioSend: audioPermission !== 'granted' ?  false : true,
                    audioRecv: true,
                }
                if(audioPermission === 'granted') {
                    // @ts-ignore
                    mediaObj.audio =  audioPermission === 'granted' ? selectedAudio !== '' && selectedAudio? {
                        deviceId: selectedAudio,
                        echoCancellation: {exact: true},
                        noiseSuppression: {exact: true},
                        autoGainControl: {exact: true},
                        googNoiseSuppression: {exact: true},
                        googNoiseReduction: {exact: true}
                    } : {
                        echoCancellation: {exact: true},
                        noiseSuppression: {exact: true},
                        autoGainControl: {exact: true},
                        googNoiseSuppression: {exact: true},
                        googNoiseReduction: {exact: true}
                    }: false;
                }
                audioRoomHandle.createOffer({
                    iceRestart: true,
                    media: mediaObj,
                    echoCancellationType: 'browser',
                    audio_bridge: true,
                    success(jsep: any) {
                        audioRoomHandle.printLog({status: 'success-audio-room-ice-restart'})
                        const publish = { request: "configure", muted: !audio, restart: true, };
                        if(!audio) {
                            audioRoomHandle.muteAudio();
                        }
                        audioRoomHandle.send({ message: publish, jsep });

                    },
                    error(error: any) {
                        audioRoomHandle.printLog({status: 'error-audio-room-ice-restart', error})
                        dispatch(showMessage('error', 'error in ice restart audio room' , {}));
                    }
                });
            });
        }
    }
}

export function changeAudioDevice(deviceId?: any, added?: boolean) {
    return async function(dispatch: any, getState: any) {
        const {audioRoomHandle} = getState().AudioRoom;
        const {audio, selectedAudio, audioPermission} = getState().Meet;
        const {displayName } = getState().Hoolva;
        const mediaObj = { replaceAudio: true,  echoCancellationType: 'browser', data: audioPermission === 'granted'? false : true, video: false, audio: audioPermission === 'granted'? selectedAudio !== '' && selectedAudio ? {
                deviceId: deviceId ? deviceId : selectedAudio,
                echoCancellation: {exact: true},
                noiseSuppression: {exact: true},
                autoGainControl: {exact: true},
                googNoiseSuppression: {exact: true},
                googNoiseReduction: {exact: true},
            } : {
                echoCancellation: {exact: true},
                noiseSuppression: {exact: true},
                autoGainControl: {exact: true},
                googNoiseSuppression: {exact: true},
                googNoiseReduction: {exact: true}
            }: false};
        console.log('media object while change audio device', mediaObj);


        audioRoomHandle.createOffer(
            {
                media: mediaObj,	// This is an audio only room
                audio_bridge: true,
                success: function(jsep: any) {
                    audioRoomHandle.printLog({status: 'success-audio-room-change-device'})
                    const publish = { request: "configure", muted: (!audio || added === false) };
                    if(!audio || added === false) {
                        audioRoomHandle.muteAudio();
                    }
                    if(added === false) {
                        dispatch(showMessage("dark", "Your microphone is muted",{position: 'bottom-center'}, true))
                        dispatch(setAudio(false));
                    }
                    audioRoomHandle.send({ message: publish, jsep: jsep });
                    HoolvaUtils.logger('Sending publish request', publish, 'success');
                },
                error: function(error: any) {
                    audioRoomHandle.printLog({status: 'error-audio-room-change-device', error})
                    //  bootbox.alert("WebRTC error... " + error.message);
                }
            });
    }
}

export function switchStunAudio() {
    return async function(dispatch: any, getState: any) {
        const {t} = getState().Auth;
        if(isSwitchStunNeeded) {
            isSwitchStunNeeded = false;
            const {audioRoomHandle} = getState().AudioRoom;
            const priority = HoolvaUtils.getCurrentStun(audioRoomHandle);
            const currentProtocol =  priority >> 24;
            const currentIceServerAudio = [...getState().Auth.clientAuth.ice.iceServersAudio];
            console.log("currentIceServerAudio", [...getState().Auth.clientAuth.ice.iceServersAudio]);
            console.log("SET_CLIENT_AUTH1",  getState().Auth.globalClientAuth)
            let filteredIceServer: any[] = [];
            if(currentIceServerAudio.length > 1) {
                if (currentProtocol === 0) {
                    console.log("tls");
                    filteredIceServer = currentIceServerAudio.filter((iceServer: any) => {
                        return  !iceServer.urls.startsWith("turns")
                    })
                    console.log(filteredIceServer)


                } else if (currentProtocol === 1) {
                    console.log("tcp");
                    const turnServers = currentIceServerAudio.filter((iceServer: any) => {
                        return  !iceServer.urls.startsWith("turns")
                    });
                    const removedTcp = turnServers.filter((turn: any) => {
                        const data = turn.urls.split('?')[1];
                        return data !== "transport=tcp"
                    });
                    console.log("removedTcp", removedTcp)
                    filteredIceServer = [...removedTcp, ...currentIceServerAudio.filter((iceServer: any) => {
                        return  iceServer.urls.startsWith("turns")
                    })];
                } else if(currentProtocol === 2) {
                    console.log("udp");
                    const turnServers = currentIceServerAudio.filter((iceServer: any) => {
                        return  !iceServer.urls.startsWith("turns")
                    });
                    const removedUdp = turnServers.filter((turn: any) => {
                        const data = turn.urls.split('?')[1];
                        return data === "transport=tcp"
                    });
                    console.log("removedUdp", removedUdp)
                    filteredIceServer = [...removedUdp, ...currentIceServerAudio.filter((iceServer: any) => {
                        return  iceServer.urls.startsWith("turns")
                    })];
                } else if(currentProtocol === 100 || currentProtocol === 110) {;
                    if(currentProtocol === 100) {
                        console.log('srflx')
                    } else if(currentProtocol === 110) {
                        console.log('prflx')
                    }
                    filteredIceServer = currentIceServerAudio;
                }
                console.log("filteredIceServer", filteredIceServer);
                const currentClientAuth = getState().Auth.clientAuth;
                currentClientAuth.ice.iceServersAudio = filteredIceServer;
                console.log("currentClientAuth", currentClientAuth);
                await dispatch(setClientAuthData(currentClientAuth));
                console.log("get config", audioRoomHandle.webrtcStuff.pc.getConfiguration())
                const currentConfig = audioRoomHandle.webrtcStuff.pc.getConfiguration();
                currentConfig.iceServers = filteredIceServer;
                if(currentProtocol === 100) {
                    currentConfig.iceTransportPolicy = "realy";
                }
                console.log("stun switch needed. we are restarting ice with this stun configuration", currentConfig);
                audioRoomHandle.webrtcStuff.pc.setConfiguration(currentConfig);
                dispatch(iceAudioRestart(true))
            } else {
                console.log("no need to do anything");
                console.log("Hey user your network is unstable");
                isSwitchStunNeeded = true;
                if(showSwitchNetWorkMessage) {
                    confirmAlert({
                        title: t("Reconnecting.PoorNetwork"),
                        message: t("Reconnecting.NetworkUnstable"),
                        closeOnClickOutside: false,
                        buttons: [
                            {
                                label: t("schedule.Ok"),
                                onClick: async () => {

                                }
                            }
                        ]
                    });
                    showSwitchNetWorkMessage = false;
                } else {
                    dispatch(setConnectionLost(777))
                }

            }
        }

    }
}


