import React,{useState, useEffect} from "react";
import classes from './topBar.module.scss';
import cx from 'classnames';
import {makeStyles} from "@material-ui/styles";
import * as Actions from '../../../store/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from "../../../components/HoolvaButtons/IconButtons/iconButton";
import {useDispatch, useSelector} from "react-redux";
import Logo from "../../../components/Logo/Logo";
import ReactTooltip from "react-tooltip";
import * as Config from '../../../Config/config';
// import {Logo} from "../../../assets/logos/logo";
// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    fluid: {
        backgroundColor: theme.videoRoomTernaryColor
    },
    button: {
        backgroundColor: theme.videoRoomTernaryColor,
        border:"1px solid "+ theme.videoRoomTernaryColor,
        color: theme.iconColor,
        '&hover': {
            backgroundColor: theme.iconHoverBGColor,
            color: theme.iconHoverColor,
        }
    }
}))
const TopBar = (props: any) => {
    const theme = useStyles(props);
    const dispatch = useDispatch();
    const {chat, hideMenu, fluid, talking,shortMenuOpt, toggleFluid,meetingInfo,
        people, joinMode, time, fullSStatus, ToggleFullScreen,
        remoteScreenShareMode,setActiveScreen,setInfoBar,infoBar,activeScreen, activeScreenShare,
        setActiveScreenShare, screenShareParticipantList, t, cloudRecordingStatus, recordStatus, localCloudRecordingStatus} = props;
    const {localRecordState} = useSelector((state:any)=>state.Record);
    const {displayTheme} = useSelector((state: any) => state.Auth);
    const { tenantInfoJSON } = useSelector((state: any) => state.Settings);
    const [speaker, setSpeaker] = useState('');
    const [speakerLen, setSpeakerLen] = useState(0);
    const [temporal, setTemporal] = useState(1);
    const [pause, setPause] = useState(false);

    const {publisherHandle} = useSelector((state:any)=>state.Meet);


    useEffect(() => {
        if(screenShareParticipantList.length > speakerLen ){
            setSpeaker(screenShareParticipantList[screenShareParticipantList.length - 1].rfdisplay);
            setActiveScreenShare(screenShareParticipantList[screenShareParticipantList.length - 1].rfid)
            setActiveScreen(2);
        }else{
            const spr = screenShareParticipantList.filter((participant: any) => {
                return participant.rfid === activeScreenShare;
            })
            if(spr.length > 0){
                setSpeaker(spr[0].rfdisplay);
            }else if(screenShareParticipantList.length > 0){
                setSpeaker(screenShareParticipantList[screenShareParticipantList.length - 1].rfdisplay);
                setActiveScreenShare(screenShareParticipantList[screenShareParticipantList.length - 1].rfid)
            }
        }
        setSpeakerLen(screenShareParticipantList.length);
    }, [activeScreenShare, screenShareParticipantList]);

    const handleJoinModeState = (mode: string) => {
        console.log('mode', mode);
        // dispatch(Actions.setJoinMode(mode === 'locked' ? 'open' : 'locked'));
        dispatch(Actions.changeJoinState('set_join_mode', mode === 'locked' ? 'open' : 'locked'));
    }
    const handleInfo = (e: any) => {
        setInfoBar(!infoBar);
        e.stopPropagation();
      };

    const handleResolution = (res: number) => {
       // dispatch(Actions.changeResolutionRequest(res, temporal))
        dispatch(Actions.switchStunAudio());
    }
    const handleTemporalChange = (temp: string) => {
        setTemporal(+temp);
    }
    const pauseAndPlayVideo = () => {
        if(pause) {
            if(publisherHandle) {
                publisherHandle.unmuteVideo()
            }
        } else {
            if(publisherHandle) {
                publisherHandle.muteVideo()
            }
        }
        setPause(!pause);
    }


    return(
        <div className={cx(classes.TopBar,!fluid ? classes.fluid: '',fluid ? theme.fluid: '', chat || people ? activeScreen === 2 && !fluid ? fullSStatus ?  classes.Position:classes.LeftChatPosition: classes.Position:  activeScreen === 2 && !fluid ? fullSStatus ? null:classes.LeftPosition:'')}>
            <div className={cx(classes.start)}>
                {/* <span onClick={localRecordState? stopRecording : () =>startRecording()}
                      style={{textTransform:'capitalize', color:'orangered', cursor:'pointer'}}  className={cx(classes.recording)}>
                    {localRecordState? 'Stop' : "Start"}
                </span> */}
                {/*<span onClick={() => handleResolution(0)}*/}
                {/*    style={{textTransform:'capitalize', color:'orangered', cursor:'pointer', width: 30}}  className={cx(classes.recording)}>*/}
                {/*    Switch*/}
                {/*</span>*/}
                {/*<span onClick={() => handleResolution(1)}*/}
                {/*      style={{textTransform:'capitalize', color:'orangered', cursor:'pointer', width: 30}}  className={cx(classes.recording)}>*/}
                {/*    1*/}
                {/*</span>*/}
                {/*<span onClick={() => handleResolution(2)}*/}
                {/*      style={{textTransform:'capitalize', color:'orangered', cursor:'pointer', width: 30}}  className={cx(classes.recording)}>*/}
                {/*    2*/}
                {/*</span>*/}
                {/*<input type="text" value={temporal} style={{width: 30}} onChange={(event) => handleTemporalChange(event.target.value)} />*/}
                {/*<span className={cx(classes.InfoSpan)} onClick={pauseAndPlayVideo}>*/}
                {/*    {pause ? '▶️' : '⏸'}*/}
                {/*</span>*/}
                {hideMenu ?
                <span style={{display: 'inline-flex'}} data-for={"MeetingInfo"}
                data-tip={"Meeting Info"} onClick={(e) => handleInfo(e)}>
                <Logo classname={classes.HoolvaLogo} topBar={true} displayTheme={displayTheme} />
                <ReactTooltip
                    id={"MeetingInfo"}
                    getContent={() => { return "Meeting Info" }}
                    className="extraClass"
                    delayHide={0}
                    effect="solid"
                />
                </span>
                :null}
                {meetingInfo && !meetingInfo.e2ee ?
                    hideMenu ?
                        meetingInfo?.host || false ? <IconButton
                            classname={[classes.button,classes.exit,theme.button]}
                            tooltipId="lockRoom"
                            clicked={() => handleJoinModeState(joinMode)}
                            tooltipText={joinMode === 'locked' ? t('meetingPage.topBar.openMeeting'):t('meetingPage.topBar.lockMeeting')}
                            iconName={joinMode === 'locked' ? 'lock-alt':'lock-open-alt'}
                            iconType={'fas'}
                        />
                        : null
                    : null
                :<IconButton
                    classname={[classes.button,classes.exit,theme.button]}
                    tooltipId="Encrypted"
                    tooltipText='Meeting is Encrypted'
                    iconName='user-lock'
                    iconType={'fas'}
                />
                }
                {!shortMenuOpt ? (
                    talking[0]?.rfdisplay ?
                        <span className={cx(classes.talking,classes.button, theme.button)}>
                            <span className={cx(classes.talkingName)}>{`${talking[0].rfdisplay}`}</span> is talking</span>: null
                ):null}
            </div>
            <div  className={cx(classes.devStatus)} >
                {/*{env === 'development' ? (<span style={{textTransform:'capitalize', color:'orangered', cursor:'pointer'}} title={iceState} className={cx(classes.recording1)}>*/}
                {/*    {`${iceState.substring(0, 1)}`}*/}
                {/*</span>) : null}*/}

                {/*<span onClick={() => handleResolution(2)}*/}
                {/*    style={{textTransform:'capitalize', color:'orangered', cursor:'pointer'}}  className={cx(classes.recording)}>*/}
                {/*    H*/}
                {/*</span>*/}
                {/*<span onClick={() => handleResolution(1)}*/}
                {/*    style={{textTransform:'capitalize', color:'orangered', cursor:'pointer'}}  className={cx(classes.recording)}>*/}
                {/*    M*/}
                {/*</span>*/}
                {/*<span onClick={() => handleResolution(0)}*/}
                {/*      style={{textTransform:'capitalize', color:'orangered', cursor:'pointer'}}  className={cx(classes.recording)}>*/}
                {/*    L*/}
                {/*</span>*/}
            </div>
            <div className={cx(classes.mid)}>

                {screenShareParticipantList.length > 0 && activeScreen === 2 ? (
                    <span className={cx(classes.screenShareList,theme.button)}>
                        <div  className={screenShareParticipantList.length > 1 ? classes.dropdown: ''}>
                            <span className={cx(classes.ScreenShare)}>
                                {`${speaker.substring(0, 15)}`}'s screen
                                {screenShareParticipantList.length > 1 ? (
                                <span className={cx(classes.dropArrow)}>
                                    <FontAwesomeIcon icon={['fal', 'angle-down']} className={cx(classes.avatar)}/>
                                </span>):null}
                            </span>
                            <div className={classes.dropDownContent}>
                                {screenShareParticipantList.filter((participant: any) => {
                                    return participant.rfid !== activeScreenShare;
                                }).map((participant: any) => {
                                    return (
                                        <span key={participant.rfid} onClick={()=>setActiveScreenShare(participant.rfid)}>
                                                         {`${participant.rfdisplay.substring(0, 15)}`}'s screen
                                        </span>
                                    )
                                })}
                            {/*{screenShareParticipantList.filter((participant: any) => {*/}
                            {/*    return participant.id !== activeScreenShare;*/}
                            {/*}).map((participant: any) => {*/}
                            {/*    return (*/}
                            {/*        <span key={participant.id} onClick={()=>setActiveScreenShare(participant.id)}>*/}
                            {/*            {`${participant.rfdisplay.substring(0, 15)}`}'s screen*/}
                            {/*        </span>*/}
                            {/*    )*/}
                            {/*})}*/}
                            </div>
                        </div>
                    </span>
                ):null}
            </div>
            <div className={cx(classes.end, chat || people ? classes.rightOpen:'')}>
                {hideMenu && (cloudRecordingStatus === 'start' || localCloudRecordingStatus=== 'start' || localRecordState || recordStatus === 'start') ? (
                    <span className={cx(classes.recording)}>
                    <FontAwesomeIcon icon={['fas', 'record-vinyl']} style={{color: "red"}} />
                    Rec
                </span>
                ) : null}
                {hideMenu && !shortMenuOpt  ? (
                    <span className={cx(classes.recording1,theme.button)}>
                        {time}
                    </span>
                ):null}
                {hideMenu ?
                <IconButton
                    classname={[classes.button,classes.Exit,theme.button]}
                    tooltipId="Speaker"
                    clicked={()=>setActiveScreen(activeScreen === 1 ? 2: 1)}
                    tooltipText={activeScreen === 1 ? remoteScreenShareMode ? t('meetingPage.topBar.screenShareView'):t('meetingPage.topBar.speakerView'):t('meetingPage.topBar.galleryView')}
                    iconName={activeScreen === 1 ? 'window-alt':'th-large'}
                    iconType={activeScreen === 1 ? 'far':'fas'}
                />:null}
                {hideMenu && !shortMenuOpt ? (
                    <IconButton
                        classname={[classes.button,classes.Sticky,theme.button]}
                        tooltipId="Sticky"
                        clicked={() => toggleFluid(!fluid)}
                        tooltipText={fluid? t('meetingPage.topBar.floatMode'): t('meetingPage.topBar.stickyMode')}
                        iconName={fluid? 'sticky-note':'layer-group'}
                        iconType={'fas'}
                    />
                ):null}
                {hideMenu ?
                    <IconButton
                        classname={[classes.button,classes.Full,theme.button]}
                        tooltipId="FullScreen"
                        clicked={() => ToggleFullScreen(!fullSStatus)}
                        tooltipText={fullSStatus? t('meetingPage.topBar.restore'): t('meetingPage.topBar.fullScreen')}
                        iconName={fullSStatus? 'compress':'expand'}
                        iconType={'fal'}
                    />
                    :null}


            </div>
        </div>
    )
}

export default React.memo(TopBar);
