import React, {useState, useEffect, useRef, useCallback} from 'react';
import classes from './settings.module.scss';
import {makeStyles} from "@material-ui/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {debounce} from 'lodash';
import cx from 'classnames';
import DetectRTC from "detectrtc";
import {useSelector, useDispatch} from "react-redux";
import * as Actions from '../../store/actions';
import * as Config from '../../Config/config';
import Lite from '../../assets/images/lite.png';
import Dark from '../../assets/images/dark.png';
import {version, buildNumber, env} from '../../../package.json'
import LocalDb from "../../../src/Services/dbService";
import { updateUserInfo } from '../../Services/apiService';
import RegularButtons from "../HoolvaButtons/RegularButtons/regularButtons";
import i18n from '../../i18n';
import Cropper from 'react-easy-crop'
import { getCroppedImg } from './ImageUtils'
import Logo from "../Logo/Logo";
// @ts-ignore
import audioTest from '../../assets/audio/soundTest.mp3';
import { Point, Area } from "react-easy-crop/types";
import SoundMeter from "../../Services/soundmeter";
import Avatar from 'react-avatar';

    import ReactCrop from 'react-image-crop';
    import 'react-image-crop/dist/ReactCrop.css';
let checkVideoDevice = true;

// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    bgColor:{
        backgroundColor: theme.background,
        color: theme.textColor1
    },
    inputText: {
        backgroundColor: theme.textboxBGColor,
        border: '1px solid ' + theme.textboxBorderColor,
        color: theme.textboxTextColor,
        "&:hover, &:focus, &:active": {
            border: "1px solid "+ theme.textboxActiveColor,
        },
        '&::placeholder': {
            color:theme.textboxPlaceholderColor
        }
    },
    borderBottom:{
        borderBottom: "1px solid " + theme.dropdownBorderColor,
    },
    btnPrimary: {
        background: theme.button1BGColor,
        border: "1px solid "+ theme.button1BorderColor,
        color: theme.button1TextColor,
        "&:hover, &:focus": {
            background: theme.button1HoverBGColor
        }
    },
    btnSecondary: {
        background: theme.button2BGColor,
        border: "1px solid "+ theme.button2BorderColor,
        color: theme.button2TextColor,
        "&:hover, &:focus": {
            color: theme.button2HoverTextColor
        }
    },
    playButton:{
        border: "1px solid "+ theme.button2BorderColor,
    },
    btnTernary: {
        background: theme.button3BGColor,
        border: "1px solid "+ theme.button3BGColor,
        color: theme.button3TextColor,
        "&:hover, &:focus": {
            border: "1px solid "+ theme.button3HoverBorderColor,
        }
    }
}))
interface Device {
    deviceId: string,
    groupId: string,
    id: string,
    kind: string,
    label: string
};
let myStream: any = null;
let meterRefresh: any = [];
let mediaRecorder: any = null;
let timer: any = null;
let _mounted = false;

const SettingsModal = (props: any) => {

    const { settingsModal, setSettingsModal, displayName, changeDisplayName, nameChange, t , handleToggleFluid} = props;
    const { dbStore, displayTheme, loggedInData } = useSelector((state:any)=>state.Auth);
    const {displayLayout,displayLanguage, dynamicBG, sliderTileLength} = useSelector((state:any)=>state.Settings);
    const {audio, video, selectedAudio, selectedVideo,selectedOutput, publishersList, isWebCam} = useSelector((state:any)=>state.Meet);
    const {meetingInfo,  audioDevices, API_ROOT, videoDevices} = useSelector((state:any)=>state.Hoolva);
    const theme = useStyles(props);
    const videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>;
    const dispatch = useDispatch();
    const [display, setDisplay] = useState('');
    const [hoverTheme, setHoverTheme] = useState(displayTheme);
    const [display2, setDisplay2] = useState('');
    const [activeTab, setActiveTab] = useState(1);
    const [tab3LoadStatus, setTab3LoadStatus] = useState(false);
    const [valid, setValid] = useState(false);
    const languages = [{key:'en',value:'English'},{key:'ml',value:'Malayalam'},{key:'uz',value:'Uzbek'},{key:'ar',value:'Arabic'},{key:'fr',value:'French'}];
    const [audioInputDevices, setAudioInputDevices] = useState<Device[]>([]);
    const [audioOutputDevices, setAudioOutputDevices] = useState<Device[]>([]);
    const [videoInputDevices, setVideoInputDevices] = useState<Device[]>([]);
    const [testAudioState, setTestAudioState] = useState(false);
    const [audioLevel, setAudioLevel] = useState(0);
    const [recordState, setRecordState] = useState(false);
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0)
    const [drop, setDrop] = useState(true)
    const [value, setValue] = useState(false)
    const [croppedImage, setCroppedImage] = useState('');
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>()

    // const [src,selectFile] = useState(null)
    //@ts-ignore
    // const handleFileChange = e =>{
    // //@ts-ignore
    //  selectFile(URL.createObjectURL(e.target.files[0]))
        
    // }
    const [image,setImage] = useState(null)
    // const [crop,setCrop] = useState( {aspect:16/9 ,unit:'px', x:130,y:50,width:200,height:200})
    // const [crop, setCrop] = useState({ x: 0, y: 0 })

    const [imageSrc, setImageSrc] = useState('')
    const [actualImage, setActualImage] = useState(false);
    //@ts-ignore
      

    const [crop, setCrop] = useState({
        unit: "%",
        width: 30,
        aspect: 5 / 5
      })
      const [src, setSrc] = useState<any>();
      const [imageRef, setImageRef] = useState<any>();
      const [croppedImageUrl, setCroppedImageUrl] = useState<any>();
      const onImageLoaded = (image:any) => {
        setImageRef(image);
      };
      const onCropComplete = (crop:any) => {
        makeClientCrop(crop);
      };
      async function makeClientCrop(crop:any) {
        if (imageRef && crop.width && crop.height) {
          const croppedImageUrl = await getCroppedImg(
            imageRef,
            crop,
            'newFile.jpeg'
          );
          if(croppedImageUrl){
            console.log("croppedImageUrl",croppedImageUrl)
            getBase64FromUrl(croppedImageUrl).then((data:any) =>{
              console.log("111111111", data);
              setCroppedImageUrl(data);
            });
          }
          
        }
      }
      const getBase64FromUrl = async (url:any) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            const base64data = reader.result;   
            resolve(base64data);
          }
        });
      }
      async function  getCroppedImg  (image:any, crop:any, fileName:any) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = await canvas.getContext('2d');
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        console.log("ctxctxctxctx",ctx)
        if(ctx){
          ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          ctx.imageSmoothingQuality = 'high';
      
          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
          );
          return new Promise((resolve, reject) => {
            canvas.toBlob(
              (blob:any) => {
                if (!blob) {
                  //reject(new Error('Canvas is empty'));
                  console.error('Canvas is empty');
                  return;
                }
                blob.name = fileName;
                let fileUrl = '';
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
              },
              'image/jpeg',
              1
            );
          });
        }
      }
      const onCropChange = (crop:any, percentCrop:any) => {
        crop.percentCrop = percentCrop;
        setCrop(crop);
      };
       const onSelectFile = (e:any) => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener('load', () =>{
            setSrc(reader.result);
          });
          reader.readAsDataURL(e.target.files[0]);
        }
        setActualImage(true);
      };
    const [audioIndicators, setaudioIndicators] = useState([
        {id: 1, active: false},
        {id: 2, active: false},
        {id: 3, active: false},
        {id: 4, active: false},
        {id: 5, active: false},
        {id: 6, active: false},
        {id: 7, active: false},
        {id: 8, active: false},
        {id: 9, active: false},
        {id: 10, active: false},
    ]);
    useEffect(() => {
        document.addEventListener("deviceSelectionChange", handleDeviceSelectionChange);
        return (() => {
            document.removeEventListener("deviceSelectionChange", handleDeviceSelectionChange);
        })

    }, [activeTab])

    const handleDeviceSelectionChange = (event: any) => {
        console.log("handleDeviceSelectionChange settings Page", event.detail.data);
        if(activeTab === 3) {
            if(event.detail.data && event.detail.data.kind === "videoinput") {
                generateAudioVideoStream(DetectRTC.hasMicrophone, DetectRTC.hasMicrophone ? (selectedAudio === ''|| !selectedAudio) ? DetectRTC.audioInputDevices[0].deviceId:  getDeviceId(selectedAudio, 'audio') : '',
                    DetectRTC.hasWebcam, DetectRTC.hasWebcam? event.detail.data.deviceId : '',
                    getVideoConstraints(), false);
            } else if(event.detail.data && event.detail.data.kind === "audioinput") {
                generateAudioVideoStream(DetectRTC.hasMicrophone, DetectRTC.hasMicrophone ? event.detail.data.deviceId : '',
                    DetectRTC.hasWebcam, DetectRTC.hasWebcam? (selectedVideo === '' || !selectedVideo) ? DetectRTC.videoInputDevices[0].deviceId: getDeviceId(selectedVideo, 'video') : '',
                    getVideoConstraints(), false);
            }
        }
    }

    const handleDeviceChange = debounce(event => {
        console.log('Active TAVBBBBBBBB', activeTab);
        if(activeTab ===3) {
            inItDetectRtc();
        }
    }, 700)

    useEffect(() => {
        setActiveTab(1)
        setDisplay(displayName);
        setDisplay2(displayName);
        setHoverTheme(displayTheme);
    }, [settingsModal]);
    useEffect(() => {
        if(activeTab === 3) {
            setTab3LoadStatus(true);
        }else if(tab3LoadStatus){
            console.log("tab3LoadStatus",tab3LoadStatus);
            setTab3LoadStatus(false);
        }
    }, [activeTab]);

    useEffect(() => {
        _mounted = true;

        return(() => {
            unloadStream()
            _mounted = false;
        })
    }, []);
    useEffect(() => {
        let level = audioLevel/10;
        let updatedAudioIndicators = audioIndicators.map(indicator => {
            if(indicator.id <= level){
                return {...indicator, active: true};
            }
            else {
                return {...indicator, active: false};
            }
        });
        console.log('level', level);
        setaudioIndicators(updatedAudioIndicators);
    },[audioLevel]);

    const checkRecordAudioTestState = () => {
        var elem = document.getElementById('audioRecord');
        if(elem) {
            // @ts-ignore
            return !elem.paused;
        }

    }
   
    const handleSaveProfileImage = () => {
        const uuid = loggedInData.token
           const userdata = { token: uuid, profile_picture: croppedImageUrl };
            console.log("handleSaveProfileImage", loggedInData, userdata)
            userdata.profile_picture = croppedImageUrl;
            updateUserInfo(API_ROOT, userdata, uuid)
                    .then((response:any) => {
                        const data = { ...loggedInData }
                        data.profile_picture = croppedImageUrl;
                        dispatch(Actions.setLoginInfo(data));
                        setActualImage(false);
                        //  dispatch(showMessage({ message: t('EDITPROFILEIMAGE_SUCCESS'), variant: 'success' }));
                    })
                    .catch((error:any) => {
                        // dispatch(showMessage({ message: error.response.statusText, variant: 'error' }));
                    });
    }
    const handleUploadCancel = () => {
        setActualImage(false);
    }
    const unloadStream = () => {
        if(myStream) {
            myStream.getTracks().forEach(function(track: MediaStreamTrack) {
                track.stop();
            });
            // @ts-ignore
            if(window.soundMeter) {
                // @ts-ignore
                window.soundMeter.stop();
                setAudioLevel(0);
            }
            // @ts-ignore
            if(meterRefresh) {
                // @ts-ignore
                meterRefresh.map(refresh => {
                    if(refresh) {
                        clearInterval(refresh);
                    }
                });
            }
            myStream = null;
        }
        if(mediaRecorder) {
            if(recordState) {
                mediaRecorder.stop();
                setRecordState(false);
                mediaRecorder = null;
            }
        }
    }


    const toggleTheme = (key:string) => {
        dispatch(Actions.setTheme(key));
        LocalDb.getDisplaySettings(dbStore, (data: any) => {
            if(data.data.length === 0) {
                LocalDb.setDisplaySettings(dbStore, {theme: Config.configuration.theme,
                    layout:Config.configuration.screenLayout,
                    language:Config.configuration.language,
                    dynamicBG:Config.configuration.dynamicBackgrounds,
                    sliderLength:Config.configuration.maxTileLimit,
                    type: 'display'}, (data: any) => {
                    console.log('data from local db insert theme', data);
                });
            }else{
                LocalDb.updateDisplaySettings(dbStore, {theme: key, layout:data.data[0].layout,language:data.data[0].language, dynamicBG:data.data[0].dynamicBG, sliderLength:data.data[0].sliderLength, _id:data.data[0]._id}, (data: any) => {
                    console.log('data from local db update theme', data);
                })
            }
        });
    }
    const HoverTheme = (key:string) => {
        if(key === ''){
            setHoverTheme(displayTheme);
        }else{
            setHoverTheme(key);
        }
    };
    const ToggleFluid = (key:string) => {
        handleToggleFluid(key === 'float' ? true:false);
    };
    const closeModal = () => {
        tabToggle(1);
        setSettingsModal(false);
    };

    const TogglesliderLength = (key:string) => {
        dispatch(Actions.setSliderTileLength(+key));
        LocalDb.getDisplaySettings(dbStore, (data: any) => {
            if(data.data.length === 0) {
                LocalDb.setDisplaySettings(dbStore, {theme: Config.configuration.theme,
                    layout:Config.configuration.screenLayout,
                    language:Config.configuration.language,
                    dynamicBG:Config.configuration.dynamicBackgrounds,
                    sliderLength:Config.configuration.maxTileLimit,
                    type: 'display'}, (data: any) => {
                    console.log('data from local db insert sliderLength', data);
                });
            }else{
                LocalDb.updateDisplaySettings(dbStore, {theme: data.data[0].theme, layout:data.data[0].layout,language:data.data[0].language, dynamicBG:data.data[0].dynamicBG, sliderLength:+key, _id:data.data[0]._id}, (data: any) => {
                    console.log('data from local db update sliderLength', data, key);
                })
            }
        });
    };
    const toggleChecked = (checked: boolean) => {
        dispatch(Actions.setDynamicBG(checked));
        LocalDb.getDisplaySettings(dbStore, (data: any) => {
            if(data.data.length === 0) {
                LocalDb.setDisplaySettings(dbStore, {theme: Config.configuration.theme,
                    layout:Config.configuration.screenLayout,
                    language:Config.configuration.language,
                    dynamicBG:Config.configuration.dynamicBackgrounds,
                    sliderLength:Config.configuration.maxTileLimit,
                    type: 'display'}, (data: any) => {
                    console.log('data from local db insert DynamicBG', data);
                });
            }else{
                LocalDb.updateDisplaySettings(dbStore, {theme: data.data[0].theme, layout:data.data[0].layout,language:data.data[0].language, dynamicBG:checked, sliderLength:data.data[0].sliderLength, _id:data.data[0]._id}, (data: any) => {
                    console.log('data from local db update DynamicBG', data, checked);
                })
            }
        });

    }
    const ToggleLanguage = (key:string) => {
        dispatch(Actions.setLanguage(key));
        i18n.changeLanguage(key);
        LocalDb.getDisplaySettings(dbStore, (data: any) => {
            if(data.data.length === 0) {
                LocalDb.setDisplaySettings(dbStore, {
                    theme: Config.configuration.theme,
                    layout:Config.configuration.screenLayout,
                    language:Config.configuration.language,
                    dynamicBG:Config.configuration.dynamicBackgrounds,
                    sliderLength:Config.configuration.maxTileLimit,
                    type: 'display'
                }, (data: any) => {
                    console.log('data from local db insert Language', data);
                });
            }else{
                LocalDb.updateDisplaySettings(dbStore, {theme: data.data[0].theme, layout:data.data[0].layout,language:key, dynamicBG:data.data[0].dynamicBG, sliderLength:data.data[0].sliderLength, _id:data.data[0]._id}, (data: any) => {
                    console.log('data from local db update Language', data, key);
                })
            }
        });
    };

    const tabToggle = (key: any) => {
        if(key === 3) {
            setTab3LoadStatus(true);
            inItDetectRtc();
            console.log("Settings stream Init", myStream);
        }else {
            if (
                myStream &&
                myStream.getVideoTracks() &&
                myStream.getVideoTracks().length > 0
            ) {
                // Video has been rejected
                console.log("Settings stream remove before", myStream);
                myStream.getTracks().forEach(function (track: MediaStreamTrack) {
                    track.stop();
                });
                console.log("Settings stream remove after", myStream);
            }
            setTab3LoadStatus(false);
        }
        setActiveTab(key);
    }
    const inItDetectRtc = () => {
        DetectRTC.load(async function () {
            dispatch(Actions.setDetectRtcState(DetectRTC));
            await setAudioInputDevices(DetectRTC.audioInputDevices);
            if(DetectRTC.audioInputDevices.length > 0) {
                if(selectedAudio === '' || !selectedAudio) {
                    console.log("setDefaultAudioDevice 7")
                    await dispatch(Actions.setDefaultAudioDevice(DetectRTC.audioInputDevices[0].deviceId, DetectRTC.audioInputDevices[0].label));
                } else {
                    let deviceName = '';
                    let detectCurrentAudioDevice = false;
                    DetectRTC.audioInputDevices.map(device => {
                        if(device.deviceId === selectedAudio) {
                            deviceName = device.label;
                            detectCurrentAudioDevice = true
                        }
                    });
                    if(detectCurrentAudioDevice) {
                        console.log("setDefaultAudioDevice 8")
                        // console.log('audio device name 2', deviceName, selectedAudio);
                        await dispatch(Actions.setDefaultAudioDevice(selectedAudio, deviceName));
                    } else {
                        console.log("setDefaultAudioDevice 9")
                        console.log('current audio device not found');
                        await dispatch(Actions.setDefaultAudioDevice(DetectRTC.audioInputDevices[0].deviceId, DetectRTC.audioInputDevices[0].label));
                    }
                }
            }
            await setVideoInputDevices(DetectRTC.videoInputDevices);
            if(DetectRTC.videoInputDevices.length > 0) {
                if (selectedVideo === '' || !selectedVideo) {
                    await dispatch(Actions.setDefaultVideoDevice(DetectRTC.videoInputDevices[0].deviceId, DetectRTC.videoInputDevices[0].label));
                } else {
                    let deviceName = '';
                    let detectCurrentVideoDevice = false;
                    DetectRTC.videoInputDevices.map(device => {
                        if(device.deviceId === selectedVideo) {
                            deviceName = device.label;
                            detectCurrentVideoDevice = true;
                        }
                    });
                    if(detectCurrentVideoDevice) {
                        await dispatch(Actions.setDefaultVideoDevice(selectedVideo, deviceName));
                    } else {
                        await dispatch(Actions.setDefaultVideoDevice(DetectRTC.videoInputDevices[0].deviceId, DetectRTC.videoInputDevices[0].label));
                    }
                }

            }
            await setAudioOutputDevices(DetectRTC.audioOutputDevices);
            if (DetectRTC.audioOutputDevices.length > 0) {
                if (selectedOutput === '' || !selectedOutput) {
                    await dispatch(Actions.setDefaultOutputDevice(DetectRTC.audioOutputDevices[0].deviceId, DetectRTC.audioOutputDevices[0].label))
                } else {
                    let deviceName = '';
                    DetectRTC.audioOutputDevices.map(device => {
                        if(device.deviceId === selectedOutput) {
                            deviceName = device.label;
                        }
                    });
                    console.log('output 3', deviceName, 'ooo', selectedOutput);
                    await dispatch(Actions.setDefaultOutputDevice(selectedOutput, deviceName));
                }
            }
            generateAudioVideoStream(DetectRTC.hasMicrophone, DetectRTC.hasMicrophone ? (selectedAudio === ''|| !selectedAudio) ? DetectRTC.audioInputDevices[0].deviceId:  getDeviceId(selectedAudio, 'audio') : '',
                DetectRTC.hasWebcam, DetectRTC.hasWebcam? (selectedVideo === '' || !selectedVideo) ? DetectRTC.videoInputDevices[0].deviceId: getDeviceId(selectedVideo, 'video') : '',
                getVideoConstraints(), false);
        });
    }
    const checkDeviceFound = (audioDeviceId: string, videoDeviceId: string) => {
        let detectVideoDevice = false;
        let detectAudioDevice = false;
        DetectRTC.videoInputDevices.map(device => {
            if(device.deviceId === videoDeviceId) {
                detectVideoDevice = true
            }
        })
        DetectRTC.audioInputDevices.map(device => {
            if(device.deviceId === audioDeviceId) {
                detectAudioDevice = true
            }
        })
        console.log('checkDeviceFound', audioDeviceId, videoDeviceId, detectAudioDevice, detectVideoDevice);
        return detectAudioDevice && detectVideoDevice
    }
    const getDeviceId = (deviceId: string, type: string) => {
        let detectDevice = false;
        if(type === 'video') {
            DetectRTC.videoInputDevices.map(device => {
                if(device.deviceId === deviceId) {
                    detectDevice = true
                }
            })

        }
        if(type === 'audio') {
            DetectRTC.audioInputDevices.map(device => {
                if(device.deviceId === deviceId) {
                    detectDevice = true
                }
            })
        }
        return detectDevice ? deviceId : type === 'video' ? DetectRTC.videoInputDevices[0].deviceId :  DetectRTC.audioInputDevices[0].deviceId
    }

    const getVideoConstraints = () => {
        return {width: { ideal: 4096 },
            height: { ideal: 2160 },
            aspectRatio: {exact : 1.777777778}}
        // return {width: {exact: Config.configuration.videoResolution.width}, height: {exact: Config.configuration.videoResolution.height}, aspectRatio: 1.777777778}
    }
    const handleDisplayNameChange = (name: string) => {
        setDisplay(name)
        if(name.trim().length >= 3 && display2 != name) {
            setValid(true)
        } else {
            setValid(false);
        }
    }
    const handleVideoDeviceChange = async(deviceId: string) => {
        console.log('Local Video Change State', video);
        await dispatch(Actions.setDefaultVideoDevice(deviceId , ''));
        await generateAudioVideoStream(true, selectedAudio, true, deviceId, getVideoConstraints(), true);
        // if(video){
        //     await dispatch(Actions.changeVideo(video,'video'));
        // }
    }
    const handleAudioDeviceChange = async (deviceId: string) => {
        console.log('On Audio device change deviceid', deviceId);
        console.log("setDefaultAudioDevice 10")
        await dispatch(Actions.setDefaultAudioDevice(deviceId, ''));
        await dispatch(Actions.changeVideo(video,'audio'));
    }
    const handleOutputDeviceChange = (deviceId: string) => {
        console.log('On change deviceid', deviceId);
        dispatch(Actions.setDefaultOutputDevice(deviceId, ''));
        if(meetingInfo.audio_bridge) {
            dispatch(Actions.attachSinkId(document.getElementById('mixedAudio'), deviceId))
        } else {
            publishersList.map((participant:any) => {
                return dispatch(Actions.attachSinkId(document.getElementById('video_' + participant.id), deviceId))
            });
        }

    }
    const generateAudioVideoStream = (audioState: boolean, audioDeviceId: string, videoState: boolean, videoDeviceId: string, videoObj: any, mode: boolean) => {
        const mediaObj = {
            audio: audioState? audioDeviceId !== '' ?
                { deviceId: audioDeviceId } : true : false,
            video: videoState ? videoDeviceId !== '' ?
                {deviceId: videoDeviceId, ...videoObj} :
                videoObj : false
        }
        console.log('media obj', mediaObj)
        navigator.mediaDevices
                    .getUserMedia(mediaObj)
            .then(async stream => {
                checkVideoDevice = true;
                dispatch(Actions.setIsWebCam(true));
                if(myStream) {
                    console.log("Settings stream Stop",myStream);
                    myStream.getTracks().forEach(function (track: MediaStreamTrack) {
                        track.stop();
                    });
                }
                // dispatch(Actions.setVideo(videoState));
                myStream = stream
                if(videoState) {
                    await dispatch(Actions.setVideoResolutions(videoObj.width.exact, videoObj.height.exact))
                }
                if(video && mode) {

                    dispatch(Actions.changeVideo(video,'video'));
                }
                console.log("Settings stream Active",myStream);
                videoRef.current.srcObject = stream;
                if((DetectRTC.browser.isSafari && DetectRTC.browser.version>=14) || !DetectRTC.browser.isSafari) {
                    try {
                        // @ts-ignore
                        window.AudioContext = window.AudioContext || window.webkitAudioContext;
                        // @ts-ignore
                        window.audioContext = new AudioContext();
                        if(myStream.getAudioTracks() && myStream.getAudioTracks().length === 0) {
                            console.log('No Audio track found in checking audioLevel');
                        } else {
                            // @ts-ignore
                            const soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
                            soundMeter.connectToSource(stream, function (e: any) {
                                if (e) {
                                    // alert(e);
                                    return;
                                }
                                meterRefresh.push(setInterval(() => {
                                    if (_mounted) {
                                        // @ts-ignore
                                        setAudioLevel(Math.round(((soundMeter.instant.toFixed(2) * 2) * 100)));

                                    }

                                }, 200));
                            });
                        }
                    } catch (e) {
                        alert('Web Audio API not supported.');
                    }
                }
                //record audio
                // @ts-ignore
                let dataArray = []
                // @ts-ignore
                mediaRecorder = new MediaRecorder(stream);
                //mediaRecorder.start();
                mediaRecorder.ondataavailable = function (ev: any) {
                    dataArray.push(ev.data);
                }
                mediaRecorder.onstart = function (ev: any) {
                    timer = setTimeout(() => {
                        stopRecord();
                    }, 15000)
                }
                mediaRecorder.onstop = function (ev: any) {
                    // blob of type mp3
                    //@ts-ignore
                    let audioData = new Blob(dataArray,
                        { 'type': 'audio/mp3;' });
                    // After fill up the chunk
                    // array make it empty
                    dataArray = [];
                    // Creating audio url with reference
                    // of created blob named 'audioData'
                    let audioSrc = window.URL
                        .createObjectURL(audioData);
                    setTestAudioState(false);
                    //@ts-ignore
                    document.getElementById("audioTest").pause();
                    //@ts-ignore
                    document.getElementById("audioTest").currentTime = 0;
                    // Pass the audio url to the 2nd video tag
                    // playAudio.src = audioSrc;
                    //@ts-ignore
                    document.getElementById('audioRecord').src = audioSrc;
                    //@ts-ignore
                    document.getElementById('audioRecord').play();
                }
            }).catch(error => {
                console.log('error in generating stream', error)
            if(checkVideoDevice) {
                generateAudioVideoStream(audioState, audioDeviceId, videoState, videoDeviceId, getVideoConstraints(), mode);
                checkVideoDevice = false;
            } else {
                dispatch(Actions.setVideo(false));
                dispatch(Actions.setIsWebCam(false));
            }

        });
    }

    const stopTestAudio = () => {
        // @ts-ignore
        const audioElement: HTMLAudioElement = document.getElementById("audioTest");
        if(audioElement) {
            setTestAudioState(false);
            audioElement.pause();
            audioElement.currentTime = 0;
        }
    }
    const playTestAudio = () => {
        // @ts-ignore
        const audioElement: HTMLAudioElement = document.getElementById("audioTest");
        if(audioElement) {
            if(recordState) {
                stopRecord()
            }
            setTestAudioState(true);
            setRecordState(false);
            // @ts-ignore
            document.getElementById('audioRecord').pause();
            // @ts-ignore
            document.getElementById('audioRecord').currentTime = 0;

            audioElement.play();
        }
    }
    const startRecord = () => {
        if(mediaRecorder) {
            setRecordState(true);
            mediaRecorder.start();
        }
        // @ts-ignore
        const testAudio = document.getElementById("audioTest");
        if(testAudio) {
            setTestAudioState(false);
            // @ts-ignore
            testAudio.pause();
            // @ts-ignore
            testAudio.currentTime = 0;
        }
    }
    const stopRecord = () => {
        if (mediaRecorder) {
            if(_mounted) {
                setRecordState(false);
            }
            const testAudio = document.getElementById("audioTest");
            if(testAudio) {
                // @ts-ignore
                testAudio.pause();
                // @ts-ignore
                testAudio.currentTime = 0;
            }

            mediaRecorder.stop();
            if(timer) {
                clearTimeout(timer);
            }
        }
    }
    console.log("cropedz",croppedImage)
    return (
        <div>
            {settingsModal ?
                <div>
                    <div className={cx(classes.modalBG)}>
                    </div>
                    <div className={cx(classes.modal)}>
                        <div className={cx(classes.content,theme.bgColor)}>
                            <div className={cx(classes.header,theme.bgColor, theme.borderBottom)}>
                                <div className={cx(classes.padding8)}>
                                    <FontAwesomeIcon onClick={() => closeModal()} className={cx(classes.close)} icon={['fas', 'times']} />
                                    {t('meetingPage.settings.title')}
                                </div>
                            </div>
                            <div className={cx(classes.body,theme.bgColor)}>
                                <div className={cx(classes.left)}>
                                    <div onClick={() => tabToggle(1)} className={cx(classes.list, activeTab ===1 ? classes.active:'')}>
                                        <FontAwesomeIcon  icon={['fas', 'cogs']} />
                                        <span>{t('meetingPage.settings.general')}</span>
                                    </div>
                                    <div onClick={() => tabToggle(2)} className={cx(classes.list, activeTab ===2 ? classes.active:'')} >
                                        <FontAwesomeIcon  icon={['fas', 'user-circle']} />
                                        <span>{t('meetingPage.settings.profile')}</span>
                                    </div>
                                    <div onClick={() => tabToggle(3)} className={cx(classes.list, activeTab ===3 ? classes.active:'')}>
                                        <FontAwesomeIcon  icon={['fas', 'camera']} />
                                        <span>{t('meetingPage.settings.audioVideo')}</span>
                                    </div>
                                    <div onClick={() => tabToggle(4)} className={cx(classes.list, activeTab ===4 ? classes.active:'')}>
                                        <FontAwesomeIcon  icon={['fas', 'palette']} />
                                        <span>{t('meetingPage.settings.theme')}</span>
                                    </div>
                                    {/*<div onClick={() => setActiveTab(5)} className={cx(classes.list, activeTab ===5 ? classes.active:'')}>*/}
                                    {/*    <FontAwesomeIcon  icon={['fas', 'cog']} />*/}
                                    {/*    <span>Keyboard shortcut</span>*/}
                                    {/*</div>*/}
                                    <div onClick={() => tabToggle(5)} className={cx(classes.list, activeTab ===6 ? classes.active:'')}>
                                        <FontAwesomeIcon  icon={['fas', 'info-circle']} />
                                        <span>{t('meetingPage.settings.about')}</span>
                                    </div>
                                </div>
                                <div className={cx(classes.right)}>
                                    { activeTab ===1 ?
                                            <div className={cx(classes.listitem)}>
                                            <div className={cx(classes.item)}>
                                                <span className={cx(classes.title)}>{t('meetingPage.settings.language')}</span>
                                                <span className={cx(classes.data)}>
                                                    <select value={displayLanguage} onChange={(event) => ToggleLanguage(event.target.value)} style={{width:'120px'}} className={cx(theme.inputText)}>
                                                    {languages.map((Lan, index) => {
                                                        return (
                                                            <option key={Lan.key} value={Lan.key}>{Lan.value}</option>
                                                            )
                                                        })
                                                    }
                                                    </select>
                                                </span>
                                            </div>
                                                {/* <div className={cx(classes.item)}>
                                                <span className={cx(classes.title)}>{t('meetingPage.settings.dynamicBackground')}</span>
                                                <span className={cx(classes.data)}>
                                                    <label className={cx(classes.container)}>
                                                        <input onChange={(event) =>toggleChecked(event.target.checked)}  checked={dynamicBG} type="checkbox"/>
                                                        <span className={cx(classes.checkmark)}></span>
                                                    </label>
                                                </span>
                                            </div> */}
                                            <div className={cx(classes.item)}>
                                                <span className={cx(classes.title)}>{t('meetingPage.settings.topAndBottomBar')}</span>
                                                <span className={cx(classes.data)}>
                                                    <select value={displayLayout} onChange={(event) => ToggleFluid(event.target.value)} style={{width:'90px'}} className={cx(theme.inputText)}>
                                                        <option  value="float">{"Sticky"}</option>
                                                        <option  value="sticky">{"Float"}</option>
                                                    </select>
                                                </span>
                                            </div>
                                            {/* <div className={cx(classes.item)}>
                                                <span className={cx(classes.title)}>{t('meetingPage.settings.participantCountInGalleryView')}</span>
                                                <span className={cx(classes.data)}>
                                                    <select value={sliderTileLength} onChange={(event) => TogglesliderLength(event.target.value)} style={{width:'50px'}} className={cx(theme.inputText)}>
                                                        <option value={2}>{"2"}</option>
                                                        <option value={4}>{"4"}</option>
                                                        <option value={9}>{"9"}</option>
                                                        <option value={16}>{"16"}</option>
                                                    </select>
                                                </span>
                                            </div> */}
                                            {/*<div className={cx(classes.item)}>*/}
                                            {/*    <span className={cx(classes.title)}>Start {Config.configuration.brandName} when computer start</span>*/}
                                            {/*    <span className={cx(classes.data)}>*/}
                                            {/*        <label className={cx(classes.container)}>*/}
                                            {/*            <input type="checkbox"/>*/}
                                            {/*            <span className={cx(classes.checkmark)}></span>*/}
                                            {/*        </label>*/}
                                            {/*    </span>*/}
                                            {/*</div>*/}
                                        </div>
                                    :null }
                                    { activeTab ===2 ?
                                        <div className={cx(classes.profile)}>
                                            {/* <span className={cx(classes.profileImg)}><FontAwesomeIcon icon={['fas', 'user']}/></span>
                                            <div>{t('meetingPage.settings.changeProfile')}</div> */}
                                            <div className={cx(classes.item)}>
                                                <span className={cx(classes.title)}>
                                                    {t('meetingPage.settings.displayName')}
                                                </span>
                                                <span className={cx(classes.data)}>
                                                    <div className={classes.displayChange}>
                                                        <input
                                                            autoFocus={true}
                                                            value={display}
                                                            onChange={(event) => handleDisplayNameChange(event.target.value)}
                                                            className={cx(theme.inputText)}
                                                        />
                                                        <RegularButtons
                                                            classname={[
                                                                theme.btnPrimary,
                                                                classes.profileChange,
                                                                !valid ? classes.desabled:''
                                                            ]}
                                                            disabled={!valid}
                                                            title={t('meetingPage.settings.updateDisplayName')}
                                                            clicked={() => changeDisplayName(display)}
                                                        />
                                                    </div>
                                                </span>
                                            </div>
                                            {!valid && display.length < 3 ? <div style={{marginBottom: 10, height: 10, color: "orangered"}}> <span>{t('meetingPage.settings.profileNameVali')}</span> </div>: null}
                                            {nameChange ?  <div style={{marginBottom: 10, height: 10, color: "green"}}><span>{t('meetingPage.settings.displayNameChangeSuccessMessage')}</span></div> : null}
                                                
                                           
                                                   
                                                       

                                            { loggedInData?
                                           ! actualImage ? 
                                            <div>
                                            
                                            <div className={cx(classes.item)}>
                                                <div className={classes.ProfilePicDiv}>
                                                    <div className={classes.ProfilePicAvatar}>
                                                        { loggedInData.profile_picture != null? (
                                                            // <img src={loggedInData.img} style={{ width: "100%" }} />
                                                            <img className={classes.ProfilePic} src ={loggedInData.profile_picture} style={{ width: "100%" }} />
                                                            ) : (
                                                                <Avatar name={displayName} size={'80'} round={false} /> 
                                                                

                                                        )}
                                                     </div>
                                                    <div className={classes.uploadButton}>
                                                        {t("meetingPage.UploadorDragandDropHere")}
                                                        <input accept="image/*"
                                                            className={cx(classes.input, classes.hide)}
                                                            id="contained-button-file"
                                                            //@ts-ignore
                                                            onChange={onSelectFile}
                                                            type="file" />
                                                    </div>
                                                </div>
                                            </div>
                                             <div>
                                             
                                         </div>
                                         </div>
                                         : <div className={classes.CropperArea}>
                                         <ReactCrop
                                                 src={src}
                                                 //@ts-ignore
                                                 crop={crop}
                                                 ruleOfThirds
                                                 onImageLoaded={onImageLoaded}
                                                 onComplete={onCropComplete}
                                                 onChange={onCropChange}
                                                 />
                                             <div className={classes.UploadButtonSet}>
                                             <RegularButtons
                                                     classname={[
                                                         theme.btnPrimary,
                                                         classes.profileChange,
                                                         classes.width100
                                                     ]}
                                                     title="save"
                                                     clicked={() => handleSaveProfileImage()}
                                                 />
                                                 
                                                 <RegularButtons
                                                     classname={[
                                                         theme.btnSecondary,
                                                         classes.profileChange,
                                                         classes.width100
                                                     ]}
                                                     title="cancel"
                                                     clicked={() => handleUploadCancel()}
                                                 />
                                                                                                     {/* {croppedImageUrl && (
                                                    <> */}
                                                    {/* {croppedImageUrl}
                                                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                                                    </>
                                                )} */}

                                             </div>
                                         </div>
                                            :
                                           
                                        //      :loggedInData ?  <div className={classes.uploadButton}>
                                        //     Upload or Drag and Drop Here
                                        //     <input accept="image/*"
                                        //         className={cx(classes.input, classes.hide)}
                                        //         id="contained-button-file"
                                        //         onChange={onFileChange}
                                        //         type="file" />
                                        // </div>
                                                    null    }
                                        </div>
                                    : null}
                                    { activeTab ===3 ?
                                        <div className={cx(classes.settings)}>
                                            <div className={cx(classes.header)}>
                                                {t('meetingPage.settings.video')}
                                            </div>
                                            <div className={cx(classes.item)}>
                                                <span className={cx(classes.title)}>{t('meetingPage.settings.cam')}</span>
                                                <span className={cx(classes.data)}>
                                                    <select value={selectedVideo}
                                                    onChange={(event) => handleVideoDeviceChange(event.target.value)}
                                                     style={{width:'200px'}} className={cx(theme.inputText)}>
                                                    {videoDevices.map((videoDevice: any, index: number) => {
                                                return (
                                                    <option key={index} value={videoDevice.deviceId}>{videoDevice.label}</option>
                                                )
                                            })}
                                                    </select>
                                                </span>
                                            </div>
                                            <video className={cx(classes.TopSpace)} autoPlay muted ref={videoRef}></video>
                                            <div className={cx(classes.header, classes.TopSpace)}>
                                                {t('meetingPage.settings.audio')}
                                            </div>
                                            <div className={cx(classes.item, classes.TopSpace)}>
                                                <span className={cx(classes.title)}>{t('meetingPage.settings.microphone')}</span>
                                                <span className={cx(classes.data)}>
                                                    <select value={selectedAudio}
                                                    onChange={(event) => handleAudioDeviceChange(event.target.value)}
                                                    style={{width:'200px'}} className={cx(theme.inputText)}>
                                                    {audioDevices.map((audioDevice: any, index: number) => {
                                                    return(
                                                        <option key={index} value={audioDevice.deviceId}>{audioDevice.label}</option>
                                                    )
                                                })}
                                                    </select>
                                                </span>
                                            </div>
                                            <div className={cx(classes.item, classes.TopSpace)}>
                                                <div className={cx(classes.audioIndicatorWrap)}>
                                                    {audioIndicators.map((level) => (
                                                        <span key={level.id} className={cx(classes.audioIndicator, level.active ? classes.on: '')}></span>
                                                    ))}
                                                </div>
                                                {
                                                    recordState ? (<span className={cx(classes.PlayButton, theme.playButton)} onClick={stopRecord}>Stop</span>)
                                                        : ( !checkRecordAudioTestState() ? DetectRTC.browser.isSafari ? null :  <span className={cx(classes.PlayButton,theme.playButton)} onClick={startRecord}>Record</span>
                                                        : (<span className={cx(classes.PlayButton,theme.playButton)}>Playing...</span>))
                                                }
                                                <audio  id="audioRecord" ></audio>
                                            </div>

                                            {/* <div className={cx(classes.header)}>
                                                {t('meetingPage.settings.speakers')}
                                            </div> */}
                                            <div className={cx(classes.item, classes.TopSpace)}>
                                                <span className={cx(classes.title)}>{t('meetingPage.settings.speakers')}</span>
                                                <span className={cx(classes.data)}>
                                                    {audioOutputDevices.length > 0 ? (
                                                        <select value={selectedOutput}
                                                        onChange={(event) => handleOutputDeviceChange(event.target.value)}
                                                         style={{width:'200px'}} className={cx(theme.inputText)}>
                                                        {audioOutputDevices.map((audioOutPut, index) => {
                                                        return (
                                                            <option key={index} value={audioOutPut.deviceId}>{audioOutPut.label}</option>
                                                        )
                                                    })}
                                                        </select>
                                                    ): (
                                                        <select className={cx(theme.inputText)}>
                                                            {(DetectRTC.browser.isSafari || DetectRTC.browser.isFirefox) ? <option key="noaudioOutputDevices" value="">{"Default"}</option> : <option key="noaudioOutputDevices" value="">{"No device found"}</option>}
                                                    
                                                        </select>

                                                    )}
                                                    
                                                </span>
                                            </div>
                                            <div className={cx(classes.item, classes.TopSpace)}>
                                                {
                                                    testAudioState ?
                                                        (<RegularButtons
                                                            classname={[
                                                                theme.btnSecondary,
                                                                classes.speakerBtn,
                                                            ]} clicked={stopTestAudio} title="Stop"/>)
                                                        : (<RegularButtons
                                                            classname={[
                                                                theme.btnSecondary,
                                                                classes.speakerBtn,
                                                            ]} clicked={playTestAudio} title="Test Speaker"/>)
                                                }

                                                <audio  loop id="audioTest" src={audioTest}></audio>
                                            </div>
                                        </div>
                                    :null}
                                    { activeTab ===4 ?
                                        <div className={cx(classes.themes)}>
                                            <div className={cx(classes.image)}>
                                                {hoverTheme === 'lite' ? (<img src={Lite} alt=""/>):(<img src={Dark} alt=""/>) }
                                            </div>
                                            <div>
                                                <RegularButtons
                                                    style={{fontSize: "small", height: 36}}
                                                    classname={[
                                                        displayTheme === 'lite' ? theme.btnPrimary: theme.btnSecondary,
                                                        classes.themeButton
                                                    ]}
                                                    hover={()=>HoverTheme('lite')}
                                                    leave={()=>HoverTheme('')}
                                                    clicked={()=>toggleTheme('lite')}
                                                    title={t('meetingPage.settings.themeLite')}
                                                />
                                                <RegularButtons
                                                    style={{fontSize: "small", height: 36}}
                                                    classname={[
                                                        displayTheme === 'dark' ? theme.btnPrimary: theme.btnSecondary,
                                                        classes.themeButton
                                                    ]}
                                                    hover={()=>HoverTheme('dark')}
                                                    leave={()=>HoverTheme('')}
                                                    clicked={()=>toggleTheme('dark')}
                                                    title={t('meetingPage.settings.themeDark')}
                                                />
                                            </div>
                                        </div>
                                    :null}
                                    { activeTab === 5 ?
                                        <div className={cx(classes.about)}>
                                            <div className={cx(classes.flex)}>
                                                <div className={cx(classes.logo)}>
                                                    <Logo displayTheme={displayTheme} />
                                                </div>
                                                {/* background.jpg */}
                                                {t('meetingPage.settings.version')} : {version} {buildNumber}
                                            </div>
                                        </div>
                                    :null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            : null }
        </div>

    );
};

export default SettingsModal;
