import React, {useState, useEffect, useRef} from 'react';
import PhoneInput from 'react-phone-input-2';
import '../../countryFlag.css';
import * as Config from '../../Config/config'

import {makeStyles} from "@material-ui/styles";
import classes from './connectivityModal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lookup from 'country-code-lookup';
import cx from 'classnames';
// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    bgColor:{
        backgroundColor: theme.background,
        color: theme.textColor1
    },
    tab:{
        backgroundColor:theme.modalBorderColor,
        color: theme.textColor3,
        '&:hover':{
            color: theme.textColor2
        }
    },
    inputText: {
        backgroundColor: theme.textboxBGColor,
        border: '1px solid ' + theme.textboxBorderColor,
        color: theme.textboxTextColor,
        "&:hover, &:focus, &:active": {
            border: "1px solid "+ theme.textboxActiveColor,
        },
        '&::placeholder': {
            color:theme.textboxPlaceholderColor
        }
    },
    border:{
        border: "1px solid " + theme.modalBorderColor,
    },
    borderBottom:{
        borderBottom: "1px solid " + theme.modalBorderColor,
    },
    btnPrimary: {
        background: theme.button1BGColor,
        border: "1px solid "+ theme.button1BorderColor,
        color: theme.button1TextColor,
        "&:hover, &:focus": {
            background: theme.button1HoverBGColor
        }
    },
    btnSecondary: {
        background: theme.button2BGColor,
        border: "1px solid "+ theme.button2BorderColor,
        color: theme.button2TextColor,
        "&:hover, &:focus": {
            color: theme.button2HoverTextColor
        }
    },

    btnTernary: {
        background: theme.button3BGColor,
        border: "1px solid "+ theme.button3BGColor,
        color: theme.button3TextColor,
        "&:hover, &:focus": {
            border: "1px solid "+ theme.button3HoverBorderColor,
        }
    }
}))
const ConnectivityModal = (props: any) => {
    const theme = useStyles(props);
    const {
        modalOpen,
        toggle,
        t,
        connectivityMode,
        setCallbackNumber,
        handleCallBack,
        meetingInfo,
        didsList,
        primaryDid
    } = props;

    const [activeTab, setActiveTab] = useState(1);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [phoneData, setPhoneData] = useState({});
    const [disableButton, setDisableButton] = useState(false);
    const [countryPhoneCode, setCountryPhoneCode] = useState('');
    const [countryCode, setCountryCode] = useState([""]);
    const [countryPhone, setCountryPhone] = useState([]);
    const [selectedPhoneCountry, SetelectedPhoneCountry] = useState('USA');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
    const [defaultIso, setDefaultIso] = useState('us');
    let countrydata: any = [];
    let countryFiltered: any = [];
    didsList.filter((d: any) => {return d.dial_in === true && d.status === "enabled"}).map((lists:any, _index:any) => {
        if( countrydata.indexOf(lists.country) === -1) {
             countrydata.push(lists.country)
             countryFiltered.push(lists)
        }})
    let list:any [];
    const countryRef = useRef();
    useEffect(() => {
        setActiveTab(1);
        setPhoneNumber('');
        setCountryPhoneCode('')
        console.log("didsList",didsList, primaryDid);
        setSelectedPhoneNumber(primaryDid);
        list = [];
        // @ts-ignore
        didsList.filter(d => {return d.dial_out === true && d.status === "enabled"}).map((n:any) => {
            let key = lookup.byCountry(n.country);
            if(key?.iso2 && key?.iso2 !== ''){
                console.log("setCountryCode",key?.iso2.toLowerCase())
                list.push(key?.iso2.toLowerCase());
            }
        })
        setCountryCode(list)
        // console.log("meetingInfo",meetingInfo);
    }, [modalOpen]);
    const handleOpen = () => {
        console.log('open modal');
    }
    const handleCountry = (Code: string) => {
        // // @ts-ignore
        // setCountryPhoneCode(countryCode[Code]);
        // // @ts-ignore
        // console.log("countryCode[Code]",countryCode[Code]);
        // // @ts-ignore
        setCallbackNumber(countryPhoneCode);
    }
    const ChangePhoneNumber = (value: string) => {
        setPhoneNumber(value);
        setCallbackNumber('+' + value);
        setCountryPhoneCode(value)
    }
    // const choosePhoneAudio = () => {
    //     setDisableButton(true);
    //     props.choosePhone('');
    // }
    // const onchooseComputerAudio = () => {
    //     setDisableButton(true);
    //     props.chooseAudio();
    // }
    // const handleCall = () => {
    //     setDisableButton(true);
    //     choosePhone(countryPhoneCode);
    // }

    return (
        <div>
            {modalOpen ?
                <div>
                    <div className={cx(classes.modalBG)}>
                    </div>
                    <div className={cx(classes.modal)}>
                        <div className={cx(classes.content, theme.bgColor, theme.border)}>
                            <div className={cx(classes.header, theme.bgColor, theme.borderBottom)}>
                                <div className={cx(classes.padding8)}>
                                    <FontAwesomeIcon onClick={ ()=> toggle(false)} className={cx(classes.close)} icon={['fas', 'times']} />
                                    {t('meetingPage.connectivityModal.title')}
                                </div>
                            </div>
                            <div className={cx(classes.body)}>
                                <div className={cx(classes.tabs)}>
                                    <div onClick={()=>setActiveTab(1)} className={cx(classes.tab,theme.tab,activeTab === 1 ? classes.active: '')}>
                                        <div className={cx(classes.titleWithBorder)}>
                                            {t('meetingPage.connectivityModal.computerAudio')}
                                        </div>
                                    </div>
                                    {meetingInfo.dial_in === true ? 
                                    <div onClick={()=>setActiveTab(2)} className={cx(classes.tab,theme.tab,activeTab === 2 ? classes.active: '')}>
                                        <div className={cx(classes.titleWithBorder)}>
                                            {t('meetingPage.connectivityModal.phoneAudio')}
                                        </div>
                                    </div>:null}
                                    {meetingInfo.dial_out === true ? 
                                    <div onClick={()=>setActiveTab(3)} className={cx(classes.tab,theme.tab,activeTab === 3 ? classes.active: '')}>
                                        <div className={cx(classes.title)}>
                                            {t('meetingPage.connectivityModal.callback')}
                                        </div>
                                    </div>:null}
                                    {meetingInfo.dial_out !== true && meetingInfo.dial_in !== true ? 
                                    <div  className={cx(classes.tab,theme.tab)}>
                                        <div className={cx(classes.title)}>
                                            
                                        </div>
                                    </div>:null}
                                </div>
                                <div className={cx(classes.tabBody)}>
                                    {activeTab === 1 ?
                                        <div className={cx(classes.bodyContent)}>
                                            <div className={cx(classes.bodyTitle)}>
                                                {t('meetingPage.connectivityModal.computerAudioMessage1')+Config.configuration.brandName +t('meetingPage.connectivityModal.computerAudioMessage2')}
                                            </div>
                                            <div className={cx(classes.input)}>
                                                {connectivityMode === '' ?
                                                    <button className={theme.btnPrimary} onClick={()=>handleCallBack('phone')}>
                                                        {t('meetingPage.connectivityModal.leaveComputerAudio')}
                                                    </button>
                                                    :<button className={theme.btnPrimary} onClick={()=>handleCallBack('computer')}>
                                                        {t('meetingPage.connectivityModal.joinWithComputerAudio')}
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    :null}
                                    {activeTab === 2 ?
                                        <div className={cx(classes.connectivity)}>
                                            <div className={cx(classes.flex)}>
                                                <div className={cx(classes.info, classes.dropDown)}>
                                                    <select style={{width:'120px'}} className={cx(theme.inputText)}
                                                    value={selectedPhoneNumber}
                                                    onChange={(event) => setSelectedPhoneNumber(event.target.value)}>
                                                         {countryFiltered.map((list:any)=>{
                                                        return (
                                                            <option key={list.did} value={list.did}>{list.country}</option>
                                                        )})}  
                                                    </select>
                                                </div>
                                                <div className={cx(classes.info)}>
                                                Dial:<br/>
                                                <ul>
                                                    <li>{
                                                    selectedPhoneNumber}</li>
                                                </ul>
                                                    <div>
                                                        Meeting ID: {meetingInfo.id}
                                                    </div>
                                                    <div>
                                                        Phone PIN: {meetingInfo.pin ? meetingInfo.pin : 'Not required'}
                                                    </div>


                                                </div>
                                            </div>
                                            <button className={theme.btnPrimary} onClick={()=>handleCallBack('phone')}>
                                                {t('meetingPage.connectivityModal.joinWithPhone')}
                                            </button>
                                        </div>
                                    : null }
                                    {activeTab === 3 ?
                                        <div >
                                            <div className={cx(classes.flex)}>
                                                <div className={cx(classes.info, classes.right)}>
                                                    {/* <ReactFlagsSelect  onSelect={handleCountry} defaultCountry='US' countries={["US", "IN"]}
                                                customLabels={countryCode}  /> */}
                                                <PhoneInput
                                                countryCodeEditable={false}
                                                country={defaultIso}
                                                disabled={countryCode.length === 0}
                                                placeholder="Type your phone number"
                                                value={countryPhoneCode}
                                                onlyCountries={[...countryCode]}
                                                onChange={phone => ChangePhoneNumber(phone)}
                                                />
                                                </div>
                                                {/* <div className={cx(classes.info, classes.call)}>
                                                    <input value={phoneNumber} maxLength={16}
                                                     onChange={(event) =>ChangePhoneNumber(event.target.value)} type="text"></input>
                                                </div> */}
                                            </div>
                                            <button disabled={phoneNumber.length<6} className={cx(classes.getACall,theme.btnPrimary,phoneNumber.length< 6 ? classes.disable:null)} onClick={()=>handleCallBack('callback')}>
                                                {t('meetingPage.connectivityModal.joinWithCallback')}
                                            </button>
                                        </div>
                                    : null }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            : null }
        </div>

    );
};

export default React.memo(ConnectivityModal);
