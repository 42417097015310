import * as Config from '../../Config/config'

export const translationAR = {
    "brandName": Config.configuration.brandName,
    "splashScreen": {
        "loading": "جار التحميل..."
    },
    "layout": {
        "noInternet": "لا يوجد اتصال بالإنترنت",
        "backOnline": "عاد الاتصال بالإنترنت"
    },
    "homePage": {
        "domainPlaceHolder": "عنوان اواسم نطاق إنترنت",
        "joinButton": "أنضم إلى اجتماع",
        "loginButton": "الدخول",
        "noAccount": "إذا لم يكن لديك حساب؟",
        "createAccount": "أنشئ حساب مجاني",
        "logoutButton": "خروج",
        "cancelButton": "إلغاء",
        "startMeetingButton": "ابدأ اجتماع",
        "meetingTab": "الاجتماعات",
        "chatTab": "المحادثات",
        "scheduleTab": "الجدول الزمني",
        "recordingTab": "التسجيلات",
        "upcomingMeeting": "الاجتماعات القادمة",
        "personalMeeting": "شخصي",
        "scheduleMeetingButton": "رتب موعد اجتماع",
        "personalMeetingRoomPlaceHolder": "غرفتي للاجتماعات الشخصية",
        "meetingIdLabel": "معرف الاجتماع",
        "meetingIdPlaceHolder": "معرف الاجتماع",
        "passwordLabel": "كلمه المرور",
        "passwordPlaceHolder": "كلمه المرور",
        "saveButton": "حفظ",
        "editButton": "تعديل",
        "deleteButton": "حذف",
        "showInvitationButton": "عرض الدعوة",
        "copyInvitationButton": "نسخ الدعوة",
        "ViewProfile":"عرض الصفحة الشخصية",
        "ChangePassword":"تغيير كلمة المرور"
    },
    "loginPage": {
        "title": "الدخول",
        "emailPlaceHolder": "البريد الإلكتروني",
        "passwordPlaceHolder": "كلمه المرور",
        "rememberMe": "تذكرنى",
        "forgotPassword": "هل نسيت كلمة السر؟",
        "cancelButton": "إلغاء",
        "loginButton": "الدخول",
        "loginWithFaceBook": "الدخول باستخدام الفيسبوك",
        "loginWithGoogle": "الدخول عبر جوجل",
        "loginError": "اسم المستخدم أو كلمة المرور غير صحيحة"
    },
    "joinPage": {
        "title": "أنضم إلى اجتماع",
        "meetingIdText": "معرف الاجتماع أو اسم الرابط الشخصي",
        "submitButton": "إرسال",
        "cancelButton": "إلغاء",
        "connectingText": "جاري الاتصال",
        "noInternet": "لا يوجد اتصال بالإنترنت",
        "wrongMeetingId": "معرف الاجتماع غير صالح",
        "invalidMeetingId": "يجب أن يتكون معرف الاجتماع من 10 أرقام"

    },
    "previewPage": {
        "title": "أنضم إلى اجتماع",
        "meetingIdLabel": "معرف الاجتماع",
        "passwordPlaceHolder": "كلمه المرور",
        "displayNamePlaceHolder": "الاسم عند العرض",
        "cancelButton": "إلغاء",
        "connectingText": "جاري الاتصال",
        "joinWithDevice": "انضم",
        "joinWithMicrophone": "استعمل الميكروفون",
        "joinWithWebCam": "استعمل الكاميرا",
        "joinWithOutDevice": "استعمل الميكروفون والكاميرا ",
        "deviceText": "اختيارات الجهاز",
        "invalidPassword": "كلمة المرور غير صحيحة",
        "noInternet": "لا يوجد اتصال بالإنترنت",
        "deviceBlockedTitle": "استعمال الكاميرا والميكروفون محظور من قبل المتصفح ",
        "deviceBlockedMessage1": "يحتاج تطبيق ",
        "deviceBlockedMessage2": " لاستعمال الكاميرا والميكروفون. انقر فوق أيقونة الكاميرا المحظورة في شريط العناوين.",
        "allowDevicePermissionTitle1": "اسمح لتطبيق ",
        "allowDevicePermissionTitle2": " باستخدام الكاميرا والميكروفون.",
        "allowDevicePermissionMessage1": "يحتاج تطبيق ",
        "allowDevicePermissionMessage2" :"إلى الوصول إلى الكاميرا والميكروفون حتى يتمكن المشاركون الآخرون من رؤيتك وسماعك.",
        "allowMicrophonePermissionTitle1": "اسمح لتطبيق ",
        "allowMicrophonePermissionTitle2":" باستخدام الميكروفون الخاص بك.",
        "allowMicrophonePermissionMessage1": "يحتاج تطبيق ",
        "allowMicrophonePermissionMessage2":" إلى الوصول إلى الميكروفون حتى يتمكن المشاركون الآخرون من سماعك.",
        "allowVideoPermissionTitle1": "اسمح لتطبيق ",
        "allowVideoPermissionTitle2": " باستخدام الكاميرا.",
        "allowVideoPermissionMessage1": "يحتاج تطبيق ",
        "allowVideoPermissionMessage2":" إلى الوصول إلى الكاميرا حتى يتمكن المشاركون الآخرون من رؤيتك.",
        "microphoneBlockedTitle": "لا يمكن استخدام الميكروفون لأنه محظور",
        "microphoneBlockedMessage1": "يحتاج تطبيق ",
        "microphoneBlockedMessage2":" للوصول إلى الميكروفون الخاص بك. انقر فوق رمز حظر الميكروفون في شريط العناوين.",
        "webCamBlockedTitle": "لا يمكن استخدام الكاميرا لأنها محظورة",
        "webCamBlockedMessage1": "يحتاج تطبيق ",
        "webCamBlockedMessage2": " للوصول إلى كاميرتك. انقر فوق أيقونة الكاميرا المحظورة في شريط العناوين.",
        "noDeviceFoundTitle": "لم يتم العثور على أجهزة الصوت والفيديو.",
        "noDeviceFoundMessage1": "يحتاج تطبيق ",
        "noDeviceFoundMessage1" :"إلى الوصول إلى الميكروفون والكاميرا. الرجاء تثبيت هذه الاجهزة أو الانضمام بدون الصوت والصور.",
        "speakerTestPlay": "شغل",
        "speakerTestStop": "توقف",
        "recordTestRecord": "سجل",
        "recordTestPlaying": "جاري التشغيل...",
        "recordTestStop": "توقف"
    },
    "meetingPage": {
        "max_concurrent_limit_reached":"اجتماع آخر لهذا المضيف قيد التقدم بالفعل. الرجاء معاودة المحاولة في وقت لاحق",
        "max_limit_reached":"وصل هذا الاجتماع إلى الحد الأقصى المسموح به لعدد المشاركين. الرجاء معاودة المحاولة في وقت لاحق",
        "max_duration_reached":"انتهى اجتماعك بسبب المهلة. يرجى ترقية خطتك للاجتماعات بلا حدود",
        "almost_duration_notification1":"سينتهي هذا الاجتماع في دقائق ",
        "almost_duration_notification2": ". يرجى ترقية خطتك لعقد اجتماعات بلا حدود",
        
        "topBar": {
            "invite": "ادعو شخص",
            "screenShareView": "مشاركة شاشتك",
            "speakerView": "اسلوب عرض المتحدث",
            "galleryView": "اسلوب عرض المعرض",
            "stickyMode": "تصميم لاصق",
            "floatMode": "تصميم طائف",
            "fullScreen": "شاشة كاملة",
            "restore": "استعادة وضع الشاشة",
            "lockMeeting": "اغلق الاجتماع",
            "openMeeting": "افتح الاجتماع"
        },
        "inviteModal": {
            "title": "ادعو اشخاص لهذا الاجتماع",
            "inviteTextAreaPhonePlaceHolder": "أدخل أو الصق أرقام الهواتف مفصولة بفاصلة أو مسافة أو فاصلة منقوطة",
            "inviteTextAreaEmailPlaceHolder": "أدخل أو الصق عناوين البريد الإلكتروني مفصولة بفاصلة أو مسافة أو فاصلة منقوطة",
            "cancelButtonLabel": "إلغاء",
            "phoneTabRightLabel": "تم بدء مكالمة الى الرقم المعطى ويجب على المشارك قبول هذه المكالمة للانضمام إلى هذا الاجتماع.",
            "inviteButtonLabel": "ادعو شخص",
            "invitingButtonLabel": "جارى الدعوة...",
            "inviteSuccessButtonLabel": "تمت بنجاح",
            "copyMeetingUrl": "انسخ عنوان الاجتماع",
            "copyMeetingInvite": "انسخ عنوان الدعوة",
            "copyStatus": "تم النسخ",
            "TabPhone": "رقم الهاتف",
            "TabEmail": "عنوان البريد الالكتروني"
        },
        "menu": {
            "settings": "إعدادات",
            "noMicrophone": "جهاز الصوت غير مثبت",
            "noMicroPhonePermission": "يرجى السماح بالوصول إلى الميكروفون",
            "noMicroPhoneShort": "لا صوت",
            "noMicroPhonePermissionShort": "لا يوجد إذن",
            "audioMute": "اكتم الصوت",
            "audioUnMute": "لا تكتم الصوت",
            "noWebCam": "جهاز الكاميرا غير مثبت",
            "noWebCamPermission": "الرجاء السماح بالوصول إلى الكاميرا",
            "noWebCamShort": "لا صورة",
            "noWebCamPermissionShort": "لا يوجد إذن",
            "stopVideo": "توقف",
            "startVideo": "ابدأ",
            "switchAudio": "تبديل الصوت",
            "switchAudioShort": "بدل بين",
            "ConnectAudioShort": "اتصل ",
            "ConnectAudio": "قم بتوصيل الصوت",
            "stopShare": "اوقف المشاركة",
            "stopShareShort": "توقف",
            "startShare": "مشاركة شاشتك",
            "startShareShort": "مشاركة",
            "people": "اشخاص",
            "chat": "محادثة",
            "raiseHand": "ارفع يدك",
            "raiseHandShort": "رفع",
            "lowerHand": "اخفض يدك",
            "lowerHandShort": "خفض",
            "leaveMeeting": "غادر الاجتماع",
            "leaveMeetingShort": "غادر",
            "menu": "قائمة",
            "stopRecord": "اوقف التسجيل",
            "startRecord": "إبدء التسجيل",
            "stopShortRecord": "توقف",
            "startShortRecord": "سجل"
        },
        "quickChat": {
            "typeMessage": "اكتب رسالة"
        },
        "rightPanel": {
            "chat": {
                "title": "محادثة",
                "typeMessage": "اكتب رسالة",
                "saveChat": "حفظ",
                "saving": "جارى الحفظ..."
            },
            "people": {
                "title": "اشخاص",
                "inMeeting": "في الاجتماع",
                "muteAll": "كتم الجميع",
                "unmuteAll": "لا تكتم الجميع",
                "allowUnMute": "السماح للأشخاص بإلغاء كتم الصوت"
            }
        },
        "leaveMeetingModal": {
            "title": "انهي الاجتماع",
            "participantTitle": "غادر الاجتماع",
            "message": "هل أنت متأكد أنك تريد إنهاء هذا الاجتماع؟",
            "participantMessage": "هل أنت متأكد أنك تريد مغادرة هذا الاجتماع؟",
            "feedback": "اعطي رآيك ومقترحاتك التقييمية",
            "cancelButton": "إلغاء",
            "leaveMeeting": "انهي لي فقط",
            "participantleaveMeeting": "غادر الاجتماع",
            "endAll": "انهي للجميع"
        },
        "connectivityModal": {
            "title": "اختر جهاز الصوت الخاص بك",
            "computerAudio": "جهاز صوت الكومبيوتر",
            "phoneAudio": "اتصل بنا",
            "callback": "إبدء الاتصال بي",
            "computerAudioMessage1": "سيستخدم تطبيق ",
            "computerAudioMessage2":" جهاز الصوت المدمج أو المتصل بجهاز الكمبيوتر.",
            "joinWithComputerAudio": "انضم واستعمل صوت الكمبيوتر",
            "leaveComputerAudio": "اترك صوت الكمبيوتر",
            "joinWithPhone": "انضم باستعمال الهاتف",
            "joinWithCallback": "استلم مكالمة"
        },
        "settings": {
            "title": "إعدادات",
            "general": "عام",
            "profile": "نمط",
            "audioVideo": "الصوت والصورة",
            "profileNameVali":"الرجاء إدخال 3 أحرف على الأقل",
            "theme": "السمات",
            "about": "فيما يتعلق بنا",
            "language": "اللغة",
            "dynamicBackground": "خلفيات متغيرة",
            "topAndBottomBar": "الإطار العلوى والسفلي",
            "participantCountInGalleryView": "المشاركون في كل صفحة في اسلوب عرض المعرض",
            "changeProfile": "تغيير",
            "displayName": "الاسم المستعمل للعرض",
            "displayNameChangeSuccessMessage": "تم تغيير اسم العرض بنجاح",
            "updateDisplayName": "تحديث",
            "viewProfile": "عرض النمط",
            "video": "فيديو",
            "cam": "كاميرا",
            "audio": "صوت",
            "mic": "مايكروفون",
            "speakers": "سماعات",
            "microphone": "مايكروفون",
            "themeLite": "خفيف",
            "themeDark": "قاتم",
            "version": "الاصدار"
        },
        "reconnecting": "جارى إعادة الاتصال...",
        "waitingToReconnect": "في انتظار الاتصال",
        "youAreOnMute": "أنت على الصمت",
        "unmuteMicrophoneRequestTitle": "طلب إلغاء كتم صوت الميكروفون",
        "unmuteMicrophoneRequestMessage": "يطلب المضيف إلغاء كتم صوت الميكروفون",
        "error4401": "تأكد من أنك متصل بالإنترنت وأن اتصالك بتطبيق "+Config.configuration.brandName+" غير محظور بواسطة جدار حماية أو وكيل.",
        "error4401Title": "غير قادر على الاتصال",
        "errorOkLabel": "حسنا",
        "putMuteByHost": "تم وضعك على وضع الصامت من قبل المضيف",
        "kickOutTitle": "إزالة من الاجتماع",
        "kickOutConfirmMessage": "هل تريد حقًا إزالة هذا الشخص من الاجتماع؟",
        "kickOutMessage": "ام المضيف بإزالتك من الاجتماع.",
        "turnOffVideoByHost": "تم إيقاف الفيديو الخاص بك من قبل المضيف",
        "unmuteMicroPhoneRequestTitle": "طلب إلغاء كتم صوت الميكروفون",
        "unmuteMicroPhoneRequestMessage": "يطلب المضيف إلغاء كتم صوت الميكروفون",
        "startCameraRequestTitle": "اطلب استعمال الكاميرا",
        "startCameraRequestMessage": "لمضيف يطلب بدء الفيديو الخاص بك"
    },
    "schedule": {
        "mode": "وضع",
        "topic": "عنوان",
        "meetingId": "معرف الاجتماع",
        "password": "كلمة المرور",
        "pin": "رقم المرور",
        "RecurringCheckBox": "يتكرر",
        "Recurrence": "تكرار",
        "Occurrences": "تكرارات",
        "RecordMode": "وضع التسجيل",
        "e2eEncryption":"تشفير من E إلى E.",
        "recordAutomatically": "إبدء التسجيل تلقائياً",
        "Security": "الحماية",
        "LockTheRoom": "أغلق الغرفة",
        "joinBeforeHost": "انضم قبل المضيف",
        "timeZone": "المنطقة التوقيت",
        "DateAndTime": "التاريخ والوقت",
        "dialInMode":"جسر الهاتف",
        "date": "التاريخ",
        "time": "الوقت",
        "invite":"يدعو",
        "security": "الحماية",
        "cancelButton": "إلغاء",
        "hideInvitationButton": "اخفي الدعوة",
        "showInvitationButton": "اظهر الدعوة",
        "copyInvitationButton": "انسخ الدعوة",
        "schedulebutton": "الجدول الزمني",
        "openMeeting":"فتح",
        "deleteModalHeader": "حذف الاجتماع",
        "deleteModalTitle": "هل تريد حقًا حذف هذا الاجتماع؟",
        "deleteModalTitleRecentLink": "",
        "deleteModalButtonAllOccurance": "حذف جميع الأحداث",
        "deleteModalButtonThisOccurance": "حذف هذا الحدث",
        "deleteModalButtonThisMeeting": "حذف هذا الاجتماع",
        "updateModalHeader": "تحرير الاجتماع",
        "updateModalTitle": "هل تريد تعديل هذا الاجتماع؟",
        "updateModalTitleRecentLink": "",
        "updateModalButtonAllOccurance": "تحرير جميع الأحداث",
        "updateModalButtonThisOccurance": "قم بتحرير هذا الحدث",
        "updatePersonalMeetingButton": "تحرير اجتماع شخصي",
        "updatePersonalMeeting": "تحرير اجتماع شخصي",
        "noUpcomingMeetingsFound": "لم يتم العثور على اجتماعات قادمة",
        "noOpenMeetingsFound":"لم يتم العثور على اجتماعات مفتوحة",
        "noPersonalMeetingsFound": "لم يتم العثور على اجتماعات شخصية",
        "RepeatEvery": "اعد كل",
        "Day": "يوم",
        "Week": "اسبوع",
        "Month": "شهر",
        "copied": "تم النسخ",
        "Occurson": "حدث في",
        "EndDate": "انتهى بتاريخ",
        "After": "بعد",
        "OfTheMonth": "لشهر",
        "scheduleResponse": "تمت جدولة الاجتماع بنجاح",
        "ScheduleMeeting": "يرتب موعد للاجتماع",
        "Ok":"حسنا",
        "editResponse":"تم تحرير الاجتماع بنجاح",

        "Cancel":"إلغاء",
        "Invite":"يدعو",
        "DeleteMeeting":"حذف الاجتماع",
        "editResponseMessage":"تم حذف الاجتماع بنجاح",
        "editResponse":"تحرير الرد",
        "MeetingInvite":"دعوة اجتماع",
        "ScheduleMeeting":"يرتب موعد للاجتماع",
        "Remove":"إزالة",
        "Startmyvideo":"ابدأ الفيديو الخاص بي",
        "IWillDoItLater":"سوف افعلها لاحقا",
        "Meeting invite sent successfully":"تم إرسال دعوة الاجتماع بنجاح",
        "Inviting":"دعوة"




    },
    "feedBack": {
        "title": "كيف سار الاجتماع من الناحية التقنية؟",
        "subTitle1": "شكرًا لك على استخدام تطبيق ",
        "subTitle2":". الرجاء مساعدتنا على تحسين ",
        "subTitle3": "بشكل أفضل.",
        "audioIssues": "مشاكل متعلقة بالصوت",
        "videoIssues": "مشاكل متعلقة بالفيديو",
        "suggestions": "أي اقتراحات للتحسين",
        "cancelLabel": "إلغاء",
        "submitLabel": "ارسل",
        "feedBackTitle": "شكرا لك على ملاحظاتك.",
        "feedBackSuccess": "تم إرسال ملاحظاتك بنجاح.",
        "echoIssue": "سمعت صدى",
        "audioMissing": "لم أستطع سماع المشاركين",
        "audioBlackout": "لا يوجد صوت على الإطلاق",
        "unexpectedEnd": "انتهى الاجتماع بشكل غير متوقع",
        "videoWasFrozen": "توقف عرض الفيديو",
        "videoWasFlickering": "لم يكن الفيديو الخاص بالمشترك واضحاً",
        "myVideoWasNotShowingToThem": "لم يكن الفيديو الخاص بي معروض لهم",
        "videoWasMissing": "فيديو المشارك مفقود"
    },
    "Waiting": {
        "SitBackAndRelax": "اجلس واسترح",
        "HostWillSoonStartMeeting": "مضيفك سيكون هنا قريبًا لبدء الاجتماع.",
        "PleaseWait": "أرجو الإنتظار",
        "HostWillSoonLetYouIn": "سوف يسمح لك مضيفك بالدخول قريبًا.",
        "Joining":"انضمام",
        "YouAreJoiningTheMeeting":"أنت تنضم إلى الاجتماع.",
        "LeaveMeeting": "غادر الاجتماع"
    },
    "WaitingModal": {
        "waitingModalTitle": "غرفة الانتظار",
        "waitingModalBody": "المشاركون ينتظرون موافقتك على الانضمام إلى هذا الاجتماع.",
        "dontShow": "لا تظهر هذه النافذة مرة أخرى لهذا الاجتماع.",
        "KnowWho": "مراجعة المشاركين",
        "Admit": "السماح بالدخول",
        "AdmitAndOpen": "السماح بالدخول وفتح",
        "Deny": "رفض الدخول",
        "Open": "فتح"
    },
    "ScreenShare":{
        "ScreenShareMaxLimitExceeded":"تم تجاوز الحد الأقصى لمشاركة الشاشة"
    }
}
