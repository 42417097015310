import React from "react";
import cx from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
const IconButton = (props: any) => {
    const {iconName, iconType, classname, clicked, style, rotate, disabled, tooltipId, tooltipText, count, nameBar,hover,leave, MouseEnter, notiCount, rightNameBar} = props;

    return (
        <>
        <button
            className={cx(classname)}
            type="button"
            id={iconName + '' + tooltipId}
            disabled={disabled}
            onClick={clicked}
            onMouseOver={hover}
            onMouseEnter={MouseEnter}
            onMouseLeave={leave}
            data-for={tooltipId}
            data-tip={tooltipText}>
            <FontAwesomeIcon icon={[iconType, iconName]} rotation={rotate ? rotate: 0}/>
            {count ? <span className={cx(notiCount)}>{count}</span>: null}
            {rightNameBar ? <span>{rightNameBar}</span>: null}
            {nameBar ? <div>{nameBar}</div>: null}
        </button>
            <ReactTooltip
                id={tooltipId}
                getContent={() => { return tooltipText }}
                className="extraClass"
                delayHide={0}
                effect="solid"
            />
            </>
        // <button disabled={disabled} className={cx(classname)} onClick={clicked} style={style}>{title}</button>
    )
}
export default IconButton;
