import React,{useEffect, useState} from "react";
import cx from "classnames";
import classes from "./iceReconnecting.module.scss";
import FadeIn from "react-fade-in";
import { makeStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegularButtons from "../HoolvaButtons/RegularButtons/regularButtons";
import * as Actions from "../../store/actions";
import {history} from "../../App";
import routes from "../../routes.json";
import {useDispatch, useSelector} from "react-redux";
import {endMeeting, setClientAuthData} from "../../store/actions";
// @ts-ignore
const useStyles = makeStyles(({ theme }) => ({
  content: {
    backgroundColor: theme.background,
    color: theme.textColor1
  },
  btnPrimary: {
    background: theme.button1BGColor,
    border: "1px solid "+ theme.button1BorderColor,
    color: theme.button1TextColor,
    "&:hover, &:focus": {
        background: theme.button1HoverBGColor
    }

  }
}));
const Reconnecting = (props: any) => {
  const theme = useStyles(props);
  const dispatch = useDispatch();
  const {hoolva} = useSelector((state:any)=>state.Meet);
    const {globalClientAuth, clientAuth} = useSelector((state:any)=>state.Auth);
  const [timer, setTimer] = useState(10) // timer
  const {t} = props;
    let inTimerInterval: any = null
  const cancelReconnection = async() => {
      if(inTimerInterval) {
          clearTimeout(inTimerInterval)
      }
      if(hoolva) {
      hoolva.destroy();
          clientAuth.ice = globalClientAuth
          await dispatch(setClientAuthData(clientAuth));
          dispatch(Actions.resetHoolva());
      dispatch(Actions.resetMeet());
      dispatch(Actions.resetScreenShare());
      history.push(routes.HOME);
    }
  }
  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);


    useEffect(() => {
        if(inTimerInterval) {
            clearTimeout(inTimerInterval)
        }
        inTimerInterval = setTimeout(async() => {
            // inTimer = inTimer + 1;
            // console.log('inTimer', inTimer);
            // if(inTimer === 20 ) {
            console.log('need to end the meeting');
            if(inTimerInterval) {
                clearInterval(inTimerInterval)
            }
            if(hoolva) {
                hoolva.destroy();
                clientAuth.ice = globalClientAuth
                await dispatch(setClientAuthData(clientAuth));
                dispatch(Actions.resetHoolva());
                dispatch(Actions.resetMeet());
                dispatch(Actions.resetScreenShare());
                history.push(routes.HOME);
            }
            //}
        }, 20000)

        return(() => {
            if(inTimerInterval) {
                clearTimeout(inTimerInterval)
            }
        })
    }, [])

    return (
    <FadeIn>
      <div className={cx(classes.transperency)}></div>
      <div className={cx(classes.hoolva)}>
        <div className={cx(classes.main, classes.flex)}>
          <div className={cx(theme.content)}>
           <div className={cx(classes.header)}>
              <div className={cx(classes.padding8)}>
                {t('meetingPage.reconnecting')}
              </div>
            </div>
            <div className={cx(classes.content)}>
              <div className={cx(classes.connect)}>
              {timer===0?'We are still trying, you may cancel this request and rejoin':'You are experiencing a connectivity issue we are trying again.'}
              </div>
            </div>
            <div className={classes.cancelButtonDiv}>
              <RegularButtons disabled={timer ===0?false:true}  clicked={() => cancelReconnection()} classname={timer ===0?theme.btnPrimary:classes.desabled}
               title={timer===0?t('Reconnecting.Rejoin'):t('Reconnecting.Rejoin')+' ('+timer+')'} />
            </div>
          </div>

        </div>
      </div>
    </FadeIn>
  );
};

export default Reconnecting;
