import React, {useState} from "react";
import cx from 'classnames';
import classes from './feedback.module.scss'
import routes from "../../routes.json";
import RegularButtons from "../../components/HoolvaButtons/RegularButtons/regularButtons";
import {makeStyles} from "@material-ui/styles";
import FadeIn from 'react-fade-in';
import * as Config from '../../Config/config';

// @ts-ignore
import StarRatings from 'react-star-ratings'
import * as apiService from '../../Services/apiService';
import {useDispatch, useSelector} from "react-redux";
import * as Actions from '../../store/actions';
import HoolvaAlertMessage from "../../components/HoolvaAlertMessage/hoolvaAlertMessage";
import {history} from "../../App";
import {withNamespaces} from "react-i18next";

// @ts-ignore
const useStyles = makeStyles(({theme}) => ({

    inputText: {
        backgroundColor: theme.textboxBGColor,
        border: '1px solid ' + theme.textboxBorderColor,
        color: theme.textboxTextColor,
        width:"94%",
        "&:hover, &:focus, &:active": {
            border: "1px solid "+ theme.textboxActiveColor,
        },
        '&::placeholder': {
            color:theme.textboxPlaceholderColor
        }
    },
    text: {
        color:theme.textColor1
    },
    btnPrimary: {
        background: theme.button1BGColor,
        border: "1px solid "+ theme.button1BorderColor,
        color: theme.button1TextColor,
        "&:hover, &:focus": {
            background: theme.button1HoverBGColor,
            border: "1px solid "+ theme.button1HoverBGColor,
        }
    },
    btnSecondary: {
        background: theme.button2BGColor,
        // border: "1px solid "+ theme.button2BorderColor,
        color: theme.button2TextColor,
        "&:hover, &:focus": {
            color: theme.button2HoverTextColor
        }
    }
}))


const FeedBack = ({t}: any,props: any) => {
    const theme = useStyles(props);
    const [rating, setRating] = useState(5);
    const [suggestion, setSuggestion] = useState('');
    const [open, setOpen] = useState(false)
    const {displayName, meetingInfo, API_ROOT, room_uuid} = useSelector((state:any)=>state.Hoolva);
    const dispatch = useDispatch();
    const [feedBackArray, setFeedBackArray] =useState( [{
        title:'I heard echo',
        label: t('feedBack.echoIssue'),
        mode:'audio',
        status:false,
        id: "echo_issue"
    },
    {
        title:"I couldn't hear one the participant",
        label:t('feedBack.audioMissing'),
        mode:'audio',
        status:false,
        id: "audio_missing"
    },
    {
        title:'No audio at All',
        label:t('feedBack.audioBlackout'),
        mode:'audio',
        status:false,
        id: "audio_blackout"
    },
    {
        title:'Meeting ended unexpectedly',
        label:t('feedBack.unexpectedEnd'),
        mode:'audio',
        status:false,
        id: "unexpected_meeting_end"
    },
    {
        title:'Video was frozen',
        label:t('feedBack.videoWasFrozen'),
        mode:'video',
        status:false,
        id: "echo_issue_video"
    },
    {
        title:"Paritcipant's video was flickering",
        label:t('feedBack.videoWasFlickering'),
        mode:'video',
        status:false,
        id: "audio_missing_video"
    },
    {
        title:'My Video was not showing to them',
        label:t('feedBack.myVideoWasNotShowingToThem'),
        mode:'video',
        status:false,
        id: "audio_blackout_video"
    },
    {
        title:"Participant's video was missing",
        label:t('feedBack.videoWasMissing'),
        mode:'video',
        status:false,
        id: "unexpected_meeting_end_video"
    }
]);
    const onRatingChange = (newRating: any) => {
        console.log('rating', newRating);
        setRating(newRating);
    }
    const handleStatusChange = (id: string, status: boolean) => {
        const feedBacks = [...feedBackArray];
        feedBacks[feedBacks.findIndex(data => data.id === id)].status = status;
        setFeedBackArray(feedBacks);
    }
    const sendFeedBack = async () => {
        const audioIssues: string[] = [];
        const videoIssues: string[] = [];
        feedBackArray.map(feedBack => {
            if(feedBack.status) {
                if(feedBack.mode === 'audio') {
                    audioIssues.push(feedBack.title)
                } else {
                    videoIssues.push(feedBack.title)
                }
            }
        })
        console.log('meeting info', meetingInfo);
        const formattedMessage = {
            rating: rating,
            participant_name: displayName,
            meeting_uuid: meetingInfo.uuid,
            room_uuid: room_uuid,
            suggestion: suggestion,
            issues: {
                audio: audioIssues,
                video: videoIssues
            }
        }
        console.log('send feedback', formattedMessage);
        const feedbackResponse = await apiService.sendFeedBack(API_ROOT, formattedMessage);

        console.log('feedback response', feedbackResponse);
        if(feedbackResponse.status === 200) {
            setOpen(true);
        }

        dispatch(Actions.resetHoolva());
    }
    const handleSuggestionChange = (value: string) => {
        setSuggestion(value);
    }
    const handleConfirm = () => {
        dispatch(Actions.resetHoolva());
        setOpen(false);
        history.push(routes.HOME);
    }

    return(
        <FadeIn>
            <div className={cx(classes.hoolva)}>
                <div className={cx(classes.main, classes.flex)}>
                    <div  className={cx(classes.content)}>
                        <div className={cx(classes.title, theme.text)}>
                            <b>{t('feedBack.title')}</b><br/><br/>
                            {t('feedBack.subTitle1')+ Config.configuration.brandName + " "+ t('feedBack.subTitle2')+ " " +Config.configuration.brandName + " "+ t('feedBack.subTitle3')}<br/><br/>
                            <StarRatings
                                rating={rating}
                                starRatedColor="#f7931d"
                                changeRating={onRatingChange}
                                numberOfStars={5}
                                starHoverColor="#f7931d"
                            />
                        </div>
                        <div className={cx(classes.flexCol)}>
                            <div className={cx(classes.half)}>
                                <b>{t('feedBack.audioIssues')}</b><br/>
                                {
                                    feedBackArray.filter(function (fb) {
                                        return fb.mode === 'audio';
                                      }).map((fb: any, index: number) => {
                                        return(
                                            <div key={fb.id} className={cx(classes.marginTop)}>
                                                <label className={cx(classes.container)}>
                                            {fb.label}
                                                <input type="checkbox" checked={fb.status} onChange={(event)=>handleStatusChange(fb.id, event.target.checked)} />
                                                <span className={cx(classes.checkmark)}></span>
                                                </label>
                                            </div>
                                        );

                                    })
                                }

                            </div>
                            <div className={cx(classes.half)}>
                                <b>{t('feedBack.videoIssues')}</b><br/>
                                {
                                    feedBackArray.filter(function (fb) {
                                        return fb.mode === 'video';
                                      }).map((fb: any, index: number) => {
                                        return(
                                            <div className={cx(classes.marginTop)} key={fb.id}>
                                                <label className={cx(classes.container)}>
                                            {fb.label}
                                                <input type="checkbox" checked={fb.status} onChange={(event)=>handleStatusChange(fb.id, event.target.checked)} />
                                                <span className={cx(classes.checkmark)}></span>
                                                </label>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <textarea name="Text1" placeholder={t('feedBack.suggestions')}
                                  value={suggestion}
                                  onChange={(event) =>handleSuggestionChange(event.target.value)}
                                       rows={4}       className={cx(theme.inputText, classes.textarea)}></textarea>
                        <div className={cx(classes.set)}>

                                <RegularButtons
                                    clicked={handleConfirm}
                                    classname={[classes.secondary, theme.btnSecondary]}
                                    title={t('feedBack.cancelLabel')}
                                />

                            <RegularButtons
                                clicked={()=> sendFeedBack()}
                                classname={[classes.primary, classes.spacing, theme.btnPrimary]}
                                title={t('feedBack.submitLabel')}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <HoolvaAlertMessage mode={true} t={t} open={open} title={t('feedBack.feedBackTitle')} message={t('feedBack.feedBackSuccess')} confirm={handleConfirm}/>
        </FadeIn>
    )
}
export default withNamespaces()(FeedBack);


