import * as Config from '../../Config/config'
let msg = {};

export const translationFR = {
    "brandName": Config.configuration.brandName,
    "splashScreen": {
        "loading": "Chargement..."
    },
    "layout": {
        "noInternet":"Pas d'Internet",
        "backOnline": "Retour en ligne",
        "encryptionError": "Plate-forme non prise en charge",
        "encryptionErrorMsg":"En-tête modal : l'indicateur Google Chrome n'est pas défini. Corps modal : vous devez activer un paramètre dans Google Chrome pour rejoindre cette réunion cryptée de bout en bout. Tapez chrome://flags dans la barre d'adresse du chrome et activez les fonctionnalités de la plate-forme Web expérimentale. Redémarrez Chrome pour le faire fonctionner.",
    },
    "homePage": {
        "domainPlaceHolder": "IP ou nom de domaine",
        "joinButton": "Rejoindre une réunion",
        "loginButton": "Connexion",
        "noAccount": "Pas de compte?",
        "createAccount": "Créez votre compte gratuit",
        "logoutButton": "Se déconnecter",
        "cancelButton": "Annuler",
        "startMeetingButton": "Démarrer la réunion",
        "meetingTab": "Réunions",
        "chatTab": "Discuter",
        "scheduleTab": "Programme",
        "recordingTab": "Enregistrement",
        "upcomingMeeting": "A venir",
        "personalMeeting": "Personnel",
        "scheduleMeetingButton": "Planifier une réunion",
        "personalMeetingRoomPlaceHolder": "Ma salle de réunion personnelle",
        "meetingIdLabel": "ID de réunion",
        "meetingIdPlaceHolder": "ID de réunion",
        "passwordLabel": "Mot de passe",
        "passwordPlaceHolder": "Mot de passe",
        "saveButton": "sauvegarder",
        "editButton": "Éditer",
        "deleteButton": "Supprimer",
        "showInvitationButton": "Afficher l'invitation",
        "copyInvitationButton": "Copier l'invitation",
        "ViewProfile":"Voir le profil",
        "ChangePassword":"Changer le mot de passe"
    },
    "loginPage": {
        "title": "Connexion",
        "emailPlaceHolder": "E-mail",
        "passwordPlaceHolder": "Mot de passe",
        "rememberMe": "Souviens-toi de moi",
        "forgotPassword": "Mot de passe oublié ?",
        "cancelButton": "Annuler",
        "loginButton": "Connexion",
        "loginWithFaceBook": "Se connecter avec Facebook",
        "loginWithGoogle": "Connectez-vous avec Google",
        "loginError": "Le nom d'utilisateur ou le mot de passe n'est pas correct"
    },
    "resetPassword": {
        "title": "réinitialiser le mot de passe",
        "newPasswordPlaceHolder": "nouveau mot de passe",
        "confirmNewPasswordPlaceHolder": "Confirmer le nouveau mot de passe",
        "cancelButton": "Annuler",
        "updateButton": "Changer le mot de passe",
    },
    "joinPage": {
        "title": "Rejoindre une réunion",
        "meetingIdText": "ID de réunion",
        "submitButton": "Nous faire parvenir",
        "cancelButton": "Annuler",
        "connectingText": "De liaison",
        "noInternet": "Pas d'Internet",
        "wrongMeetingId": "ID de réunion non valide",
        "invalidMeetingId": "L'ID de la réunion doit comporter 10 chiffres"

    },
    "previewPage": {
        "title": "Passer à la réunion",
        "meetingIdLabel": "ID de réunion",
        "passwordPlaceHolder": "Mot de passe",
        "displayNamePlaceHolder": "Afficher un nom",
        "cancelButton": "Annuler",
        "connectingText": "De liaison",
        "joinWithDevice": "Rejoindre",
        "joinWithMicrophone": "Rejoindre avec micro",
        "joinWithWebCam": "Rejoindre avec cam",
        "joinWithOutDevice": "Rejoindre avec la caméra et le micro désactivés",
        "deviceText":"Sélection de l'appareil",
        "invalidPassword": "Mot de passe incorrect",
        "noInternet": "Pas d'Internet",
        "deviceBlockedTitle": "La caméra et le micro sont bloqués.",
        "deviceBlockedMessage1":"",
        "deviceBlockedMessage2":  " doit accéder à votre caméra et à votre micro. Cliquez sur l'icône de caméra bloquée dans la barre d'adresse.",
        "allowDevicePermissionTitle1": "Allow ",
        "allowDevicePermissionTitle2":" pour utiliser votre caméra et votre micro.",
        "allowDevicePermissionMessage1":"",
        "allowDevicePermissionMessage2": " doit accéder à votre caméra et à votre micro pour que les autres participants puissent vous voir et vous entendre.",
        "allowMicrophonePermissionTitle1": "Allow ",
        "allowMicrophonePermissionTitle2": "pour utiliser votre micro.",
        "allowMicrophonePermissionMessage1":"",
        "allowMicrophonePermissionMessage2":  " doit accéder à votre micro pour que les autres participants puissent vous entendre.",
        "allowVideoPermissionTitle1": "Allow " ,
        "allowVideoPermissionTitle2": " pour utiliser votre cam.",
        "allowVideoPermissionMessage1":"",
        "allowVideoPermissionMessage2": " doit accéder à votre caméra pour que les autres participants puissent vous voir. ",
        "microphoneBlockedTitle": "Le micro est bloqué.",
        "microphoneBlockedMessage1":"",
        "microphoneBlockedMessage2": " doit accéder à votre micro. Cliquez sur l'icône du micro bloqué dans la barre d'adresse.",
        "webCamBlockedTitle": "La came est bloquée",
        "webCamBlockedMessage1":"",
        "webCamBlockedMessage2":  " doit accéder à votre cam. Cliquez sur l'icône de caméra bloquée dans la barre d'adresse.",
        "noDeviceFoundTitle": "Aucun périphérique audio et vidéo trouvé.",
        "noDeviceFoundMessage1":"",
        "noDeviceFoundMessage2":  " doit accéder à votre micro et à votre caméra. Veuillez installer ou rejoindre sans appareils.",
        "speakerTestPlay": "Jouer",
        "speakerTestStop": "Arrêter",
        "recordTestRecord": "Disque",
        "recordTestPlaying": "En jouant...",
        "recordTestStop": "Arrêter"
    },
    "meetingPage": {
        "max_concurrent_limit_reached":"Une autre réunion pour cet hôte est déjà en cours. Veuillez réessayer plus tard",
        "max_limit_reached":"Cette réunion a atteint le nombre maximum de participants autorisé. Veuillez réessayer plus tard",
        "max_duration_reached":"Votre réunion s'est terminée en raison d'une limite de temps. Veuillez mettre à niveau votre plan pour des réunions sans limites",
        "almost_duration_notification1":"Cette réunion se terminera dans ",
        "almost_duration_notification2":" minutes. Veuillez mettre à niveau votre plan pour avoir des réunions sans limites",
        "PleaseTryAgainLater":"Veuillez réessayer plus tard",
        "TimeOut":"Temps libre",
        "HostEndTheMeeting":"L'hôte met fin à la réunion",
        "MeetingEnded":"Réunion terminée ",
        "UploadorDragandDropHere":"Téléchargez ou faites glisser et déposez ici",

     
        "topBar": {
            "invite": "Inviter",
            "screenShareView": "Partage d'écran",
            "speakerView": "Vue du haut-parleur",
            "galleryView": "Affichage de la galerie",
            "stickyMode": "Mise en page collante",
            "floatMode": "Disposition flottante",
            "fullScreen": "Plein écran",
            "restore": "Écran de restauration",
            "lockMeeting":"Verrouiller la réunion",
            "openMeeting":"Déverrouiller la réunion"
        },
        "inviteModal": {
            "title": "Inviter des personnes à cette réunion",
            "inviteTextAreaPhonePlaceHolder": "Entrez ou collez les numéros de téléphone séparés par une virgule, un espace ou un point-virgule",
            "inviteTextAreaEmailPlaceHolder": "Saisissez ou collez les e-mails séparés par une virgule, un espace ou un point-virgule",
            "cancelButtonLabel": "Annuler",
            "phoneTabRightLabel": "Un appel est déclenché vers le numéro indiqué et le participant doit accepter l'appel pour rejoindre cette réunion.",
            "inviteButtonLabel": "Inviter",
            "invitingButtonLabel": "Attrayant..",
            "inviteSuccessButtonLabel": "Succès",
            "copyMeetingUrl": "Copier l'URL de la réunion",
            "copyMeetingInvite": "Copier l'invitation à la réunion",
            "copyStatus": "Copié",
            "TabPhone":"Téléphoner",
            "TabEmail":"E-mail",
            "MeetingURL":"URL de la réunion",
            "MeetingTopic":"Sujet de la réunion",
            "MeetingID":"ID de réunion",
            "Password":"Mot de passe",
            "PhonePIN":"NIP du téléphone",
        },
        "menu": {
            "settings": "Réglages",
            "noMicrophone": "Périphérique audio non installé",
            "noMicroPhonePermission": "Veuillez autoriser l'accès au micro",
            "noMicroPhoneShort": "Pas de son",
            "noMicroPhonePermissionShort": "Aucune autorisation",
            "audioMute" : "Muet",
            
            "audioUnMute": "Rétablir le son",
            "noWebCam": "Périphérique vidéo non installé",
            "noWebCamPermission": "Veuillez autoriser l'accès à la caméra",
            "noWebCamShort": "Pas de vidéo",
            "noWebCamPermissionShort": "Aucune autorisation",
            "stopVideo": "Arrêter",
            "startVideo": "Démarrer",
            "switchAudio": "Changer de son",
            "switchAudioShort": "Changer",
            "ConnectAudioShort":"Relier ",
            "ConnectAudio":"Connecter le son",
            "stopShare":  "Arrêter le partage",
            "stopShareShort": "Arrêter",
            "startShare": "Partager l'écran",
            "startShareShort": "Partager",
            "people": "Gens",
            "chat": "Discuter",
            "raiseHand": "Lever la main",
            "raiseHandShort": "Élever",
            "lowerHand": "Main inférieure",
            "lowerHandShort": "Plus bas",
            "leaveMeeting": "Quitter la réunion",
            "leaveMeetingShort": "Quitter",
            "menu":"Menu",
            "stopRecord":" Arrêter l'enregistrement",
            "startRecord":"Démarrer l'enregistrement",
            "stopShortRecord":"Arrêter",
            "startShortRecord":"Disque"
        },
        "quickChat": {
            "typeMessage": "Tapez un message"
        },
        "rightPanel": {
            "chat": {
                "title": "Discuter",
                "typeMessage": "Tapez un message",
                "saveChat": "sauvegarder",
                "saving": "Économie..."
            },
            "people": {
                "title": "Gens",
                "inMeeting": "En réunion",
                "muteAll": "Rendre tout silencieux",
                "unmuteAll": "Rétablir tout",
                "allowUnMute": "Autoriser les utilisateurs à réactiver le son",
                "Allow":"Autoriser",
                "Deny":"Nier",
                "AllowAll":"autorise tout",
                "DenyAll":"Nier tous",
                "Waiting":"Attendre",            }
        },
        "leaveMeetingModal": {
            "title": "Fin de réunion",
            "participantTitle": "Quitter la réunion",
            "message": "Voulez-vous vraiment mettre fin à cette réunion ?",
            "participantMessage": "Voulez-vous vraiment quitter cette réunion ?",
            "feedback": "Donnez votre avis",
            "cancelButton": "Annuler",
            "leaveMeeting": "Fin pour moi",
            "participantleaveMeeting": "Quitter la réunion",
            "endAll": "Fin pour tous"
        },
        "connectivityModal": {
            "title": "Choisissez votre connectivité audio",
            "computerAudio": "Audio de l'ordinateur",
            "phoneAudio": "Composer",
            "callback": "Composer",
            "computerAudioMessage1":"",
            "computerAudioMessage2": " utilisera les périphériques audio intégrés ou connectés à votre ordinateur.",
            "joinWithComputerAudio": "Joindre avec l'audio de l'ordinateur",
            "leaveComputerAudio": "Laisser l'audio de l'ordinateur",
            "joinWithPhone": "Rejoindre avec le téléphone",
            "joinWithCallback": "Recevoir un appel"
        },
        "settings": {
            "title": "Réglages",
            "general": "Général",
            "profile": "Profil",
            "audioVideo": "Audio Video",
            "profileNameVali":"Veuillez saisir au moins 3 caractères",
            "theme": "Thèmes",
            "about": "Sur",
            "language": "Langue",
            "dynamicBackground": "Arrière-plans dynamiques",
            "topAndBottomBar": "Barre supérieure et inférieure",
            "participantCountInGalleryView": "Participants par page dans la vue galerie",
            "changeProfile": "Changement",
            "displayName": "Afficher un nom",
            "displayNameChangeSuccessMessage": "Nom d'affichage modifié avec succès",
            "updateDisplayName": "Mettre à jour",
            "viewProfile": "Voir le profil",
            "video": "Vidéo",
            "cam": "Came",
            "audio": "l'audio",
            "mic": "Micro",
            "speakers": "Haut-parleurs",
            "microphone": "Microphone",
            "themeLite": "Léger",
            "themeDark": "Sombre",
            "version": "Version"
        },
        "reconnecting": "Reconnexion...",
        "waitingToReconnect": "Attente de la connexion",
        "youAreOnMute": "Vous êtes en mode muet",
        "unmuteMicrophoneRequestTitle": "Activer la demande de microphone",
        "unmuteMicrophoneRequestMessage": "L'hôte demande de réactiver votre microphone",
        "error4401": "Assurez-vous que vous êtes connecté à Internet et que votre connexion à " + Config.configuration.brandName + " n'est pas bloqué par un pare-feu ou un proxy.",
        "error4401Title": "Impossible de se connecter",
        "errorOkLabel": "D'accord",
        "putMuteByHost": "Vous êtes mis en sourdine par l'hôte",
        "kickOutTitle": "Retirer de la réunion",
        "kickOutConfirmMessage": "Voulez-vous vraiment supprimer cette personne de la réunion ?",
        "kickOutMessage": "L'organisateur vous a supprimé de la réunion.",
        "turnOffVideoByHost": "Votre vidéo est désactivée par l'hôte",
        "unmuteMicroPhoneRequestTitle": "Activer la demande de microphone",
        "unmuteMicroPhoneRequestMessage": "L'hôte demande de réactiver votre microphone",
        "startCameraRequestTitle": "Démarrer la demande de caméra",
        "startCameraRequestMessage": "L'hôte demande à démarrer votre vidéo"
    },
    "schedule": {
        "mode": "Mode",
        "topic": "Sujet",
        "meetingId": "ID de réunion",
        "password": "Mot de passe",
        "e2eEncryption":"Chiffrement de bout en bout",
        "autoRecord":"Enregistrement automatique",
        "pin": "Épingler",
        "RecurringCheckBox":"Récurrent",
        "Recurrence":"Récurrence",
        "Occurrences":"Occurrences",
        "RecordMode":"Mode d'enregistrement",
        "dialInMode":"Pont téléphonique",
        "recordAutomatically":"Enregistrer automatiquement",
        "Security":"Sécurité",
        "LockTheRoom":"Verrouiller la chambre",
        "joinBeforeHost":"rejoindre avant l'hôte",
        "timeZone":"Fuseau horaire",
        "DateAndTime":"Date et l'heure",
        "date":"Date",
        "time":"Temps",
        "invite":"Inviter",
        "security":"Sécurité",
        "cancelButton":"Annuler",
        "hideInvitationButton": "Masquer l'invitation",
        "showInvitationButton": "Afficher l'invitation",
        "copyInvitationButton":"Copier l'invitation",
        "schedulebutton":"Programme",
        "openMeeting":"Ouvrir",
        "deleteModalHeader": "Supprimer la réunion",
        "deleteModalTitle": "Voulez-vous vraiment supprimer cette réunion ?",
        "deleteModalTitleRecentLink": "",
        "deleteModalButtonAllOccurance": "Supprimer toutes les occurrences",
        "deleteModalButtonThisOccurance": "Supprimer cette occurrence",
        "deleteModalButtonThisMeeting": "Supprimer cette réunion",
        "updateModalHeader": "Modifier la réunion",
        "updateModalTitle": "Voulez-vous vraiment modifier cette réunion ?",
        "updateModalTitleRecentLink": "",
        "updateModalButtonAllOccurance": "Modifier toutes les occurrences",
        "updateModalButtonThisOccurance": "Modifier cette occurrence",
        "updatePersonalMeetingButton": "Modifier la réunion personnelle",
        "updatePersonalMeeting": "Modifier la réunion personnelle",
        "noUpcomingMeetingsFound":"Aucune réunion à venir trouvée",
        "noOpenMeetingsFound":"Aucune réunion ouverte trouvée",
        "noPersonalMeetingsFound":"Aucune réunion personnelle trouvée",
        "RepeatEvery":"Répétez chaque",
        "Day":"Jour",
        "Week":"Semaine",
        "Month":"Mois",
        "copied":"Copié",
        "Occurson":"Se produit le",
        "EndDate":"Date de fin",
        "After":"après",
        "OfTheMonth":"du mois",
        "scheduleResponse": "Réunion planifiée avec succès",
        "ScheduleMeeting": "Planifier une réunion",
        "Ok":"d'accord",
        "editResponse":"Réunion modifiée avec succès",

        "Cancel":"Annuler",
        "Invite":"Inviter",
        "DeleteMeeting":"Supprimer la réunion",
        "editResponse":"Réunion modifiée avec succès",
        "editResponseMessage":"Réunion supprimée avec succès",
        "MeetingInvite":"Invitation à une réunion",
        "ScheduleMeeting":"Planifier une réunion",
        "Remove":"Retirer",
        "Startmyvideo":"Démarrer ma vidéo",
        "IWillDoItLater":"Je le ferais plus tard",
        "Meeting invite sent successfully":"Invitation à la réunion envoyée avec succès",
        "Inviting":"Attrayante..",
        "StayMuted":"Restez silencieux",
        "UnmuteNow":"Activer maintenant",
        "WhereDoYouWantToStoreThisRecording":"Où voulez-vous stocker cet enregistrement",
        "ThisComputer":"Cet ordinateur",
        "Cloud":"Cloud",
        "Record":"Disque",
        






    },
    "infoWindow": {
        "MeetingID":"ID de réunion",
        "Host":"Hôte",
        "Password":"Mot de passe",
        "Pin":"Épingler",
        "InviteLink":"Lien d'invitation",
        "Copied":"Copié",
        "CopyMeetingURL":"Copier l'URL de la réunion"
    },
    "feedBack": {
        "title": "Comment c'était?",
        "subTitle1": "Merci d'utiliser", 
        "subTitle2": ". Merci de nous aider à améliorer ", 
        "subTitle3": " mieux.",
        "audioIssues": "Problèmes audio",
        "videoIssues": "Problèmes vidéo",
        "suggestions": "Toute suggestion d'amélioration",
        "cancelLabel": "Annuler",
        "submitLabel": "Nous faire parvenir",
        "feedBackTitle": "Merci pour votre avis.",
        "feedBackSuccess": "Vos commentaires ont été soumis avec succès.",
        "echoIssue": "j'ai entendu l'écho",
        "audioMissing": "Je n'ai pas entendu l'un des participants",
        "audioBlackout": "Pas de son du tout",
        "unexpectedEnd": "La réunion s'est terminée de manière inattendue",
        "videoWasFrozen":"La vidéo a été gelée",
        "videoWasFlickering":"La vidéo du participant scintillait",
        "myVideoWasNotShowingToThem":"Ma vidéo ne leur était pas montrée",
        "videoWasMissing":"La vidéo du participant était manquante"
    },
    "Waiting":{
        "SitBackAndRelax":"Asseyez-vous et détendez-vous,",
        "HostWillSoonStartMeeting":"Votre hôte sera bientôt là pour commencer la réunion.",
        "PleaseWait":"S'il vous plaît, attendez,",
        "HostWillSoonLetYouIn":"Votre hôte vous laissera bientôt entrer.",
        "Joining":"Joindre",
        "YouAreJoiningTheMeeting":"Vous rejoignez la réunion.",
        "LeaveMeeting":"Quitter la réunion",
        "IfYouAreTheHost":"Si vous êtes l'hôte",
        "toStartThisMeeting":"pour commencer cette réunion",
        "GettingBored":"S'ennuyer?",
        "Try":"Essayer",
        "fun":"Amusant",
    },
    "WaitingModal":{
        "waitingModalTitle":"Salle d'attente",
        "waitingModalBody":"Les gens attendent votre approbation pour rejoindre cette réunion.",
        "dontShow":"Ne plus afficher cette fenêtre pour cette réunion.",
        "KnowWho":"La revue",
        "Admit":"Admettre",
        "AdmitAndOpen":"Admettre et ouvrir",
        "Deny":"Refuser",
        "Open":"& ouvrir"
    },
    "Reconnecting":{
        "Rejoin":"Rejoindre",
        "PoorNetwork":"Mauvais réseau",
        "NetworkUnstable":"Votre réseau semble instable. Veuillez passer à un autre réseau si disponible.",

    },
    "ScreenShare":{
        "ScreenShareMaxLimitExceeded":"Limite maximale de partage d'écran dépassée"
    }
}
