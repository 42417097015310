import * as Actions from '../actions';
import * as Config from '../../Config/config';

const initialState: any = {
    API_ROOT: Config.API_ROOT,
    SERVER_ROOT: Config.SERVER,
    meetingId: '',
    password: '',
    displayName: '',
    systemId: '',
    ip: '',
    meetingInfo: null,
    systemState: null,
    room_uuid: '',
    sessionId: null,
    windowMode: true,
    audioDevices: [],
    videoDevices: [],
    xqMessagesInfo: null
};

const HoolvaReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case Actions.SET_ENVIRONMENT: {
            return {
                ...state,
                API_ROOT: action.apiroot,
                SERVER_ROOT: action.server
            }
        }
        case Actions.SET_SESSION_ID: {
            return {
                ...state,
                sessionId: action.sessionId
            }
        }
        case Actions.SET_WINDOW_MODE: {
            return {
                ...state,
                windowMode: action.mode
            }
        }
        case Actions.SET_AUDIO_DEVICES: {
            return {
                ...state,
                audioDevices: action.audioDevices
            }
        }
        case Actions.SET_VIDEO_DEVICES: {
            return {
                ...state,
                videoDevices: action.videoDevices
            }
        }
        case Actions.SET_MEETING_ID: {
            return {
                ...state,
                meetingId: action.meetingId
            }
        }
        case Actions.SET_MEETING_PASSWORD: {
            return {
                ...state,
                password: action.password
            }
        }
        case Actions.SET_DISPLAY_NAME: {
            return {
                ...state,
                displayName: action.displayName
            }
        }
        case Actions.SET_SYSTEM_ID: {
            return {
                ...state,
                systemId: action.systemId
            }
        }
        case Actions.SET_ROOM_UUID: {
            return {
                ...state,
                room_uuid: action.roomUUid
            }
        }
        case Actions.SET_MEETING_INFO: {
            return  {
                ...state,
                meetingInfo: action.meetingInfo
            }
        }
        case Actions.SET_XQ_MESSAGES_INFO: {
            return {
                ...state,
                xqMessagesInfo: action.xqMessagesInfo
            }
        }
        case Actions.SET_IP: {
            return {
                ...state,
                ip: action.ip
            }
        }
        case Actions.SET_SYSTEM_STATE: {
            return {
                ...state,
                systemState: action.systemState
            }
        }
        case Actions.RESET_HOOLVA: {
            return {
                ...state,
                meetingId: '',
                password: '',
                ip: '',
                meetingInfo: null
            };
        }


        default: {
            return state;
        }
    }
}
export default HoolvaReducer;
