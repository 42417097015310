import React, { useEffect, useState } from "react";
import classes from "./infoWindow.module.scss";
import * as Config from "../../Config/config";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../../../src/store/actions";
import { makeStyles } from "@material-ui/styles";
// @ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// @ts-ignore
const useStyles = makeStyles(({ theme }) => ({
  link: {
    color: theme.button1BGColor,
  },
}));
const InfoWindow = (props: any) => {
  const theme = useStyles(props);
  const { t, infoBar, meetingInfo, participantList } = props;
  const { MeetingInvite } = useSelector((state: any) => state.Schedule);
  const [copyURL, setCopyURL] = useState(false);
  const [inviteUrl, setInviteUrl] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    getMeetingInvite();
  }, [MeetingInvite]);
  useEffect(() => {
    if (infoBar ) {
      console.log("meetingInfo",meetingInfo);
      loadInvite();
    }
  }, [infoBar]);
  const loadInvite = () => {
    let data = {
      meeting_uuid: meetingInfo.uuid,
    };
    dispatch(Actions.getMeetingInvite(data));
  };
  const getMeetingInvite = () => {
    if (MeetingInvite) {
      setInviteUrl(MeetingInvite.meeting_url);
    }
  };
  //   const Prevent = (e: any) => {
  //     setShortMenu(!shortMenu);
  //     e.stopPropagation();
  //   };
  const handleCopy = () => {
    setCopyURL(true);
    // e.stopPropagation();
    const timer = setTimeout(() => {
      setCopyURL(false);
      if (timer) {
        clearTimeout(timer);
      }
    }, 3000);
  };
  const Prevent = (e: any) => {
    e.stopPropagation();
  };
  return (
    <div>
      {infoBar ? (
        <div className={classes.InfoBox} onClick={(e) => Prevent(e)}>
          <table>
            <tbody>
              <tr>
                <td className={classes.head} colSpan={2}>
                  {meetingInfo?.name}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>{t("infoWindow.MeetingID")}</td>
                <td className={classes.node}>{meetingInfo.id}</td>
              </tr>
              {participantList.length !== 0 ? (
                <tr>
                  <td>{t("infoWindow.Host")}</td>
                  <td className={classes.node}>
                    {participantList.map((participant: any, index: number) => {
                      return (
                        <span key={index}>
                          {index > 0 ? <span>, </span> : null}
                          {participant.rfdisplay}
                          {participant.isPublisher ? " (You)" : null}
                        </span>
                      );
                    })}
                  </td>
                </tr>
              ) : null}
              {meetingInfo.password !== '' && meetingInfo.password !== null ? <>
                <tr>
                  <td>{t("infoWindow.Password")}</td>
                  <td className={classes.node}>{meetingInfo.password}</td>
                </tr></>:null}
                {meetingInfo.dial_out === true && meetingInfo.pin !== null  ? <>
                <tr>
                  <td>{t("infoWindow.Pin")}</td>
                  <td className={classes.node}>{meetingInfo.pin}</td>
                </tr>
              </>:null}
              <tr>
                <td>{t("infoWindow.InviteLink")}</td>
                <td className={classes.node}>{inviteUrl}</td>
              </tr>
              <tr>
                <td></td>
                <td className={classes.node}>
                  <CopyToClipboard
                    text={inviteUrl}
                    onCopy={() => handleCopy()}
                    options={{ format: "text/plain" }}
                  >
                    <span
                      onClick={(e) => Prevent(e)}
                      className={cx(classes.linkSpan)}
                    >
                      <FontAwesomeIcon icon={["fad", "copy"]} />
                      <span className={classes.linkName}>
                        {copyURL ? t("infoWindow.Copied") : t("infoWindow.CopyMeetingURL")}
                      </span>
                    </span>
                  </CopyToClipboard>
                </td>
              </tr>
            </tbody>
          </table>
          <br></br>
        </div>
      ) : null}
    </div>
  );
};

export default InfoWindow;
