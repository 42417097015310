import React, {useEffect, useState} from "react";
import Iframe from 'react-iframe'
import cx from "classnames";
import classes from "./waitingRoom.module.scss";
import FadeIn from "react-fade-in";
import { makeStyles } from "@material-ui/styles";
import * as Actions from '../../store/actions';
import * as Config from "../../Config/config";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import JoinB4Host from "../../assets/images/joinBeforeHost.png";
import RegularButtons from "../../components/HoolvaButtons/RegularButtons/regularButtons";
import HoolvaAlertMessage from "../../components/HoolvaAlertMessage/hoolvaAlertMessage";
import LockedRoom from "../../assets/images/LockedRoom.png";
import LocalDb from "../../Services/dbService";
import {history} from "../../App";
import routes from "../../routes.json";
import {endMeeting, setMeetingId, setMeetingPassword} from "../../store/actions";
import Logo from "../../components/Logo/Logo";

// @ts-ignore
const useStyles = makeStyles(({ theme }) => ({
  content: {
    color: theme.textColor1,
  },
  loader: {
    color: theme.button1BGColor,
  },
  btnSecondary: {
    background: theme.button2BGColor,
    border: "1px solid " + theme.button2BorderColor,
    color: theme.button2TextColor,
    "&:hover, &:focus": {
      color: theme.button2HoverTextColor,
    },
  },
}));
const WaitingRoom = ({ t }: any, props: any) => {
  const theme = useStyles(props);
  const location = useLocation();
  const dispatch = useDispatch();
  const { displayTheme, loggedIn, dbStore } = useSelector((state: any) => state.Auth);
  const { waitingRoomLoader, hoolva, webrtcStatus, confirmData } = useSelector((state: any) => state.Meet);
  const { meetingInfo } = useSelector((state: any) => state.Hoolva);
  const [game, setGame] = useState(false);
  useEffect(() => {
    if(!webrtcStatus) {
      if(hoolva) {
        dispatch(endMeeting({title: 'Unable to connect',
          message: 'Could not reach our servers, please try again.',
          code: 4409}))
      }
    }
  },[webrtcStatus]);


  const handleLeaveMeeting = () => {
    dispatch(setMeetingId(''));
    dispatch(setMeetingPassword(''));
    dispatch(Actions.endMeeting(undefined));
  };
  const handleLogin = async() => {
    await dispatch(Actions.endMeeting(undefined));
    if(loggedIn) {
      const logOutResponse: any = await dispatch(Actions.doLogout());
      console.log('logOutResponse', logOutResponse);
      LocalDb.removeAuthInfo(dbStore, (data: any) => {
        console.log('removed data from nedb', data)
        dispatch(Actions.setDispalyName(''));
        dispatch(Actions.setLoginInfo(null));
        dispatch(Actions.setMeetingInfo(null));
        history.push({pathname: routes.LOGIN, state: true});
      })
    } else {
      history.push({pathname: routes.LOGIN, state: true});
    }
  }
  const handleGame = () => {
    setGame(!game);
  }
  const handleExit = () => {
    const data={
        open:false
    }
    dispatch(Actions.setConfirmData(data));
    dispatch(Actions.endMeeting(null, false));
    // history.push(routes.HOME);
  }
  return (
    <FadeIn>
      <div className={cx(classes.hoolva)}>
        <div className={cx(classes.main, classes.flex)}>

          <div className={cx(classes.content, theme.content)}>
          <div className={cx(classes.Logo)}>
            <Logo displayTheme={displayTheme} />
            <div className={cx(classes.rightP10)}>
              <RegularButtons
                classname={[classes.primary, theme.btnSecondary]}
                title={t("Waiting.LeaveMeeting")}
                clicked={() => handleLeaveMeeting()}
              />
            </div>
          </div>
            {location.state === "closed" ? (
              <div>
                <div className={cx(classes.TitleHead1)}>
                  <div className={cx(classes.Title,theme.loader)}>
                  {waitingRoomLoader ?
                    <span>{t("Waiting.Joining")} <div className={cx(classes.dotPulse, theme.loader)}></div></span>
                    : t("Waiting.SitBackAndRelax")
                  }

                  </div>
                  <div className={cx(classes.SubTitle)}>
                  {waitingRoomLoader ?
                      t("Waiting.YouAreJoiningTheMeeting")
                      : t("Waiting.HostWillSoonStartMeeting")
                    }
                  </div>
                  {
                    meetingInfo?.waiting_fun_game ? (
                        <div className={cx(classes.Game)}>
                          {!game ?
                              <span>
                                  {t("Waiting.GettingBored?")} <a onClick={handleGame} className={cx(classes.GLink)}>{t("Waiting.Try")} {Config.configuration.brandName} {t("Waiting.fun")}</a>
                              </span>
                              :<a onClick={handleGame} className={cx(classes.GLink)}>{t("schedule.Cancel")} {Config.configuration.brandName} {t("Waiting.fun")}</a>}
                        </div>
                    ) :  null
                  }

                </div>
                {!game ?
                <img src={JoinB4Host} alt="" />
                :<div className={classes.anim}>
                <Iframe  url={Config.configuration.brandingUrl + '/hoolva-fun-bike'} className={classes.Iframe}/>
                {/* <Iframe url="http://127.0.0.1:8081/" className={classes.Iframe}/> */}
                <div className={classes.Gflex}><FontAwesomeIcon icon={["far", "keyboard"]} /> <span>{t("Waiting.PlayWithArrowkeys")}</span></div>
              </div>
                }
              </div>
            ) : (
              <div>
                <div className={cx(classes.TitleHead)}>
                  <div className={cx(classes.Title, theme.loader)}>
                  {waitingRoomLoader ?
                    <span>{t("Waiting.Joining")} <div className={cx(classes.dotPulse, theme.loader)}></div></span>
                    : t("Waiting.PleaseWait")
                  }
                  </div>
                  <div className={cx(classes.SubTitle)}>
                    {waitingRoomLoader ?
                      t("Waiting.YouAreJoiningTheMeeting")
                      : t("Waiting.HostWillSoonLetYouIn")
                    }
                  </div>
                  {meetingInfo?.waiting_fun_game ? (
                      <div className={cx(classes.Game)}>
                        {!game ?
                            <span>
                              {t("Waiting.GettingBored")} <a onClick={handleGame} className={cx(classes.GLink)}>{t("Waiting.Try")} {Config.configuration.brandName} {t("Waiting.fun")}</a>
                            </span>
                            :<a onClick={handleGame} className={cx(classes.GLink)}>{t("Waiting.BackToWaitingRoom")}</a>}
                      </div>
                  ) : null}

                </div>
                {!game ?
                <img src={LockedRoom} alt="" />
                :<div className={classes.anim}>
                      <Iframe  url={Config.configuration.brandingUrl + '/hoolva-fun-bike'} className={classes.Iframe}/>
                  {/* <Iframe url="http://127.0.0.1:8081/" className={classes.Iframe}/> */}
                  <div className={classes.Gflex}><FontAwesomeIcon icon={["far", "keyboard"]} /> <span>{t("Waiting.PlayWithArrowkeys")}</span></div>
                </div>
                }
              </div>
            )}
          </div>
          <div className={classes.Login}>
            <span>{t("Waiting.IfYouAreTheHost")},
              <a style={{marginLeft:"4px", color: '#03a9f4'}} onClick={handleLogin}>{loggedIn ? 'switch account' : 'sign in'}</a> {t("Waiting.toStartThisMeeting")}</span>
          </div>
        </div>
        <HoolvaAlertMessage mode={true} t={t} open={confirmData.open} title={confirmData.title} message={confirmData.message} confirm={() =>handleExit()}/>
      </div>
    </FadeIn>
  );
};

export default withNamespaces()(WaitingRoom);
