//@ts-ignore
import RecordRTC, {MultiStreamRecorder, getSeekableBlob} from 'recordrtc';
import * as Config from '../../Config/config'
import {participants} from "./meet.action";
// @ts-ignore
import {Janus} from 'janus-gateway';

export const SET_RECORDER = 'SET_RECORDER';
export const SET_RECORD_PARTICIPANT = 'SET_RECORD_PARTICIPANT';
export const canvasWidth = 1280;
export const canvasHeight = 720;
export const SET_LOCAL_RECORDING_STATUS = 'SET_LOCAL_RECORDING_STATUS';
let globalStreamArray: any [] = [];
let canVasArray: any [] = [];
let userAvathar: any = null;
let videoAvathar: any = null;
let muteIcon: any = null;

const randomColor = ["#FAEBD7", "#7FFFD4", "#8A2BE2", "#A52A2A", "#5F9EA0", "#D2691E", "#FF7F50", "#6495ED", "#DC143C", "#00FFFF", "#008B8B", "#556B2F", "#FF8C00", "#9932CC", "#E9967A", "#8FBC8F", "#00CED1", "#228B22", "#CD5C5C", "#98FB98", "#4169E1", "#2E8B57", "#87CEEB", "#00FF7F"];

let videoRecordInterval: any [] = [];

export function setLocalRecordingNotification(status: string) {
    return{
        type: SET_LOCAL_RECORDING_STATUS,
        status
    }
}

export function setRecorder (recorder: any, mode: string, recordType: string) {
    return {
        type: SET_RECORDER,
        recorder,
        mode,
        recordType
    }
}
export function setCurrentRecordParticipants(videoParticipant: any, audioParticipant: any) {
    return {
        type: SET_RECORD_PARTICIPANT,
        videoParticipant,
        audioParticipant,
    }
}

const updateVideoInCanvas = (context: any, publisherHandle: any, fillStyle: string) => {
    videoRecordInterval.push(setInterval(() => {
        if(publisherHandle.video === 'unmuted' && publisherHandle.state !== 'connecting') {
            try {
                if(document.getElementById('video_' + publisherHandle.id)) {
                    context.drawImage(document.getElementById('video_' + publisherHandle.id), 0, 0, canvasWidth, canvasHeight);
                } else {
                    if(videoAvathar) {
                        context.drawImage(videoAvathar, canvasWidth/2 -50, canvasHeight/2 -50, 100, 100)
                    }
                }
            } catch(e) {
                if(videoAvathar) {
                    context.drawImage(videoAvathar, canvasWidth/2 -50, canvasHeight/2 -50, 100, 100)
                }
            }

            context.font = "bold 20px Arial";
            if(publisherHandle.audio === 'muted') {
                try {
                    if(muteIcon) {
                        context.drawImage(muteIcon, 10, canvasHeight - 20, 20, 20)
                    }
                } catch(e) {

                }
            }
            context.textAlign = "start";
            context.fillText(publisherHandle.rfdisplay, 50, canvasHeight - 10);
        } else {
            context.fillStyle = "white";
            context.fillRect(0, 0, canvasWidth , canvasHeight);
            context.fillStyle = fillStyle;
            context.fillRect(1, 1, canvasWidth -2, canvasHeight - 2);

            // Draw text
            context.font = "bold 100px Arial";
            context.fillStyle = "white";
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.drawImage(publisherHandle.video === 'unmuted' ? videoAvathar : userAvathar, canvasWidth/2 -50, canvasHeight/2 -50, 100, 100)

            // context.fillText(publisherHandle.rfdisplay.slice(0,2).toUpperCase(), canvasWidth / 2, canvasHeight /2);
            context.font = "bold 20px Arial";
            if(publisherHandle.audio === 'muted') {
                try {
                    if(muteIcon) {
                        context.drawImage(muteIcon, 10, canvasHeight - 20, 20, 20)
                    }
                } catch(e) {

                }

            }
            context.textAlign = "start";
            context.fillText(publisherHandle.rfdisplay, 50, canvasHeight - 10);
        }
    }, 100));
}

const getAvatharImage = (type: string) => {
    const avathar = type === "user" ? document.getElementById('userAvathar') :
        type === 'mute' ? document.getElementById('userMute') :
            type=== 'video' ? document.getElementById('videoSlash') : null;
    const image = new Image();

    // @ts-ignore
    var xml = new XMLSerializer().serializeToString(avathar);
    var svg64 = btoa(xml);
    var b64Start = 'data:image/svg+xml;base64,';

// prepend a "header"
    var image64 = b64Start + svg64;
    image.src = image64;
    return image;
}

export function updateRecording() {
    return async function (dispatch: any, getState: any) {
        videoRecordInterval.map(interval => {
            if(interval) {
                clearInterval(interval);
                interval = null;
            }
        })
        videoRecordInterval = [];
        const {screenSharePublisherHandle, screenShareParticipants} = getState().ScreenShare;
        const {audioRoomHandle} = getState().AudioRoom;
        const {recorder, recorderMode, recordType} = getState().Record;
        if(recordType === 'video') {
            const currentScreenShareList = screenShareParticipants.filter((participant: any) => {
                return participant.removed !== true
            });
            console.log('currentScreenShareList', currentScreenShareList, screenSharePublisherHandle);
            let streamsArray: any[] = [];

            if(screenSharePublisherHandle && currentScreenShareList.length !== 0) {
                let remoteScreenShareStream: any [] = [];
                currentScreenShareList.map((screen: any) => {
                    remoteScreenShareStream.push(screen.webrtcStuff.remoteStream)
                })
                const streamList = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream, screenSharePublisherHandle.webrtcStuff.myStream];
                streamsArray = [...streamList, ...remoteScreenShareStream];
                const filteredStream = streamsArray.filter(stream=> stream);
                recorder.getInternalRecorder().resetVideoStreams(filteredStream);
            } else if(screenSharePublisherHandle && currentScreenShareList.length == 0) {
                streamsArray = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream, screenSharePublisherHandle.webrtcStuff.myStream];
                const filteredStream = streamsArray.filter(stream=> stream);
                recorder.getInternalRecorder().resetVideoStreams(filteredStream);
            } else if(!screenSharePublisherHandle && currentScreenShareList.length !== 0) {
                let remoteScreenShareStream: any [] = [];
                currentScreenShareList.map((screen: any) => {
                    remoteScreenShareStream.push(screen.webrtcStuff.remoteStream)
                });
                const streamList = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream];
                streamsArray = [...streamList, ...remoteScreenShareStream];
                const filteredStream = streamsArray.filter(stream=> stream);
                recorder.getInternalRecorder().resetVideoStreams(filteredStream);
            } else {
                dispatch(startRecording(recordType, recorderMode, true))
            }
        }
    }
}

const createCanvas = (participant: any, type: string) => {
        let canvas: any = document.createElement("canvas");
        // const fillStyle1 = randomColor[Math.floor(Math.random() * randomColor.length)];
        const fillStyle1 = '#404041';
        canvas.height = canvasHeight;
        canvas.width = canvasWidth;
        let context: any = canvas.getContext('2d');
        context.fillStyle = fillStyle1;
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.font = "bold 100px Arial";
        context.fillStyle = "white";
        context.textAlign = "center";
        context.textBaseline = "middle";
        if(participant.video === 'unmuted') {
            console.log('unmuted participant');
            if(type !== 'audio') {
                updateVideoInCanvas(context, participant, fillStyle1);
            } else {
                context.font = "bold 60px Arial";
                context.fillText(Config.configuration.brandName.toUpperCase(), canvas.width / 2, canvas.height / 2);
            }
        } else {
            if(type === 'audio') {
                context.font = "bold 60px Arial";
                context.fillText(Config.configuration.brandName.toUpperCase(), canvas.width / 2, canvas.height / 2);
            } else {
                updateVideoInCanvas(context, participant, fillStyle1);
                context.fillText(participant.rfdisplay.slice(0,2).toUpperCase(), canvas.width / 2, canvas.height / 2);
            }

        }
        if(type !== 'audio') {
            context.font = "bold 12px Arial";
          //  context.fillText(participant.rfdisplay, 60, (canvas.height - 10));
        }
        canVasArray.push(canvas);
        return canvas.captureStream(30);
}

export function startRecording(type: string, mode: string, update: boolean) {
    return async function (dispatch: any, getState: any) {
        console.log('recccccc', type, mode, update);
        const options = {
            mimeType: type === 'audio' ? 'audio/webm' : 'video/webm',
            type: type,
            videoBitsPerSecond: 2048000,
            frameInterval: 20,
            video: {
                width: 1280,
                height: 720,
                minWidth: 1280,
                minHeight: 720,
                minAspectRatio: 1.77
            }
        }
        if(!update) {
            userAvathar = getAvatharImage('user');
            videoAvathar = getAvatharImage('video');
            muteIcon = getAvatharImage('mute');
        }

        canVasArray.map(canvas => {
            if(canvas) {
                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvasWidth, canvasHeight)
            }
        });

        globalStreamArray.map(stream => {
            try {
                // Try a MediaStreamTrack.stop() for each track
                var tracks = stream.getTracks();
                for(var mst of tracks) {
                    if(mst) {
                        mst.stop();
                        stream = null;
                    }
                }
            } catch(e) {
                // Do nothing if this fails
            }
        });
        globalStreamArray = [];
        console.log('options record', options);
        const {audioRoomHandle} = getState().AudioRoom;
        const {publisherHandle} = getState().Meet;
        const {recorder} = getState().Record;
        let streamsArray: any[] = [];
        const {screenSharePublisherHandle, screenShareParticipants} = getState().ScreenShare;
        const currentScreenShareList = screenShareParticipants.filter((participant: any) => {
            return participant.removed !== true
        });
        if(type === 'audio') {
            streamsArray = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream];
        } else {
            if (screenSharePublisherHandle && currentScreenShareList.length !== 0 && mode === 'gallery') {
                let remoteScreenShareStream: any [] = [];
                currentScreenShareList.map((screen: any) => {
                    remoteScreenShareStream.push(screen.webrtcStuff.remoteStream)
                })
                const streamList = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream, screenSharePublisherHandle.webrtcStuff.myStream];
                streamsArray = [...streamList, ...remoteScreenShareStream];
            } else if (screenSharePublisherHandle && currentScreenShareList.length == 0) {
                streamsArray = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream, screenSharePublisherHandle.webrtcStuff.myStream];
            } else if (!screenSharePublisherHandle && currentScreenShareList.length !== 0 && mode === 'gallery') {
                let remoteScreenShareStream: any [] = [];
                currentScreenShareList.map((screen: any) => {
                    remoteScreenShareStream.push(screen.webrtcStuff.remoteStream)
                });
                const streamList = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream];
                streamsArray = [...streamList, ...remoteScreenShareStream];

            } else if(screenSharePublisherHandle && currentScreenShareList.length !== 0  && mode !== 'gallery') {
                streamsArray = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream, screenSharePublisherHandle.webrtcStuff.myStream];
            } else {
                console.log('here not having screen share', type);
                videoRecordInterval.map(interval => {
                    if(interval) {
                        clearInterval(interval);
                        interval = null;
                    }
                })
                videoRecordInterval = [];
                if (mode === 'gallery') {
                    let publisherStream: any [] = [];
                    const currentVideoParticipantList = participants.filter(participant => {
                        return participant.removed !== true && participant.video === 'unmuted' && !participant.detached;
                    }).slice(0, 4);
                    currentVideoParticipantList.map((participant: any) => {
                        const stream = createCanvas(participant, type);
                        publisherStream.push(stream);
                    });
                    const currentAudioParticipantList = participants.filter(participant => {
                        return participant.removed !== true && participant.video === 'muted';
                    }).slice(0, 4 - currentVideoParticipantList.length);
                    if(currentVideoParticipantList.length < 4) {
                        currentAudioParticipantList.map((participant: any) => {
                            publisherStream.push(createCanvas(participant, type));
                        })
                    }
                    globalStreamArray = publisherStream;
                    dispatch(setCurrentRecordParticipants(currentVideoParticipantList, currentAudioParticipantList));
                    console.log('all publisher streams', publisherStream);
                    const streamList = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream];
                   //
                    streamsArray = streamList.concat(publisherStream);
                } else {
                    console.log('not gallery');
                    const stream = createCanvas(publisherHandle, type);
                    globalStreamArray  = [stream]
                    streamsArray = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream, stream];
                }
            }
        }
        // const filteredStream: any = streamsArray.filter(stream=> stream);
        if(update) {
            console.log('streamsArray in update', streamsArray, recorder);
            recorder.getInternalRecorder().resetVideoStreams(streamsArray);
        } else {
            console.log('streamsArray', streamsArray);
            // @ts-ignore
            const recorderObj = new RecordRTC(streamsArray, options);
            const recordEvent = {
                event : 'client_recording',
                status: 'start',
                update: {
                    client_recording: 'start'
                }
            }
            if(publisherHandle) {
                publisherHandle.send({message: recordEvent})
            }
            dispatch(setRecorder(recorderObj, mode, type));
            recorderObj.startRecording();
        }
    }
}



// export function startRecording (type: string, mode: string, update: boolean) {
//     return async function (dispatch: any, getState: any) {
//         const randomColor = ["#F0F8FF", "#FAEBD7", "#00FFFF", "#7FFFD4", "#8A2BE2", "#A52A2A", "#5F9EA0", "#D2691E", "#FF7F50", "#6495ED", "#DC143C", "#00FFFF", "#008B8B", "#556B2F", "#FF8C00", "#9932CC", "#E9967A", "#8FBC8F", "#00CED1", "#228B22", "#CD5C5C", "#98FB98", "#4169E1", "#2E8B57", "#87CEEB", "#00FF7F" ];
//         const options = {
//             mimeType: 'video/webm',
//             type: 'video'
//         }
//         const {audioRoomHandle} = getState().AudioRoom;
//         const {publisherHandle} = getState().Meet;
//         const {screenSharePublisherHandle, screenShareParticipants} = getState().ScreenShare;
//         let canvas:any = document.getElementById("myCanvas");
//         const context = canvas.getContext('2d');
//         const fillStyle = randomColor[Math.floor(Math.random() * randomColor.length)];
//         context.fillStyle = fillStyle;
//         context.fillRect(0, 0, canvas.width, canvas.height);
//
//         // Draw text
//         context.font = "bold 100px Arial";
//         context.fillStyle = "white";
//         context.textAlign = "center";
//         context.textBaseline = "middle";
//         if(publisherHandle.video === 'unmuted') {
//             if(type !== 'audio') {
//                 updateVideoInCanvas(context, publisherHandle, fillStyle);
//             } else {
//                 context.font = "bold 60px Arial";
//                 context.fillText(Config.configuration.brandName.toUpperCase(), canvas.width / 2, canvas.height / 2);
//             }
//         } else {
//             if(type === 'audio') {
//                 context.font = "bold 60px Arial";
//                 context.fillText(Config.configuration.brandName.toUpperCase(), canvas.width / 2, canvas.height / 2);
//             } else {
//                 updateVideoInCanvas(context, publisherHandle, fillStyle);
//                 context.fillText(publisherHandle.rfdisplay.slice(0,2).toUpperCase(), canvas.width / 2, canvas.height / 2);
//             }
//
//         }
//         if(type !== 'audio') {
//             context.font = "bold 12px Arial";
//             context.fillText(publisherHandle.rfdisplay, 60, (canvas.height - 10));
//         }
//
//         let publisherStream:any= canvas.captureStream(30);
//         let streamsArray: any = [];
//         if(mode !== 'gallery') {
//             streamsArray = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream, publisherStream];
//         } else {
//
//             const currentParticipantList = participants.filter(participant => {
//                 return participant.removed !== true && !participant.isPublisher;
//             }).slice(0,3);
//             const streams: any = [];
//             currentParticipantList.map((participant: any) => {
//                 let rCanvas: any = document.createElement("canvas");
//                 const fillStyle1 = randomColor[Math.floor(Math.random() * randomColor.length)];
//                 rCanvas.height = 360;
//                 rCanvas.width = 640;
//                 let rContext: any = rCanvas.getContext('2d');
//
//                 rContext.fillStyle = fillStyle1;
//
//                 rContext.fillRect(0, 0, canvas.width, canvas.height);
//
//                 rContext.font = "bold 100px Arial";
//                 rContext.fillStyle = "white";
//                 rContext.textAlign = "center";
//                 rContext.textBaseline = "middle";
//                 if(participant.video === 'unmuted') {
//                     if(type !== 'audio') {
//                         updateVideoInCanvas(rContext, participant, fillStyle1);
//                     } else {
//                         rContext.font = "bold 60px Arial";
//                         rContext.fillText(Config.configuration.brandName.toUpperCase(), rCanvas.width / 2, rCanvas.height / 2);
//                     }
//                 } else {
//                     if(type === 'audio') {
//                         rContext.font = "bold 60px Arial";
//                         rContext.fillText(Config.configuration.brandName.toUpperCase(), rCanvas.width / 2, rCanvas.height / 2);
//                     } else {
//                         updateVideoInCanvas(rContext, participant, fillStyle1);
//                         rContext.fillText(participant.rfdisplay.slice(0,2).toUpperCase(), rCanvas.width / 2, rCanvas.height / 2);
//                     }
//
//                 }
//                 if(type !== 'audio') {
//                     rContext.font = "bold 12px Arial";
//                     rContext.fillText(participant.rfdisplay, 60, (rCanvas.height - 10));
//                 }
//                 let rStream:any= rCanvas.captureStream(30);
//                 streams.push(rStream);
//
//             });
//             console.log('streams other publisher', streams);
//             const streamList = [audioRoomHandle.webrtcStuff.myStream, audioRoomHandle.webrtcStuff.remoteStream, publisherStream];
//             streamsArray = [...streamList, ...streams];
//             console.log('streamsArraystreamsArray', streamsArray);
//         }
//
//         const recorder = new MultiStreamRecorder(streamsArray, options);
//         dispatch(setRecorder(recorder, mode, type));
//         console.log('recorder', recorder);
//         recorder.record();
//     }
// }

export function stopRecording (check ?: boolean) {
    return async function (dispatch: any, getState: any) {
        const {recorder, recordType} = getState().Record;
        const {publisherHandle} = getState().Meet;
        console.log('recorder', recorder);
        if(check) {
            const recordEvent = {
                event : 'client_recording',
                status: 'stop',
                update: {
                    client_recording: 'stop'
                }
            }
            if(publisherHandle) {
                publisherHandle.send({message: recordEvent})
            }
        }
        recorder.stopRecording(function() {
            console.log('blobbbb', recorder);
            canVasArray.map(canvas => {
                if(canvas) {
                    const context = canvas.getContext('2d');
                    context.clearRect(0, 0, canvasWidth, canvasHeight)
                }
            })
            globalStreamArray.map(stream => {
                try {
                    // Try a MediaStreamTrack.stop() for each track
                    var tracks = stream.getTracks();
                    for(var mst of tracks) {
                        if(mst) {
                            mst.stop();
                            stream = null;
                        }
                    }
                } catch(e) {
                    // Do nothing if this fails
                }
            });
            globalStreamArray = [];
            try {
                getSeekableBlob(recorder.getBlob(), function(seekableBlob: any) {
                    console.log('seekableble blob', seekableBlob);
                    let blob: any = new Blob([seekableBlob], recordType === 'audio' ? {type: 'audio/mp3'}:  {type: 'video/mp4'});
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.download = 'Record ' + new Date().getTime();
                    link.href = url;
                    recorder.reset()
                   // recorder.reset();
                    recorder.destroy();
                    blob = null;
                    seekableBlob = null;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(setRecorder(null, '', ''));
                    // });
                });
            } catch (e) {
                console.log('without seekable blob');
                let blob: any = new Blob([recorder.getBlob()], recordType === 'audio' ? {type: 'audio/mp3'}:  {type: 'video/mp4'});
                // recorder.stop(function() {
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.download = 'Record ' + new Date().getTime();
                link.href = url;
                recorder.reset()
               // recorder.reset();
                recorder.destroy();
                blob = null;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                dispatch(setRecorder(null, '', ''));
            }
            videoRecordInterval.map(interval => {
                if(interval) {
                    clearInterval(interval);
                    interval = null;
                }
            })
            videoRecordInterval = [];

            //let blob = recorder.getBlob();
            // let blob = new Blob([recorder.blob], recordType === 'audio' ? {type: 'audio/mp3'}:  {type: 'video/mp4'});
            //
            // const url = URL.createObjectURL(blob);
            // const link = document.createElement("a");
            // link.download = 'Record ' + new Date().getTime();
            // link.href = url;
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
            // dispatch(setRecorder(null, '', ''));
        });
    }
}



