import React, { useState } from "react";
// @ts-ignore
import { Janus } from "janus-gateway";
import classes from "./menu.module.scss";
import cx from "classnames";
import IconButton from "../../../components/HoolvaButtons/IconButtons/iconButton";
import QuickChat from "../../../components/QuickChat/quickChat";
import QuickMenu from "../../../components/QuickMenu/quickMenu";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Actions from "../../../store/actions";
import DetectRTC from "detectrtc";
import HoolvaAlertMessage from "../../../components/HoolvaAlertMessage/hoolvaAlertMessage";
// @ts-ignore
const useStyles = makeStyles(({ theme }) => ({
  fluid: {
    backgroundColor: theme.videoRoomTernaryColor,
  },
  button: {
    backgroundColor: theme.videoRoomTernaryColor,
    border: "1px solid " + theme.videoRoomTernaryColor,
    color: theme.iconColor,
    "&hover": {
      backgroundColor: theme.iconHoverBGColor,
      color: theme.iconHoverColor,
    },
  },
  mainColor:{
    color:theme.button1BGColor,
},

  notiCount: {
    backgroundColor: theme.button1BGColor + "!important",
    color: theme.videoRoomTernaryColor + "!important",
  },
  exit: {
    color: theme.iconColor,
  },
}));
const Menu = (props: any) => {
  const theme = useStyles(props);
  const { localRecordState } = useSelector((state: any) => state.Record);
  const dispatch = useDispatch();
  const {
    chatNotificationCount,
    initScreenShare,
    toggleAudio,
    toggleVideo,
    localScreenShareMode,
    stopScreenShare,
    participantList,
    audio,
    video,
    toggleChat,
    togglePeople,
    chat,
    people,
    fluid,
    toggleQuickChat,
    setEndModal,
    quickChat,
    setSettingsModal,
    reconnectAudio,
    raiseHand,
    requestRaiseHand,
    requestLowerHand,
    allowUnMute,
    activeScreen,
    t,
    fullSStatus,
    connectivityMode,
    recordState,
    meetingInfo,
    shortMenuOpt,
    setShortMenu,
    shortMenu,
    screenShareButtonState,
    localCloudRecordingStatus,
    setRecordModal,
    stopRecording,
    recordFlag,
    setRecordFlag
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [delayHandler, setDelayHandler] = useState(0);

  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const { systemState } = useSelector((state: any) => state.Hoolva);
  const {
    audioPermission,
    videoPermission,
    speechMode,
    publisherHandle,
    disableVideoButton,
    isWebCam,
  } = useSelector((state: any) => state.Meet);


  const Prevent = (e: any) => {
    setShortMenu(!shortMenu);
    e.stopPropagation();
  };
  const ToggleMenu = () => {
    setShortMenu(!shortMenu);
  };
  const handleMouseEnter = () => {
    setDelayHandler(
      window.setTimeout(() => {
        toggleQuickChat(true);
      }, 500)
    );
  };

  const handleMouseLeave = () => {
    clearTimeout(delayHandler);
  };

  const handleConfirm = () => {
    setShowConfirm(false);
  };
  const ChangeQuickChat = (status: boolean) => {
    toggleQuickChat(status);
  };
  const TabSwitch = (mode: string) => {
    if (mode === "chat") {
      togglePeople(false);
      toggleChat(!chat);
    } else {
      togglePeople(!people);
      toggleChat(false);
    }
  };
  const handleSendReaction = (reaction: string) => {
    const formattedMessage = {
      event: "send_message",
      type: "reaction",
      message: reaction,
    };
    if (publisherHandle) {
      publisherHandle.send({ message: formattedMessage });
    }
  };
  const handleQuickChatMessage = (message: string) => {
    const formattedMessage = {
      event: "send_message",
      type: "text",
      message,
    };
    if (publisherHandle) {
      publisherHandle.send({ message: formattedMessage });
    }
  };
  const getCookie = (name: string) => {
    setSettingsModal(true);
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");
    console.log("cookieArr", cookieArr);

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");

      /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
      if (name == cookiePair[0].trim()) {
        // Decode the cookie value and return
        console.log(
          "cookiePair[1]",
          cookiePair[1],
          decodeURIComponent(cookiePair[1])
        );
        return decodeURIComponent(cookiePair[1]);
      }
    }

    // Return null if not found
    return null;
  };
  const showAlertMessage = () => {
    DetectRTC.load(function () {
      if (audioPermission !== "granted" && videoPermission !== "granted") {
        if (audioPermission === "denied" && videoPermission === "denied") {
          setConfirmTitle(t("previewPage.deviceBlockedTitle"));
          setConfirmMessage(t("previewPage.deviceBlockedMessage"));
        } else if (
          audioPermission === "prompt" &&
          videoPermission === "prompt"
        ) {
          setConfirmTitle(t("previewPage.allowDevicePermissionTitle"));
          setConfirmMessage(t("previewPage.allowDevicePermissionMessage"));
        } else if (audioPermission === "prompt" && !DetectRTC.hasWebcam) {
          setConfirmTitle(t("previewPage.allowMicrophonePermissionTitle"));
          setConfirmMessage(t("previewPage.allowMicrophonePermissionMessage"));
        } else if (!DetectRTC.hasMicrophone && videoPermission === "prompt") {
          setConfirmTitle(t("previewPage.allowVideoPermissionTitle"));
          setConfirmMessage(t("previewPage.allowVideoPermissionMessage"));
        } else if (audioPermission === "denied" && !DetectRTC.hasWebcam) {
          setConfirmTitle(t("previewPage.microphoneBlockedTitle"));
          setConfirmMessage(t("previewPage.microphoneBlockedMessage"));
        } else if (!DetectRTC.hasMicrophone && videoPermission === "denied") {
          setConfirmTitle(t("previewPage.webCamBlockedTitle"));
          setConfirmMessage(t("previewPage.webCamBlockedMessage"));
        } else if (!DetectRTC.hasMicrophone && !DetectRTC.hasWebcam) {
          setConfirmTitle(t("previewPage.noDeviceFoundTitle"));
          setConfirmMessage(t("previewPage.noDeviceFoundMessage"));
        }
        setShowConfirm(true);
      }
    });
  };
  const handleRecord = () => {
    console.log("recordFlag",recordFlag);
    if (recordFlag === '') {
      if (meetingInfo.e2ee) {
        if (meetingInfo.record_mode === 'host_with_others') {
          dispatch(Actions.startRecording('video', 'gallery', false))
        } else if (meetingInfo.record_mode === 'audio_only') {
          console.log('meetingInfo.record_mode 3333', meetingInfo.record_mode);
          dispatch(Actions.startRecording('audio', 'host', false))
        } else if (meetingInfo.record_mode === 'host_only') {
          dispatch(Actions.startRecording('video', 'host', false))
        }
        setRecordFlag('L');
      } else {
        setRecordModal(true);
      }
    } else if (recordFlag === 'L') {
      dispatch(Actions.stopRecording(true));
      setRecordFlag('');
    } else if (recordFlag === 'C') {
      dispatch(Actions.sendRecordRequest("configure", false));
      dispatch(Actions.setRecordState(false));
      setRecordFlag('');
    }
  };

  return (
    <div
      className={cx(
        classes.buttonPos,
        chat || people
          ? activeScreen === 2 && !fluid
            ? fullSStatus
              ? classes.chatButtons
              : classes.SpeakerchatButtons
            : classes.chatButtons
          : activeScreen === 2 && !fluid
            ? fullSStatus
              ? null
              : classes.SpeakerButtons
            : "",
        fluid ? classes.fluid : classes.PosChange,
        fluid ? theme.fluid : ""
      )}
    >
      {!shortMenuOpt ? (
        <IconButton
          classname={[classes.button, classes.settings, theme.button]}
          tooltipId="settings"
          tooltipText={t("meetingPage.menu.settings")}
          clicked={() => getCookie("hoolva-app")}
          iconName={"cog"}
          iconType={"fas"}
          nameBar={fluid ? t("meetingPage.menu.settings") : false}
        />
      ) : null}
      {speechMode && !audio ?
        <div className={cx(classes.speechDetection)}>
          <span style={{ color: 'white' }}>
            <FontAwesomeIcon className={theme.mainColor} icon={['fas', 'microphone-slash']} />
            {t('meetingPage.youAreOnMute')}
          </span>
        </div>
      : null }
      {!systemState.hasMicrophone || audioPermission === "denied" ? (
        <IconButton
          classname={[classes.button, theme.button, classes.danger]}
          clicked={() => showAlertMessage()}
          tooltipId={
            !systemState.hasMicrophone
              ? "no_audio_device"
              : "no_audio_permission"
          }
          tooltipText={
            !systemState.hasMicrophone
              ? t("meetingPage.menu.noMicrophone")
              : t("meetingPage.menu.noMicroPhonePermission")
          }
          iconName={"microphone-slash"}
          iconType={"fas"}
          nameBar={
            fluid
              ? !systemState.hasMicrophone
                ? t("meetingPage.menu.noMicroPhoneShort")
                : t("meetingPage.menu.noMicroPhonePermissionShort")
              : false
          }
        />
      ) : (
        <IconButton
          classname={[
            classes.button,
            fluid ? classes.unmute : "",
            theme.button,
            connectivityMode === "phone" ? classes.disabled : "",
          ]}
          clicked={() => toggleAudio(!audio)}
          tooltipId={audio ? "unmute_audio" : "mute_audio"}
          tooltipText={
            audio
              ? t("meetingPage.menu.audioMute")
              : t("meetingPage.menu.audioUnMute")
          }
          iconName={audio ? "microphone" : "microphone-slash"}
          iconType={"fas"}
          disabled={!allowUnMute || connectivityMode === "phone" ? true : false}
          nameBar={
            fluid
              ? audio
                ? t("meetingPage.menu.audioMute")
                : t("meetingPage.menu.audioUnMute")
              : false
          }
        />
      )}

      {!systemState.hasWebcam || videoPermission === "denied" || !isWebCam ? (
        <IconButton
          classname={[classes.button, theme.button, classes.danger]}
          clicked={() => showAlertMessage()}
          tooltipId={!systemState.hasWebcam || !isWebCam ? "no_webcam" : "permission_denied"}
          tooltipText={
            !systemState.hasWebcam || !isWebCam
              ? t("meetingPage.menu.noWebCam")
              : t("meetingPage.menu.noWebCamPermission")
          }
          iconName={"video-slash"}
          iconType={"fas"}
          nameBar={
            fluid
              ? !systemState.hasWebcam
                ? t("meetingPage.menu.noWebCamShort")
                : t("meetingPage.menu.noWebCamPermissionShort")
              : false
          }
        />
      ) : (
        <IconButton
          classname={[classes.button, theme.button]}
          disabled={disableVideoButton}
          clicked={() => toggleVideo(!video)}
          tooltipId={"start_video"}
          tooltipText={
            video
              ? t("meetingPage.menu.stopVideo")
              : t("meetingPage.menu.startVideo")
          }
          iconName={video ? "video" : "video-slash"}
          iconType={"fas"}
          nameBar={
            fluid
              ? video
                ? t("meetingPage.menu.stopVideo")
                : t("meetingPage.menu.startVideo")
              : false
          }
        />
      )}
      {shortMenuOpt && shortMenu ? (
        <QuickMenu
          toggleAudio={toggleAudio}
          fluid={fluid}
          audio={audio}
          systemState={systemState}
          audioPermission={audioPermission}
          showAlertMessage={showAlertMessage}
          toggleVideo={toggleVideo}
          getCookie={getCookie}
          meetingInfo={meetingInfo}
          videoPermission={videoPermission}
          connectivityMode={connectivityMode}
          reconnectAudio={reconnectAudio}
          localScreenShareMode={localScreenShareMode}
          stopScreenShare={stopScreenShare}
          initScreenShare={initScreenShare}
          TabSwitch={TabSwitch}
          setShortMenu={setShortMenu}
          participantList={participantList}
          requestLowerHand={requestLowerHand}
          requestRaiseHand={requestRaiseHand}
          chatNotificationCount={chatNotificationCount}
          raiseHand={raiseHand}
          video={video}
          t={t}
        />
      ) : null}
      {!shortMenuOpt ? (
        <span>
          {meetingInfo && !meetingInfo?.e2ee ? 
            connectivityMode !== "phone" ? (
              <IconButton
                classname={[classes.button, theme.button]}
                clicked={() => reconnectAudio(true)}
                tooltipId="connect_audio"
                tooltipText={t("meetingPage.menu.switchAudio")}
                iconName={"exchange-alt"}
                iconType={"fas"}
                nameBar={fluid ? t("meetingPage.menu.switchAudioShort") : false}
              />
            ) : (
              <IconButton
                classname={[classes.button, theme.button]}
                clicked={() => reconnectAudio(true)}
                tooltipId="connect_audio"
                tooltipText={t("ConnectAudio")}
                iconName={"plug"}
                iconType={"fas"}
                nameBar={fluid ? t("meetingPage.menu.ConnectAudioShort") : false}
              />
            ):null}

          {localScreenShareMode ? (
            <IconButton
              classname={[classes.button, classes.screenShare, theme.button]}
              clicked={() => stopScreenShare()}
              tooltipId="stop_share"
              tooltipText={t("meetingPage.menu.stopShare")}
              style={{ color: "orangered" }}
              iconName={"square"}
              iconType={"fas"}
              nameBar={fluid ? t("meetingPage.menu.stopShareShort") : false}
            />
          ) : (
            <IconButton
              classname={[classes.button, theme.button]}
              clicked={() => initScreenShare()}
              disabled={!screenShareButtonState}
              tooltipId="screen_share"
              tooltipText={t("meetingPage.menu.startShare")}
              iconName={"presentation"}
              iconType={"fas"}
              nameBar={fluid ? t("meetingPage.menu.startShareShort") : false}
            />
          )}
          <IconButton
            classname={[classes.button, theme.button]}
            clicked={() => TabSwitch("people")}
            tooltipId="people"
            tooltipText={t("meetingPage.menu.people")}
            iconName={"users"}
            iconType={"fas"}
            count={participantList.length}
            notiCount={[theme.notiCount]}
            nameBar={fluid ? t("meetingPage.menu.people") : false}
          />
          {/* {meetingInfo && meetingInfo.e2ee ? 
          null
          : */}
          <IconButton
          classname={[classes.button, theme.button]}
          clicked={() => TabSwitch("chat")}
          // hover={() => tryQuickChat()}
          MouseEnter={() => handleMouseEnter()}
          leave={() => handleMouseLeave()}
          // tooltipId={chat ? 'stop_chat' : 'start_chat'}
          // tooltipText={chat ? 'Chat' : 'Chat'}
          iconName={chat ? "comment-alt" : "comment-alt"}
          iconType={"fas"}
          count={chatNotificationCount}
          nameBar={fluid ? t("meetingPage.menu.chat") : false}
        />
          {quickChat && !chat ? (
            <QuickChat
              t={t}
              quickChat={quickChat}
              ChangeQuickChat={ChangeQuickChat}
              sendMsg={handleQuickChatMessage}
              sendReaction={handleSendReaction}
            />
          ) : null}
          {raiseHand ? (
            <IconButton
              classname={[classes.button, classes.hand, theme.button]}
              clicked={() => requestLowerHand()}
              tooltipId="lower_hand"
              tooltipText={t("meetingPage.menu.lowerHand")}
              iconName={"hand-paper"}
              iconType={"fas"}
              rotate={180}
              nameBar={fluid ? t("meetingPage.menu.lowerHandShort") : false}
            />
          ) : (
            <IconButton
              classname={[classes.button, classes.hand, theme.button]}
              clicked={() => requestRaiseHand()}
              tooltipId="raise_hand"
              tooltipText={t("meetingPage.menu.raiseHand")}
              iconName={"hand-paper"}
              iconType={"fas"}
              nameBar={fluid ? t("meetingPage.menu.raiseHandShort") : false}
            />
          )}
          {meetingInfo && meetingInfo.e2ee ? 
          null
          :meetingInfo?.host ? (
            <IconButton
              classname={[
                classes.button,
                classes.hand,
                theme.button,
                localCloudRecordingStatus === 'start' || recordFlag !== '' ? classes.Recording : null,
              ]}
              clicked={() => handleRecord()}
              tooltipId="record"
              tooltipText={
                localCloudRecordingStatus === 'start' || recordFlag !== ''
                  ? t("meetingPage.menu.stopRecord")
                  : t("meetingPage.menu.startRecord")
              }
              iconName={localCloudRecordingStatus === 'start' ? "record-vinyl" : "record-vinyl"}
              iconType={"fas"}
              rotate={180}
              nameBar={
                fluid
                  ? localCloudRecordingStatus === 'start' || recordFlag !== ''
                    ? t("meetingPage.menu.stopShortRecord")
                    : t("meetingPage.menu.startShortRecord")
                  : false
              }
            />
          ) : // <span onClick={()=>handleRecord(recordState)} className={cx(recordState ? classes.Stop:null,theme.button, classes.recording)}>
            //     <FontAwesomeIcon icon={['fas', 'record-vinyl']} />
            //     {recordState ? 'Stop':'Rec'}
            // </span>
            null}
        </span>
      ) : (
        <span style={{ display: 'inline-flex' }} onClick={(e) => Prevent(e)}>
          <IconButton
            classname={[classes.button, theme.button]}
            tooltipId="Menu"
            tooltipText={t("meetingPage.menu.menu")}
            //    clicked={() => ToggleMenu()}
            iconName={"bars"}
            iconType={"fas"}
            nameBar={fluid ? t("meetingPage.menu.menu") : false}
          //    shortMenu
          />
        </span>
      )}
      <IconButton
        classname={[classes.button, classes.exit, theme.button, theme.exit]}
        clicked={() => setEndModal(true)}
        tooltipId="end_meeting"
        tooltipText={t("meetingPage.menu.leaveMeeting")}
        iconName={"portal-exit"}
        iconType={"fad"}
        nameBar={fluid ? t("meetingPage.menu.leaveMeetingShort") : false}
      />
      <HoolvaAlertMessage
        open={showConfirm}
        mode={true}
        t={t}
        title={confirmTitle}
        message={confirmMessage}
        confirm={() => handleConfirm()}
      />
    </div>
  );
};
export default React.memo(Menu);
