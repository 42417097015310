import React from 'react'
import cx from "classnames";
import classes from "./hoolva.module.scss";
import {withNamespaces} from "react-i18next";
import Logo from "../Logo/Logo";

const HoolvaSplashScreen = ({ t }: any, props: any) => {
    return (
        <div className={cx(classes.hoolva)}>
            <div className={cx(classes.main, classes.flex)}>
                <div  className={cx(classes.content)}>
                    <div className={cx(classes.logo)}>
                        <Logo displayTheme="lite"/>
                    </div>
                    <div className={cx(classes.title)}>{t('splashScreen.loading')}</div>
                </div>
            </div>
        </div>
    )
}
export default withNamespaces()(HoolvaSplashScreen);
