import * as Config from '../../Config/config'

let brandName = Config.configuration.brandName;
if (Config.configuration.brandName === 'Hoolva') {
    brandName = "ഹൂൽവ"
}
let msg = {};
export const translationML =
{
    "brandName": brandName,
    "splashScreen": {
        "loading": "ലോഡിംഗ്..."
    },
    "layout": {
        "noInternet": "ഇൻറ്റർനെറ്റ് ഇല്ല",
        "backOnline": "താങ്കൾ വീണ്ടും ഓൺലൈൻ ആണ്"
    },
    "homePage": {
        "domainPlaceHolder": "ഐപി അല്ലെങ്കിൽ ഡൊമെയ്ൻ നാമം",
        "joinButton": "മീറ്റിംഗിൽ ചേരുക",
        "loginButton": "ലോഗിൻ",
        "noAccount": "അംഗത്വമില്ലേ?",
        "createAccount": "അംഗത്വമെടുക്കുക",
        "logoutButton": "ലോഗൗട്ട്",
        "cancelButton": "റദ്ദാക്കുക",
        "startMeetingButton": "മീറ്റിംഗ് ആരംഭിക്കുക",
        "meetingTab": "മീറ്റിംഗ്‌സ്",
        "chatTab": "ചാറ്റ്",
        "scheduleTab": "ഷെഡ്യൂൾ",
        "recordingTab": "റെക്കോർഡിംഗ്",
        "upcomingMeeting": "ഇനി വരാനിരിക്കുന്ന",
        "personalMeeting": "വ്യക്തിഗതം",
        "scheduleMeetingButton": "മീറ്റിംഗ് ഷെഡ്യൂൾ",
        "personalMeetingRoomPlaceHolder": "വ്യക്തിഗതം",
        "meetingIdLabel": "മീറ്റിംഗ് ഐഡി",
        "meetingIdPlaceHolder": "മീറ്റിംഗ് ഐഡി",
        "passwordLabel": "പാസ്വേർഡ്",
        "passwordPlaceHolder": "പാസ്വേർഡ്",
        "saveButton": "സേവ് ചെയ്യുക",
        "editButton": "എഡിറ്റ് ചെയ്യുക",
        "deleteButton": "ഇല്ലാതാക്കുക",
        "showInvitationButton": "ക്ഷണം കാണിക്കുക",
        "copyInvitationButton": "ക്ഷണം പകർത്തുക",
        "ViewProfile": "വ്യക്തിവിവരങ്ങൾ കാണുക",
        "ChangePassword": "പാസ്വേഡ് മാറ്റുക"
    },
    "loginPage": {
        "title": "ലോഗിൻ",
        "emailPlaceHolder": "ഇമെയിൽ",
        "passwordPlaceHolder": "പാസ്വേർഡ്",
        "rememberMe": "ഓർത്തിരിക്കുക",
        "forgotPassword": "താങ്കൾ പാസ്വേർഡ് മറന്നോ ?",
        "cancelButton": "റദ്ദാക്കുക",
        "loginButton": "ലോഗിൻ",
        "loginWithFaceBook": "ഫേസ്ബുക്ക് ലോഗിൻ",
        "loginWithGoogle": "ഗൂഗിൾ ലോഗിൻ",
        "loginError": "ഇമെയിൽ/പാസ്വേർഡ് അസാധുവാണ്"
    },
    "joinPage": {
        "title": "മീറ്റിംഗിൽ ചേരുക",
        "meetingIdText": "മീറ്റിംഗ് ഐഡി",
        "submitButton": "സമർപ്പിക്കുക",
        "cancelButton": "റദ്ദാക്കുക",
        "connectingText": "കണക്റ്റ് ചെയുന്നു",
        "noInternet": "ഇൻറ്റർനെറ്റ് ഇല്ല",
        "wrongMeetingId": "മീറ്റിംഗ് ഐഡി അസാധുവാണ്",
        "invalidMeetingId": "മീറ്റിംഗ് ഐഡി 10 അക്കങ്ങളായിരിക്കണം"

    },
    "previewPage": {
        "title": "മീറ്റിംഗിലേക്ക് പോകുക",
        "meetingIdLabel": "മീറ്റിംഗ് ഐഡി",
        "passwordPlaceHolder": "പാസ്വേർഡ്",
        "displayNamePlaceHolder": "പ്രദർശന നാമം",
        "cancelButton": "റദ്ദാക്കുക",
        "connectingText": "കണക്റ്റ് ചെയുന്നു",
        "joinWithDevice": "ചേരുക",
        "joinWithMicrophone": "മൈക്ക് ഉപയോഗിച്ച് ചേരുക",
        "joinWithWebCam": "ക്യാമറ ഉപയോഗിച്ച് ചേരുക",
        "joinWithOutDevice": "ക്യാമറയും മൈക്കും ഓഫാക്കി ചേരുക",
        "deviceText": "ഉപകരണം തിരഞ്ഞെടുക്കുക",
        "invalidPassword": "പാസ്വേർഡ് അസാധുവാണ്",
        "noInternet": "ഇൻറ്റർനെറ്റ് ഇല്ല",
        "deviceBlockedTitle": "ക്യാമറയും മൈക്കും ബ്ലോക്കാണ്.",
        "deviceBlockedMessage": "താങ്കളുടെ ക്യാമറയും മൈക്കും ആക്സസ് ചെയ്യേണ്ടതുണ്ട്. അഡ്രസ് ബാറിലെ ക്യാമറ ബ്ലോക്ക് ചെയ്‌ത ഐക്കണിൽ ക്ലിക്കുചെയ്യുക.",
        "allowDevicePermissionTitle1": "താങ്കളുടെ ക്യാമറയും മൈക്കും ഉപയോഗിക്കാൻ ",
        "allowDevicePermissionTitle2": "യെ അനുവദിക്കുക.",
        "allowDevicePermissionMessage1": "മറ്റുള്ളവർക്ക് താങ്കളെ കാണാനും കേൾക്കാനും കഴിയാൻ വേണ്ടി " ,
        "allowDevicePermissionMessage2":"യ്ക് താങ്കളുടെ ക്യാമറയും മൈക്കും ആക്‌സസ് ചെയ്യേണ്ടതുണ്ട്.",
        "allowMicrophonePermissionTitle1": "താങ്കളുടെ മൈക്ക് ഉപയോഗിക്കാൻ " ,
        "allowMicrophonePermissionTitle2": "യെ അനുവദിക്കുക.",
        "allowMicrophonePermissionMessage1": "മറ്റുള്ളവർക്ക് താങ്കളെ കേൾക്കാൻ വേണ്ടി " ,
        "allowMicrophonePermissionMessage2": "യ്ക് താങ്കളുടെ മൈക്ക് ആക്‌സസ് ചെയ്യേണ്ടതുണ്ട്.",
        "allowVideoPermissionTitle1": "താങ്കളുടെ ക്യാമറ ഉപയോഗിക്കാൻ " ,
        "allowVideoPermissionTitle2":"യെ അനുവദിക്കുക.",
        "allowVideoPermissionMessage1": "മറ്റുള്ളവർക്ക് താങ്കളെ കാണാൻ വേണ്ടി ",
        "allowVideoPermissionMessage2": "യ്ക് താങ്കളുടെ ക്യാമറ ആക്‌സസ് ചെയ്യേണ്ടതുണ്ട്.",
        "microphoneBlockedTitle": "മൈക്ക് ബ്ലോക്ക്‌ഡ്‌ ആണ്",
        "microphoneBlockedMessage1":"",
        "microphoneBlockedMessage2":"യ്ക്ക് താങ്കളുടെ മൈക്ക് ആക്‌സസ് ചെയ്യേണ്ടതുണ്ട്.അഡ്രസ് ബാറിലെ മൈക്ക് ബ്ലോക്ക് ചെയ്‌ത ഐക്കണിൽ ക്ലിക്കുചെയ്യുക.",
        "webCamBlockedTitle": "ക്യാമറ ബ്ലോക്ക്‌ഡ്‌ ആണ്",
        "webCamBlockedMessage1":"",
        "webCamBlockedMessage2": "യ്ക്ക് താങ്കളുടെ ക്യാമറ ആക്‌സസ് ചെയ്യേണ്ടതുണ്ട്.അഡ്രസ് ബാറിലെ ക്യാമറ ബ്ലോക്ക് ചെയ്‌ത ഐക്കണിൽ ക്ലിക്കുചെയ്യുക.",
        "noDeviceFoundTitle": "ഓഡിയോ, വീഡിയോ ഉപകരണങ്ങളൊന്നും കണ്ടെത്തിയില്ല.",
        "noDeviceFoundMessage1":"",
        "noDeviceFoundMessage2":  "യ്ക്ക് താങ്കളുടെ മൈക്കും ക്യാമറയും ആക്‌സസ് ചെയ്യേണ്ടതുണ്ട്. ദയവായി ഓഡിയോ,വീഡിയോ ഉപകരണങ്ങൾ ഇൻസ്റ്റാൾ ചെയ്യുക അല്ലെങ്കിൽ ഉപകരണങ്ങൾ ഇല്ലാതെ ചേരുക.",
        "speakerTestPlay": "പ്ലേ",
        "speakerTestStop": "സ്റ്റോപ്പ്",
        "recordTestRecord": "റെക്കോർഡ്",
        "recordTestPlaying": "പ്ലേയിങ് ...",
        "recordTestStop": "സ്റ്റോപ്പ്"
    },
    "meetingPage": {
        "max_concurrent_limit_reached":"ഈ ഹോസ്റ്റിനായി മറ്റൊരു മീറ്റിംഗ് ഇതിനകം പുരോഗമിക്കുകയാണ്. ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക",
        "max_limit_reached":"ഈ മീറ്റിംഗ് അനുവദനീയമായ പങ്കാളികളുടെ പരമാവധി എണ്ണത്തിലെത്തി. ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക ",
        "max_duration_reached":"സമയ പരിധി കാരണം നിങ്ങളുടെ മീറ്റിംഗ് അവസാനിച്ചു. പരിധികളില്ലാതെ മീറ്റിംഗുകൾക്കായി നിങ്ങളുടെ പ്ലാൻ അപ്ഗ്രേഡ് ചെയ്യുക",
        "almost_duration_notification1":"മീറ്റിംഗ്",
        "almost_duration_notification2":"മിനിറ്റിൽ അവസാനിക്കും. പരിധികളില്ലാതെ മീറ്റിംഗുകൾ നടത്താൻ നിങ്ങളുടെ പ്ലാൻ അപ്ഗ്രേഡ് ചെയ്യുക ",
        "PleaseTryAgainLater":"ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക",
        "TimeOut":"ടൈം ഔട്ട്",
        "HostEndTheMeeting":"ഹോസ്റ്റ് മീറ്റിംഗ് അവസാനിപ്പിക്കുക",
        "MeetingEnded":"മീറ്റിംഗ് അവസാനിച്ചു",
        "UploadorDragandDropHere":"ഇവിടെ അപ്‌ലോഡ് ചെയ്യുക അല്ലെങ്കിൽ വലിച്ചിടുക",

    

        "topBar": {
            "invite": "ക്ഷണിക്കുക",
            "screenShareView": "സ്‌ക്രീൻ പങ്കിടൽ",
            "speakerView": "സ്പീക്കർ വ്യൂ",
            "galleryView": "ഗാലറി വ്യൂ",
            "stickyMode": "സ്റ്റിക്കി ലേഔട്ട്",
            "floatMode": "ഫ്ലോട്ട് ലേഔട്ട്",
            "fullScreen": "ഫുൾ സ്ക്രീൻ",
            "restore": "റീസ്റ്റോർ സ്ക്രീൻ",
            "lockMeeting": "ലോക്ക് മീറ്റിംഗ്",
            "openMeeting": "ഓപ്പൺ മീറ്റിംഗ്",
            
        },
        "inviteModal": {
            "title": "ഈ മീറ്റിംഗിലേക്ക് ആളുകളെ ക്ഷണിക്കുക",
            "inviteTextAreaPhonePlaceHolder": "കോമ, സ്‌പേസ് അല്ലെങ്കിൽ അർദ്ധവിരാമം എന്നിവയാൽ വേർതിരിച്ച ഫോൺ നമ്പറുകൾ നൽകുക അല്ലെങ്കിൽ പേസ്റ്റ് ചെയ്യുക",
            "inviteTextAreaEmailPlaceHolder": "കോമ, സ്പേസ് അല്ലെങ്കിൽ അർദ്ധവിരാമം എന്നിവയാൽ വേർതിരിച്ച ഇമെയിലുകൾ നൽകുക അല്ലെങ്കിൽ പേസ്റ്റ് ചെയ്യുക",
            "cancelButtonLabel": "റദ്ദാക്കുക",
            "phoneTabRightLabel": "നൽകിയ നമ്പറിലേക്ക് ഒരു കോൾ ആരംഭിക്കുകയും പങ്കെടുക്കുന്നയാൾ ഈ മീറ്റിംഗിൽ ചേരുന്നതിനുള്ള കോൾ സ്വീകരിക്കുകയും വേണം.",
            "inviteButtonLabel": "ക്ഷണിക്കുക",
            "invitingButtonLabel": "ക്ഷണിക്കുന്നു ..",
            "inviteSuccessButtonLabel": "വിജയം",
            "copyMeetingUrl": "മീറ്റിംഗ് URL പകർത്തുക",
            "copyMeetingInvite": "മീറ്റിംഗ് ക്ഷണം കോപ്പി ചെയ്യുക",
            "copyStatus": "കോപീഡ്",
            "TabPhone": "ഫോൺ",
            "TabEmail": "ഇമെയിൽ",
            "MeetingURL":"മീറ്റിംഗ് URL",
            "MeetingTopic":"മീറ്റിംഗ് വിഷയം",
            "MeetingID":"മീറ്റിംഗ് ഐഡി",
            "Password":"പാസ്‌വേഡ്",
            "PhonePIN":"ഫോൺ പിൻ",

        },
        "menu": {
            "settings": "സെറ്റിംഗ്സ്",
            "noMicrophone": "ഓഡിയോ ഉപകരണം ഇൻസ്റ്റാൾ ചെയ്‌തിട്ടില്ല",
            "noMicroPhonePermission": "മൈക്ക് ഉപയോഗിക്കാൻ അനുവദിക്കുക",
            "noMicroPhoneShort": "ഓഡിയോ ഇല്ല",
            "noMicroPhonePermissionShort": "അനുമതിയില്ല",
            "audioMute": "മ്യൂട്ട്",
            "audioUnMute": "അൺമ്യൂട്ട്",
            "noWebCam": "ഓഡിയോ ഉപകരണം ഇൻസ്റ്റാൾ ചെയ്‌തിട്ടില്ല",
            "noWebCamPermission": "ക്യാമറ ഉപയോഗിക്കാൻ അനുവദിക്കുക",
            "noWebCamShort": "വീഡിയോ ഇല്ല",
            "noWebCamPermissionShort": "അനുമതിയില്ല",
            "stopVideo": "സ്റ്റോപ്പ്",
            "startVideo": "സ്റ്റാർട്ട്",
            "switchAudio": "സ്വിച്ച് ഓഡിയോ",
            "switchAudioShort": "സ്വിച്ച്",
            "ConnectAudioShort": "കണക്റ്റ്",
            "ConnectAudio": "ഓഡിയോ കണക്റ്റുചെയ്യുക",
            "stopShare": "സ്റ്റോപ്പ് ഷെയർ",
            "stopShareShort": "സ്റ്റോപ്പ്",
            "startShare": "ഷെയർ സ്ക്രീൻ",
            "startShareShort": "ഷെയർ",
            "people": "അംഗങ്ങൾ",
            "chat": "ചാറ്റ്",
            "raiseHand": "കൈ ഉയർത്തുക",
            "raiseHandShort": "ഉയർത്തുക",
            "lowerHand": "കൈ താഴ്ത്തുക",
            "lowerHandShort": "താഴ്ത്തുക",
            "leaveMeeting": "മീറ്റിംഗിൽ നിന്നും പുറത്തു പോകുക",
            "leaveMeetingShort": "പുറത്തു പോകുക",
            "menu": "മെനു",
            "stopRecord": "റെക്കോർഡ് നിർത്തുക",
            "startRecord": "റെക്കോർഡ് ആരംഭിക്കുക",
            "stopShortRecord": "സ്റ്റോപ്പ്",
            "startShortRecord": "റെക്കോർഡ്"
        },
        "quickChat": {
            "typeMessage": "മെസ്സേജ് ടൈപ്പുചെയ്യുക"
        },
        "rightPanel": {
            "chat": {
                "title": "ചാറ്റ്",
                "typeMessage": "മെസ്സേജ് ടൈപ്പുചെയ്യുക",
                "saveChat": "സേവ് ചെയ്യുക",
                "saving": "സേവിങ്..."
            },
            "people": {
                "title": "അംഗങ്ങൾ",
                "inMeeting": "മീറ്റിംഗിൽ",
                "muteAll": "മ്യൂട്ട് ഓൾ",
                "unmuteAll": "അൺമ്യൂട്ട് ഓൾ",
                "allowUnMute": "അംഗങ്ങളെ അൺമ്യൂട്ട് ചെയ്യാൻ അനുവദിക്കുക",
                "Cancel":"റദ്ദാക്കുക",
                "Remove":"നീക്കം ചെയ്യുക",
                "Ok":"ഒകെ",
                "Allow":"അനുവദിക്കുക",
                "Deny":"നിഷേധിക്കുന്നു",
                "AllowAll":"എല്ലാം അനുവദിക്കുക",
                "DenyAll":"എല്ലാം നിഷേധിക്കുക",
                "Waiting":"കാത്തിരിക്കുന്നു",
            }
        },
        "leaveMeetingModal": {
            "title": "മീറ്റിംഗ് അവസാനിപ്പിക്കുക",
            "participantTitle": "മീറ്റിംഗിൽ നിന്നും പുറത്തു പോകുക",
            "message": "ഈ മീറ്റിംഗ് അവസാനിപ്പിക്കണമെന്ന് താങ്കൾക്ക് ഉറപ്പാണോ?",
            "participantMessage": "ഈ മീറ്റിംഗ് ഉപേക്ഷിക്കാൻ താങ്കൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
            "feedback": "ഫീഡ്‌ബാക്ക് നൽകുക",
            "cancelButton": "റദ്ദാക്കുക",
            "leaveMeeting": "മീറ്റിംഗിൽ നിന്നും പുറത്തു പോകുക",
            "participantleaveMeeting": "മീറ്റിംഗിൽ നിന്നും പുറത്തു പോകുക",
            "endAll": "മീറ്റിംഗ് അവസാനിപ്പിക്കുക"
        },
        "connectivityModal": {
            "title": "താങ്കളുടെ ഓഡിയോ കണക്റ്റിവിറ്റി തിരഞ്ഞെടുക്കുക",
            "computerAudio": "കമ്പ്യൂട്ടർ ഓഡിയോ",
            "phoneAudio": "ഡയൽ ഇൻ ചെയ്യുക",
            "callback": "ഡയൽ ഔട്ട് ചെയ്യുക",
            "computerAudioMessage1":"",
            "computerAudioMessage2":  " താങ്കളുടെ കമ്പ്യൂട്ടർ അന്തർനിർമ്മിത അല്ലെങ്കിൽ കണക്റ്റുചെയ്‌ത ഓഡിയോ ഉപകരണങ്ങൾ ഉപയോഗിക്കും.",
            "joinWithComputerAudio": "കമ്പ്യൂട്ടർ ഓഡിയോയിൽ ചേരുക",
            "leaveComputerAudio": "കമ്പ്യൂട്ടർ ഓഡിയോ ഉപേക്ഷിക്കുക",
            "joinWithPhone": "ഫോണിൽ ചേരുക",
            "joinWithCallback": "ഒരു കോൾ നേടുക"
        },
        "settings": {
            "title": "സെറ്റിംഗ്സ്",
            "general": "ജനറൽ",
            "profile": "പ്രൊഫൈൽ",
            "audioVideo": "ഓഡിയോ & വീഡിയോ",
            "theme": "തീമുകൾ",
            "about": "എബൌട്ട്",
            "language": "ഭാഷ",
            "profileNameVali": "ദയവായി കുറഞ്ഞത് 3 പ്രതീകങ്ങൾ നൽകുക",
            "dynamicBackground": "ഡൈനാമിക്  ബാക്ഗ്രൗണ്ടസ്",
            "topAndBottomBar": "മുകളിലും താഴെയുമുള്ള ബാർ",
            "participantCountInGalleryView": "ഗാലറി വ്യൂവിലെ ഓരോ പേജിലും പങ്കെടുക്കുന്നവർ",
            "changeProfile": "മാറ്റുക",
            "displayName": "പ്രദർശന നാമം",
            "displayNameChangeSuccessMessage": "പ്രദർശന നാമം വിജയകരമായി മാറ്റി",
            "updateDisplayName": "അപ്ഡേറ്റ് ചെയ്യുക",
            "viewProfile": "വ്യക്തിവിവരങ്ങൾ കാണുക",
            "video": "വീഡിയോ",
            "cam": "ക്യാമറ",
            "audio": "ഓഡിയോ",
            "mic": "മൈക്ക്",
            "speakers": "സ്പീക്കറുകൾ",
            "microphone": "മൈക്രോഫോൺ",
            "themeLite": "ലൈറ്റ്",
            "themeDark": "ഡാർക്ക്",
            "version": "വേർഷൻ"
        },
        "reconnecting": "റീകണക്റ്റിംഗ്...",
        "waitingToReconnect": "ദയവായി കാത്തിരിക്കുക ",
        "youAreOnMute": "താങ്കൾ മ്യൂട്ടിലാണ്",
        "unmuteMicrophoneRequestTitle": "മൈക്രോഫോൺ അൺമ്യൂട്ട് ചെയ്യാനുള്ള അഭ്യർത്ഥന",
        "unmuteMicrophoneRequestMessage": "താങ്കളുടെ മൈക്രോഫോൺ അൺമ്യൂട്ടുചെയ്യാൻ ഹോസ്റ്റ് അഭ്യർത്ഥിക്കുന്നു",
        "error4401": "താങ്കൾ ഇൻറ്റർനെറ്റ് കണക്റ്റുചെയ്‌തിട്ടുണ്ടെന്നും " + brandName + " യിലേക്കുള്ള താങ്കളുടെ കണക്ഷൻ ഒരു ഫയർവാൾ അല്ലെങ്കിൽ പ്രോക്‌സി തടഞ്ഞിട്ടില്ലെന്നും ഉറപ്പാക്കുക.",
        "error4401Title": "കണക്റ്റു ചെയ്യാൻ കഴിയുന്നില്ല",
        "errorOkLabel": "ഓക്കേ",
        "putMuteByHost": "ഹോസ്റ്റ് താങ്കളെ മ്യൂട്ട് ചെയ്തിരിക്കുന്നു",
        "kickOutTitle": "മീറ്റിംഗിൽ നിന്ന് നീക്കംചെയ്യുക",
        "kickOutConfirmMessage": "ഈ വ്യക്തിയെ മീറ്റിംഗിൽ നിന്ന് നീക്കംചെയ്യാൻ താങ്കൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
        "kickOutMessage": "ഹോസ്റ്റ് താങ്കളെ മീറ്റിംഗിൽ നിന്ന് നീക്കംചെയ്‌തു.",
        "turnOffVideoByHost": "താങ്കളുടെ വീഡിയോ ഹോസ്റ്റ് ഓഫാക്കി",
        "unmuteMicroPhoneRequestTitle": "മൈക്രോഫോൺ അൺമ്യൂട്ട് ചെയ്യാനുള്ള അഭ്യർത്ഥന",
        "unmuteMicroPhoneRequestMessage": "താങ്കളുടെ മൈക്രോഫോൺ അൺമ്യൂട്ടുചെയ്യാൻ ഹോസ്റ്റ് അഭ്യർത്ഥിക്കുന്നു",
        "startCameraRequestTitle": "ക്യാമറ സ്റ്റാർട്ട് ചെയ്യാൻ അഭ്യർത്ഥിക്കുന്നു",
        "startCameraRequestMessage": "താങ്കളുടെ വീഡിയോ സ്റ്റാർട്ട് ചെയ്യാൻ ഹോസ്റ്റ് അഭ്യർത്ഥിക്കുന്നു"
    },
    "schedule": {
        "mode": "മോഡ്",
        "topic": "വിഷയം",
        "meetingId": "മീറ്റിംഗ് ഐഡി",
        "password": "പാസ്വേർഡ്",
        "pin": "പിൻ",
        "RecurringCheckBox": "ആവർത്തിക്കുന്ന",
        "Recurrence": "ആവർത്തനം",
        "Occurrences": "സംഭവങ്ങൾ",
        "RecordMode": "റെക്കോർഡ് മോഡ്",
        "e2eEncryption": "E to E എൻക്രിപ്ഷൻ",
        "recordAutomatically": "റെക്കോർഡ് ഓട്ടോമാറ്റിക്കലി",
        "Security": "സെക്യൂരിറ്റി",
        "LockTheRoom": "റൂം ലോക്ക് ചെയ്യുക",
        "joinBeforeHost": "ഹോസ്റ്റിന് മുമ്പായി ചേരുക",
        "timeZone": "സമയ മേഖല",
        "DateAndTime": "തീയതിയും സമയവും",
        "dialInMode": "ഫോൺ ബ്രിഡ്ജ്",
        "date": "തീയതി",
        "time": "സമയം",
        "invite": "ക്ഷണിക്കുക",
        "security": "സെക്യൂരിറ്റി",
        "cancelButton": "റദ്ദാക്കുക",
        "hideInvitationButton": "ക്ഷണം മറയ്‌ക്കുക",
        "showInvitationButton": "ക്ഷണം കാണിക്കുക",
        "copyInvitationButton": "ക്ഷണം പകർത്തുക",
        "schedulebutton": "ഷെഡ്യൂൾ",
        "openMeeting": "തുറക്കുക",
        "deleteModalHeader": "മീറ്റിംഗ് ഇല്ലാതാക്കുക",
        "deleteModalTitle": "ഈ മീറ്റിംഗ് ഇല്ലാതാക്കാൻ താങ്കൾ ശരിക്കും ആഗ്രഹിക്കുന്നുണ്ടോ?",
        "deleteModalTitleRecentLink": "",
        "deleteModalButtonAllOccurance": "സംഭവിക്കാനിരിക്കുന്ന എല്ലാ മീറ്റിംഗുകളും ഇല്ലാതാക്കുക",
        "deleteModalButtonThisOccurance": "സംഭവിക്കാനിരിക്കുന്ന ഈ മീറ്റിംഗ് ഇല്ലാതാക്കുക",
        "deleteModalButtonThisMeeting": "ഈ മീറ്റിംഗ് ഇല്ലാതാക്കുക",
        "updateModalHeader": "മീറ്റിംഗ് എഡിറ്റുചെയ്യുക",
        "updateModalTitle": "ഈ മീറ്റിംഗ് എഡിറ്റുചെയ്യാൻ താങ്കൾ ശരിക്കും ആഗ്രഹിക്കുന്നുണ്ടോ?",
        "updateModalTitleRecentLink": "",
        "updateModalButtonAllOccurance": "സംഭവിക്കാനിരിക്കുന്ന എല്ലാ മീറ്റിംഗുകളും എഡിറ്റുചെയ്യുക",
        "updateModalButtonThisOccurance": "സംഭവിക്കാനിരിക്കുന്ന ഈ മീറ്റിംഗ് എഡിറ്റുചെയ്യുക",
        "updatePersonalMeetingButton": "എൻ്റെ മീറ്റിംഗ് എഡിറ്റുചെയ്യുക",
        "updatePersonalMeeting": "എൻ്റെ മീറ്റിംഗ് എഡിറ്റുചെയ്യുക",
        "noUpcomingMeetingsFound": "ഭാവിയിൽ മീറ്റിംഗുകളൊന്നും ഷെഡ്യൂൾ ചെയ്യപ്പെട്ടിട്ടില്ല",
        "noPersonalMeetingsFound": "എൻ്റെ മീറ്റിംഗുകളൊന്നും കണ്ടെത്തിയില്ല",
        "RepeatEvery": "എല്ലാം ആവർത്തിക്കുക",
        "Day": "ദിവസം",
        "Week": "ആഴ്ച",
        "Month": "മാസം",
        "copied": "കോപീഡ്",
        "Occurson": "സംഭവിക്കുന്നു",
        "EndDate": "അവസാന ദിവസം",
        "After": "ശേഷം",
        "OfTheMonth": "മാസത്തിലെ",
        "scheduleResponse":"മീറ്റിംഗ് ഷെഡ്യൂൾ വിജയകരമായി",
        "ScheduleMeeting":"മീറ്റിംഗ് ഷെഡ്യൂൾ",
        "Ok":"ഒകെ",
        "Cancel":"റദ്ദാക്കുക",
        "Invite":"ക്ഷണിക്കുക",
        "DeleteMeeting":"മീറ്റിംഗ് ഇല്ലാതാക്കുക",
        "editResponseMessage":"മീറ്റിംഗ് വിജയകരമായി ഇല്ലാതാക്കി",
        "MeetingInvite":"മീറ്റിംഗ് ക്ഷണം",
        "ScheduleMeeting":"ഷെഡ്യൂൾ മീറ്റിംഗ്",
        "editResponse":"മീറ്റിംഗ് വിജയകരമായി എഡിറ്റ് ചെയ്തു",
        "Remove":"നീക്കം ചെയ്യുക",
        "Startmyvideo":"എന്റെ വീഡിയോ ആരംഭിക്കുക",
        "IWillDoItLater":"ഞാൻ അത് പിന്നീട് ചെയ്യും",
        "Meeting invite sent successfully":"മീറ്റിംഗ് ക്ഷണം വിജയകരമായി അയച്ചു",
        "Inviting":"ക്ഷണിക്കുന്നു..",
        "StayMuted":"നിശബ്ദമായിരിക്കുക",
        "UnmuteNow":"ഇപ്പോൾ അൺമ്യൂട്ട് ചെയ്യുക",
        "WhereDoYouWantToStoreThisRecording":"ഈ റെക്കോർഡിംഗ് എവിടെയാണ് നിങ്ങൾ സൂക്ഷിക്കേണ്ടത്",
        "ThisComputer":"ഈ കമ്പ്യൂട്ടർ",
        "Cloud":"ക്ലൗഡ്",
        "Record":"രേഖപ്പെടുത്തുക",
       
    },
    "infoWindow": {
        "MeetingID":"മീറ്റിംഗ് ഐഡി",
        "Host":"ഹോസ്റ്റ്",
        "Password":"പാസ്‌വേഡ്",
        "Pin":"പിൻ",
        "InviteLink":"ക്ഷണ ലിങ്ക്",
        "Copied":"പകർത്തി",
        "CopyMeetingURL":"മീറ്റിംഗ് URL പകർത്തുക"
    },
    "feedBack": {
        "title": "താങ്കളുടെ  അഭിപ്രായം രേഖപ്പെടുത്തുക",
        "subTitle1":"",
        "subTitle2":" ഉപയോഗിച്ചതിന് നന്ദി. ",
        "subTitle3": " മികച്ചതാക്കാൻ ഞങ്ങളെ സഹായിക്കൂ.",
        "audioIssues": "ഓഡിയോ പ്രശ്നങ്ങൾ",
        "videoIssues": "വീഡിയോ പ്രശ്നങ്ങൾ",
        "suggestions": "മെച്ചപ്പെടുത്തുന്നതിനുള്ള നിർദ്ദേശങ്ങൾ",
        "cancelLabel": "റദ്ദാക്കുക",
        "submitLabel": "സമർപ്പിക്കുക",
        "feedBackTitle": "താങ്കളുടെ അഭിപ്രായത്തിന് നന്ദി.",
        "feedBackSuccess": "താങ്കളുടെ ഫീഡ്‌ബാക്ക് വിജയകരമായി സമർപ്പിച്ചു.",
        "echoIssue": "ഞാൻ പ്രതിധ്വനി കേട്ടു",
        "audioMissing": "പങ്കെടുത്തവരിൽ ഒരാളെ എനിക്ക് കേൾക്കാനായില്ല",
        "audioBlackout": "ഓഡിയോ ലഭിക്കുന്നില്ലാരുന്നു",
        "unexpectedEnd": "മീറ്റിംഗ് അപ്രതീക്ഷിതമായി അവസാനിച്ചു",
        "videoWasFrozen": "വീഡിയോ ഫ്രീസുചെയ്‌തു",
        "videoWasFlickering": "പങ്കെടുത്തവരുടെ വീഡിയോ മിന്നുന്നതായിരുന്നു",
        "myVideoWasNotShowingToThem": "എൻ്റെ വീഡിയോ മറ്റുള്ളവർക്ക് കാണിക്കുന്നില്ലാരുന്നു",
        "videoWasMissing": "പങ്കെടുത്തവരുടെ വീഡിയോ കാണിക്കുന്നില്ലാരുന്നു"
    },
    "Waiting": {
        "SitBackAndRelax": "ദയവായി കാത്തിരിക്കുക,",
        "HostWillSoonStartMeeting": "മീറ്റിംഗ് ആരംഭിക്കുന്നതിന് താങ്കളുടെ ഹോസ്റ്റ് ഉടൻ ഇവിടെയെത്തും.",
        "PleaseWait": "ദയവായി കാത്തിരിക്കൂ,",
        "HostWillSoonLetYouIn": "താങ്കളുടെ ഹോസ്റ്റ് ഉടൻ താങ്കളെ പ്രവേശിപ്പിക്കും.",
        "Joining": "ചേരുന്നു",
        "YouAreJoiningTheMeeting": "നിങ്ങൾ മീറ്റിംഗിൽ ചേരുന്നു.",
        "LeaveMeeting": "മീറ്റിംഗിൽ നിന്നും പുറത്തു പോകുക",
        "IfYouAreTheHost":"നിങ്ങൾ ഹോസ്റ്റ് ആണെങ്കിൽ",
        "toStartThisMeeting":"ഈ മീറ്റിംഗ് ആരംഭിക്കുക",
    },
    "WaitingModal": {
        "waitingModalTitle": "വെയ്റ്റിംഗ് റൂം",
        "waitingModalBody": "ഈ മീറ്റിംഗിൽ ചേരുന്നതിന് താങ്കളുടെ സമ്മതത്തിനായി ആളുകൾ കാത്തിരിക്കുന്നു.",
        "dontShow": "ഈ വിൻഡോ വീണ്ടും മീറ്റിംഗിൽ കാണിക്കരുത്.",
        "KnowWho": "റിവ്യൂ",
        "Admit": "സമ്മതിക്കുക",
        "AdmitAndOpen": "സമ്മതം കൊടുത്ത് ഓപ്പൺ ചെയ്യുക",
        "Deny": "നിരസിക്കുക",
        "Open": " & ഓപ്പൺ ചെയ്യുക"
    },
    "Reconnecting":{
        "Rejoin":"വീണ്ടും ചേരുക",
        "PoorNetwork":"മോശം നെറ്റ്‌വർക്ക്",
        "NetworkUnstable":"നിങ്ങളുടെ നെറ്റ്‌വർക്ക് അസ്ഥിരമാണെന്ന് തോന്നുന്നു. ലഭ്യമെങ്കിൽ മറ്റൊരു നെറ്റ്‌വർക്കിലേക്ക് മാറുക.",

    },
    "ScreenShare":{
        "ScreenShareMaxLimitExceeded":"സ്‌ക്രീൻ പങ്കിടൽ പരമാവധി പരിധി കവിഞ്ഞു"
    }
}
