import React, {useState, useEffect} from 'react';
import { withNamespaces } from 'react-i18next';
//@ts-ignore
import * as ebml from 'ebml';
import $ from "jquery";
import { initThemeObserver } from "./themeColors";
import classes from './App.module.scss';
import {applyMiddleware, combineReducers, createStore} from "redux";
import {Provider} from "react-redux";
import AuthReducer from "./store/reducers/auth.reducers";
import HoolvaReducer from "./store/reducers/hoolva.reducers";
import thunk from "redux-thunk";
import HoolvaTheme from "./themes/hoolvaTheme";
import MeetReducer from "./store/reducers/meet.reducers";
import {library} from "@fortawesome/fontawesome-svg-core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import NotifyMessageReducer from "./store/reducers/notifyMessage.reducer";
import { faLockAlt,faLockOpenAlt, faPresentation } from '@fortawesome/pro-solid-svg-icons';
import {  faWindowAlt, faKeyboard } from '@fortawesome/pro-regular-svg-icons';
import {
    faEdit,
    faComment,
    faCloud,
    faDesktop,
    faPaperPlane,
    faStopCircle,
    faArrowAltCircleRight,
    faCompressWide,
    faCompressArrowsAlt,
    faExpand,
    faCompress,
    faAngleDown, faFileUpload
} from '@fortawesome/pro-light-svg-icons';
import {
    faVolumeOff,
    faSortUp,
    faHeadset,
    faUserCircle,
    faTimes,
    faCamera,
    faEnvelope,
    faCog,
    faCogs,
    faUser,
    faCommentAlt,
    faVideoSlash,
    faVideo,
    faMicrophone,
    faUserPlus,
    faUsers,
    faShareSquare,
    faRecordVinyl,
    faPlug,
    faEllipsisV,
    faCircle,
    faMicrophoneSlash,
    faThLarge,
    faPalette,
    faSquare,
    faPlay,
    faInfo,
    faUserAltSlash,
    faChevronLeft,
    faChevronRight,
    faVolumeUp,
    faStickyNote,
    faLayerGroup,
    faSignOutAlt,
    faCheck,
    faEllipsisH,
    faPaperclip,
    faSmile,
    faThumbsUp,
    faHeart,
    faHandPaper,
    faHandRock,
    faWifi,
    faCrown,
    faBrush,
    faExchangeAlt,
    faBars,
    faInfoCircle,
    faCircleNotch, faFileImage, faFilePdf, faFileAlt,
    faExclamationCircle,
    faUserLock
} from '@fortawesome/pro-solid-svg-icons';
import {Router} from "react-router";
import Auth from "./Auth/auth";

import HoolvaMessage from "./components/HoolvaMesage/hoolvaMessage";
import {createBrowserHistory} from "history";
import HoolvaLayout from "./HoolvaLayout/HoolvaLayout";
import ScreenShareReducer from "./store/reducers/screenShare.reducer";
import HoolvaAuth from "./Auth/HoolvaAuth";
import {faUserCrown,faPortalExit,faCopy} from "@fortawesome/pro-duotone-svg-icons";
import ScheduleReducer from "./store/reducers/schedule.reducer";
import SettingsReducer from "./store/reducers/settings.reducer";
import SSO from "./Auth/sso";
import AudioRoomReducer from "./store/reducers/audioRoom.reducer";
import {env} from "../package.json";
import RecorderReducer from "./store/reducers/record.reducer";
import {faEye, faEyeSlash, faRedo} from "@fortawesome/free-solid-svg-icons";

library.add(
  faSmile,
  faMicrophoneSlash,
  faPaperPlane,
  faStopCircle,
  faEdit,
  faCommentAlt,
  faUserCircle,
  faVideo,
  faVideoSlash,
  faVolumeOff,
  faSortUp,
  faHandPaper,
  faRecordVinyl,
  faUserPlus,
  faUsers,
  faUser,
  faShareSquare,
  faSquare,
  faEnvelope,
  faFileUpload,
  faComment,
  faCloud,
  faCog,
  faCogs,
  faMicrophone,
  faHeadset,
  faDesktop,
  faCloud,
  faUserCircle,
  faArrowAltCircleRight,
  faCamera,
  faTimes,
  faPortalExit,
  faCompressWide,
  faCompressArrowsAlt,
  faExpand,
  faCompress,
  faPlug,
  faExchangeAlt,
  faCircle,
  faEllipsisV,
  faWindowAlt,
  faThLarge,
  faPalette,
  faInfo,
  faPlay,
  faUserAltSlash,
  faChevronLeft,
  faChevronRight,
  faVolumeUp,
  faStickyNote,
  faLayerGroup,
  faSignOutAlt,
  faCheck,
  faEllipsisH,
  faPaperclip,
  faThumbsUp,
  faHeart,
  faWifi,
  faHandRock,
  faPresentation,
  faUserCrown,
  faCrown,
  faAngleDown,
  faBrush,
  faInfoCircle,
  faBars,
  faLockAlt,
  faLockOpenAlt,
  faCopy,
  faCircleNotch,
  faFileImage,
  faFilePdf,
  faFileAlt,
  faKeyboard,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faRedo,
  faUserLock
);

/**
 * Add all reducers to root reducers
 */
const rootReducer = combineReducers({
  Auth: AuthReducer,
  Hoolva: HoolvaReducer,
  Meet: MeetReducer,
  Notify: NotifyMessageReducer,
    ScreenShare: ScreenShareReducer,
    Schedule: ScheduleReducer,
    Settings: SettingsReducer,
    AudioRoom: AudioRoomReducer,
    Record: RecorderReducer
});

/**
 * creating browser history stored in history variable
 * import `history` for navigation
 */
export const history = createBrowserHistory();

/**
 * Global redux store to manage global state
 */
const store = createStore(rootReducer, applyMiddleware(thunk));

/**
 * `useForceUpdate` to update the dom hard
 */
// @ts-ignore
const useForceUpdate = () => useState()[1].bind(null, {});


$("html").addClass("lite");

// @ts-ignore
const App = ({ t }) => {
  const forceUpdate = useForceUpdate();

    useEffect(() => {
        if(env === 'production') {
            document.addEventListener('contextmenu', event => event.preventDefault());
        }
    }, [])

  useEffect(() => {
    const themeObserver = initThemeObserver(onThemeChange);
    return(() => {
      if (themeObserver) {
        themeObserver.disconnect();
      }
    })
  }, [forceUpdate])

  /**
   * Function Name: onThemeChange
   * description: when ever theme change this function trigger, we need to update the dom
   */
  const onThemeChange = (): void => {
    console.log('force update function');
    forceUpdate();
  }
  // useEffect(() => {
  //     history.listen((event: any) => {
  //         console.log('history events', event);
  //     })
  // }, [])
    // useEffect(() => {
    //     history.listen((newLocation: any, action: any) => {
    //         console.log('history 123', history, newLocation)
    //         if (action === "PUSH") {
    //             window.location.hash = "#";
    //         } else {
    //             history.go(1);
    //         }
    //     })
            // if (action === "PUSH") {
            //     if (
            //         newLocation.pathname !== this.currentPathname ||
            //         newLocation.search !== this.currentSearch
            //     ) {
            //         // Save new location
            //         this.currentPathname = newLocation.pathname;
            //         this.currentSearch = newLocation.search;
            //
            //         // Clone location object and push it to history
            //         history.push({
            //             pathname: newLocation.pathname,
            //             search: newLocation.search
            //         });
            //     }
            // } else {
            //     // Send user back if they try to navigate back
            //     history.go(1);
            // }
    // }, [])



    return (
    <div className={classes.App}  >
      <Provider store={store}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Auth>
            <Router history={history}>
                <HoolvaAuth meetingId={store.getState().Hoolva.meetingId}>
                    <SSO>
                <HoolvaTheme>

                        <HoolvaLayout />

                </HoolvaTheme>
                    </SSO>
                </HoolvaAuth>
            </Router>
          </Auth>
          <HoolvaMessage />
          </MuiPickersUtilsProvider>
      </Provider>

    </div>
  );
}

// @ts-ignore
export default withNamespaces()(App);
