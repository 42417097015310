import React from 'react';
import cx from 'classnames';
import classes from '../chat.module.scss';
import Linkify from 'react-linkify';
import HoolvaAnimation from '../../../../../components/HoolvaAnimations/hoolvaAnimation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import XQMessageApi from '../../../../../Services/xqService/xqService';
// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
  RightMsg: {
    color: theme.textColor1 + ' !important',
  },
  LeftMsg: {
    color: theme.textColor1 + ' !important',
  },
  Msg: {
    color: theme.textColor3,
  },
  selectBar: {
    backgroundColor: theme.videoRoomTernaryColor,
    color: theme.textColor3 + '!important',
    '&:hover': {
      color: theme.iconHoverColor,
    },
  },
  fa: {
    color: theme.textColor3,
    '&:hover': {
      color: theme.iconHoverColor,
    },
  },
  settings: {
    color: theme.textColor3,
    '&:hover': {
      color: theme.iconHoverColor,
    },
  },
  selectDD: {
    color: theme.textColor3,
    '&:hover': {
      color: theme.iconHoverColor,
    },
    option: {},
  },
  TextArea: {
    backgroundColor: theme.videoRoomTernaryColor,
  },
  Text: {
    backgroundColor: theme.chatBGColor,
    color: theme.textColor1 + '!important',
  },
  FileUpload: {
    backgroundColor: theme.videoRoomTernaryColor,
    color:theme.textColor3 + "!important",
    '&:hover': {
      color:theme.iconHoverColor
    }
  },
}));
const Message = (props: any) => {
  const theme = useStyles(props);
  const { message, meetingInfo } = props;
  // const downloadFile = (message: any) => {
  //   if(message.status !== 'failed'){
  //     fetch(message.url)
  //     .then(response => {
  //       response.blob().then(blob => {
  //         let url = window.URL.createObjectURL(blob);
  //         let a = document.createElement('a');
  //         a.href = url;
  //         a.download = message.message;
  //         a.click();
  //       });
  //     });
  //   }
  // }
  const downloadFile = (message: any) => {
    if(message.status !== 'failed'){
      fetch(message.url)
      .then(response => {
          response.blob().then(async blob => {
            if(meetingInfo.e2ee) {
              var file = new File([blob], "decrypted_file.txt", {type: "text/plain"});
                await XQMessageApi.decryptFile(file)
                .then(async (response:any) => {
                    const blob = new Blob([response]);
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = message.message;
                    a.click();
                  }
                )
            }
            else {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = message.message;
              a.click();
            }
            }
          )
        }
      )
    }
  };

  return (
      <div key={message.timestamp} className={cx(!message.sender ? classes.left:classes.right)}>
        {message.sender ? (
            <span>
          You(
              {message.event === 'send_message'
                  ? message.participant_name
                  : 'Everyone'}
              )
        </span>
        ) : (
            <span>
          {message.participant_name}(
              {message.event === 'receive_message' ? 'Private' : 'Everyone'})
        </span>
        )}
        <div
            className={cx(classes.msgBox, message.status === 'failed' ? classes.failed: null)}
            style={!message.sender ? {justifyContent:'flex-start'}: {justifyContent: 'flex-end' }}
        >
          <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
          )}>
            {message.type === 'reaction' ? (
                <HoolvaAnimation
                    width={50}
                    height={50}
                    type={message.message}
                    loop={false}
                    autoplay={false}
                    hover={true}
                    completed={() => console.log()}
                />
            ) :message.type === 'file' ? (
                    <span onClick={() => !message.loading && message.status !== 'failed' ? downloadFile(message): null} className={cx(classes.fileUpload,message.status === 'failed' ? classes.notAllowed:null, theme.FileUpload, !message.loading ? classes.hover:classes.progress)}>
              <span className={cx(classes.fileIcon)}>
              {message.loading ? (
                      <FontAwesomeIcon  icon={['fas', 'circle-notch']} spin className={cx(classes.avatarSpin)}/>):
                  message.status === 'failed' ?
                      <FontAwesomeIcon icon={['fas', 'exclamation-circle']}/> : 
                  message.format === 'image/png' || message.format === 'image/jpeg' || message.format === 'image/jpg' ? (
                      <FontAwesomeIcon icon={['fas', 'file-image']}/>
                  ): message.format === "application/pdf" ? (
                      <FontAwesomeIcon icon={['fas', 'file-pdf']}/>
                  ): <FontAwesomeIcon icon={['fas', 'file-alt']}/>
              }

              </span>
              <span className={cx(classes.fileName)}>
                <div title={ message.status !== 'failed' ? message.message: 'Upload Failed'} className={cx(classes.overFlowWrap)}>{message.message}</div>
                {message.size}
              </span>
            </span>):
                (<span
                        className={cx(!message.sender ? theme.RightMsg : theme.LeftMsg, classes.leftText)}
                    >
              {message.message}

            </span>
                )}
          </Linkify>
        </div>
        <span>{moment(message.timestamp).fromNow()}</span>
        { message.status !== 'failed' && message.status ? (
            <span style={{ marginLeft: '6px' }}>
          <FontAwesomeIcon icon={['fas', 'check']} />
        </span>
        ) : null}
      </div>
  );
};
export default Message;
