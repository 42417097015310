import {map, pickBy, transform, intersection, trim} from 'lodash';
// @ts-ignore
import themeColors from './theme.module.scss';

const DEFAULT_THEME = "lite";

const themes = map(themeColors.themes.split(","), val => trim(val));

/**
*  For each theme variable, we pick the ones that start with "theme_".
*  These will be our theme variables as theme_themeName_variableName.
*  This will output a flat object of variable names with their values.
**/

const themeVars = pickBy(themeColors, (value, key): boolean => {
    if (key === "themes") return false;
    if (key.indexOf("theme_") !== 0) return false;
    return true;
});

/***
 *  This transforms the flat object from above into a nested object like so:
 * {
 *  themeName: {
 *     variableName: value,
 *     ...
 *   },
 *   ...
 * }
**/

interface Window {
    [key:string]: any; // Add index signature
}

const byTheme = transform(
    themeVars,
    (result: any, value, key: any) => {
        const parts = key.split("_");
        const theme = parts[1];
        const variable = parts[2];
        result[theme] = result[theme] || {};
        result[theme][variable] = value;
    },
    {}
);

/*
  gets the current theme the user has applied, from the html el classname
*/
export function getCurrentTheme() {
    const htmlClasses = document.querySelector("html")?.className.split(/\s+/);
    const matches = intersection(themes, htmlClasses);
    return matches[0] || DEFAULT_THEME;
    // const {displayTheme} = useSelector((state:any)=>state.Hoolva);
    // return 'lite';
}

/*
  similar to the scss theme() method - returns the color for the current theme
*/
export default function theme(variableName: string) {
    const currentTheme = getCurrentTheme();
    const variables = byTheme[currentTheme];
    return variables[variableName];
}

export function getTheme() {
    // console.log("currentTheme",currentTheme);currentTheme: string
    const currentTheme = getCurrentTheme();
    const variables = byTheme[currentTheme];
    return variables;
}

/* *************** functions below here are not generally needed except at the root app level ***************** */

function onMutationChange(onThemeChange : any, mutationsList: any[]) {
    let wasUpdated = false;
    const currentTheme = getCurrentTheme();

    mutationsList.forEach(mutation => {
        if (mutation.attributeName === "class") {
            // @ts-ignore
            if (currentTheme !== window.__currentTheme) {
                wasUpdated = true;
            }
        }
    });

    if (wasUpdated) {
        // @ts-ignore
        onThemeChange(window.__currentTheme, currentTheme);
        // @ts-ignore
        window.__currentTheme = currentTheme;
    }
}

/*
  inits a mutation observer to watch the html element for class changes.
  onThemeChange should be a method with optional usage of params oldTheme, newTheme.

  This is needed for react, since react won't rerender with the new theme colors
  if the theme changes. We can watch the change manually, then have a callback that
  allows us to manually rerender as a result.
*/
export function initThemeObserver(onThemeChange: any) {
    // @ts-ignore
    window.__currentTheme = getCurrentTheme();
    const themeObserver = new MutationObserver(
        onMutationChange.bind(null, onThemeChange)
    );
    // @ts-ignore
    themeObserver.observe(document.querySelector("html"), { attributes: true });
    return themeObserver;
}


