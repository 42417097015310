import * as Actions from "../actions";
import * as Config from '../../Config/config';

const initialState: any = {
    displayLayout: Config.configuration.displayLayout,
    displayLanguage:Config.configuration.displayLanguage,
    dynamicBG:Config.configuration.dynamicBackgrounds,
    sliderTileLength:Config.configuration.maxTileLimit,
    ThemeLiteJSON:[],
    ThemeDarkJSON:[],
    tenantInfoJSON:{}

};
const SettingsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Actions.SET_LAYOUT : {
            return {
                ...state,
                displayLayout: action.displayLayout
            }
        }
        case Actions.SET_LANGUAGE : {
            return {
                ...state,
                displayLanguage: action.displayLanguage
            }
        }
        case Actions.SET_DYNAMIC_BG : {
            return {
                ...state,
                dynamicBG: action.dynamicBG
            }
        }
        case Actions.SET_SLIDER_TILE_LENGTH : {
            return {
                ...state,
                sliderTileLength: action.sliderTileLength
            }
        }
        case Actions.SET_LITETHEME: {
            return {
                ...state,
                ThemeLiteJSON: action.ThemeLiteJSON
            }
        }
        case Actions.SET_DARKTHEME: {
            return {
                ...state,
                ThemeDarkJSON: action.ThemeDarkJSON
            }
        }
        case Actions.SET_TENANTINFO: {
            return {
                ...state,
                tenantInfoJSON: action.tenantInfoJSON
            }
        }

        default: {
            return state;
        }
    }
}
export default SettingsReducer
