import * as Actions from '../actions';
const initialState: any = {
    screenSharePublisherHandle: null,
    screenShareId: null,
    screenSharePrivateId: null,
    remoteScreenShareMode: false,
    localScreenShareMode: false,
    screenShareParticipants: [],
    screenShareButtonState: true
};

const ScreenShareReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case Actions.SET_SCREEN_SHARE_PUBLISHER_HANDLE: {
            return {
                ...state,
                screenSharePublisherHandle: action.handle
            }
        }
        case Actions.SET_SCREEN_SHARE_INFO: {
            return {
                ...state,
                screenShareId: action.msg.id,
                screenSharePrivateId: action.msg.private_id
            }
        }
        case Actions.SET_REMOTE_SCREEN_SHARE_MODE: {
            return {
                ...state,
                remoteScreenShareMode: action.mode
            }
        }
        case Actions.SET_LOCAL_SCREEN_SHARE_MODE: {
            return {
                ...state,
                localScreenShareMode: action.mode
            }
        }
        case Actions.SET_SCREENSHARE_PARTICIPANTLIST: {
            return {
                ...state,
                screenShareParticipants: action.participant
            }
        }
        case Actions.SCREEN_SHARE_BUTTON_STATE: {
            return {
                ...state,
                screenShareButtonState: action.state
            }
        }
        case Actions.RESET_SCREENSHARE: {
            return {...initialState}
        }
        default: {
            return state;
        }
    }
}
export default ScreenShareReducer;
