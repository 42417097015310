// import { useEffect } from 'react';
import * as Config from '../../Config/config';  
// let msg = {};

export const translationDE = {
    "brandName": Config.configuration.brandName,
    "splashScreen": {
        "loading": "Loading..."
    },
    "layout": {
        "noInternet":"Kein Internet",
        "backOnline": "Zurück Online"
    },
    "homePage": {
        "domainPlaceHolder": "IP or domain name",
        "joinButton": "An einem Meeting teilnehmen",
        "loginButton": "Anmeldung",
        "noAccount": "Kein Account?",
        "createAccount": "Erstelle deinen kostenfreien Account",
        "logoutButton": "Ausloggen",
        "cancelButton": "Stornieren",
        "startMeetingButton": "Starten Sie ein Meeting",
        "meetingTab": "Treffen",
        "chatTab": "Plaudern",
        "scheduleTab": "Plaudern",
        "recordingTab": "Aufzeichnung",
        "upcomingMeeting": "Bevorstehende",
        "personalMeeting": "Persönlich",
        "scheduleMeetingButton": "Ein Meeting planen",
        "personalMeetingRoomPlaceHolder": "Mein persönlicher Besprechungsraum",
        "meetingIdLabel": "Konferenz-ID",
        "meetingIdPlaceHolder": "Konferenz-ID",
        "passwordLabel": "Passwort",
        "passwordPlaceHolder": "Passwort",
        "saveButton": "Sparen",
        "editButton": "Bearbeiten",
        "deleteButton": "Löschen",
        "showInvitationButton": "Einladung anzeigen",
        "copyInvitationButton": "Einladung kopieren"
    },
    "loginPage": {
        "title": "Anmeldung",
        "emailPlaceHolder": "Email",
        "passwordPlaceHolder": "Passwort",
        "rememberMe": "Behalte mich in Erinnerung",
        "forgotPassword": "Passwort vergessen ?",
        "cancelButton": "Stornieren",
        "loginButton": "Anmeldung",
        "loginWithFaceBook": "Mit Facebook einloggen",
        "loginWithGoogle": "Loggen Sie sich mit Google ein",
        "loginError": "Benutzername oder Passwort ist nicht korrekt"
    },
    "joinPage": {
        "title": "Join a meeting",
        "meetingIdText": "Meeting ID or personal link name",
        "submitButton": "Submit",
        "cancelButton": "Cancel",
        "connectingText": "Connecting",
        "noInternet": "No internet",
        "wrongMeetingId": "Invalid meeting ID",
        "invalidMeetingId": "Meeting ID should be 10 digits"

    },
    "previewPage": {
        "title": "Join a meeting",
        "meetingIdLabel": "Meeting ID",
        "passwordPlaceHolder": "Password",
        "displayNamePlaceHolder": "Display name",
        "cancelButton": "Cancel",
        "connectingText": "Connecting",
        "joinWithDevice": "Join",
        "joinWithMicrophone": "Join with mic",
        "joinWithWebCam": "Join with cam",
        "joinWithOutDevice": "Join with cam & mic off",
        "deviceText":"Device selection",
        "invalidPassword": "Invalid password",
        "noInternet": "No internet",
        "deviceBlockedTitle": "Cam and mic are blocked.",
        "deviceBlockedMessage1":"",
        "deviceBlockedMessage2":  " needs to access your cam and mic. Click the cam blocked icon in the address bar.",
        "allowDevicePermissionTitle1": "Allow ", 
        "allowDevicePermissionTitle2": "to use your cam and mic.",
        "allowDevicePermissionMessage1":"",
        "allowDevicePermissionMessage2": " needs to access your cam and mic so that other participants can see you and hear you.",
        "allowMicrophonePermissionTitle1": "Allow ",
        "allowMicrophonePermissionTitle2": "to use your mic.",
        "allowMicrophonePermissionMessage1":"",
        "allowMicrophonePermissionMessage2": " needs to access your mic so that other participants can hear you.",
        "allowVideoPermissionTitle1": "Allow ",
        "allowVideoPermissionTitle2": " to use your cam.",
        "allowVideoPermissionMessage1":"",
        "allowVideoPermissionMessage2":  " needs to access your cam so that other participants can see you. ",
        "microphoneBlockedTitle": "Mic is blocked.",
        "microphoneBlockedMessage1":"",
        "microphoneBlockedMessage2":  " needs to access your mic. Click the mic blocked icon in the address bar.",
        "webCamBlockedTitle": "Cam is blocked",
        "webCamBlockedMessage1":"",
        "webCamBlockedMessage2":" needs to access your cam. Click the cam blocked icon in the address bar.",
        "noDeviceFoundTitle": "No audio and video devices found.",
        "noDeviceFoundMessage1":"",
        "noDeviceFoundMessage2": " needs to access your mic and cam. Please install or join with out devices.",
        "speakerTestPlay": "Play",
        "speakerTestStop": "Stop",
        "recordTestRecord": "Record",
        "recordTestPlaying": "Playing...",
        "recordTestStop": "Stop"
    },
    "meetingPage": {
        "max_concurrent_limit_reached":"Another meeting for this host is already in progress. Please try again later",
        "max_limit_reached":"This meeting has reached the maximum number of participants allowed. Please try again later",
        "max_duration_reached":"Your meeting ended due to time limit. Please upgrade your plan for meetings without limits",
        "almost_duration_notification1":"This meeting will end in ",
        "almost_duration_notification2":" minutes. Please upgrade your plan to have meetings without limits",
            "topBar": {
            "invite": "Invite",
            "screenShareView": "Screen Share",
            "speakerView": "Speaker View",
            "galleryView": "Gallery View",
            "stickyMode": "Sticky Layout",
            "floatMode": "Float Layout",
            "fullScreen": "Full Screen",
            "restore": "Restore Screen"
        },
        "inviteModal": {
            "title": "Invite people to this meeting",
            "inviteTextAreaPhonePlaceHolder": "Enter or paste phone numbers separated by comma, new line, space or semicolon",
            "inviteTextAreaEmailPlaceHolder": "Enter or paste emails separated by comma, new line, space or semicolon",
            "cancelButtonLabel": "Cancel",
            "inviteButtonLabel": "Invite",
            "invitingButtonLabel": "Inviting..",
            "inviteSuccessButtonLabel": "Success",
            "copyMeetingUrl": "Copy meeting URL",
            "copyMeetingInvite": "Copy meeting invite",
            "copyStatus": "Copied",
            "TabPhone":"Phone",
            "TabEmail":"Email"
        },
        "menu": {
            "settings": "Settings",
            "noMicrophone": "Audio device not installed",
            "noMicroPhonePermission": "Please allow access to mic",
            "noMicroPhoneShort": "No Audio",
            "noMicroPhonePermissionShort": "No Permission",
            "audioMute" : "Mute",
            "audioUnMute": "Unmute",
            "noWebCam": "Audio device not installed",
            "noWebCamPermission": "Please allow access to cam",
            "noWebCamShort": "No Audio",
            "noWebCamPermissionShort": "No Permission",
            "stopVideo": "Stop",
            "startVideo": "Start",
            "switchAudio": "Switch Audio",
            "switchAudioShort": "Switch",
            "stopShare":  "Stop Share",
            "stopShareShort": "Stop",
            "startShare": "Share Screen",
            "startShareShort": "Share",
            "people": "People",
            "chat": "Chat",
            "raiseHand": "Raise Hand",
            "raiseHandShort": "Raise",
            "lowerHand": "Lower Hand",
            "lowerHandShort": "Lower",
            "leaveMeeting": "Leave Meeting",
            "leaveMeetingShort": "Leave",
            "stopRecord":" Stop Record",
            "startRecord":"Start Record",
            "stopShortRecord":"Stop",
            "startShortRecord":"Record"
        },
        "quickChat": {
            "typeMessage": "Type a message"
        },
        "rightPanel": {
            "chat": {
                "title": "Chat",
                "typeMessage": "Type a message",
                "saveChat": "Save",
                "saving": "Saving..."
            },
            "people": {
                "title": "People",
                "inMeeting": "In Meeting",
                "muteAll": "Mute All",
                "unmuteAll": "Unmute All",
                "allowUnMute": "Allow people to unmute"
            }
        },
        "leaveMeetingModal": {
            "title": "Leave meeting",
            "message": "Are you sure you want to leave this meeting?",
            "feedback": "Give feedback",
            "cancelButton": "Cancel",
            "leaveMeeting": "End meeting",
            "endAll": "End for all"
        },
        "connectivityModal": {
            "title": "Choose your audio connectivity",
            "computerAudio": "Computer Audio",
            "phoneAudio": "Phone Call",
            "callback": "Callback",
            "computerAudioMessage":"",

            "computerAudioMessage2":" will use your computer in-built or connected audio devices.",
            "joinWithComputerAudio": "Join with computer audio",
            "joinWithPhone": "Join with phone",
            "joinWithCallback": "Get a call"
        },
        "settings": {
            "title": "Einstellungen",
            "general": "Allgemeines",
            "profile": "Profil",
            "audioVideo": "Audio & Video",
            "theme": "Themen",
            "profileNameVali":"Please enter minimum 3 characters",
            "about": "Über",
            "language": "Language",
            "dynamicBackground": "Dynamic backgrounds",
            "topAndBottomBar": "Top and bottom bar",
            "participantCountInGalleryView": "Participants per page in gallery view",
            "changeProfile": "Change",
            "displayName": "Display name",
            "displayNameChangeSuccessMessage": "Display name changed successfully",
            "updateDisplayName": "Update",
            "viewProfile": "View Profile",
            "video": "Video",
            "cam": "Cam",
            "audio": "Audio",
            "mic": "Mic",
            "speakers": "Speakers",
            "microphone": "Microphone",
            "themeLite": "Lite",
            "themeDark": "Dark",
            "version": "Version"
        },
        "reconnecting": "Reconnecting ...",
        "youAreOnMute": "You are on mute",
        "unmuteMicrophoneRequestTitle": "Unmute microphone request",
        "unmuteMicrophoneRequestMessage": "The host is requesting to unmute your microphone",
        "error4401": "Make sure you are connected to the internet and your connection to "+ Config.configuration.brandName +" is not blocked by a firewall or proxy.",
        "error4401Title": "Unable to connect",
        "errorOkLabel": "Ok",
        "putMuteByHost": "You are put on mute by the host",
        "kickOutTitle": "Kickout",
        "kickOutConfirmMessage": "Do you really want to kick out this person from meeting?",
        "kickOutMessage": "Admin kicked you",
        "turnOffVideoByHost": "Your video is turned off by the host",
        "unmuteMicroPhoneRequestTitle": "Unmute microphone request",
        "unmuteMicroPhoneRequestMessage": "The host is requesting to unmute your microphone",
        "startCameraRequestTitle": "Start camera request",
        "startCameraRequestMessage": "The host is requesting to start your video"
    },
    "schedule": {
        "mode": "Mode",
        "topic": "Topic",
        "meetingId": "Meeting ID",
        "password": "Password",
        "e2eEncryption":"E to E Encryption",
        "RecurringCheckBox":"Recurring",
        "Recurrence":"Recurrence",
        "RecordMode":"Record mode",
        "recordAutomatically":"Record automatically",
        "Security":"Security",
        "LockTheRoom":"Lock the room",
        "joinBeforeHost":"join before host",
        "timeZone":"Time zone",
        "dialInMode":"Phone bridge",
        "date":"Date",
        "time":"Time",
        "cancelButton":"Cancel",
        "schedulebutton":"Schedule",
        "deleteModalHeader": "Delete recurring meeting",
        "deleteModalTitle": "",
        "deleteModalTitleRecentLink": "",
        "deleteModalButtonAllOccurance": "Delete all occurance",
        "deleteModalButtonThisOccurance": "Delete this occurance",
        "updateModalHeader": "Edit recurring meeting",
        "updateModalTitle": "",
        "updateModalTitleRecentLink": "",
        "updateModalButtonAllOccurance": "Edit all occurance",
        "updateModalButtonThisOccurance": "Edit this occurance",
        "scheduleResponse": "Meeting scheduled successfully",
        "ScheduleMeeting": "Schedule Meeting",
        "Ok":"ok",
        "editResponse":"Meeting edited successfully",

        "Cancel":"cancel",
        "Invite":"Invite",
        "DeleteMeeting":"Delete Meeting",
        "editResponseMessage":"Meeting deleted successfully",
        "MeetingInvite":"Meeting invite",
        "ScheduleMeeting":"Schedule Meeting",
        "Remove":"Remove",
        "Startmyvideo":"Start my video",
        "IWillDoItLater":"I\'ll do it later",
        "Meeting invite sent successfully":"Meeting invite sent successfully",
        "Inviting":"Inviting.."

    },
    "feedBack": {
        "title": "How did it go?",
        "subTitle1": "Thank you for using " ,
        "subTitle2": ". Please help us to improve",
        "subTitle3": "better.",
        "audioIssues": "Audio issues",
        "videoIssues": "Video issues",
        "suggestions": "Any suggestions for improvement",
        "cancelLabel": "Cancel",
        "submitLabel": "Submit",
        "feedBackTitle": "Thank you for your feedback.",
        "feedBackSuccess": "Your feedback has been submitted successfully. ",
        "echoIssue": "I heard echo",
        "audioMissing": "I couldn't hear one the participant",
        "audioBlackout": "No audio at All",
        "unexpectedEnd": "Meeting ended unexpectedly"
    },
    "Waiting":{
        "SitBackAndRelax":"Sit back and relax,",
        "HostWillSoonStartMeeting":"Your host will soon be here to start the meeting.",
        "PleaseWait":"Please Wait,",
        "HostWillSoonLetYouIn":"Your Host will soon let you in."
    },
    "WaitingModal":{
        "waitingModalTitle":"Waiting room",
        "waitingModalBody":"People are waiting for your approval to join this meeting.",
        "dontShow":"Don't show this window again for this meeting.",
        "KnowWho":"Review",
        "Admit":"Admit",
        "AdmitAndOpen":"Admit & open",
        "Deny":"Deny",
        "Open":" & open"
    },
    "ScreenShare":{
        "ScreenShareMaxLimitExceeded":"Maximale limiet voor schermaandeel overschreden"
    }
}
