import { FileDecrypt, FileEncrypt, FetchKey, AuthorizeAlias, GeneratePacket, FetchQuantumEntropy, Decrypt, Encrypt, ServerResponse, XQSDK } from "@xqmsg/jssdk-core";

const textEncryptionAlgorithm = "AES"; //Either "AES" or "OTPv2"
const fileEncryptionAlgorithm = "OTPv2"; //Either "AES" or "OTPv2"

const xqsdkConfig = {
    XQ_API_KEY: "",
    DASHBOARD_API_KEY: "",
};
let sdk:XQSDK;
let _debug:boolean = false;
const XQMessageApi = {

    // Setup app api key and/or dashboard api key
    setup:
        function setup(apiKey:string, dashboardApiKey:string) {
            xqsdkConfig.XQ_API_KEY = apiKey;
            xqsdkConfig.DASHBOARD_API_KEY = dashboardApiKey;
            sdk = new XQSDK({
                XQ_API_KEY: apiKey,
                DASHBOARD_API_KEY: dashboardApiKey
            });
            _debug && console.log("SDK::",sdk);
        },

    // Get Access Token For Host or a Participant without 2FA
    getAccessToken:
    // hostOrParticipantId: host or participant id
        async function Get_AccessToken_For_Participant(hostOrParticipantId:string) {
            let xqdata = {accessToken:null};
            await authorizeAlias(hostOrParticipantId,xqdata);
            _debug && console.log("getAccessToken::xqdata=",xqdata);
            if(xqdata.accessToken != null) {
                return {accessToken: xqdata.accessToken};
            }
            return null;
        },

    // Authorize the host and then get a key locator for the host and the participant(s)
    authorizeHostAndGetKeyLocator:
    // hostId: host id
    // recipients: a comma separated list of receipents
    // recipientIdSuffix: a string to be appended to each participant id, which is in this case is @alias.local
    // keyStrength: key strength,
    // keyExpirationInHours: for how long in hours the key locator will remain valid:
        async function AuthorizeAlias_CreateKey_GetKeyLocator(hostId:string, recipients:string, recipientIdSuffix:string, keyStrength:number, keyExpirationInHours:number) {
            let xqdata = {accessToken:'',quantumBits:'',keyLocator:''};
            _debug && console.log("AuthorizeAlias_CreateKey_GetKeyLocator::params=",sdk,hostId,recipients,recipientIdSuffix,keyStrength,keyExpirationInHours);
            await authorizeAlias(hostId,xqdata);
            _debug && console.log("AuthorizeAlias_CreateKey_GetKeyLocator::authorizeAlias::xqdata=",xqdata);
            if(xqdata.accessToken != null) {
                await requestQuantumBits(xqdata);
                _debug && console.log("AuthorizeAlias_CreateKey_GetKeyLocator::requestQuantumBits::xqdata=",xqdata);
                if(xqdata.quantumBits != null) {
                    await addKeyToXQandGetKeyLocator(xqdata.accessToken,xqdata.quantumBits,recipients,recipientIdSuffix,keyExpirationInHours,false,xqdata);
                    _debug && console.log("AuthorizeAlias_CreateKey_GetKeyLocator::addKeyToXQandGetKeyLocator::xqdata=",xqdata);
                    if(xqdata.keyLocator != null) {
                        //_debug && console.log("AuthorizeAlias_CreateKey_GetLocator::",xqdata);
                        return {key:xqdata.quantumBits, keyLocator: xqdata.keyLocator};
                    }
                }
            }
            return null;
        },

    // Authorize host or participant then Retrieve the key for a host or participant
    authorizeAndRetrieveKey:
    // hostOrParticipantId: host or receipent id
    // keykeyLocator: key locator,
        async function AuthorizeAlias_FetchKey(hostOrParticipantId:string,keyLocator:string) {
            let xqdata = {accessToken:'',key:''};
            await authorizeAlias(hostOrParticipantId,xqdata);
            if(xqdata.accessToken != null) {
                await fetchKeyByKeyLocator(keyLocator,xqdata);
                if(xqdata.key != null) {
                    return xqdata.key;
                }
            }
            return null;
        },

    // Do not Authorize the host but get a key locator for the host and the participant(s)
    getKeyLocatorOnly:
    // hostAccessToken: host access token
    // recipients: a comma separated list of receipents, each recipient id must end with @alias.local
    // keyStrength: key strength,
    // keyExpirationInHours: for how long in hours the key locator will remain valid:
        async function CreateKey_GetKeyLocator(hostAccessToken:string, recipients:string, recipientIdSuffix:string, keyStrength:number, keyExpirationInHours:number) {
            let xqdata = {quantumBits:'',keyLocator:''};
            await requestQuantumBits(xqdata);
            if(xqdata.quantumBits != null) {
                await addKeyToXQandGetKeyLocator(hostAccessToken,xqdata.quantumBits,recipients,recipientIdSuffix,keyExpirationInHours,false,xqdata);
                if(xqdata.keyLocator != null) {
                    //_debug && console.log("CreateKey_GetKeyLocator::",xqdata);
                    return {key:xqdata.quantumBits, keyLocator: xqdata.keyLocator};
                }
            }
            return null;
        },

    encryptText:
        async function EncryptText(plainText:string, recipients:string,expires:number,keyLocator:string) {
            const payload = {
                [Encrypt.TEXT]: plainText,
                [Encrypt.RECIPIENTS]: recipients.split(","),
                [Encrypt.EXPIRES_HOURS]: expires,
                [Encrypt.LOCATOR_KEY]: keyLocator,
                //[Encrypt.ENCRYPTION_KEY]: encryptionKey
            };
              
            let algorithm = sdk.getAlgorithm(textEncryptionAlgorithm); // Either "AES" or "OTPv2"
            let result:any=null;
            await new Encrypt(sdk, algorithm)
            .supplyAsync(payload)
            .then((response:ServerResponse|undefined) => {
                _debug && console.log("Encrypt::response:",response);
                switch (response && response.status) {
                    case ServerResponse.OK: {
                        // Do something with the data
                        const data = response && response.payload;
                        _debug && console.log("Encrypt::data:",data);
                        result = {keyLocator:data.locatorKey,encryptedText:data.encryptedText};
                        break;
                    }
                    case ServerResponse.ERROR: {
                        // Something went wrong...
                        break;
                    }
                }
                return response;
            });
            return result;
        },

    decryptText:
        async function DecryptText(keyLocator:string,encryptedText:string) {
            const payload = {
                [Decrypt.LOCATOR_KEY]: keyLocator,
                [Decrypt.ENCRYPTED_TEXT]: encryptedText,
            };

            const algorithm = sdk.getAlgorithm(textEncryptionAlgorithm); // Either "AES" or "OTPv2"
            let result:any=null;
            await new Decrypt(sdk, algorithm)
            .supplyAsync(payload)
            .then((response:ServerResponse|undefined) => {
                _debug && console.log("Decrypt::response:",response);
                switch (response?.status) {
                    case ServerResponse.OK: {
                        const data = response.payload;
                        result={decryptedText:data.decryptedText};
                        //const decryptedText = data[EncryptionAlgorithm.DECRYPTED_TEXT];
                        // Do something with the data
                        break;
                    }
                    case ServerResponse.ERROR: {
                    // Something went wrong...
                    break;
                    }
                    default: {
                        break;
                    }
                }

                return response;
            });
            return result;
        },

    encryptFile:
        async function EncryptFile(FileToEncrypt:File,recipients:string,expires:number,delete_on_receipt:boolean) {

            const algorithm = sdk.getAlgorithm(fileEncryptionAlgorithm); // Either "AES" or "OTPv2"
            const sourceFile = FileToEncrypt;

            let result:any=null;
            await new FileEncrypt(sdk, algorithm)
            .supplyAsync({
                [FileEncrypt.RECIPIENTS]: recipients.split(","),
                [FileEncrypt.EXPIRES_HOURS]: expires,
                [FileEncrypt.SOURCE_FILE]: sourceFile,
                [FileEncrypt.DELETE_ON_RECEIPT]: delete_on_receipt,
            })
            .then((response: any) => {
                _debug && console.log("FileEncrypt::response:",response);
                switch (response.status) {
                    case ServerResponse.OK: {
                        var encryptedFile = response.payload;
                        result = response;
                        // Do something with the data
                        break;
                    }
                    case ServerResponse.ERROR: {
                        // Something went wrong...
                        break;
                    }
                }

                return response;
            });
            return result;
        },

    decryptFile:
    async function DecryptFile(infile:File) {

        const algorithm = sdk.getAlgorithm(fileEncryptionAlgorithm); // Either "AES" or "OTPv2"

        //A file object containing the encrypted text.
        const sourceFile = new File([infile], "encrypted.txt", {
        type: "text/plain",
        });
        
        let decryptedFile;
        await new FileDecrypt(sdk, algorithm)
        .supplyAsync({ [FileDecrypt.SOURCE_FILE]: sourceFile })
        .then(async (response: any) => {
            _debug && console.log("DecryptFile::response",response);
            switch (response.status) {
                case ServerResponse.OK: {
                    decryptedFile = response.payload;
                    
                    //alert('your file has downloaded!');
                    //return decryptedFile;
                    break;
                }
                case ServerResponse.ERROR: {
                    // Something went wrong...
                    break;
                }
            }

            return response;
        });
        return decryptedFile;
    },

    // Do not Authorize host or participant but Retrieve the key for a host or participant
    retrieveKeyOnly:
    // hostOrParticipantId: host or receipent id
    // keykeyLocator: key locator,
        async function AuthorizeAlias_FetchKey(hostOrParticipantAccessToken:string,keyLocator:string) {
            let xqdata = {key:''};
            await fetchKeyByKeyLocator(keyLocator,xqdata);
            if(xqdata.key != null) {
                return xqdata.key;
            }
            return null;
        },

    allowParticipantsToUseKey:
        async function name(params:string) {

        },

    testDelectRecipient:
        async function Test(recipient: string) {

            let xqdata = {accessToken:'',quantumBits:'',keyLocator:'',deleteResult:''};
            await authorizeAlias(recipient+'@alias.local',xqdata);
            _debug && console.log("authorizeAlias::",xqdata.accessToken);

            await deleteRecipient(xqdata.accessToken,xqdata);
            _debug && console.log("deleteRecipient::",xqdata.deleteResult);
        },

    getKeyForHostSide2:
        async function AuthorizeAlias_CreateKey_GetKeyLocator_v2(host: string,recipients: string,keyStrength:number,keyExpirationInHours: number) {
            let xqdata = {accessToken:'',quantumBits:'',keyPacket:'',keyLocator:''};
            await authorizeAlias(host,xqdata);
            if(xqdata.accessToken != null) {
                await requestQuantumBits(xqdata);
                if(xqdata.quantumBits != null) {
                    await createKeyPacket(xqdata.accessToken,xqdata.quantumBits, recipients, keyExpirationInHours, xqdata);
                    if(xqdata.keyPacket != null) {
                        await storeKeyPacket(xqdata.accessToken, xqdata.keyPacket,xqdata);
                        if(xqdata.keyLocator != null) {
                            //_debug && console.log("AuthorizeAlias_CreateKey_GetLocator2::",xqdata);
                            return {key:xqdata.quantumBits, keyLocator: xqdata.keyLocator};
                        }
                    }
                }
            }
            return null;
        },
};

async function authorizeAlias(hostOrParticipantId:string, result:{accessToken:any}) {
    let accessToken = null;
    await new AuthorizeAlias(sdk)
    .supplyAsync({[AuthorizeAlias.USER]: hostOrParticipantId,})
    .then((response: ServerResponse) => {
        //_debug && console.log("authorizeAlias::response=",response);
        switch (response?.status) {
            case ServerResponse.OK: {
                // Success - The alias user was authorized.
                accessToken = response.payload;
                break;
            }
            case ServerResponse.ERROR: {
                // Something went wrong...
                break;
            }
        }
        return response;
    });
    result.accessToken = accessToken;
    return accessToken;
}

async function requestQuantumBits(result: {quantumBits:any}) {
    let quantumBits = null;
    const payload = {
        [FetchQuantumEntropy.KS]: FetchQuantumEntropy._256
    };

    await new FetchQuantumEntropy(sdk)
    .supplyAsync(payload)
    .then((response: ServerResponse) => {
        //_debug && console.log("requestQuantumBits::response=",response);
        switch (response.status) {
            case ServerResponse.OK: {
                // Do something with the key
                quantumBits = response.payload;
                break;
            }
            case ServerResponse.ERROR: {
                // Something went wrong...
                break;
            }
        }
        return response;
    });
    result.quantumBits = quantumBits;
    return result;
}

async function addKeyToXQandGetKeyLocator(accessToken:string, key:string, recipients:string, recipientIdSuffix:string, expires:number, deleteOnReceipt:boolean, result:{keyLocator:any}) {
    let keyLocator = null;  
    const payload = {
        [GeneratePacket.KEY]: key,
        [GeneratePacket.RECIPIENTS]: recipients.split(",").map(participant => participant.trim() + recipientIdSuffix),
        [GeneratePacket.EXPIRES_HOURS]: expires,
        [GeneratePacket.DELETE_ON_RECEIPT]: deleteOnReceipt
    };
    
    await new GeneratePacket(sdk)
    .supplyAsync(payload)
    .then((response: ServerResponse) => {
        switch (response.status) {
            case ServerResponse.OK: {
                // This is the locator token that can later on be used
                // to retrieve the key by valid recipients.
                keyLocator = response.payload;
                break;
            }
            case ServerResponse.ERROR: {
                // Something went wrong...
                break;
            }
        }
        return response;
    });
    result.keyLocator = keyLocator;
    return keyLocator;
}

async function fetchKeyByKeyLocator(keyLocator: string ,result: {key: any}) {
    //keyLocator = encodeURIComponent(keyLocator);
    let key = null;
    const payload = {
        [FetchKey.LOCATOR_KEY]: keyLocator 
    };

    await new FetchKey(sdk)
    .supplyAsync(payload)
    .then((response: ServerResponse) => {
        switch (response.status) {
            case ServerResponse.OK: {
                // The received key can now be used to decrypt the original message.
                key = response.payload;
                break;
            }
            case ServerResponse.ERROR: {
                // Something went wrong...
                break;
            }
        }
        return response;
    });
    result.key = key;
    return key;
}

async function deleteRecipient(authorizationToken: string,result: {deleteResult: any}) {
    const endpoint_url = "https://subscription.xqmsg.net/v2/subscriber";

    const data = {
        method: "DEL",
        headers: {
            "api-key": xqsdkConfig.XQ_API_KEY,
            "authorization": 'Bearer ' + authorizationToken,
            "content-type": "application/json",
        }
    };
    let deleteResult = null;
    await fetch(endpoint_url,data)
        .then(response => {
            if (response.status === 200 && response.ok === true) {
                return response.text();
            } else {
                return null;
            }
        })
        .then(response => {
            deleteResult =  response;
        })
        .catch(err => {
            _debug && console.log("createSubscriber: ",err);
        });
    result.deleteResult = deleteResult;
    return deleteResult;
}

async function createKeyPacket(authorizationToken: string, key: string, recipients: string, expires: number, result: {keyPacket: any}) {
    const endpoint_url = `https://subscription.xqmsg.net/v2/packet`;

    let keyPacket = null;
    await fetch(endpoint_url,{
        method: "POST",
        headers: {
            "api-key": xqsdkConfig.XQ_API_KEY,
            "authorization": 'Bearer ' + authorizationToken,
            "content-type": "application/json",
            "accept-language": "en_US"
        },
        body: JSON.stringify({
            key: key,
            recipients: recipients.split(","),
            expires: expires
        })
    })
        .then(response => {
            if (response.status === 200 && response.ok === true) {
                return response.text();
            } else {
                return null;
            }
        })
        .then(response => {
            keyPacket = response;
        })
        .catch(err => {
            _debug && console.log("requestQuantumBits: ",err);
        });
    result.keyPacket = keyPacket;
    return keyPacket;
}

async function storeKeyPacket(authorizationToken: string, key: string, result: {keyLocator: any}) {
    const endpoint_url = `https://validation.xqmsg.net/v2/packet`;

    let keyLocator = null;
    await fetch(endpoint_url,{
        method: "POST",
        headers: {
            "api-key": xqsdkConfig.XQ_API_KEY,
            "authorization": 'Bearer ' + authorizationToken,
        },
        body: key
    })
        .then(response => {
            if (response.status === 200 && response.ok === true) {
                return response.text();
            } else {
                return null;
            }
        })
        .then(response => {
            keyLocator = response;
        })
        .catch(err => {
            _debug && console.log("requestQuantumBits: ",err);
        });
    result.keyLocator = keyLocator;
    return keyLocator;
}

export default XQMessageApi;
