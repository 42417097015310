import * as Config from '../../Config/config'
let msg = {};

export const translationUZ = {
    "brandName": Config.configuration.brandName,
    "splashScreen": {
        "loading": "Yuklanmoqda ..."
    },
    "maket": {
        "noInternet": "Internet yo'q",
        "backOnline": "Internet qayta ulandi"
    },
    "homePage": {
        "domainPlaceHolder": "IP yoki domen nomi",
        "joinButton": "Uchrashuvga qo'shiling",
        "loginButton": "Kirish",
        "noAccount": "Hisob qaydnomasi yo'qmi?",
        "createAccount": "BEPUL hisob qaydnomangizni yarating",
        "logoutButton": "Chiqish",
        "cancelButton": "Bekor qilish",
        "startMeetingButton": "Uchrashuvni boshlash",
        "meetingTab": "Uchrashuvlar",
        "chatTab": "Suhbat",
        "scheduleTab": "Jadval",
        "recordTab": "Yozib olish",
        "comingMeeting": "Kelgusi",
        "personalMeeting": "Shaxsiy",
        "scheduleMeetingButton": "Uchrashuvni rejalashtirish",
        "personalMeetingRoomPlaceHolder": "Mening shaxsiy uchrashuv xonam",
        "meetingIdLabel": "Uchrashuv identifikatori",
        "meetingIdPlaceHolder": "Uchrashuv identifikatori",
        "passwordLabel": "Parol",
        "passwordPlaceHolder": "Parol",
        "saveButton": "Saqlash",
        "editButton": "Tahrirlash",
        "deleteButton": "O'chirish",
        "showInvitationButton": "Taklifni ko'rsatish",
        "copyInvitationButton": "Taklifnomani nusxalash",
        "ViewProfile": "Profilni ko'rish",
        "ChangePassword": "Parolni o'zgartirish"
    },
    "loginPage": {
        "title": "Kirish",
        "emailPlaceHolder": "Elektron pochta",
        "passwordPlaceHolder": "Parol",
        "rememberMe": "Meni esla",
        "forgotPassword": "Parolni unutdingizmi?",
        "cancelButton": "Bekor qilish",
        "loginButton": "Kirish",
        "loginWithFaceBook": "Facebook bilan kirish",
        "loginWithGoogle": "Google bilan kirish",
        "loginError": "Foydalanuvchi nomi yoki parol noto'g'ri"
    },
    "joinPage": {
        "title": "Uchrashuvga qo'shiling",
        "meetingIdText": "Uchrashuv identifikatori yoki shaxsiy havola nomi",
        "submitButton": "Topshirish",
        "cancelButton": "Bekor qilish",
        "connectText": "Clannish",
        "noInternet": "Internet yo'q",
        "wrongMeetingId": "Noto'g'ri uchrashuv identifikatori",
        "invalidMeetingId": "Uchrashuv identifikatori 10 ta raqamdli bo'lishi kerak"

    },
    "previewPage": {
        "title": "Uchrashuvga boring",
        "meetingIdLabel": "Uchrashuv identifikatori",
        "passwordPlaceHolder": "Parol",
        "displayNamePlaceHolder": "Displey nomi",
        "cancelButton": "Bekor qilish",
        "connectText": "Ulanish",
        "joinWithDevice": "Qo'shiling",
        "joinWithMicrophone": "Mikrofon bilan qo'shiling",
        "joinWithWebCam": "Kamera bilan qo'shiling",
        "joinWithOutDevice": "Kamera va mikrofon o'chirilgan holda qo'shiling",
        "deviceText": "Qurilmani tanlash",
        "invalidPassword": "Yaroqsiz parol",
        "noInternet": "Internet yo'q",
        "deviceBlockedTitle": "Kamera va mikrofon bloklangan.",
        "deviceBlockedMessage1":"",
        "deviceBlockedMessage2":  " kamerangizga va mikrofoningizga kirishi kerak. Manzil satridagi kamerani blokirovka qilish belgisini bosing.",
        "allowDevicePermissionTitle1":"",
        "allowDevicePermissionTitle2":  "ga kamerangiz va mikrofoningizdan foydalanishga ruxsat bering.",
        "allowDevicePermissionMessage1": "",
        "allowDevicePermissionMessage2": " kamerangiz va mikrofoningizga kirishi kerak, shunda boshqa ishtirokchilar sizni ko'rishlari va sizni eshitishlari mumkin.",
        "allowMicrophonePermissionTitle1": "",
        "allowMicrophonePermissionTitle2": "ga mikrofoningizdan foydalanishga ruxsat bering.",
        "allowMicrophonePermissionMessage1": "Boshqa ishtirokchilar sizni eshitishlari uchun " ,
        "allowMicrophonePermissionMessage2": " mikrofoningizga kirishi kerak.",
        "allowVideoPermissionTitle1":"",
        "allowVideoPermissionTitle2":  "ga sizning kamerangizdan foydalanishga ruxsat bering.",
        "allowVideoPermissionMessage1": "Boshqa ishtirokchilar sizni ko'rishlari uchun " ,
        "allowVideoPermissionMessage2":" sizning kamerangizga kirishi kerak.",
        "microphoneBlockedTitle": "Mikrofon bloklangan.",
        "microphoneBlockedMessage1": "",
        "microphoneBlockedMessage2": " mikrofoningizga kirishi kerak. Manzil panelidagi mikrofon bloklangan belgini bosing.",
        "webCamBlockedTitle": "Kamera bloklangan",
        "webCamBlockedMessage1":"",
        "webCamBlockedMessage2": " sizning kamerangizga kirishi kerak. Manzil satridagi kameraning bloklangan belgisini bosing.",
        "noDeviceFoundTitle": "Hech qanday audio va video qurilmalar topilmadi.",
        "noDeviceFoundMessage1":"",
        "noDeviceFoundMessage2":  " mikrofoningiz va kamerangizga kirishi kerak. Iltimos, o'rnating yoki qurilmalarsiz qo'shiling.",
        "speakerTestPlay": "ijro etish",
        "speakerTestStop": "To'xtatish",
        "recordTestRecord": "Yozib olish",
        "recordTestPlaying": "ijro etilmoqda ...",
        "recordTestStop": "To'xtatish"
    },
    "meetingPage": {
        "max_concurrent_limit_reached":"Bu mezbon uchun boshqa uchrashuv allaqachon davom etmoqda. Iltimos keyinroq qayta urinib ko'ring",
        "max_limit_reached":"Bu uchrashuv ruxsat etilgan maksimal ishtirokchilar soniga yetdi. Iltimos keyinroq qayta urinib ko'ring",
        "max_duration_reached":"Uchrashuvingiz vaqt chegarasi tufayli tugadi. Iltimos, uchrashuvlar rejangizni cheksiz yangilang",
        "almost_duration_notification1":"Bu uchrashuv ",
        "almost_duration_notification2":" daqiqada tugaydi. Iltimos, uchrashuvlarni cheksiz o‘tkazish uchun rejangizni yangilang",
 
        "topBar": {
            "invite": "taklif qilish",
            "screenShareView": "Ekran almashinuvi",
            "speakerView": "Nutq so'zlovchi ko'rinishi",
            "galleryView": "Galereya ko'rinishi",
            "stickyMode": "Yopishtirilish tartibi",
            "floatMode": "Qalqish tartibi",
            "fullScreen": "To'liq ekran",
            "restore": "Ekranni tiklash",
            "lockMeeting": "Uchrashuvni bloklash",
            "openMeeting": "Uchrashuvni ochish"
        },
        "InviteModal": {
            "title": "Odamlarni ushbu yig'ilishga taklif qiling",
            "inviteTextAreaPhonePlaceHolder": "Vergul, oraliq yoki nuqtaali vergul bilan ajratilgan telefon raqamlarini kiriting yoki joylashtiring",
            "inviteTextAreaEmailPlaceHolder": "Vergul, bo'sh joy yoki nuqtaali vergul bilan ajratilgan elektron pochta manzillarini kiriting yoki joylashtiring",
            "cancelButtonLabel": "Bekor qilish",
            "phoneTabRightLabel": "Berilgan raqamga qo'ng'iroq boshlanadi va ishtirokchi ushbu yig'ilishga qo'shilish uchun qo'ng'iroqni qabul qilishi kerak.",
            "InviteButtonLabel": "Taklif qilish",
            "invitingButtonLabel": "Taklif qilinmoqda ..",
            "inviteSuccessButtonLabel": "Muvaffaqiyat",
            "copyMeetingUrl": "Uchrashuvning URL manzilini nusxalash",
            "copyMeetingInvite": "Uchrashuvga taklifnomani nusxalash",
            "copyStatus": "Nusxa olindi",
            "TabPhone": "Telefon",
            "TabEmail": "Elektron pochta"
        },
        "menu": {
            "settings": "Sozlash",
            "noMicrophone": "Ovoz qurilmasi o'rnatilmagan",
            "noMicroPhonePermission": "Iltimos, mikrofonga kirishga ruxsat bering",
            "noMicroPhoneShort": "Ovoz yo'q",
            "noMicroPhonePermissionShort": "Ruxsat yo'q",
            "audioMute": "Ovozni o'chirish",
            "audioUnMute": "Ovozni yoqish",
            "noWebCam": "Ovoz qurilmasi o'rnatilmagan",
            "noWebCamPermission": "Iltimos, kameraga kirishga ruxsat bering",
            "noWebCamShort": "Ovoz yo'q",
            "noWebCamPermissionShort": "Ruxsat yo'q",
            "stopVideo": "To'xtatish",
            "startVideo": "Boshlash",
            "switchAudio": "Ovozni almashtirish",
            "switchAudioShort": "Almashtirish",
            "ConnectAudioShort": "Ulanish",
            "ConnectAudio": "Ovozni ulash",
            "stopShare": "Ulashishni to'xtatish",
            "stopShareShort": "To'xtatish",
            "startShare": "Ekranni ulashish",
            "startShareShort": "Bahamlashish",
            "people": "Odamlar",
            "chat": "Suhbat",
            "raiseHand": "Qo'lni ko'tarish",
            "raiseHandShort": "Ko'tarish",
            "lowerHand": "Qo’lni pastlatish",
            "lowerHandShort": "Pastga tushir",
            "leaveMeeting": "Uchrashuvni tark etish",
            "leaveMeetingShort": "Ketish",
            "menu": "Menyu",
            "stopRecord": "Yozishni to'xtatish",
            "startRecord": "Yozishni boshlash",
            "stopShortRecord": "To'xtatish",
            "startShortRecord": "Yozib olish"
        },
        "quickChat": {
            "typeMessage": "Xabar yozing"
        },
        "rightPanel": {
            "chat": {
                "title": "suhbat",
                "typeMessage": "Xabar yozing",
                "saveChat": "Saqlash",
                "saving": "Saqlab olinmoqda"
            },
            "people": {
                "title": "Odamlar",
                "inMeeting": "Uchrashuvda",
                "muteAll": "Hammasining ovozini  o'chirish",
                "unmuteAll": "Hammasining ovozini yoqish",
                "allowUnMute": "Odamlarga ovozni yoqishga ruxsat berish"
            }
        },
        "leaveMeetingModal": {
            "title": "Uchrashuvni yakunlash",
            "participantTitle": "Uchrashuvni tark etish",
            "message": "Ushbu uchrashuvni tugatishni xohlaysizmi?",
            "participantMessage": "Ushbu yig'ilishni tark etmoqchimisiz?",
            "feedback": "Fikr bildiring",
            "cancelButton": "Bekor qilish",
            "leaveMeeting": "Men uchun tugatish",
            "participantleaveMeeting": "Uchrashuvdan chiqish",
            "endAll": "Hamma uchun tugatish"
        },
        "connectivityModal": {
            "title": "Audio ulanishni tanlang",
            "computerAudio": "Computer ovoz",
            "phoneAudio": "Terish",
            "callback": "Chaqirish",
            "computerAudioMessage1":"",
            "computerAudioMessage2": " sizning kompyuteringizda o'rnatilgan yoki ulangan audio qurilmalardan foydalanadi.",
            "joinWithComputerAudio": "Kompyuter ovozi bilan qo'shiling",
            "leaveComputerAudio": "Kompyuter ovozini to’htating",
            "joinWithPhone": "Telefon bilan qo'shiling",
            "joinWithCallback": "Qo'ng'iroq bilan chaqirtirilish"
        },
        "settings": {
            "title": "Sozlamalar",
            "general": "Umumiy",
            "profile": "Profil",
            "audioVideo": "Ovoz va video",
            "profileNameVali": "Iltimos, kamida 3 ta belgini kiriting",
            "theme": "Mavzular",
            "about": "Haqida",
            "language": "Til",
            "dynamicBackground": "Dinamik fon",
            "topAndBottomBar": "Yuqori va pastki satr",
            "participantCountInGalleryView": "Sahifa ishtirokchilari gallereya ko’rinishida nechtadan",
            "changeProfile": "O'zgartirish",
            "displayName": "Displey nomi",
            "displayNameChangeSuccessMessage": "Displey nomi muvaffaqiyatli o'zgartirildi",
            "updateDisplayName": "Yangilash",
            "viewProfile": "Profilni ko'rish",
            "video": "Video",
            "cam": "Kamera",
            "audio": "Ovoz",
            "mic": "Mikrofon",
            "speakers": "Ma'ruzachilar",
            "microphone": "Mikrofon",
            "themeLite": "Yorug’",
            "themeDark": "Qorongi",
            "version": "Versiya"
        },
        "reconnecting": "Qayta ulanish ...",
        "waitingToReconnect": "Iltimos kuting",
        "youAreOnMute": "Siz ovozsizsiz",
        "unmuteMicrophoneRequestTitle": "Mikrofon ovozini yoqishni so’rash",
        "unmuteMicrophoneRequestMessage": "Uchrashuv egasi mikrofoningizni yoqishingizni so'ramoqda",
        "error4401": "Internetga ulanganingizga ishonch hosil qiling va " + Config.configuration.brandName + " bilan aloqangiz xavfsizlik devori yoki proksi-server tomonidan bloklanmaganligini aniqlang.",
        "error4401Title": "Ulanish imkoni yo’q",
        "errorOkLabel": "Ok",
        "putMuteByHost": "Uchrashuv egasi sizni ovozingizni to’htatdi",
        "kickOutTitle": "Uchrashuvdan chiqarib tashlash",
        "kickOutConfirmMessage": "Siz haqiqatan ham bu kishini uchrashuvdan chiqarib yubormoqchimisiz?",
        "kickOutMessage": "Uchrashuv egasi sizni uchrashuvdan chetlashtirdi.",
        "turnOffVideoByHost": "Sizning videongizni uchrashuv egasi o'chirib qo'ydi",
        "unmuteMicroPhoneRequestTitle": "Mikrofon ovozini yoqishni so’rash",
        "unmuteMicroPhoneRequestMessage": "Uchrashuv egasi mikrofoningizni yoqishingizni so'ramoqda",
        "startCameraRequestTitle": "Kamerani yoqishni so’rash",
        "startCameraRequestMessage": "Uchrashuv egasi videongizni boshlashni so'ramoqda"
    },
    "schedule": {
        "mode": "Rejim",
        "topic": "Mavzu",
        "meetingId": "Uchrashuv identifikatori",
        "password": "Parol",
        "dialInMode": "Telefon ko'prigi",
        "pin": "Pin",
        "RecurringCheckBox": "Takrorlanuvchi",
        "Recurrence": "Takrorlanish",
        "Occurrences": "Voqealar",
        "RecordMode": "Yozib olish rejimi",
        "e2eEncryption": "E - E shifrlash",
        "recordAutomatically": "Avtomatik ravishda yozib olish",
        "Security": "Xavfsizlik",
        "LockTheRoom": "Xonani qulflash",
        "joinBeforeHost": "Uchrashuv egasidan oldin qo'shilish",
        "timeZone": "Mintaqa vaqti",
        "DateAndTime": "Sana va vaqt",
        "date": "Sana",
        "time": "Vaqt",
        "invite": "taklif qiling",
        "security": "Xavfsizlik",
        "cancelButton": "Bekor qilish",
        "hideInvitationButton": "Taklifnomani yashirish",
        "showInvitationButton": "Taklifnomani ko'rsatish",
        "copyInvitationButton": "Taklifnomani nusxalash",
        "schedulebutton": "Jadval",
        "deleteModalHeader": "Uchrashuvni o'chirish",
        "deleteModalTitle": "Siz haqiqatdan ham ushbu uchrashuvni o'chirishni xohlaysizmi?",
        "deleteModalTitleRecentLink": "",
        "deleteModalButtonAllOccurance": "Barcha voqealarni o'chirish",
        "deleteModalButtonThisOccurance": "Ushbu voqeani o'chirish",
        "deleteModalButtonThisMeeting": "Ushbu uchrashuvni o'chirish",
        "updateModalHeader": "Uchrashuvni tahrirlash",
        "updateModalTitle": "Siz haqiqatan ham ushbu uchrashuvni tahrirlamoqchimisiz?",
        "updateModalTitleRecentLink": "",
        "updateModalButtonAllOccurance": "Barcha voqealarni tahrirlash",
        "updateModalButtonThisOccurance": "Ushbu voqeani tahrirlash",
        "updatePersonalMeetingButton": "Shaxsiy uchrashuvni tahrirlash",
        "updatePersonalMeeting": "Shaxsiy uchrashuvni tahrirlash",
        "noUpcomingMeetingsFound": "Yaqinlashayapgan uchrashuvlar topilmadi",
        "noPersonalMeetingsFound": "Shaxsiy uchrashuvlar topilmadi",
        "RepeatEvery": "Har birini takrorlang",
        "Day": "Kun",
        "Week": "Hafta",
        "Month": "Oy",
        "copied": "Ko'chirildi",
        "Occurson": "Vujudga keladi",
        "EndDate": "Tugash sanasi",
        "Keyin": "Keyin",
        "OfTheMonth": "Oy",
        "scheduleResponse": "Uchrashuv muvaffaqiyatli rejalashtirilgan",
        "ScheduleMeeting": "Uchrashuvni rejalashtirish",
        "Ok":"xop",
        "editResponse":"Uchrashuv tahrirlandi",

        "Cancel":"bekor qilish",
        "Invite":"Taklif qiling",
        "DeleteMeeting":"Uchrashuvni oʻchirish",
        "editResponseMessage":"Uchrashuv muvaffaqiyatli oʻchirildi",
        "MeetingInvite":"Uchrashuv taklifi",
        "ScheduleMeeting":"Uchrashuvni rejalashtirish",
        "Remove":"O'chirish",
        "Startmyvideo":"Videomni boshlang",
        "IWillDoItLater":"Men buni keyinroq qilaman",
        "Meeting invite sent successfully":"Uchrashuvga taklif yuborildi",
        "Inviting":"Taklif etuvchi.."





    },
    "feedBack": {
        "title": "Qanday o'tdi?",
        "subTitle1":"",
        "subTitle2": "'dan foydalanganingiz uchun rahmat. Iltimos, " ,
        "subTitle3": "ni yahshilashga bizga yordam bering.",
        "audioIssues": "Audio muammolar",
        "videoIssues": "Video muammolar",
        "suggestions": "Takomillashtirishga qanday takliflaringiz bor",
        "cancelLabel": "Bekor qilish",
        "submitLabel": "Topshirish",
        "feedBackTitle": "Fikringiz uchun rahmat",
        "feedBackSuccess": "Fikrlaringiz muvaffaqiyatli yuborildi.",
        "echoIssue": "Men ask sado eshitdim",
        "audioMissing": "Bitta ishtirokchini eshita olmadim",
        "audioBlackout": "Umuman ovoz yo'q",
        "unexpectedEnd": "Uchrashuv kutilmaganda yakunlandi",
        "videoWasFrozen": "Video muzlagandi",
        "videoWasFlickering": "Ishitirokchining videosi miltilladi",
        "myVideoWasNotShowingToThem": "Mening videomni ular ko'raolmadi",
        "videoWasMissing": "Ishtirokchining videosi yo'qolib qoldi"
    },
    "Waiting": {
        "SitBackAndRelax": "O’tirib dam oling",
        "HostWillSoonStartMeeting": "Sizning uchrashuv egangiz tez orada uchrashuvni boshlash uchun bu erda bo'ladi.",
        "PleaseWait": "Iltimos, kuting",
        "HostWillSoonLetYouIn": "Sizning uchrashuv egangiz tez orada sizni ichkariga kiritadi.",
        "Joining": "Qo'shilish",
        "YouAreJoiningTheMeeting": "Siz yig'ilishga qo'shilasiz.",
        "LeaveMeeting": "Uchrashuvni tark etish"
    },
    "WaitingModal": {
        "waitingModalTitle": "Kutish xonasi",
        "waitingModalBody": "Odamlar ushbu uchrashuvga qo'shilish uchun sizning ruhsatingizni kutmoqdalar.",
        "dontShow": "Ushbu uchrashuv uchun ushbu oynani boshqa ko'rsatmang.",
        "KnowWho": "Sharh",
        "Admit": "Qabul qilish",
        "AdmitAndOpen": "Qabul qilish va ochish",
        "Deny": "Rad etish",
        "Open": "& Ochish"
    },
    "ScreenShare":{
        "ScreenShareMaxLimitExceeded":"Ekranni ulashish maksimal chegarasidan oshib ketdi"
    }
}

