import axios from 'axios';
axios.defaults.withCredentials = true;
const CancelToken = axios.CancelToken;
export let source: any = null;

axios.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json';
        config.headers['hoolva-app'] = process.env.REACT_APP_API_KEY;
        if(localStorage.getItem("rtkiops")) {
            config.headers['hoolva-user'] = localStorage.getItem("rtkiops");
        }
        return config;
    });


axios.interceptors.response.use(
    response => {
        return response
    },
    err => {
        return new Promise((resolve, reject) => {
            if (err?.response?.status === 401 && err?.config && !err?.config.__isRetryRequest) {
                // if you ever get an unauthorized response, logout the user
                // this.emit('onAutoLogout', 'Invalid access_token');
                //  this.setSession(null);
                console.log('unauthorized plz naviagate to login page');
                throw {
                    reason: err.response.statusText,
                    status: err.response.status
                }
            }
            else  if(err.response){
                throw {
                    reason: err.response.statusText,
                    status: err.response.status
                }
            } else {
                throw {
                    reason: "network error",
                    status: 'failed'
                }
            }
        });
    }
);

export async function loginClient(url: string, data: any) {
    try {
        // @ts-ignore
        return await axios.post(url + 'login/client', data, {crossDomain: true});
    } catch (error) {
        return error;
    }
}

/**
 * Function Name: getCurrentMeetingInfo
 * @param url  : url to send the request
 * @param meetingInfo: object contain meeting id , password and system id
 */

export async function getCurrentMeetingInfo(url: string, meetingInfo: object) {
    try {
        source = CancelToken.source();
        return await axios.post(url + 'meetingInfo', meetingInfo, {cancelToken: source.token});
    } catch (error) {
        console.log('meetingInfoerror', error)
       return error;
    }
}

export async function doLogin(url: string, userData: object) {
    try {
        return await axios.post(url + 'login/user', userData);
    } catch (error) {
        return error;
    }
}
export async function updatePassword(url: string, userData: object) {
    console.log("updateResp", userData);
    try {
        return await axios.post(url + 'updatePassword', userData);
    } catch (error) {
        return error;
    }
}

export async function doLogOut(url: string) {
    try {
        return await axios.get(url + 'logout/user');
    } catch(error) {
        return error;
    }
}

export async function getUserPermission(url: string) {
    try {
        return await axios.get(url + 'getUserPermissions');
    } catch(error) {
        return error;
    }
}

export async function sendFeedBack(url: string, feedback: any) {
    try {
        return await axios.post(url + 'feedback', feedback);
    } catch (error) {
        return error;
    }
}

export async function scheduleMeeting(url: string, meetingData: any) {
    try {
        return await axios.post(url + 'addMeeting', meetingData);
    } catch (error) {
        return error;
    }
}

export async function updateMeeting(url: string, uuid: string, meetingData: any) {
    try {
        return await axios.post(url + 'updateMeeting/' + uuid, meetingData);
    } catch (error) {
        return error;
    }
}
export async function updateScheduleMeeting(url: string, meetingData: any) {
    try {
        return await axios.post(url + 'updateMeetingSchedule', meetingData);
    } catch (error) {
        return error;
    }
}
export async function deleteMeeting(url: string, uuid:any) {
    try {
        return await axios.delete(url + 'deleteMeeting/'+uuid);
    } catch (error) {
        return error;
    }
}

export async function getMeeting(url: string, data: any) {
    try {
        return await axios.post(url + 'getMeetings', data);
    } catch (error) {
        return error;
    }
}
export async function sendCalendarScheduleMeeting(url: string, data: any) {
    try {
        return await axios.post(url + 'sendCalendarScheduleMeeting', data);
    } catch (error) {
        return error;
    }
}
export async function getMeetingInvite(url: string, data: any) {
    try {
        return await axios.post(url + 'meetingInvite', data);
    } catch (error) {
        return error;
    }
}

export async function sendCalendarMeetingInvite(url: string, data: any) {
    try {
        return await axios.post(url + 'sendCalendarMeetingInvite', data);
    } catch (error) {
        return error;
    }
}

export async function getPersonalMeeting(url: string, data: any) {
    try {
        return await axios.post(url + 'getMeetings', data);
    } catch (error) {
        return error;
    }
}
export async function sendEmail(url: string, data: any) {
    try {
       // https://us7-dev.hoolva.com/v1/sendEmail
        return await axios.post(url + 'sendEmail', data);
    } catch (error) {
        return error;
    }
}

export async function getUpComingMeetings(url: string) {
    try {
        // https://us7-dev.hoolva.com/v1/getMeetingSchedules
        return await axios.post(url + 'getMeetingSchedules', {curr_date_time: Number(Math.round(new Date().getTime()/1000))});
    } catch (error) {
        return error;
    }
}
export async function getSSOProviderConfig(url: string) {
    try {
        return await axios.get(url + 'authProviderConfigs');
    } catch (error) {
        console.log('error in authProviderConfig', error)
    }
}

export async function doSSOProviderLogin(url: string, data: any) {
    try {
        return await axios.post(url + 'authProviderLogin', data);
    } catch (error) {
        return error;
    }
}
export async function doSSOProviderSignUp(url: string, data: any) {
    try {
        return await axios.post(url + 'authProviderSignup', data);
    } catch (error) {
        return error;
    }
}

export async function getDids(url: string, data:any) {
    try {
        return await axios.post(url + 'getDids', data);
    } catch (error) {
        return error;
    }
}

export async function submitChatFile(url: string, data: any, room_uuid:any) {
    try {
        console.log("selectedFile",url,room_uuid)
        return await axios.post(url + 'updocs/'+room_uuid, data);
    } catch (error) {
        return error;
    }
}
export async function getTenantInfo(url: string) {
    try {
        var select = ["brandname","clients","brandUrl","logos","theme","signup", "sname", "dial_in","dial_out"];
        // @ts-ignore
        return await axios.post(url + 'getTenantInfo',{select}, {crossDomain: true});
    } catch (error) {
        return error;
    }
}
export async function updateUserInfo(url:any, userInfo: any, uuid:any) {
    return new Promise((resolve, reject) => {
        axios.post(url+'updateUser/' + uuid, userInfo)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error.response);
            });
    });
};




