import React from 'react';
import {ThemeProvider} from "@material-ui/styles";
import { getTheme } from "../themeColors";

const HoolvaTheme = (props: any) => {
    const theme = {theme: getTheme()};
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
}
export default React.memo(HoolvaTheme)
