import * as NotifyActions from './notifyMessage.action';
import * as Config from '../../Config/config'
import {showMessage} from "./notifyMessage.action";
import '../../react-confirm-alert.css';
import DetectRTC from 'detectrtc';
import {confirmAlert} from "react-confirm-alert";
// @ts-ignore
import {Janus} from 'janus-gateway';
import {handleScreenShareAfterEnded, participants, setActiveScreen, setPublishersList, getRoomKey} from "./meet.action";
import $ from "jquery";
import {updateRecording} from "./record.action";



export const SET_SCREEN_SHARE_PUBLISHER_HANDLE: string = 'SET_SCREEN_SHARE_PUBLISHER_HANDLE';
export const SET_SCREEN_SHARE_INFO: string = 'SET_SCREEN_SHARE_INFO';
export const SET_REMOTE_SCREEN_SHARE_MODE: string = 'SET_REMOTE_SCREEN_SHARE_MODE';
export const SET_LOCAL_SCREEN_SHARE_MODE: string = 'SET_LOCAL_SCREEN_SHARE_MODE';
export const SET_SCREENSHARE_PARTICIPANTLIST: string = 'SET_SCREENSHARE_PARTICIPANTLIST';
export const SCREEN_SHARE_BUTTON_STATE: string = 'SCREEN_SHARE_BUTTON_STATE';
export const RESET_SCREENSHARE: string = 'RESET_SCREENSHARE';

export let screenShareParticipants: any[] = [];
export let screenShareIceRestart = false;

export function setScreenSharePublisherHandle(handle: any) {
    return {
        type: SET_SCREEN_SHARE_PUBLISHER_HANDLE,
        handle
    }
}
export function resetIceRestartScreenShare() {
    screenShareIceRestart = false;
}

export function setScreenShareButtonState(state: boolean) {
    return {
        type: SCREEN_SHARE_BUTTON_STATE,
        state
    }
}
export function setScreenShareInfo(msg: any) {
    return {
        type: SET_SCREEN_SHARE_INFO,
        msg
    };
}
export function setRemoteScreenShareMode(mode: boolean) {
    return {
        type: SET_REMOTE_SCREEN_SHARE_MODE,
        mode
    }
}
export function setLocalShareMode(mode: boolean) {
    return {
        type: SET_LOCAL_SCREEN_SHARE_MODE,
        mode
    };
}
export function setScreenSharePublisherList(participant: any) {
    return {
        type: SET_SCREENSHARE_PARTICIPANTLIST,
        participant
    }
}

export function resetScreenShare() {
    screenShareParticipants = [];
    return {
        type: RESET_SCREENSHARE
    }
}

export function sendJoinScreenShareRequest(handle: any, roomId: number, display: string, feedId: string) {
    return async function(dispatch: any, getState: any) {
        const joinRequest = {
            request: 'join',
            room: roomId,
            ptype: 'subscriber',
            feed : feedId
        };
        handle.send({ message: joinRequest });
    };
}

export function sendJoinAndConfigure(handle: any, id: number, display: string) {
    return async function(dispatch: any, getState: any) {
        const{meetingInfo,xqMessagesInfo} = getState().Hoolva;
        let shared: any = null;
        if(meetingInfo.e2ee) {
            shared = await getRoomKey(meetingInfo.meetingId, xqMessagesInfo.key);
        }
        if(DetectRTC.browser.isSafari) {
            confirmAlert({
                title: 'Screen share permission',
                message: 'Safari needs your permission to start the screen share. Do you want to allow?',
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Cancel',
                        onClick: () =>  {
                            handle.printLog({status: 'screen_share request canceled by user'})
                            handle.hangup();
                            handle.detach();
                            dispatch(setScreenShareButtonState(true));
                        }
                    },
                    {
                    label: 'Ok',
                    onClick: () =>  {
                        handle.createOffer({
                            media: {
                                video: 'screen',
                                audioSend: false,
                                videoRecv: false,
                                screenshareFrameRate: Config.configuration.screenshareFrameRate,
                                screenshareHeight: Config.configuration.screenshareHeight,
                                screenshareWidth: Config.configuration.screenshareWidth
                            },
                            sframe: meetingInfo.e2ee ? {
                                //more than 5 bytes value don't allow for outgoingId and incomingId
                                //so need to truncate
                                outgoingId: 34212,
                                sharedKey: shared
                                //keyPair: GenKeyPair
                            } : null,
                            isPublisher: true,
                
                            // simulcast: Config.configuration.simulcast,
                            async success(jsep: any) {
                                handle.printLog({status: 'success-screenshare-joinandconfigure'})
                                const publish = {
                                    request: 'joinandconfigure',
                                    audio: false,
                                    video: true,
                                    bitrate: Config.configuration.screenshareBitrate,
                                    videocodec: meetingInfo.e2ee ? 'vp8' : 'h264',
                                    room: +id,
                                    ptype: 'publisher',
                                    display: display,
                                };
                                handle.send({ message: publish, jsep });
                            },
                            error(error: any) {
                                handle.printLog({status: 'error-screenshare-joinandconfigure', error})
                                handle.hangup();
                                handle.detach();
                                dispatch(setScreenShareButtonState(true));
                                dispatch(showMessage('error', 'Error in screen share configure request', {}));
                            }
                        })
                    }
                  }
                ]
              });
        }
        else {
            handle.createOffer({
                media: {
                    video: 'screen',
                    audioSend: false,
                    videoRecv: false,
                    screenshareFrameRate: Config.configuration.screenshareFrameRate,
                    screenshareHeight: Config.configuration.screenshareHeight,
                    screenshareWidth: Config.configuration.screenshareWidth
                },
                sframe: meetingInfo.e2ee ? {
                    //more than 5 bytes value don't allow for outgoingId and incomingId
                    //so need to truncate
                    outgoingId: 34212,
                    sharedKey: shared
                    //keyPair: GenKeyPair
                } : null,
                isPublisher: true,
    
                // simulcast: Config.configuration.simulcast,
                async success(jsep: any) {
                    handle.printLog({status: 'success-screenshare-joinandconfigure'})
                    const publish = {
                        request: 'joinandconfigure',
                        audio: false,
                        video: true,
                        bitrate: Config.configuration.screenshareBitrate,
                        videocodec: meetingInfo.e2ee ? 'vp8' : 'h264',
                        room: +id,
                        ptype: 'publisher',
                        display: display,
                    };
                    handle.send({ message: publish, jsep });
                },
                error(error: any) {
                    handle.printLog({status: 'error-screenshare-joinandconfigure', error})
                    handle.hangup();
                    handle.detach();
                    dispatch(showMessage('error', 'Error in screen share configure request', {}));
                }
            })
        }

        
    }
}

export function sendScreenShareConfigureRequest() {
    return async function(dispatch: any, getState: any) {
        const {screenSharePublisherHandle} = getState().ScreenShare;
        await screenSharePublisherHandle.createOffer({
            media: {
                video: 'screen',
                audioSend: false,
                videoRecv: false,
                screenshareFrameRate: Config.configuration.screenshareFrameRate
            },
           // simulcast: Config.configuration.simulcast,
            async success(jsep: any) {
                const publish = {
                    request: 'configure',
                    audio: false,
                    video: true,
                    bitrate: Config.configuration.screenshareBitrate
                };
                screenSharePublisherHandle.send({ message: publish, jsep });
            },
            error(error: any) {
                console.log('Error in  screen share configure request');
                dispatch(showMessage('error', 'Error in screen share configure request', {}));
            }
        })
    }
}
export function stopScreenShare() {
    return async function(dispatch: any, getState: any) {
        console.log('screen share stopped');
        await dispatch(setScreenSharePublisherHandle(null))
        screenShareIceRestart = false;
        const {recorder} = getState().Record;
        if (recorder) {
            dispatch(updateRecording());
        }
    }
}
export function initScreenShare() {
    return async function(dispatch: any, getState: any) {
        screenShareIceRestart = false;
        const {hoolva, publisherHandle} = getState().Meet;
        const {meetingInfo, displayName, meetingId} = getState().Hoolva
        hoolva.attach({
            plugin: 'janus.plugin.videoroom',
            opaqueId: 'hoolva_video_room',
            room: +meetingId,
            ptype: "publisher",
            displayName: displayName,
            meeting_uuid: meetingInfo.uuid,
            screenShare: true,
            room_part_id: publisherHandle.room_part_id,
            success(pluginHandle: any) {
                hoolva.printLog({status: 'success-scrrenshare-attach'})
                dispatch(setScreenSharePublisherHandle(pluginHandle));
                dispatch(sendJoinAndConfigure(pluginHandle, meetingId, displayName));
               // dispatch(sendJoinScreenShareRequest(pluginHandle, +meetingInfo.screenshare_id, displayName, 'publisher', ''));
            },
            error(error: any) {
                hoolva.printLog({status: 'error-scrrenshare-attach', error})
                dispatch(setScreenShareButtonState(true));
                NotifyActions.showMessage('error', 'Error in attaching screen share plugin', {})
            },
            consentDialog(on: boolean) {
                console.log(`Consent dialog should be ${on ? 'on' : 'off'} now`);
                if(!on) {
                    dispatch(setScreenShareButtonState(true));
                }
            },
            webrtcState(on: boolean) {
                hoolva.printLog({status: 'webrtc-state-scrrenshare-attach', data: { message: `WebRTC PeerConnection for screen share is ${on ? 'up' : 'down'} now`}})
                if(on) {
                    const {recorder} = getState().Record;
                    if(recorder) {
                        dispatch(updateRecording());
                    }
                }
            },
            iceState(state: string) {
                hoolva.printLog({status: 'ice-state-screenshare', data: { message: state}})
                if(state === 'failed') {
                    screenShareIceRestart = true;
                }
            },
            onlocalstream(stream: MediaStream, handle: any) {
                stream.addEventListener('inactive', e => {
                    dispatch(setLocalShareMode(false));
                    const leaveRequest = { request: 'leave' };
                    handle.send({ message: leaveRequest });
                    screenShareIceRestart = false;
                });
                if(DetectRTC.browser.isFirefox) {
                    stream.getVideoTracks()[0].addEventListener('ended', e => {
                        dispatch(setLocalShareMode(false));
                        const leaveRequest = { request: 'leave' };
                        handle.send({ message: leaveRequest });
                        screenShareIceRestart = false;
                    })
                }
            },
            onmessage(msg: any, jsep: any) {
                const event = msg.videoroom;
                const {screenSharePublisherHandle} = getState().ScreenShare;
                if (event !== undefined && event !== null) {
                    if (event === 'joined') {
                        dispatch(setScreenShareInfo(msg));
                        dispatch(setLocalShareMode(true));
                      //  dispatch(sendScreenShareConfigureRequest())
                    }
                    if(event === 'event') {
                        if(msg.error) {
                            if(msg?.error_code === 430) {
                                dispatch(showMessage('error','Video compatibility issue', {}));
                                if(screenSharePublisherHandle) {
                                    screenSharePublisherHandle.hangup();
                                    screenSharePublisherHandle.detach();
                                    dispatch(stopScreenShare());
                                }
                            } 
                        }
                        if(msg.leaving === 'ok') {
                            const {screenSharePublisherHandle} = getState().ScreenShare;
                            if(screenSharePublisherHandle) {
                                screenSharePublisherHandle.hangup();
                                screenSharePublisherHandle.detach();
                                dispatch(stopScreenShare());
                            }
                        }
                    }
                }
                if (jsep !== undefined && jsep !== null) {
                    console.log('Handling SDP as well...');
                    screenSharePublisherHandle.handleRemoteJsep({ jsep });
                }
            }
        });
    }
}


export function unSubscribeScreenShare(msg: any) {
    return async function(dispatch: any, getState: any) {
        const { leaving } = msg;
        let remoteFeed = screenShareParticipants.find(participant => participant.rfid === leaving);
        console.log('screen share leaving', remoteFeed);
        if(remoteFeed !== null && remoteFeed !== undefined) {
            remoteFeed.detach();
            dispatch(setRemoteScreenShareMode(false));
            console.log("remoteFeed", remoteFeed);
            const index = screenShareParticipants.findIndex((participant => participant.rfid == leaving));
            screenShareParticipants[index].removed = true;
            dispatch(setScreenSharePublisherList(screenShareParticipants));
            dispatch(handleScreenShareAfterEnded())
        }
    }
}

export function subscribeScreenShare(feedId: string, display: string, room_part_id: any) {
    return async function(dispatch: any, getState: any) {
        let shareScreenHandle: any = null;
        const {hoolva} = getState().Meet;
        const {meetingInfo, meetingId} = getState().Hoolva
        hoolva.attach({
            plugin: 'janus.plugin.videoroom',
            opaqueId: 'hoolva-screenshare-particiant',
            ptype: "subscriber",
            participant_id: feedId,
            displayName: display,
            meeting_uuid: meetingInfo.uuid,
            screenShare: true,
            room_part_id: room_part_id,
            success(pluginHandle: any) {
                shareScreenHandle = pluginHandle;
                hoolva.printLog({status: 'success-attach-screenshare-subsriber'})
                dispatch(sendJoinScreenShareRequest(pluginHandle, +meetingId, display, feedId));
                let screenShareParticipantList = [...screenShareParticipants];
                let falsyIndex: any;
                if(!screenShareParticipantList.every(function(element, index) {falsyIndex = index; return element.removed !== true})) {
                    screenShareParticipants[falsyIndex] = pluginHandle;
                } else {
                    screenShareParticipants = [...screenShareParticipants, pluginHandle];
                }
                dispatch(setScreenSharePublisherList(screenShareParticipants));
            },
            error(error: any) {
                dispatch(showMessage('error', 'error in screen share attach in subscriber', {}));
                hoolva.printLog({status: 'error-attach-screenshare-subsriber', error})
            },
            async onmessage(msg: any, jsep: any) {
                const event = msg.videoroom;
                if (msg.error !== undefined && msg.error !== null) {
                    hoolva.printLog({status: 'error-attach-screenshare-subsriber', error: msg.error})
                    dispatch(showMessage('error', 'screen share error event in subscriber', {}));
                } else if (event !== undefined && event !== null) {
                    if (event === 'attached') {

                    }
                    else if (event === 'event') {

                    } else {

                    }
                    if (jsep !== undefined && jsep !== null) {
                        let shared = null;
                        if(getState().Hoolva.meetingInfo.e2ee) {
                            shared = await getRoomKey(getState().Hoolva.meetingInfo.meetingId, getState().Hoolva.xqMessagesInfo.key);
                        }
                        shareScreenHandle.createAnswer({
                            jsep,
                            media: { audioSend: false, videoSend: false },
                            sframe: getState().Hoolva.meetingInfo.e2ee ? {
                                outgoingId: 34212,
                                incomingId: Math.floor(+shareScreenHandle.rfid/100000),
                                sharedKey: shared
                            } : null,
                            isPublisher: false,
                            async success(jsepAnswer: any) {
                                hoolva.printLog({status: 'succes-create-answer-screenshare-subsriber'})
                                const body = {
                                    request: 'start',
                                    room: +meetingId
                                };
                                console.log('screen share start', body)
                                shareScreenHandle.send({message: body, jsep: jsepAnswer });
                                dispatch(setRemoteScreenShareMode(true));
                                dispatch(setActiveScreen(2));
                            },
                            error(error: any) {
                                hoolva.printLog({status: 'error-create-answer-screenshare-subsriber', error})
                                dispatch(showMessage('error', 'error in starting screen share in subscriber', {}));
                            }
                        });
                    }
                }
            },
            webrtcState(on: boolean, reason: string, handle: any) {
                hoolva.printLog({status: 'webrtc-state-screenshare-subsriber', data: {messgae: `WebRTC screen share PeerConnection  is ${on ? 'up' : 'down'} now`}})
                if(on) {
                    handle.state = 'connected';
                    if(screenShareParticipants.length > 0) {
                        if (screenShareParticipants.findIndex(data => data.rfid === handle.rfid) !== -1) {
                            screenShareParticipants[screenShareParticipants.findIndex(data => data.rfid === handle.rfid)] = handle;
                            setScreenSharePublisherList(participants)
                        }
                    }
                    const {recorder, recorderMode} = getState().Record;
                    if(recorder && (recorderMode === 'gallery'|| recorderMode === 'host') ) {
                        dispatch(updateRecording());
                    }
                }
            },
            onlocalstream(stream: MediaStream, handle: any) {
                console.log(
                    'The subscriber stream is recvonly, we dont expect anything here',
                    stream
                );
            },
            onremotestream(stream: MediaStream, handle: any, type: string,) {
                console.log('screen share remote stream', handle, document.getElementById('screenshare_' + handle.id), stream)
                Janus.attachMediaStream(
                    document.getElementById('screenshare_' + handle.id),
                    stream
                );

                $(`#screenShare${handle.id}`).html(handle.rfdisplay);
            },
            oncleanup() {
                // Janus.log(` ::: Got a cleanup notification (remote feed ${id}) :::`);
                shareScreenHandle = null;
            }
        });
    }
}

export function iceRestartScreenShare() {
    return async function(dispatch: any, getState: any) {
        if(screenShareIceRestart) {
            screenShareIceRestart = false;
            const {screenSharePublisherHandle} = getState().ScreenShare;
            screenSharePublisherHandle.createOffer({
                iceRestart: true,
                success(jsep: any) {
                    screenSharePublisherHandle.printLog({status: 'icerestart-screenshare-success'})
                    const publish = {
                        request: 'configure',
                        restart: true,
                    };
                    screenSharePublisherHandle.send({ message: publish, jsep });
                },
                error(error: any) {
                    screenSharePublisherHandle.printLog({status: 'icerestart-screenshare-error', error})
                    dispatch(showMessage('error', 'error in ice restart screen share' , {}));
                }
            });
        }
    }
}

