import React, {useEffect, useState } from "react";
import cx from "classnames";
import classes from "./people.module.scss";
import {makeStyles} from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector,useDispatch } from "react-redux";
import * as Actions from "../../../../store/actions";
import Avatar from 'react-avatar';
import IconButton from "../../../../components/HoolvaButtons/IconButtons/iconButton";
import {env} from '../../../../../package.json';
import classNames from "classnames";
// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    fluid: {
        backgroundColor: theme.videoRoomTernaryColor
    },
    button: {
        backgroundColor: theme.videoRoomTernaryColor,
        border:"1px solid "+ theme.videoRoomTernaryColor,
        color: theme.iconColor,
        '&hover': {
            backgroundColor: theme.iconHoverBGColor,
            color: theme.iconHoverColor,
        }
    }
}))
const People = (props: any) => {
    const theme = useStyles(props);
  const dispatch = useDispatch();
  const {
    participantList,
    meetingInfo,
    waitingList,
    t,
    openInvite,
    allowUnMute,
    localUnmuteCheckBox,
    disableVideoButton,
    currentSlider,
    maxTileinslider
  } = props;
  const {loggedInData} = useSelector((state: any) => state.Auth);
  const { API_ROOT, displayName } = useSelector((state: any) => state.Hoolva);


  const onUnMuteParticipant = (id: any) => {
    dispatch(Actions.muteUnmuteParticipant("unmute_request", id ? +id : null));
  };
  const onMuteParticipant = (id: any) => {
    if (localUnmuteCheckBox) {
      dispatch(Actions.muteUnmuteParticipant("mute_request", id ? +id : null));
    } else {
      dispatch(Actions.muteUnmuteParticipant("mute_order", id ? +id : null));
    }
  };
  const kickParticipant = (id: any) => {
    dispatch(Actions.setConfirmMessage("kick", true, { id: +id }));
    // dispatch(Actions.sendKickMessage(+id));
  };
  const toggleAudio = (mode: boolean) => {
    //  disabled={!allowUnMute || connectivityMode ==='phone' ? true:false}
    if (allowUnMute) {
      if(meetingInfo.audio_bridge) {
        dispatch(Actions.toggleMixedAudioMute(mode));
      } else {
        dispatch(Actions.toggleMute(mode));
      }
    } else {
      dispatch(Actions.showMessage("warn", "No permission to unmute", {}));
    }
  };
  const toggleVideo = (mode: boolean) => {
    dispatch(Actions.toggleVideo(mode));
  };
  const requestTurnVideoOn = (id: any) => {
    dispatch(Actions.sendVideoToggleRequest("video_on_request", +id));
  };
  const requestTurnVideoOff = (id: any) => {
    dispatch(Actions.sendVideoToggleRequest("video_off_request", +id));
  };
  const requestLowerHand = (id: any) => {
    dispatch(Actions.sendRaiseHandRequest("lowerhand_request", +id));
  };
  const requestRemoteLowerHand = (id: any) => {
    dispatch(Actions.sendRaiseHandRequest("remote_lowerhand_request", +id));
  };
  const handleAllowMute = (value: boolean) => {
    // setAllowMute(value);
    dispatch(Actions.allowLocalUnmute(value));
  };
  const handleApproveAll = () => {
    console.log('waiting list', waitingList);
    const approveList = waitingList.map((list: any) => {
      return { session_id: list.session_id, request_tid: list.request_tid, enter: true };
    });
    console.log("approvelist", approveList);
    dispatch(Actions.approveDenyParticipants("join_responses", approveList));
    dispatch(Actions.setWaitingRoomParticipants([]));
  };
  const handleDenyAll = () => {
    const denyList = waitingList.map((list: any) => {
      return { session_id: list.session_id,  request_tid: list.request_tid, enter: false };
    });
    console.log("approvelist", denyList);
    dispatch(Actions.approveDenyParticipants("join_responses", denyList));
    dispatch(Actions.setWaitingRoomParticipants([]));
  };

  const handleApproveParticipant = (list: any) => {
    const approveList = [{ session_id: list.session_id, request_tid: list.request_tid, enter: true }];
    dispatch(Actions.approveDenyParticipants("join_responses", approveList));
    const removeWaitingList = waitingList.filter((wList: any) => {
      return wList.session_id !== list.session_id;
    });
    console.log("removeWaitingList", removeWaitingList);
    dispatch(Actions.setWaitingRoomParticipants(removeWaitingList));
  };
  const handleDenyParticipant = (list: any) => {
    const denyList = [{ session_id: list.session_id, request_tid: list.request_tid, enter: false }];
    dispatch(Actions.approveDenyParticipants("join_responses", denyList));
    const removeWaitingList = waitingList.filter((wList: any) => {
      return wList.session_id !== list.session_id;
    });
    dispatch(Actions.setWaitingRoomParticipants(removeWaitingList));
  };
  const moveToSlide = (participants: any, index: number) => {
    const start = currentSlider* maxTileinslider;
    const end =  (currentSlider * maxTileinslider) + (maxTileinslider - 1);
    if(index >= start && index <= end) {

    } else {
      console.log('participant not in current slide');
     // Array(8).fill().map((_, i) => i * i);
     // for(let i=0; i<=index; i++) {
     //
     // }
      let sliderLength = 0;
      if (participantList.length > 0) {
         sliderLength = Math.floor(
            participantList.length / maxTileinslider
        );
        if (sliderLength < 1) {
          sliderLength = 1;
        } else {
          let extraCount =
              participantList.length % maxTileinslider;
          if (extraCount !== 0) {
            sliderLength = sliderLength + 1;
          }
        }
      }


      console.log('total slide', sliderLength)
      for (let i =0 ; i<sliderLength; i++) {
        const nstart = i * maxTileinslider;
        const nend = (i * maxTileinslider) + (maxTileinslider - 1);
        console.log('nstart', nstart, nend, index)
        if(index >= nstart && index <= nend) {
          dispatch(Actions.setCurrentSlider(i));
          return;
        }
      }
      // Array(totalSlide).fill(1).map((_, i) => {

      // });

    }
  }

  const createAvatar = (name:any)=>{
    let userName = name?name.trim():null
      if(userName!== null){
          if(/\s/g.test(String(userName)) === true){
              let str = String(userName).split(" ")
              console.log(str, 'str')
              let fName = str[0]
              let lName = str[str.length -1]
              console.log(fName, lName ,'kkkkz')
              return (fName.concat(' ',lName))
          }else{
              let str = String(userName).slice(0,3)
              let fName = str[0]
              let lName = str[1]
              console.log(fName, lName ,'kkkkz')
              return(fName.concat(' ',lName))
          }
      }
  }
  function updateProfileName(fname: string) {
    const name = fname ? fname.trim() : null;
    let newName = '';
    if (name !== null) {
      if (/\s/g.test(String(name)) === true) {
        const str = String(name).split(' ');
        const fName = str[0];
        const lName = str[str.length - 1];
        newName = fName.concat(' ', lName);
      } else {
        const str = String(name).slice(0, 3);
        const fName = str[0];
        const lName = str[1];
        newName = fName.concat(' ', lName);
      }
    }
    return newName;
  }

  return (
    <div className={classes.people}>
      <div className={classes.List}>
        {meetingInfo && meetingInfo.host && waitingList.length > 0 ? (
            <div>
              <div className={classes.title}>
                <div className={classes.buttons}>
                  <div style={{ width: "50%" }}>
                    {t("meetingPage.rightPanel.people.Waiting")} ({waitingList.length})
                  </div>
                  <div>
                    {waitingList.length > 0 ? (
                        <button onClick={handleApproveAll}>{t("meetingPage.rightPanel.people.AllowAll")}</button>
                    ) : null}
                  </div>
                  <div>
                    {waitingList.length > 0 ? (
                        <button onClick={handleDenyAll}>{t("meetingPage.rightPanel.people.DenyAll")}</button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={classes.WaitingListScroll}>
              {waitingList.map((list: any) => {
                return (
                    <div className={classes.waitingList} key={list.session_id}>
                      <div className={classes.User}>
                        <div className={classes.avatar}>
                          <FontAwesomeIcon icon={["fas", "user"]} />
                        </div>
                        <div className={classes.name}>{list.name}</div>
                      </div>
                      <div className={classes.button}>
                        <div>
                          <button
                              onClick={() =>
                                  handleApproveParticipant(list)
                              }
                          >
                         { t("meetingPage.rightPanel.people.Allow")}
                          </button>
                        </div>
                        <div>
                          <button
                              onClick={() => handleDenyParticipant(list)}
                          >
                            {t("meetingPage.rightPanel.people.Deny")}
                          </button>
                        </div>
                      </div>
                    </div>
                );
              })}
              </div>
            </div>
        ) : null}

        <div className={cx(classes.title, classes.topMargin)}>
          <div className={classes.buttons}>
            <div style={{ width: "50%" }}>
              {t("meetingPage.rightPanel.people.inMeeting")} (
              {participantList.length})
            </div>
            {meetingInfo ? (
              meetingInfo?.host ? (
                <>
                  <div>
                    <button onClick={() => onMuteParticipant(null)}>
                      {t("meetingPage.rightPanel.people.muteAll")}
                    </button>
                  </div>
                  <div>
                    <button onClick={() => onUnMuteParticipant(null)}>
                      {t("meetingPage.rightPanel.people.unmuteAll")}
                    </button>
                  </div>
                </>
              ) : null
            ) : null}
          </div>
        </div>
        {meetingInfo?.host ? (
          <label className={cx(classes.container)}>
            {t("meetingPage.rightPanel.people.allowUnMute")}
            <input
              type="checkbox"
              checked={localUnmuteCheckBox}
              onChange={(event) => handleAllowMute(event.target.checked)}
            />
            <span className={cx(classes.checkmark)}></span>
          </label>
        ) : null}
        <div className={cx(classes.loading, waitingList.length > 0 ? classes.ParticipantWithWaitingScroll:classes.ParticipantScroll)}>
          {participantList.map((participant: any, index: number) => {
            return (
              <div className={classes.ActiveList} key={participant.id}>
                <div className={classes.User}>
                  <div className={classes.avatar}>
                    {/*{*/}
                    {/*    meetingInfo.host ?*/}
                    {/*    <FontAwesomeIcon className={cx(classes.host)} icon={["fas", "crown"]} />*/}
                    {/*    : null*/}
                    {/*}*/}

                    {participant?.profile_picture !== null && participant?.profile_picture !== "undefined" && participant?.profile_picture ?
                                <img  src ={participant?.profile_picture} style={{ height:  '28px'  }} /> 
                                    : loggedInData?.profile_picture !== null && loggedInData?.profile_picture &&  loggedInData?.profile_picture !== "undefined" && participant?.isPublisher ?
                                        <img src ={loggedInData?.profile_picture} style={{ height:  '28px'  }} /> 
                                            : <Avatar name={updateProfileName(participant.rfdisplay)} size={'28'} round={false} />
                                            }



                    {/* {!participant?.profile_picture ? 
                            participant?.profile_picture !== null && participant?.profile_picture !== "undefined" && participant?.profile_picture ?
                                <img  src ={participant?.profile_picture} style={{ height: '28px' }} /> 
                                    : loggedInData?.profile_picture !== null && loggedInData?.profile_picture !== "undefined" && participant?.isPublisher ?
                                        <img src ={loggedInData?.profile_picture} style={{ height: '28px' }} /> 
                                            : <Avatar name={participant.rfdisplay} size={'28'} round={false} />
                                                : <Avatar name={participant.rfdisplay} size={'28'} round={false} />} */}

                    {/* {participant.host ? (
                       loggedInData.profile_picture!=null||loggedInData.profile_picture!="undefined"? 
                       <img src ={loggedInData.profile_picture} style={{maxWidth:50}} />
                       : <div>
                            <Avatar name={createAvatar(participant.rfdisplay)} title={participant.rfdisplay}  size={'28'} round={false}/>
                          </div>
                        ): loggedInData.profile_picture!=null||loggedInData.profile_picture!="undefined"? (
                          <img src ={loggedInData.profile_picture}  style={{ maxWidth:50 }} />
                          ) : (
                            <Avatar name={createAvatar(participant.rfdisplay)} title={participant.rfdisplay} size={'28'} round={false}  />
                          )} */}
                  </div>
                  <div title={participant.rfdisplay} className={classes.name} onClick={() => moveToSlide(participant, index)}>
                    <span className={cx(classes.DisplasNameElli)}>{`${participant.rfdisplay}`}</span>
                    {participant.isPublisher ? " (You)" : null}
                    {env !== 'production' ? participant.detached ? ' (No)' : ' (Yes)': null}
                  </div>
                </div>

                <div className={classes.button}>
                  {/* <FontAwesomeIcon className={(cx(classes.red))} icon={["fal", "presentation"]} /> */}
                  {participant.raisehand ? (
                    <FontAwesomeIcon
                      className={cx(classes.gold)}
                      onClick={() =>
                        participant.isPublisher
                          ? requestLowerHand(participant.rfid)
                          : meetingInfo.host
                          ? requestRemoteLowerHand(participant.rfid)
                          : null
                      }
                      icon={["fas", "hand-paper"]}
                    />
                  ) : (
                    <span className={classes.blank}></span>
                  )}
                  {participant.host ? (
                    <FontAwesomeIcon
                      className={classes.crown}
                      icon={["fad", "user-crown"]}
                    />
                  ) : meetingInfo.host ? (
                    <FontAwesomeIcon
                      icon={["fas", "sign-out-alt"]}
                      onClick={() => kickParticipant(participant.rfid)}
                    />
                  ) : (
                    null
                  )}
                  {participant.audio === "muted" ? (
                    <FontAwesomeIcon
                      className={cx(
                        meetingInfo.host || participant.isPublisher
                          ? ""
                          : classes.notAllowed
                      )}
                      icon={["fas", "microphone-slash"]}
                      onClick={() =>
                        participant.isPublisher
                          ? toggleAudio(true)
                          : meetingInfo.host
                          ? onUnMuteParticipant(participant.rfid)
                          : null
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={cx(
                        meetingInfo.host || participant.isPublisher
                          ? ""
                          : classes.notAllowed
                      )}
                      icon={["fas", "microphone"]}
                      onClick={() =>
                        participant.isPublisher
                          ? toggleAudio(false)
                          : meetingInfo.host
                          ? onMuteParticipant(participant.rfid)
                          : null
                      }
                    />
                  )}
                  {participant.video === "muted" ? (
                    <FontAwesomeIcon
                      className={cx(
                        meetingInfo.host || participant.isPublisher
                          ? ""
                          : classes.notAllowed
                      )}
                      icon={["fas", "video-slash"]}
                      onClick={() =>
                        participant.isPublisher
                          ? disableVideoButton ? null :toggleVideo(true)
                          : meetingInfo.host
                          ? requestTurnVideoOn(participant.rfid)
                          : null
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={cx(
                        meetingInfo.host || participant.isPublisher
                          ? ""
                          : classes.notAllowed
                      )}
                      icon={["fas", "video"]}
                      onClick={() =>
                        participant.isPublisher
                          ? disableVideoButton ? null :toggleVideo(false)
                          : meetingInfo.host
                          ? requestTurnVideoOff(participant.rfid)
                          : null
                      }
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {meetingInfo?.host || false ? (
        <div className={cx(classes.ListInvite)}>
          <IconButton
            classname={[classes.button,theme.button]}
            tooltipId="userplus"
            clicked={() => openInvite(true)}
            tooltipText={t("meetingPage.topBar.invite")}
            iconName={"user-plus"}
            rightNameBar={t("meetingPage.topBar.invite")}
            iconType={"fas"}
          />
        </div>
      ) : null}
    </div>
  );
};
export default React.memo(People);
