export const SET_LAYOUT: string = 'SET_LAYOUT';
export const SET_LANGUAGE: string = 'SET_LANGUAGE';
export const SET_DYNAMIC_BG: string = 'SET_DYNAMIC_BG';
export const SET_SLIDER_TILE_LENGTH: string = 'SET_SLIDER_TILE_LENGTH';
export const SET_LITETHEME: string = 'SET_LITETHEME';
export const SET_DARKTHEME:string = 'SET_DARKTHEME';
export const SET_TENANTINFO:string = 'SET_TENANTINFO';

export function setLayout(displayLayout: string) {
    return {
        type: SET_LAYOUT,
        displayLayout
    }
}
export function setLanguage(displayLanguage: string) {
    return {
        type: SET_LANGUAGE,
        displayLanguage
    }
}
export function setDynamicBG(dynamicBG: boolean) {
    return {
        type: SET_DYNAMIC_BG,
        dynamicBG
    }
}
export function setSliderTileLength(sliderTileLength: number) {
    return {
        type: SET_SLIDER_TILE_LENGTH,
        sliderTileLength
    }
}
export function setThemeLiteVariables(ThemeLiteJSON: any) {
    return {
        type: SET_LITETHEME,
        ThemeLiteJSON
    }
}
export function setThemeDarkVariables(ThemeDarkJSON: any) {
    return {
        type: SET_DARKTHEME,
        ThemeDarkJSON
    }
}
export function setTenantInfo(tenantInfoJSON: any) {
    return {
        type: SET_TENANTINFO,
        tenantInfoJSON
    }
}
