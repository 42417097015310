import React,{useState} from "react";
import classes from "./record.module.scss";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../store/actions";
import IconButton from "../HoolvaButtons/IconButtons/iconButton";
import * as Config from '../../Config/config';
import DetectRTC from "detectrtc";
import HoolvaAlertMessage from "../../components/HoolvaAlertMessage/hoolvaAlertMessage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Record = (props: any) => {
  const { recordModal, setRecordModal, setRecordFlag, recordStatus, meetingInfo,t } = props;
  const { localRecordState } = useSelector((state: any) => state.Record);
  const reset = (e: any) => {
    e.preventDefault();
  };
  const [showConfirm, setShowConfirm] = useState(false);
    const [confirmTitle, setConfirmTitle] = useState('Browser not compatible');
    const [confirmMessage, setConfirmMessage] = useState('Local recording is not supported on this browser. Please use chrome to enjoy full functionalities.');
  const dispatch = useDispatch();
  const startRecording = (flag: string) => {
    if (!DetectRTC.browser.isChrome && flag === "L") {
      setShowConfirm(true);
    } else {
      setRecordFlag(flag);
      if (flag === "L") {
        console.log('meetingInfo.record_mode', meetingInfo.record_mode);
        if (recordStatus !== 'start') {
          console.log('meetingInfo.record_mode 2222', meetingInfo.record_mode);
          if (meetingInfo.record_mode === 'host_with_others') {
            dispatch(Actions.startRecording('video', 'gallery', false))
          } else if (meetingInfo.record_mode === 'audio_only') {
            console.log('meetingInfo.record_mode 3333', meetingInfo.record_mode);
            dispatch(Actions.startRecording('audio', 'host', false))
          } else if (meetingInfo.record_mode === 'host_only') {
            dispatch(Actions.startRecording('video', 'host', false))
          }
        }
      } else {
        dispatch(Actions.sendRecordRequest("configure", true));
        dispatch(Actions.setRecordState(true));
      }
      setRecordModal(false);
    }
  }
  const handleConfirm = () => {
    setShowConfirm(false);
}
  return (
    <>
      {recordModal ? (
        <div>
          <div className={cx(classes.modalBG)}></div>
          <div className={cx(classes.modal)}>
            <div className={cx(classes.content)}>
              <div className={cx(classes.header)}>
                <div className={cx(classes.padding8)}>
                  <FontAwesomeIcon
                    onClick={() => setRecordModal(false)}
                    className={cx(classes.close)}
                    icon={["fas", "times"]}
                  />
                  {t("schedule.Record")}
                </div>
              </div>
              <div className={cx(classes.body)}>
                <div className={cx(classes.tabBody)}>
                  <div className={cx(classes.bodyContent)}>
                    <div className={cx(classes.bodyTitle)}>
                     {t("schedule.WhereDoYouWantToStoreThisRecording")}?
                    </div>
                    <div className={classes.record}>
                      <div style={{ display: "flex", height: 98 }}>
                        {/*<div style={{flex:1, textAlign: 'center', justifyContent: 'center', alignSelf: 'center', margin: "auto", borderRight: '1px solid #405050'}}>*/}
                        <IconButton
                          clicked={() => startRecording('L')}
                          tooltipId="settings"
                          iconName={"desktop"}
                          iconType={"fal"}
                          nameBar={t("schedule.ThisComputer")}
                        />
                        {/*</div>*/}
                        {/*<div style={{flex:1, textAlign: 'center', justifyContent: 'center', alignSelf: 'center', margin: "auto" }}>*/}
                        <IconButton
                          clicked={() => startRecording('C')}
                          tooltipId="settings"
                          iconName={"cloud"}
                          iconType={"fal"}
                          nameBar={Config.configuration.brandName + t("schedule.Cloud")}
                        />
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HoolvaAlertMessage mode={true} t={t} open={showConfirm} title={confirmTitle} message={confirmMessage} confirm={() =>handleConfirm()}/>
        </div>
      ) : null}
    </>
  );
};
export default Record;
