import * as Actions from '../actions';
import* as Config from '../../Config/config';

const initialState: any = {
    audio: false,
    video: false,
    isWebCam: true,
    audioDeviceName: '',
    videoDeviceName: '',
    outputDeviceName: '',
    connectivityMode: '',
    chat: false,
    speechMode: false,
    chatNotificationCount: 0,
    disableVideoButton: false,
    audioPermission: '',
    videoPermission: '',
    data: false,
    selectedAudio: '',
    selectedVideo: '',
    selectedOutput: '',
    hoolva: null,
    publisherHandle: null,
    topic: '',
    publisherId: '',
    publisherPrivateId: '',
    isAdmin: false,
    startTime: '',
    publishersList: [],
    currentSlider: 0,
    maxTileinslider: Config.configuration.maxTileLimit,
    stateUpdate: false,
    messageList: [],
    waitingList: [],
    showWaitingModal: false,
    preventWaitingModal: false,
    people: false,
    iceState: '',
    confirmData: {
        open: false,
        title: '',
        message: '',
        type: ''
    },
    raiseHand: false,
    timeInSec: 0,
    time: '00:00:00',
    feedback: true,
    allowUnMute: true,
    localUnmuteCheckBox: false,
    activeScreen: 1,
    activeSpeaker: null,
    nameChange: false,
    recordState:false,
    joinMode: 'open',
    cloudRecordingStatus: 'stop',
    localCloudRecordingStatus:'stop',
    notifyAlert: false,
    joinedState: false,
    webrtcStatus: true,
    waitingRoomLoader:false,
    videoResWidth: Config.configuration.videoResolution2.width,
    videoResHeight: Config.configuration.videoResolution2.height,
    latencyMode: 'LOW',
    didsList:[],
    primaryDid: ''
};

const MeetReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case Actions.SET_AUDIO_STATE: {
            return {
                ...state,
                audio: action.audio
            }
        }
        case Actions.SET_VIDEO_STATE: {
            return  {
                ...state,
                video: action.video
            }
        }
        case Actions.SET_IS_WEB_CAM: {
            console.log('setting webcam mode', action.mode)
            return {
                ...state,
                isWebCam: action.mode
            }
        }
        case Actions.SET_AUDIO_PERMISSION: {
            return  {
                ...state,
                audioPermission: action.permission
            }
        }
        case Actions.SET_VIDEO_PERMISSION: {
            return {
                ...state,
                videoPermission: action.permission
            }
        }
        case Actions.SET_VIDEO_RESOLUTIONS: {
            return {
                ...state,
                videoResWidth: action.width,
                videoResHeight: action.height
            }
        }
        case Actions.SET_HOOLVA_CONNECTION: {
            console.log('SET_HOOLVA_CONNECTION', action.hoolva)
            return  {
                ...state,
                hoolva: action.hoolva
            }
        }
        case Actions.SET_PUBLISHER_PLUGIN_HANDLE: {
            return {
                ...state,
                publisherHandle: action.pluginHandle
            }
        }
        case Actions.SET_CURRENT_MEETING_INFO: {
            return {
                ...state,
                topic: action.topic,
                publisherId: action.id,
                publisherPrivateId: action.private_id,
                isAdmin: action.admin,
                startTime: action.start_time
            }
        }
        case Actions.SET_PUBLISHERS_LIST: {
            return {
                ...state,
                publishersList: action.publishers,
                stateUpdate: !state.stateUpdate
            };
        }
        case Actions.SET_JOIN_MODE: {
            return {
                ...state,
                joinMode: action.mode
            }
        }
        case Actions.SET_JOINED_STATE: {
            return {
                ...state,
                joinedState: action.state
            }
        }
        case Actions.SET_CURRENT_SLIDER: {
            return {
                ...state,
                currentSlider: action.slider
            }
        }
        case Actions.SET_WEBRTC_STATUS: {
            return {
                ...state,
                webrtcStatus: action.mode
            }
        }
        case Actions.SET_SELECTED_OUTPUT_DEVICE: {
            return {
                ...state,
                outputDeviceName: action.label,
                selectedOutput: action.deviceId
            }
        }
        case Actions.SET_SELECTED_VIDEO_DEVICE: {
            return {
                ...state,
                videoDeviceName: action.label,
                selectedVideo: action.deviceId
            }
        }
        case Actions.SET_LATENCY_MODE: {
            return {
                ...state,
                latencyMode: action.mode
            }
        }
        case Actions.SET_SELECTED_AUDIO_DEVICE: {
            return {
                ...state,
                audioDeviceName: action.label,
                selectedAudio: action.deviceId
            }
        }
        case Actions.SET_DATA_MODE: {
            return {
                ...state,
                data: action.mode
            }
        }
        case Actions.SET_WAITING_PARTICIPANTS: {
            return {
                ...state,
                waitingList: action.participants
            }
        }
        case Actions.SET_MESSAGE_LIST: {
            return {
                ...state,
                messageList: action.messages
            }
        }
        case Actions.SET_DISABLE_VIDEO_BUTTON: {
            return {
                ...state,
                disableVideoButton: action.mode
            }
        }
        case Actions.SHOW_WAITING_MODAL: {
            return  {
                ...state,
                showWaitingModal: action.show
            }
        }
        case Actions.PREVENT_WAITING_MODAL: {
            return {
                ...state,
                preventWaitingModal: action.mode
            }
        }
        case Actions.SET_CLOUD_RECORDING_STATUS: {
            return {
                ...state,
                cloudRecordingStatus: action.status
            }
        }
        case Actions.SET_LOCAL_CLOUD_RECORDING_STATUS: {
            return {
                ...state,
                localCloudRecordingStatus: action.status
            }
        }
        case Actions.SET_PEOPLE_TAB: {
            return {
                ...state,
                people: action.people
            }
        }
        case Actions.SET_CHAT_MODE: {
            return {
                ...state,
                chat: action.mode
            }
        }
        case Actions.SET_CHAT_NOTIFICATION_COUNT: {
            return {
                ...state,
                chatNotificationCount: action.count
            }
        }
        case Actions.SET_SPEECH_DETECTION: {
            return {
                ...state,
                speechMode: action.speechMode
            }
        }

        case Actions.SET_ICE_STATE: {
            return {
                ...state,
                iceState: action.state
            }
        }

        case Actions.SET_RAISE_HAND: {
            return {
                ...state,
                raiseHand: action.mode
            }
        }

        case Actions.SET_CONFIRM_DATA: {
            return {
                ...state,
                confirmData: action.data
            }
        }
        case Actions.SET_NOTIFICATION_ALERT: {
            return {
                ...state,
                notifyAlert: action.notify
            }
        }
        case Actions.SET_TIMER: {
            return {
                ...state,
                time: action.time,
                timeInSec: action.seconds
            }
        }
        case Actions.SET_FEED_BACK: {
            return {
                ...state,
                feedback: action.mode
            }
        }
        case Actions.SET_ALLOW_UNMUTE: {
            return {
                ...state,
                allowUnMute: action.mode
            }
        }
        case Actions.SET_ACTIVE_SCREEN: {
            return {
                ...state,
                activeScreen: action.screen
            }
        }
        case Actions.SET_ACTIVE_SPEAKER: {
            return {
                ...state,
                activeSpeaker: action.speaker
            }
        }
        case Actions.SET_DISPLAY_NAME_CHANGE: {
            return {
                ...state,
                nameChange: action.mode
            }
        }
        case Actions.SET_CONNECTIVITY_MODE: {
            return {
                ...state,
                connectivityMode: action.mode
            }
        }
        case Actions.SET_MAX_TILE_LIMIT: {
            return {
                ...state,
                maxTileinslider: action.limit
            }
        }
        case Actions.RESET_MEET: {
            const {selectedAudio, selectedVideo, selectedOutput, audioPermission, videoPermission} = state;
            return {
                ...initialState,
                selectedAudio,
                selectedVideo,
                selectedOutput,
                audioPermission,
                videoPermission
            }
        }
        case Actions.SET_RECORD_STATE: {
            return {
                ...state,
                recordState: action.recordState
            }
        }
        case Actions.SET_LOCAL_UNMUTE_CHECK_STATE: {
            return {
                ...state,
                localUnmuteCheckBox: action.localUnmuteCheckBox
            }
        }
        case Actions.SET_WAITING_ROOM_LOADER: {
            return {
                ...state,
                waitingRoomLoader: action.mode
            }
        }

        case Actions.SET_DIDS: {
            return {
                ...state,
                didsList: action.didsList,
                primaryDid:action.primaryDid
            }
        }

        default: {
            return state;
        }
    }
}
export default MeetReducer;
