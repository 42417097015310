import React, {useState} from 'react';
import classes from './waitingModal.module.scss';
import {makeStyles} from "@material-ui/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Actions from '../../store/actions'
import {useDispatch} from "react-redux";
import cx from 'classnames';
import RegularButtons from "../HoolvaButtons/RegularButtons/regularButtons";
// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    bgColor:{
        backgroundColor: theme.background,
        color: theme.textColor1
    },
    inputText: {
        backgroundColor: theme.textboxBGColor,
        border: '1px solid ' + theme.textboxBorderColor,
        color: theme.textboxTextColor,
        "&:hover, &:focus, &:active": {
            border: "1px solid "+ theme.textboxActiveColor,
        },
        '&::placeholder': {
            color:theme.textboxPlaceholderColor
        }
    },
    border:{
        border: "1px solid " + theme.modalBorderColor,
    },
    text: {
        color:theme.textColor1
    },
    btnPrimary: {
        background: theme.button1BGColor,
        border: "1px solid "+ theme.button1BorderColor,
        color: theme.button1TextColor,
        "&:hover, &:focus": {
            background: theme.button1HoverBGColor
        }
    },
    btnSecondary: {
        background: theme.button2BGColor,
        border: "1px solid "+ theme.button2BorderColor,
        color: theme.button2TextColor,
        "&:hover, &:focus": {
            color: theme.button2HoverTextColor
        }
    },
    btnTernary: {
        background: theme.button3BGColor,
        border: "1px solid "+ theme.button3BGColor,
        color: theme.button3TextColor,
        "&:hover, &:focus": {
            border: "1px solid "+ theme.button3HoverBorderColor,
        }
    }
}))
const EndMeetingModal = (props: any) => {
    const {t,modalWaitingOpen,setWaitingModalHide,waitingModalHide,setModalWaitingOpen,waitingList, toggleChat, togglePeople, e2ee} = props;
    const theme = useStyles(props);
    const dispatch = useDispatch();
    const toggleChecked = (checked: any) => {
        setWaitingModalHide(checked);
    }
    const OpenPeople = () => {
        setModalWaitingOpen(false);
        togglePeople(true);
        toggleChat(false);
    }
    const handleApproveAll = () => {
        const approveList = waitingList.map((list: any) => {
          return { session_id: list.session_id, request_tid: list.request_tid, enter: true };
        });
        console.log("approvelist", approveList);
        dispatch(Actions.approveDenyParticipants("join_responses", approveList));
        dispatch(Actions.setWaitingRoomParticipants([]));
    };
    const handleDenyAll = () => {
        const denyList = waitingList.map((list: any) => {
            return { session_id: list.session_id, request_tid: list.request_tid, enter: false };
        });
        console.log("approvelist", denyList);
        dispatch(Actions.approveDenyParticipants("join_responses", denyList));
        dispatch(Actions.setWaitingRoomParticipants([]));
    };
    const Waiting = (key:any) => {
        if(key === 'AdmitAndOpen'){
            handleApproveAll();
            dispatch(Actions.changeJoinState('set_join_mode', 'open'));
        }else if(key === 'Admit'){
            handleApproveAll()
        }else{
            handleDenyAll();
        }
        setModalWaitingOpen(false);
    }
    return (
        <div>
            {modalWaitingOpen ?
                <div>
                    <div className={cx(classes.modalBG)}>
                    </div>
                    <div className={cx(classes.modal)}>
                        <div className={cx(classes.content,theme.bgColor, theme.border)}>
                            <div className={cx(classes.header,theme.bgColor)}>
                                <div className={cx(classes.padding8)}>
                                    <FontAwesomeIcon onClick={() => setModalWaitingOpen(false)} className={cx(classes.close)} icon={['fas', 'times']} />
                                    {waitingList.length>1 ? t('WaitingModal.waitingModalTitle'): t('WaitingModal.waitingModalTitle')}
                                </div>
                            </div>
                            <div className={cx(classes.body,theme.bgColor, theme.text)}>
                                <div className={cx(classes.left)}>
                                    <div className={cx(classes.msg)}>
                                        {waitingList.length>1 ? t('WaitingModal.waitingModalBody'): t('WaitingModal.waitingModalBody')}
                                        <label className={cx(classes.container)}>
                                            {t('WaitingModal.dontShow')}
                                            <input onChange={(event) =>toggleChecked(event.target.checked)}  checked={waitingModalHide} type="checkbox"/>
                                            <span className={cx(classes.checkmark)}></span>
                                        </label>
                                    </div>
                                    <div className={cx( classes.bottomButtons)}>
                                        <div className={cx(classes.space)}>
                                            <RegularButtons index={2} clicked={() => Waiting('Deny')} classname={[theme.btnTernary]}
                                            title={waitingList.length > 1 ? t('WaitingModal.Deny')+' ('+waitingList.length+')':t('WaitingModal.Deny')} />
                                        </div>
                                        <div className={cx(classes.space)}>
                                            <RegularButtons index={1} clicked={() => OpenPeople()} classname={[theme.btnTernary]}
                                            title={t('WaitingModal.KnowWho')} />
                                        </div>
                                        {!e2ee ? 
                                            <div className={cx(classes.space)}>
                                                <RegularButtons index={2} clicked={() => Waiting('AdmitAndOpen')} classname={[theme.btnTernary]}
                                                title={waitingList.length > 1 ? t('WaitingModal.Admit')+' ('+waitingList.length+')'+t('WaitingModal.Open'):t('WaitingModal.AdmitAndOpen')} />
                                            </div>
                                        :null
                                        }
                                        <div className={cx(classes.space)}>
                                            <RegularButtons index={2} clicked={() => Waiting('Admit')} classname={[theme.btnPrimary]}
                                            title={waitingList.length > 1 ? t('WaitingModal.Admit')+' ('+waitingList.length+')':t('WaitingModal.Admit')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            : null }
        </div>

    );
};

export default EndMeetingModal;
