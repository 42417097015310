import React, {lazy, Suspense} from 'react';
import {Route, Switch} from "react-router";
import routes from "./routes.json";
import Reconnecting from "./containers/reconnecting/reconnecting";
import FeedBack from "./containers/feedback/feedback";
import Meeting from "./containers/Meeting/meeting";
import waitingRoom from './containers/waitingRoom/waitingRoom';
import Account from "./containers/account/account";


const InfoPage = lazy(() => import('./containers/info/info'));
const HomePage = lazy(() => import('./containers/home/home'));
const LoginPage = lazy(() => import('./containers/Login/login'));
const ResetPassword = lazy(() => import('./containers/resetpassword/resetpassword'));
const JoinPage = lazy(() => import('./containers/JoinPage/joinPage'));
const SchedulePage = lazy(() => import('./containers/Schedule/schedule'));
const Join = lazy(() => import('./containers/join/join'));

const Routes = (props: any) => {
    const {verified} = props;
    return (
        <Suspense fallback={""}>
            <Switch>
                <Route path={routes.MEETING} component={verified ? Meeting : InfoPage}/>
                <Route path={routes.WAITINGROOM} component={verified ? waitingRoom: InfoPage} />
                <Route path={routes.JOIN} component={verified ? Join : InfoPage}/>
                <Route path={routes.JOINAMEETING} component={verified ? JoinPage : InfoPage}/>
                <Route path={routes.SCHEDULE} component={verified ? SchedulePage : InfoPage}/>
                <Route path={routes.LOGIN} component={verified ? LoginPage : InfoPage}/>
                <Route path={routes.RESET} component={ResetPassword}/>
                <Route path={routes.HOME} component={verified ? HomePage : InfoPage}/>
                <Route path={routes.INFO} component={InfoPage}/>
                <Route path={routes.FEEDBACK} component={FeedBack}/>
                <Route path={routes.RECONNECT} component={Reconnecting}/>
                <Route path={routes.ACCOUNT} component={Account}/>
                <Route path={routes.LANDINGPAGE} component={verified ? HomePage : InfoPage}/>
            </Switch>
        </Suspense>
    )
}
export default Routes;

