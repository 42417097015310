import React, {useState, useRef, useEffect, useCallback} from 'react';
import cx from 'classnames';
// @ts-ignore
import {Janus} from 'janus-gateway';
import {debounce} from 'lodash';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import classes from './meeting.module.scss';
import {makeStyles} from "@material-ui/styles";
import VideoSlider from "./videoSlider/videoSlider";
import SpeakerView from "./speakerView/speakerView";
import ConnectivityModal from "../../components/ConnectivityModal/connectivityModal";
import EndMeetingModal from "../../components/EndMeetingModal/endMeetingModal";
import WaitingModal from "../../components/WaitingModal/waitingModal";
import InviteModal from "../../components/InviteModal/inviteModal";
import SettingsModal from "../../components/SettingsModal/settings";
import IceReconnecting from "../../components/IceReconnecting/iceReconnecting";
import Menu from "./Menu/menu";
import TopBar from "./TopBar/topBar";
import {useSelector, useDispatch} from "react-redux";
import * as Actions from '../../store/actions';
import {
    optimizeBandWidthBasedOnView, participants, setCurrentSlider,
    setLocalShareMode,
    stopScreenShare,
    activeSpeakers
} from "../../store/actions";
import RightPanel from "./RightPanel/rightPanel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import routes from '../../routes.json';
import {history} from "../../App";
import Record from "../../components/Record/record";
import HoolvaConfirmMessage from "../../components/HoolvaConfirmMessage/hoolvaConfirmMessage";
import HoolvaAlertMessage from "../../components/HoolvaAlertMessage/hoolvaAlertMessage";
import HoolvaNotificationMessage from "../../components/HoolvaNotificationMessage/hoolvaNotificationMessage";
import InfoWindow from "../../components/InfoWindow/infoWindow";
import {withNamespaces} from "react-i18next";
import * as Config from '../../Config/config';
import LocalDb from "../../Services/dbService";


// @ts-ignore
const useStyles = makeStyles(({theme}) => ({
    MainBG:{
        backgroundColor:theme.videoRoomPrimaryColor
    },
    mainColor:{
        color:theme.button1BGColor,
    },
    containerDiv: {
        display: "flex",
        backgroundColor: theme.primaryColor,
        flexDirection: 'column',
        color: theme.textPrimary
    }
}))
let speechStream: any = null;
var timeout:any;
const Meeting = ({t}: any,props: any) => {

    const theme = useStyles(props);
    const dispatch = useDispatch();
    const mainContainer = useRef() as React.MutableRefObject<HTMLDivElement>;
    const fullScreen = useFullScreenHandle();

    /**
     * Component states here
     * */
    const [modalOpen, setModalOpen] = useState(false);
    const [inviteModal, setInviteModal] = useState(false);
    const [settingsModal, setSettingsModal] = useState(false);
    const [endModal, setEndModal] = useState(false);
    const [quickChat, setQuickChat] = useState(false);
    const [activeScreenShare, setActiveScreenShare] = useState(0);
    const [fullSStatus, setFullSStatus] = useState(false);
    const [callbackNumber, setCallbackNumber] = useState('');
    const [hideMenu, setHideMenu] = useState(true);
    const [shortMenuOpt, setShortMenuOpt] = useState(false);
    const [shortMenu, setShortMenu] = useState(false);
    const [infoBar, setInfoBar] = useState(false);
    const [showSlowLink, setShowSlowLink] = useState(false);
    const [recordModal, setRecordModal] = useState(false);
    const [recordFlag, setRecordFlag] = useState('');
    /**
     * Redux state here
     * */
    const {meetingId, displayName,password, meetingInfo, room_uuid} = useSelector((state:any)=>state.Hoolva);
    const {displayLayout} = useSelector((state:any)=>state.Settings);
    const {notifyMessage} = useSelector((state:any)=>state.Notify);
    const {audioRoomHandle, connectionLost} = useSelector((state:any)=>state.AudioRoom);
    const {audio, video, speechMode, publishersList, stateUpdate, chat, people, hoolva,
        chatNotificationCount,iceState, confirmData, raiseHand, publisherHandle, connectivityMode,
        feedback, time, allowUnMute, activeScreen, activeSpeaker, nameChange, currentSlider, maxTileinslider,
        recordState, localUnmuteCheckBox,
        waitingList, joinMode, showWaitingModal, preventWaitingModal,
        cloudRecordingStatus,localCloudRecordingStatus,disableVideoButton,
        didsList, primaryDid} = useSelector((state:any)=>state.Meet);
    const {recordStatus} = useSelector((state: any) => state.Record);
    const {localScreenShareMode, remoteScreenShareMode,
        screenSharePublisherHandle, screenShareParticipants, screenShareButtonState} = useSelector((state:any)=>state.ScreenShare);
    const goFull = (status:boolean) => {
        status ? setFullSStatus(true): setFullSStatus(false);
        status ? fullScreen.enter() : fullScreen.exit();
    }
    const reportChange = useCallback((state, handle) => {
        setFullSStatus(state);
    }, []);
    useEffect(() => {
        console.log("meeting info in useEffect", meetingInfo, localCloudRecordingStatus)
        if(meetingInfo.auto_record && localCloudRecordingStatus === 'start') {
            setRecordFlag('C');
        }
    },[localCloudRecordingStatus])

    useEffect(() => {

        if(meetingId === '' || hoolva === null) {
            history.push(routes.HOME);
        }
        navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
        return(() => {
            navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
        })

    }, []);

    useEffect(() => {
        let timer: any = null;
        if (connectionLost >= 25) {
            setShowSlowLink(true);
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            timer = setTimeout(() => {
                setShowSlowLink(false);
                dispatch(Actions.setConnectionLost(0));
            }, 5000);
        }
    }, [connectionLost])
    const handleExit = (confirmData:any) => {
        const data={
            open:false
         }
         dispatch(Actions.setConfirmData(data));
         if(!(confirmData?.key === 'almost_duration_notification')){
            dispatch(Actions.endMeeting(null, true));
         }
         
        //  history.push(routes.HOME);
       }

    useEffect(() => {
        if(document.getElementById('mixedAudio')) {
            // @ts-ignore
            if (document.getElementById('mixedAudio').srcObject === null) {
                if(audioRoomHandle?.webrtcStuff?.remoteStream) {
                    Janus.attachMediaStream(document.getElementById('mixedAudio'), audioRoomHandle.webrtcStuff.remoteStream);
                }
            }
        }
    })

    // useEffect(() => {
    //     dispatch(Actions.optimizeBandWidthBasedOnSimulCast());
    // }, [currentSlider, activeScreen, participants.filter(participant => {
    //     return participant.removed !== true && participant.video === 'unmuted' && !participant.detached;
    // }).length, screenShareParticipants.filter((screen: any) => {
    //     return screen.removed !== true
    // }).length, activeSpeakers.length])



    useEffect(() => {

            initBandWidthOptimization();

        return(() => {
            initBandWidthOptimization.cancel();
        })
    }, [currentSlider]);
    const initBandWidthOptimization = debounce(() => {
        console.log('debouncing current slider', currentSlider);
        if(Config.configuration.bandWidthOptimization && !meetingInfo.audio_bridge) {
                dispatch(Actions.optimizeBandWidthBasedOnView());

        } else if(meetingInfo.audio_bridge) {
            console.log('subscribeVideoRoomBasedOnView calling from slide change');
                dispatch(Actions.subscribeVideoRoomBasedOnView());

        }
    }, 500)
    // useEffect(() => {
    //     if(waitingList.length>0 && meetingInfo.host && !waitingModalHide && !people) {
    //         setModalWaitingOpen(true);
    //     }
    // }, [waitingList]);

    useEffect(() => {
        console.log("screenShareParticipantList",screenShareParticipants);
        const screenShareParticipantList = screenShareParticipants.filter((participant: any) => {
            return participant.removed !== true;
        })
        if(screenShareParticipantList.length>0){
            setActiveScreenShare(screenShareParticipantList[screenShareParticipantList.length-1].rfid)
        }

    }, [screenShareParticipants]);
    // useEffect(() => {
    //     if(remoteScreenShareMode){
    //         setActiveScreen(2)
    //     }
    // }, [remoteScreenShareMode]);

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener('blur', onBlur);
        return(() => {
            window.removeEventListener("focus", onFocus);
            window.addEventListener('blur', onBlur);
        })
    },[]);

    const handleDeviceChange = debounce(async event => {
        console.log("device change event Meeting page");
        dispatch(Actions.handleDeviceChange("meeting"));
    }, 700)


    const onFocus = () => {
        console.log('on focused');
        dispatch(Actions.setWindowMode(true));
    }
    const onBlur = () => {
        console.log('on blured');
        dispatch(Actions.setWindowMode(false));
    }

    useEffect(() => {
        let isSubscribed = true;
        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        }).then(stream => {
            speechStream = stream;
            if(isSubscribed) {
                dispatch(Actions.speechDetection(speechStream));
            }

        });
        return(() => {
            isSubscribed = false;
            if (speechStream) {
                speechStream.getTracks().forEach(function(track: MediaStreamTrack) {
                    track.stop();
                });
            }
        })

    }, []);
    window.onmousemove = function(){

            setHideMenu(false);

        if (timeout !== undefined) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(()=>{setHideMenu(true)},6000);
    }
    const handelChooseComputerAudio = () => {
        console.log('choose computer Auth', audio, video);
        // dispatch(Actions.joinAndConfigure(audio, video, false))
    }
    const toggleFeedBack = (mode: boolean) => {
        dispatch(Actions.setFeedBack(mode));
    }
    const handleEndMeeting = async () => {
        setEndModal(false);
        await dispatch(Actions.setCurrentSlider(0));
        if(recordState){
            dispatch(Actions.sendRecordRequest('configure', false));
            dispatch(Actions.setRecordState(false));
        }
        dispatch(Actions.endMeeting(null));
    }
    const setModalWaitingOpen = (mode: boolean) => {
        dispatch(Actions.setShowWaitingModal(mode));
    }
    const setWaitingModalHide = (mode: boolean) => {
        dispatch(Actions.preventShowWaitingModal(mode));
    }
    const handleEndForAll = async () => {
        setEndModal(false);
        await dispatch(Actions.setCurrentSlider(0));
        if(recordState){
            dispatch(Actions.sendRecordRequest('configure', false));
            dispatch(Actions.setRecordState(false));
        }
        dispatch(Actions.sendEndMeetingRequest('end_meeting_request'));
        console.log('endFor all');
    }
    const handleChangeDisplayName = (name: string) => {
        dispatch(Actions.sendDisplayNameChangeRequest('change_display', name));
    }
    const handleReconnectAudio = (Status: boolean) => {
        setModalOpen(Status);
    };

    const handleCallBack = (flag: string) => {
        if(flag === 'callback'){
            console.log('init Call', callbackNumber);
            dispatch(Actions.sendCallBackRequest('callback_request', callbackNumber));
            flag = 'phone';
        }
        flag = flag === 'computer' ? '': flag;
        if(flag !== 'computer'){
            if(meetingInfo.audio_bridge) {
                dispatch(Actions.toggleMixedAudioMute(false));
            } else {
                dispatch(Actions.toggleMute(false));
            }

        }
        dispatch(Actions.setConnectivityMode(flag));
        setModalOpen(false);
    }
    const handleSetQuickChat = (Status: boolean) => {
        setQuickChat(Status);
        setInfoBar(false);
        setShortMenu(false);
    };

    const handleScreenShare = async () => {
       await dispatch(Actions.setScreenShareButtonState(false));
        dispatch(Actions.initScreenShare());

    }
    const handleStopScreenShare = () => {
        dispatch(setLocalShareMode(false));
        const leaveRequest = { request: 'leave' };
        if(screenSharePublisherHandle) {
            screenSharePublisherHandle.send({ message: leaveRequest });
            // screenSharePublisherHandle.hangup();
            // screenSharePublisherHandle.detach();
        }
    }


    const toggleActiveScreen = async (mode: any) => {
        if(mode === 1) {
            await dispatch(Actions.setMaxTileLimit(Config.configuration.maxTileLimit));
        } else {
            await dispatch(Actions.setMaxTileLimit(Math.floor((window.innerHeight - 200) / 135)));
        }
        await debounceActiveScreen(mode)
    }
    const debounceActiveScreen = debounce(async mode => {
        console.log('debouncing....');
        dispatch(Actions.setActiveScreen(mode));
        if(Config.configuration.bandWidthOptimization && !meetingInfo.audio_bridge) {
            dispatch(optimizeBandWidthBasedOnView());
        } else if(Config.configuration.bandWidthOptimization && meetingInfo.audio_bridge) {
            console.log('subscribeVideoRoomBasedOnView calling from switching to active speaker');
            dispatch(Actions.subscribeVideoRoomBasedOnView());
        }
    }, 500)

    const handleMuteUnmuteAudio = (mode: boolean) => {
        if(meetingInfo.audio_bridge) {
          dispatch(Actions.toggleMixedAudioMute(mode))
        } else {
            dispatch(Actions.toggleMute(mode));
        }

    }

    const handleToggleVideo = (mode: boolean) => {
        dispatch(Actions.toggleVideo(mode));
    }
    const handleToggleFluid = (mode: boolean) => {
        const dbStore = LocalDb.loadLocalDb();
        dispatch(Actions.setLayout(mode ? 'float':'sticky'));
        LocalDb.getDisplaySettings(dbStore, (data: any) => {
            if(data.data.length === 0) {
                LocalDb.setDisplaySettings(dbStore, {theme: Config.configuration.theme,
                    layout:Config.configuration.screenLayout,
                    language:Config.configuration.language,
                    dynamicBG:Config.configuration.dynamicBackgrounds,
                    sliderLength:Config.configuration.maxTileLimit,
                    type: 'display'}, (data: any) => {
                    console.log('data from local db insert Layout', data);
                });
            }else{
                console.log('DBStore GetData', data);
                LocalDb.updateDisplaySettings(dbStore, {theme: data.data[0].theme, layout:mode ? 'float':'sticky',language:data.data[0].language, dynamicBG:data.data[0].dynamicBG, sliderLength:data.data[0].sliderLength, _id:data.data[0]._id}, (data: any) => {
                    console.log('data from local db update Layout', data);
                })
            }
        });
    }
    const handleToggleChat = (mode: boolean) => {
        // setChat(mode);
        if(mode) {
            dispatch(Actions.setChatNotification(0));
        }
        dispatch(Actions.setChatMode(mode));
    }
    const handleTogglePeople = (mode: boolean) => {
       // setTogglePeople(mode);
        dispatch(Actions.setPeopleTab(mode))
    }

    const handleCloseConfirmModal = (type: string) => {
        dispatch(Actions.setConfirmMessage(type, false, {}))
    }

    const handleConfirm = (type: string, data: any) => {
        if(type === 'unmuteAudio') {
            if(meetingInfo.audio_bridge) {
                dispatch(Actions.toggleMixedAudioMute(true));
            } else {
                dispatch(Actions.toggleMute(true));
            }

        } else if (type === 'unmuteVideo') {
            dispatch(Actions.toggleVideo(true));
        } else if(type === 'kick') {
          dispatch(Actions.sendKickMessage(data.id));
        }
        dispatch(Actions.setConfirmMessage(type, false, {}))
    }

    const sendRequestRaiseHand = () => {
        dispatch(Actions.sendRaiseHandRequest('raisehand_request', +publisherHandle.rfid ))
    }
    const sendRequestLowerHand = () => {
        dispatch(Actions.sendRaiseHandRequest('lowerhand_request', +publisherHandle.rfid))
    }


    return(
        <FullScreen
        handle={fullScreen}
        onChange={reportChange}
        >
            <div onClick={() =>handleSetQuickChat(false)} ref={mainContainer} className={cx(classes.hoolva, theme.MainBG)}>
                 {/* { !hideMenu  || displayLayout === 'float' ? */}
                <TopBar localCloudRecordingStatus={localCloudRecordingStatus} setInfoBar={setInfoBar} infoBar={infoBar} recordStatus={recordStatus} talking={publishersList.filter((participant: any) => {
                    return participant.talking === true && !participant.isPublisher && participant.removed !== true;
                })} shortMenuOpt={shortMenuOpt} joinMode={joinMode} meetingInfo={meetingInfo}  ToggleFullScreen={goFull} fullSStatus={fullSStatus} chat={chat} openInvite={setInviteModal}
                        people={people} cloudRecordingStatus={cloudRecordingStatus} hideMenu={!hideMenu  || displayLayout === 'float' ? true: false} fluid={displayLayout === 'float' ? true : false}
                        iceState={iceState} remoteScreenShareMode={remoteScreenShareMode} setActiveScreen={toggleActiveScreen} activeScreen={activeScreen}
                        toggleFluid={handleToggleFluid} screenShareParticipantList={screenShareParticipants.filter((participant: any) => {
                            return participant.removed !== true;
                        })} time={time} activeScreenShare={activeScreenShare} setActiveScreenShare={setActiveScreenShare} t={t}/>
                {/* : null} */}

                <div className={cx(classes.flex)}>
                    {showSlowLink ? (
                        <div className={classes.slowInternet}>
                            <span>
                               {connectionLost === 777 ? 'Poor network detected' : 'Poor connectivity'}
                            </span>
                        </div>
                    ): null}

                    <div className={cx(classes.content)}>


                        <div className={cx(activeScreen === 2 && fullSStatus ? classes.hideElement:null)}>
                            <VideoSlider
                                participantList={publishersList.filter((participant: any) => {
                                    return participant.removed !== true;
                                })}
                                currentSlider={currentSlider}
                                setShortMenuOpt={setShortMenuOpt}
                                activeScreen={activeScreen}
                                fluid={displayLayout === 'float' ? true : false}
                                connectivityMode={connectivityMode}
                                stateUpdate={stateUpdate}
                                chat={chat}
                                people={people}
                                maxTileinslider={maxTileinslider}
                            />
                        </div>

                         <div className={cx(activeScreen === 1  ? classes.hideElement : chat || people ? classes.SpeakerChatView:classes.SpeakerView, fullSStatus ? classes.leftSpacing:null)}>
                            <SpeakerView
                                chat={chat}
                                people={people}
                                fluid={displayLayout === 'float' ? true : false}
                                activeScreenShare={activeScreenShare}
                                fullSStatus={fullSStatus}
                                activeSpeaker={activeSpeaker}
                                participantList={publishersList.filter((participant: any) => {
                                    return participant.removed !== true;
                                })}
                                screenShareParticipantList={screenShareParticipants.filter((participant: any) => {
                                    return participant.removed !== true;
                                })}/>
                        </div>
                    </div>
                    {chat || people?
                    <RightPanel
                        chat={chat}
                        t={t}
                        activeScreen={activeScreen}
                        people={people}
                        disableVideoButton={disableVideoButton}
                        meetingInfo={meetingInfo}
                        room_uuid={room_uuid}
                        localUnmuteCheckBox={localUnmuteCheckBox}
                        toggleChat={handleToggleChat}
                        togglePeople={handleTogglePeople}
                        raiseHand={raiseHand}
                        openInvite={setInviteModal}
                        requestLowerHand={sendRequestLowerHand}
                        requestRaiseHand={sendRequestRaiseHand}
                        participantList={publishersList.filter((participant: any) => {
                            return participant.removed !== true;
                        })}
                        waitingList={waitingList}
                        allowUnMute={allowUnMute}
                        connectivityMode={connectivityMode}
                        currentSlider={currentSlider}
                        maxTileinslider={maxTileinslider}
                    /> : null}
                </div>
                { !hideMenu || displayLayout === 'float'?
                <Menu
                    endMeeting={handleEndMeeting}
                    reconnectAudio={handleReconnectAudio}
                    initScreenShare={handleScreenShare}
                    toggleAudio={handleMuteUnmuteAudio}
                    toggleVideo={handleToggleVideo}
                    toggleChat={handleToggleChat}
                    togglePeople={handleTogglePeople}
                    screenShareButtonState={screenShareButtonState}
                    localScreenShareMode={localScreenShareMode}
                    stopScreenShare={handleStopScreenShare}
                    toggleQuickChat={handleSetQuickChat}
                    connectivityMode={connectivityMode}
                    quickChat={quickChat}
                    audio={audio}
                    activeScreen={activeScreen}
                    chat={chat}
                    recordFlag={recordFlag}
                    setRecordFlag={setRecordFlag}
                    people={people}
                    recordState={recordState}
                    meetingInfo={meetingInfo}
                    fluid={displayLayout === 'float' ? true : false}
                    video={video}
                    setSettingsModal={setSettingsModal}
                    setEndModal={setEndModal}
                    chatNotificationCount={chatNotificationCount}
                    participantList={publishersList.filter((participant: any) => {
                        return participant.removed !== true;
                    })}
                    setRecordModal={setRecordModal}
                    fullSStatus={fullSStatus}
                    raiseHand={raiseHand}
                    localCloudRecordingStatus={localCloudRecordingStatus}
                    requestLowerHand={sendRequestLowerHand}
                    requestRaiseHand={sendRequestRaiseHand}
                    allowUnMute={allowUnMute}
                    shortMenuOpt={shortMenuOpt}
                    shortMenu={shortMenu}
                    setShortMenu={setShortMenu}
                    t={t}
                />
                : null}
                {/* {speechMode && !audio ?
                    <div className={cx(classes.speechDetection)}>
                        <span style={{color:'white'}}>
                            <FontAwesomeIcon className={theme.mainColor} icon={['fas', 'microphone-slash']} />
                            {t('meetingPage.youAreOnMute')}
                        </span>
                    </div>
                :  null} */}
                { iceState === 'failed' ?
                    <IceReconnecting t={t}/> : null
                }
                {notifyMessage !== '' ? <HoolvaNotificationMessage message={notifyMessage} /> : null}

                <HoolvaConfirmMessage open={confirmData.open} t={t} title={confirmData.title}
                                        message={confirmData.message} type={confirmData.type}
                                      data={confirmData.data} mode={confirmData?.mode ? true: false}
                                        cancel={handleCloseConfirmModal}
                                        confirm={handleConfirm}/>
                <InviteModal setInviteModal={setInviteModal} modalOpen={inviteModal}
                             meetingId={meetingId} password={password} meetingInfo={meetingInfo}
                             didsList={didsList} t={t} />
                <SettingsModal setSettingsModal={setSettingsModal} settingsModal={settingsModal}
                               displayName={displayName} handleToggleFluid={handleToggleFluid} nameChange={nameChange}
                               changeDisplayName={handleChangeDisplayName} t={t}/>
                <EndMeetingModal endMeeting={handleEndMeeting} setEndModal={setEndModal} feedback={feedback}
                                 setFeedBack={toggleFeedBack} t={t}
                                 modalOpen={endModal} meetingInfo={meetingInfo} endForAll={handleEndForAll} />
                <WaitingModal toggleChat={handleToggleChat} e2ee={meetingInfo?.e2ee}
                        togglePeople={handleTogglePeople} waitingList={waitingList} setWaitingModalHide={setWaitingModalHide} waitingModalHide={preventWaitingModal} modalWaitingOpen={showWaitingModal} setModalWaitingOpen={setModalWaitingOpen} t={t} />
                <Record recordStatus={recordStatus} t={t} meetingInfo={meetingInfo} recordFlag={recordFlag} setRecordFlag={setRecordFlag} recordModal={recordModal} setRecordModal={setRecordModal}></Record>
                <ConnectivityModal
                    t={t}
                        modalOpen={modalOpen}
                        connectivityMode={connectivityMode}
                        toggle={handleReconnectAudio}
                        handleCallBack={handleCallBack}
                        setCallbackNumber={setCallbackNumber}
                        didsList={didsList}
                        primaryDid = {primaryDid}

                        meetingInfo={meetingInfo}
                        // chooseAudio={handelChooseComputerAudio}
                        // choosePhone={handleChoosePhone}
                        // meetingId={meetingId}
                        // selectedMode={selectedMode}
                        // handleClose={handleConnectivityModalClose}
                    />
                    <InfoWindow t={t} infoBar={infoBar}  meetingInfo={meetingInfo}
                    participantList={publishersList.filter((participant: any) => {
                        return participant.removed !== true && participant.host === true;
                    })}></InfoWindow>
                <audio id="mixedAudio" autoPlay={true} muted={connectivityMode === 'phone'}></audio>
                <FontAwesomeIcon style={{display: "none"}} id="hostAvathar"
                    icon={["fad", "user-crown"]}
                    className={classes.crown}
                />
                <FontAwesomeIcon
                    style={{display: "none"}}
                    id="userMute"
                    icon={["fas", "microphone-slash"]}
                    />
                <FontAwesomeIcon
                    style={{display: "none"}}
                    id="videoSlash"
                    icon={["fas", "video-slash"]}
                />
                {/* <HoolvaConfirmMessage open={confirmData.open} title={confirmData.title}
                                        message={confirmData.message}
                                        cancel={handleExit}
                                        confirm={handleExit}/> */}
                <HoolvaAlertMessage open={confirmData.open} t={t} mode={confirmData?.mode ? true: false} title={confirmData.title} message={confirmData.message} confirm={() =>handleExit(confirmData)}/>
                <FontAwesomeIcon style={{display: "none"}} id="userAvathar" icon={["fas", "user"]} />
                <canvas id="myCanvas" width="640" height="360" style={{display: 'none'}}></canvas>
            </div>
        </FullScreen>
    )
}
export default withNamespaces()(Meeting as any);
