import * as Actions from "../actions";

const initialState: any = {
    upcomingMeetings: [],
    activeMeeting: null,
    activeScheduleMeeting:null,
    meetingListLength:0,
    personalMeeting:[],
    openMeetings:[],
    MeetingInvite:''
};
const ScheduleReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Actions.SET_UPCOMING_MEETING : {
            return {
                ...state,
                upcomingMeetings: action.meetings
            }
        }
        case Actions.SET_ACTIVE_MEETING: {
            return {
                ...state,
                activeMeeting: action.meeting
            }
        }
        case Actions.SET_OPEN_MEETING: {
            return {
                ...state,
                openMeetings: action.openMeetings
            }
        }
        case Actions.SET_ACTIVE_SCHEDULE_MEETING: {
            return {
                ...state,
                activeScheduleMeeting: action.meeting
            }
        }
        case Actions.SET_MEETING_LIST_LENGTH: {
            return {
                ...state,
                meetingListLength: action.meetingListLength
            }
        }
        case Actions.SET_PERSONAL_MEETING: {
            return {
                ...state,
                personalMeeting: action.personalMeeting
            }
        }
        case Actions.SET_MEETING_INVITE: {
            return {
                ...state,
                MeetingInvite: action.MeetingInvite
            }
        }
        
        default: {
            return state;
        }
    }
}
export default ScheduleReducer
