import React from "react";
import cx from "classnames";
import classes from "./account.module.scss";
import FadeIn from "react-fade-in";
import * as Config from "../../Config/config";
import { makeStyles } from "@material-ui/styles";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router";
import * as Actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import LocalDb from "../../Services/dbService";
import { history } from "../../App";
import routes from "../../routes.json";
// @ts-ignore
const useStyles = makeStyles(({ theme }) => ({
  content: {
    backgroundColor: theme.background,
    color: theme.textColor1,
    border:'1px solid '+ theme.iconColor,
  },
  line:{
    borderBottom:'1px solid '+ theme.iconColor,
  },
  box:{
    backgroundColor:theme.textColor1,
    color:theme.textColor3
  },
  btnPrimary: {
    background: theme.button1BGColor,
    border: "1px solid " + theme.button1BorderColor,
    color: theme.button1TextColor,
    "&:hover, &:focus": {
      background: theme.button1HoverBGColor,
      border: "1px solid " + theme.button1HoverBGColor,
    },
  }
}));
const Account = ({ t }: any, props: any) => {
  const theme = useStyles(props);
  const dispatch = useDispatch();
  const location = useLocation();
  // @ts-ignore
  const { provider, token, pic, rememberMe, login, name, email } = location.state;
  // @ts-ignore
  //  console.log('provider', state.provider, state.token);
  const { dbStore } = useSelector((state: any) => state.Auth);
  const meetingId = useSelector((state: any) => state.Hoolva.meetingId);
  const handleCreateAccount = async () => {
    // @ts-ignore
    console.log("token", token);
    const data = {
      provider,
      token_id: token,
    };

    const ssoSignUp = await dispatch(Actions.doSSOProviderSignUp(data));
    //@ts-ignore
    if (ssoSignUp.status === 200) {
      //@ts-ignore
      const loginInfo = ssoSignUp.data;
      loginInfo.img = pic;
      loginInfo.type = "auth";
      loginInfo.srtf = "";
      loginInfo.sso = provider;
      console.log("loginInfo",loginInfo)
      if (rememberMe) {
        LocalDb.setAuthInfo(dbStore, loginInfo, (data: any) => {
          dispatch(Actions.setLoginInfo(loginInfo));
          //@ts-ignore
          dispatch(Actions.setDispalyName(ssoSignUp.data.firstname + " " + ssoSignUp.data.lastname));
          if (login) {
            const meetingInfo = {
              id: +meetingId,
            };
            dispatch(Actions.checkAudioVideoPermission());
            const response: any = dispatch(Actions.getMeetingInfo(meetingInfo));
          } else {
            history.push(routes.HOME);
          }
        });
      } else {
        //@ts-ignore
        dispatch(Actions.setLoginInfo(ssoSignUp.data));
        //@ts-ignore
        dispatch(Actions.setDispalyName(loginResponse.data.firstname + " " + loginResponse.data.lastname));
        if (login) {
          const meetingInfo = {
            id: +meetingId,
          };
          dispatch(Actions.checkAudioVideoPermission());
          const response: any = dispatch(Actions.getMeetingInfo(meetingInfo));
        } else {
          history.push(routes.HOME);
        }
      }
    }
  };
  return (
    <FadeIn>
      <div className={cx(classes.hoolva)}>
        <div className={cx(classes.main, classes.flex)}>
          <div className={cx(classes.content, theme.content)}>
            <div className={cx(classes.heading)}>Welcome to {Config.configuration.brandName}</div>
            <div className={cx(classes.subtitle,theme.line)}>
              Create your {Config.configuration.brandName} account with your {provider} account
            </div>
            <div className={cx(classes.Info)}>
              <div className={cx(classes.img, theme.box)}>
              <img  style={{width: 35}} src={pic} />
              </div>
              <div className={cx(classes.right)}>
                <div className={cx(classes.Name)}>{name}</div>
                <div className={cx(classes.Email)}>{email}</div>
              </div>
            </div>
            <div className={cx(classes.Createbtn)}>
              <button
                className={cx(classes.connect, theme.btnPrimary)}
                onClick={handleCreateAccount}
              >
                Create Account
              </button>
            </div>
            <div className={cx(classes.terms)}>
              By submitting this form, you agree to the <a
                      className={cx(classes.aToButton)}
                      href={"https://hoolva.com/terms-and-conditions/"}
                      rel="noopener noreferrer"
                      target={"_blank"}
                    >
                      Terms of Service
                    </a>
            </div>
          </div>
          <div className={cx(classes.legal)}>
          CopyrightⒸ2021 {Config.configuration.brandName}. All rights  reserved.<br></br>
          <a
                      className={cx(classes.aToButton)}
                      href={"https://hoolva.com/privacy-policy/"}
                      rel="noopener noreferrer"
                      target={"_blank"}
                    >
                      Privacy & Legal Policies
                    </a>

        </div>
        </div>

      </div>
    </FadeIn>
  );
};

export default withNamespaces()(Account);
