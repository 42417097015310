import * as Config from "../../Config/config";
import React from "react";
import { useSelector } from "react-redux";


const Logo = (props: any) => {
    const {displayTheme, classname, topBar} = props;
    const {tenantInfoJSON} = useSelector((state:any)=>state.Settings);
    return (
        <img className={classname}
        src={topBar ? tenantInfoJSON?.logos?.favicon : displayTheme === "dark" ?  tenantInfoJSON?.logos?.logoWhite : tenantInfoJSON?.logos?.logoDark}
        />
    )
}
export default Logo;
